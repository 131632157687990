import React from "react";

import Mainpage from "./Mainpage";
import SecondComponent from "./SecondComponent";
import { useSelector } from "react-redux";
import ThirdComponent from "./ThirdComponent";

function FirstComponent() {
  const { page, role } = useSelector((state) => state.changePage);

  return (
    <>
      {page === "first" && <Mainpage />}

      {page === "second" && (
        <>
          {role === "student" || role === "teacher" ? (
            <SecondComponent />
          ) : null}
        </>
      )}

      {page === "third" && <ThirdComponent />}
    </>
  );
}

export default FirstComponent;
