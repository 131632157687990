import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/login/Login";
import {
  TeacherLayout,
  AdminLayout,
  StudentLayout,
  GStudentLayout,
  ParentLayout,
  SuperAdminLayout,
} from "../layout/Layout";
import AdminOverview from "../pages/admin/AdminOverview";
import TeacherOverview from "../pages/teacher/TeacherOverview";
import StudentOverview from "../pages/student/StudentOverview";
import AdminSignin from "../pages/admin/AdminSignin";
import AdminSignup from "../pages/admin/AdminSignup";
import TeacherSignin from "../pages/teacher/TeacherSignin";
import TeacherSignup from "../pages/teacher/TeacherSignup";
import StudentSignin from "../pages/student/StudentSignin";
import StudentSignup from "../pages/student/StudentSignup";
import Profile from "../pages/profile/Profile";
import AdminTeacher from "../pages/admin/AdminTeacher";
import AdminStudents from "../pages/admin/AdminStudents";
import AdminClasses from "../pages/admin/AdminClasses";
import AdminQuizzes from "../pages/admin/AdminQuizzes";
import StudentClasses from "../pages/student/StudentClasses";
import CreateQuiz from "../pages/teacher/CreateQuiz";
import ClassOverview from "../pages/teacher/ClassOverview";
import QuizResult from "../pages/teacher/QuizResult";
import ListOfQuizes from "../pages/teacher/ListOfQuizes";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import EndScreen from "./../pages/student/QuestionDisplayPage/EndScreen";
import Error404 from "../components/Error404";
import SpellingChoice from "../pages/teacher/QuizType/SpellingChoice";
import SnackBarComponent from "../components/SnackBarComponent";
import SetQuestionType from "../pages/teacher/SetQuestionType";
import QuizList from "../pages/student/QuizList";
import StartQuiz from "../pages/student/QuestionDisplayPage/StartQuiz";
import GStudentSignin from "../pages/student/GStudentSignin";
import GQuizList from "../pages/student/GQuizList";
import ViewResult from "../pages/student/ViewResult";
import GProfile from "../pages/profile/GProfile";
import GStudentOverview from "../pages/student/GStudentOverview";
import ResetPage from "../components/ResetPage";
import StudentList from "../pages/teacher/StudentList";
import QuizResultAdmin from "../pages/admin/QuizResultAdmin";
import ParentSignin from "../pages/parent/ParentSignin";
import ParentResult from "../pages/parent/ParentResult";
import ViewAnswers from "../components/teacher/ViewAnswers";
import ViewQuestion from "../components/admin/ViewQuestion";
import GStudentViewResult from "../pages/student/GStudentViewResult";
import StudentTransfer from "../components/StudentTransfer";
import SuperAdminSignin from "../pages/superAdmin/SuperAdminSignin";
import SuperAdminStudents from "../pages/superAdmin/SuperAdminStudents";
import SuperAdminTeacher from "../pages/superAdmin/SuperAdminTeacher";
import SuperAdminOverview from "../pages/superAdmin/SuperAdminOverview";
import SuperAdminAdmins from "../pages/superAdmin/SuperAdminAdmins";
import ChangeSuperPassword from "../components/superadmin/ChangeSuperPassword";
import Test from "../components/Test";
import LoginPage from "../pages/loginPage/LoginPage";
import Signup from "../pages/signup/Signup";
import Signin from "../pages/signin/Signin";
import SwitchComponent from "../pages/teacher/SwitchComponent";
import RequestComponent from "../pages/admin/RequestComponent";
import ScrollToTop from "./ScrollToTop";
import About from "../pages/login/about";
import Contact from "../pages/login/contact";
import CreateClass from "../pages/teacher/CreateClass";
// import UpdateAlert from "../components/Notification";

const Routers = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <SnackBarComponent />
        {/* <UpdateAlert /> */}
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="loginPage" element={<LoginPage />} />
          <Route path="end-screen" element={<EndScreen />} />
          <Route path="reset" element={<ResetPage />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="signup"
            element={
              <PublicRoute>
                <Signup />
              </PublicRoute>
            }
          />
          <Route
            path="signin"
            element={
              <PublicRoute>
                <Signin />
              </PublicRoute>
            }
          />
          <Route path="test" element={<Test />} />

          <Route
            path="quiz-question/:quiz_id/:type"
            element={<ViewQuestion />}
          />

          <Route path="view-answer/:id/:sid" element={<ViewAnswers />} />
          {/* Parent Auth Route */}
          <Route
            path="/parent"
            element={
              <PublicRoute>
                <ParentSignin />
              </PublicRoute>
            }
          />

          {/* Super Admin Auth Routes */}

          <Route
            path="/super"
            element={
              <PublicRoute>
                <SuperAdminSignin />
              </PublicRoute>
            }
          />

          {/* Admin Auth Routes */}

          <Route
            path="/admin"
            element={
              <PublicRoute>
                <AdminSignin />
              </PublicRoute>
            }
          />

          <Route
            path="/admin/signup"
            element={
              <PublicRoute>
                <AdminSignup />
              </PublicRoute>
            }
          />

          {/* Teacher Auth Routes */}
          <Route
            path="/teacher"
            element={
              <PublicRoute>
                <TeacherSignin />
              </PublicRoute>
            }
          />
          <Route
            path="/teacher/signup"
            element={
              <PublicRoute>
                <TeacherSignup />
              </PublicRoute>
            }
          />

          {/* Student Auth Routes */}
          <Route
            path="/student"
            element={
              <PublicRoute>
                <StudentSignin />
              </PublicRoute>
            }
          />
          <Route
            path="/gstudent"
            element={
              <PublicRoute>
                <GStudentSignin />
              </PublicRoute>
            }
          />
          <Route
            path="/student/signup"
            element={
              <PublicRoute>
                <StudentSignup />
              </PublicRoute>
            }
          />

          {/* Super Admin dashboard Routes */}

          <Route
            path="/dashboard/super"
            element={
              <ProtectedRoute allowRoles={["super"]}>
                <SuperAdminLayout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute allowRoles={["super"]}>
                  <SuperAdminOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="change-password"
              element={
                <ProtectedRoute allowRoles={["super"]}>
                  <ChangeSuperPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="admins"
              element={
                <ProtectedRoute allowRoles={["super"]}>
                  <SuperAdminAdmins />
                </ProtectedRoute>
              }
            />

            <Route
              path="teachers"
              element={
                <ProtectedRoute allowRoles={["super"]}>
                  <SuperAdminTeacher />
                </ProtectedRoute>
              }
            />

            <Route
              path="students"
              element={
                <ProtectedRoute allowRoles={["super"]}>
                  <SuperAdminStudents />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Admin dashboard Routes */}

          <Route
            path="/dashboard/admin"
            element={
              <ProtectedRoute allowRoles={["admin"]}>
                <AdminLayout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute allowRoles={["admin"]}>
                  <AdminOverview />
                </ProtectedRoute>
              }
            />
            <Route path="profile" element={<Profile type={"admin"} />} />
            <Route
              path="teachers"
              element={
                <ProtectedRoute allowRoles={["admin"]}>
                  <AdminTeacher />
                </ProtectedRoute>
              }
            />
            <Route
              path="students"
              element={
                <ProtectedRoute allowRoles={["admin"]}>
                  <AdminStudents />
                </ProtectedRoute>
              }
            />
            <Route
              path="requests"
              element={
                <ProtectedRoute allowRoles={["admin"]}>
                  <RequestComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="classes"
              element={
                <ProtectedRoute allowRoles={["admin"]}>
                  <AdminClasses />
                </ProtectedRoute>
              }
            />
            <Route
              path="quizzes"
              element={
                <ProtectedRoute allowRoles={["admin"]}>
                  <AdminQuizzes />
                </ProtectedRoute>
              }
            />
            <Route
              path="result"
              element={
                <ProtectedRoute allowRoles={["admin"]}>
                  <QuizResultAdmin />
                </ProtectedRoute>
              }
            />
            <Route
              path="student-transfer"
              element={
                <ProtectedRoute allowRoles={["admin"]}>
                  <StudentTransfer />
                </ProtectedRoute>
              }
            />

            {/* ADMIN TEACHER ROUTES START */}
            <Route
              path="teacher"
              element={
                <ProtectedRoute allowRoles={["admin"]}>
                  <TeacherOverview />
                </ProtectedRoute>
              }
            />
            <Route path="teacher/create-quiz" element={<CreateQuiz />} />
            <Route path="teacher/create-class" element={<CreateClass />} />
            <Route path="teacher/student-list" element={<StudentList />} />
            <Route
              path="teacher/student-transfer"
              element={<StudentTransfer />}
            />
            <Route path="teacher/classes" element={<ClassOverview />} />
            <Route path="teacher/quizzes" element={<ListOfQuizes />} />
            <Route path="teacher/result" element={<QuizResult />} />
            <Route path="teacher/quiz/:quiz_id" element={<SetQuestionType />} />
            <Route path="teacher/spelling" element={<SpellingChoice />} />

            {/* ADMIN TEACHER ROUTES END */}
          </Route>

          {/* Teacher dashboard Routes*/}
          {/* <Route element={<ProtectedRoute allowRoles={["teacher"]} />}> */}
          <Route
            path="/dashboard/teacher"
            element={
              <ProtectedRoute allowRoles={["teacher"]}>
                <TeacherLayout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute allowRoles={["teacher"]}>
                  <TeacherOverview />
                </ProtectedRoute>
              }
            />
            <Route path="profile" element={<Profile type={"teacher"} />} />
            <Route path="create-quiz" element={<CreateQuiz />} />
            <Route path="create-class" element={<CreateClass />} />
            <Route path="student-list" element={<StudentList />} />
            <Route path="student-transfer" element={<StudentTransfer />} />
            <Route path="classes" element={<ClassOverview />} />
            <Route path="quizzes" element={<ListOfQuizes />} />
            <Route path="result" element={<QuizResult />} />
            <Route path="quiz/:quiz_id" element={<SetQuestionType />} />
            <Route path="spelling" element={<SpellingChoice />} />
            <Route
              path="organisation"
              element={
                <ProtectedRoute allowRoles={["teacher"]}>
                  <SwitchComponent />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* </Route> */}

          {/* Parent Dashboard Route */}

          <Route
            path="/dashboard/parent"
            element={
              <ProtectedRoute allowRoles={["parent"]}>
                <ParentLayout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute allowRoles={["parent"]}>
                  <ParentResult />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Student dashboard Routes*/}

          <Route
            path="/dashboard/student"
            element={
              <ProtectedRoute allowRoles={["student"]}>
                <StudentLayout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute allowRoles={["student"]}>
                  <StudentOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute allowRoles={["student"]}>
                  <Profile type={"student"} />
                </ProtectedRoute>
              }
            />
            <Route
              path="results"
              element={
                <ProtectedRoute allowRoles={["student"]}>
                  <ViewResult />
                </ProtectedRoute>
              }
            />
            <Route
              path="class"
              element={
                <ProtectedRoute allowRoles={["student"]}>
                  <StudentClasses />
                </ProtectedRoute>
              }
            />
            <Route
              path="organisation"
              element={
                <ProtectedRoute allowRoles={["student"]}>
                  <SwitchComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="quiz"
              element={
                <ProtectedRoute allowRoles={["student"]}>
                  <QuizList />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Global Student */}
          <Route
            path="/dashboard/gstudent"
            element={
              <ProtectedRoute allowRoles={["gstudent"]}>
                <GStudentLayout />
              </ProtectedRoute>
            }
          >
            <Route
              index
              element={
                <ProtectedRoute allowRoles={["gstudent"]}>
                  <GStudentOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute allowRoles={["gstudent"]}>
                  <GProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="results"
              element={
                <ProtectedRoute allowRoles={["gstudent"]}>
                  <GStudentViewResult />
                </ProtectedRoute>
              }
            />
            <Route
              path="quiz"
              element={
                <ProtectedRoute allowRoles={["gstudent"]}>
                  <GQuizList />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* START QUIZ */}
          <Route
            path="/quiz/:quiz_id"
            element={
              <ProtectedRoute allowRoles={["student", "gstudent"]}>
                <StartQuiz />
              </ProtectedRoute>
            }
          />

          {/*NOT FOUND */}
          <Route path="/*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routers;
