import { Box, Button, TextField, Stack, Typography } from "@mui/material";
import logo from "../../images/edu-dark.png";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Api from "../../api/API";
import { useDispatch } from "react-redux";
import { setOpen } from "../../slices/StateManager";
import ReactLoader from "../../components/ReactLoader";
const ParentSignin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const API = new Api();
    setLoading(true);
    API.loginParent(e.target.email.value)
      .then((res) => {
        if (res.data.token !== null) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", res.data.role);
          setLoading(false);
          navigate("/dashboard/parent");
          dispatch(
            setOpen({ message: `Welcome back`, open: true, variant: "success" })
          );
        } else {
          setLoading(false);
          dispatch(
            setOpen({
              message: `Invalid Credentials`,
              open: true,
              variant: "error",
            })
          );
        }
      })
      .catch((error) => {
        setLoading(false);

        dispatch(
          setOpen({
            message: error.response
              ? error.response.data.message
              : error.message,
            open: true,
            variant: "error",
          })
        );
      });
  };
  return (
    <>
      {loading && <ReactLoader />}
      <motion.div
        initial={{ opacity: 0, x: -40 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1.5, type: "spring", stiffness: 100 }}
      >
        <Box
          sx={{
            p: " 3% 5% ",
            width: { xs: "80%", md: "40%" },
            margin: "12vh auto",
            background: "#F9F9F9",
            borderRadius: "10px",
            boxShadow: "0px 0px 3px 1px rgba(203, 203, 203, 1)",
          }}
        >
          <Stack direction={"column"} spacing={5}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <img src={logo} alt="edusupport" />

              <Typography>Sign In</Typography>
            </Stack>

            <form onSubmit={handleSubmit}>
              <TextField
                required
                type="email"
                fullWidth
                id="email"
                label="Email"
                variant="outlined"
              />

              <Box sx={{ m: "3% 0" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mb: 3, textTransform: "capitalize", float: "right" }}
                >
                  Signin
                </Button>
              </Box>
            </form>
          </Stack>
        </Box>
      </motion.div>
      <Box mt="-5vh" textAlign={"center"}>
        <Button
          onClick={() => {
            window.open(
              "https://firebasestorage.googleapis.com/v0/b/edu-support-f9e95.appspot.com/o/files%2FEduSupport%20Terms%20and%20Conditions.pdf?alt=media&token=0532a887-db08-4024-8309-69811ae16faf",
              "_blank"
            );
          }}
        >
          Terms & Conditions
        </Button>

        <Button
          onClick={() => {
            window.open(
              "https://firebasestorage.googleapis.com/v0/b/edu-support-f9e95.appspot.com/o/files%2FEduSupport%20Privacy%20Policy%20-%20rev.pdf?alt=media&token=5882655b-27e8-4e50-974d-fc0954d1243f"
            );
          }}
        >
          Privacy policy
        </Button>
      </Box>
      <Box textAlign={"center"}>
        <Button>Developed By Hi-Dev Solutions</Button>
      </Box>
    </>
  );
};

export default ParentSignin;
