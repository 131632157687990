import { Box, Typography } from "@mui/material";
import React from "react";

const OverviewCard = ({ icon, text, amount }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#1976d21a",
				display: "flex",
				maxWidth: { xs: "100%", lg: "50%" },
				p: "2% 4%",
				borderRadius: "15px",
				m: "2% 0",
			}}
		>
			<Box sx={{ alignSelf: "center" }}>{icon()}</Box>
			<Box
				sx={{
					flexGrow: 1,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Typography align="center" variant="h6" fontSize={15} color={"body"}>
					{text}
				</Typography>
				<Typography align="center" variant="h6" color={"body"}>
					{amount}
				</Typography>
			</Box>
		</Box>
	);
};

export default OverviewCard;
