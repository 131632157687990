import React from "react";

import {
  Paper,
  Modal,
  Backdrop,
  Fade,
  Stack,
  Typography,
  Box,
  Grid,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const EndScreen2 = ({ opened, setOpened, state }) => {
  let navigate = useNavigate();
  const role = localStorage.getItem("role");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    height: "100vh",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: "1% 0",
    overflowY: "scroll",
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opened}
        onClose={() => setOpened(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opened}>
          <Box sx={style}>
            <Grid container>
              <Grid item md={4}></Grid>
              <Grid item md={4} sx={{ mt: 10 }}>
                <Paper elevation={2}>
                  <Stack spacing={2} sx={{ p: 7 }}>
                    <Typography variant="h5" sx={{ textAlign: "center" }}>
                      Below Is Your Result!
                    </Typography>
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      Point:&nbsp;<b>{state?.point}</b> Points
                    </Typography>
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      Right Answers:&nbsp;<b>{state?.right_answers}</b>
                    </Typography>
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      Wrong Answers:&nbsp;<b>{state?.wrong_answers}</b>
                    </Typography>
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      Remark:&nbsp;<b>{state?.status}</b>
                    </Typography>
                    <Typography sx={{ textAlign: "center" }}>
                      Percentage:&nbsp;<b>{state?.percent}%</b>
                    </Typography>
                    <Typography sx={{ textAlign: "center" }}>
                      Time Taken:&nbsp;<b>{state?.time_taken}</b>
                    </Typography>

                    <Button
                      variant="contained"
                      onClick={() => {
                        if (role === "student") {
                          navigate("/dashboard/student");
                        } else if (role === "gstudent") {
                          navigate("/dashboard/gstudent");
                        }
                      }}
                    >
                      Click here to go homepage
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default EndScreen2;
