import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Divider, Stack, Typography } from "@mui/material";
import logo from "../../../images/edu-dark.png";
import { ArrowLeft } from "@mui/icons-material";
import StartQuizTwo from "./StartQuizTwo";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../api/API";
import { useDispatch } from "react-redux";
import { shuffleArray } from "../../../api/constant";
import ReactLoader from "../../../components/ReactLoader";

const StartQuiz = () => {
  const [next, setNext] = useState("one");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quiz_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [orname, setOrgName] = useState("Edusupport");
  const [quizData, setQuizData] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [status, setStatus] = useState(false);

  // const submit = () => {
  // 	const token = localStorage.getItem("token");
  // 	const time_taken = localStorage.getItem("time");
  // 	const type = localStorage.getItem("type");
  // 	let answers = JSON.parse(localStorage.getItem("answers"));
  // 	const API = new Api();

  // 	if (type === "normal") {
  // 		const values = JSON.parse(localStorage.getItem("values"));
  // 		values?.forEach((item) => {
  // 			if (Array.isArray(item?.options)) {
  // 				item.options = item?.options.filter((option) => option != null);
  // 			}
  // 		});
  // 		let answer = values.concat(answers);
  // 		const Arr1 = answer.filter((elem) => elem?.id !== "");
  // 		answers = Arr1.filter((elem) => elem?.options !== "");
  // 	}

  // 	setLoading(true);
  // 	API.scoreStudent(token, quiz_id, answers, time_taken)
  // 		.then((res) => {
  // 			setLoading(false);
  // 			localStorage.setItem("load", "false");
  // 			localStorage.removeItem("reload");
  // 			localStorage.removeItem("quiz_id");
  // 			localStorage.removeItem("quiz_name");
  // 			localStorage.removeItem("answers");
  // 			localStorage.removeItem("values");
  // 			localStorage.removeItem("type");
  // 			localStorage.removeItem("time");
  // 			dispatch(
  // 				setOpen({
  // 					message: res.data.message,
  // 					open: true,
  // 					variant: "success",
  // 				})
  // 			);
  // 			navigate("/end-screen", { state: { data: res.data.data } });
  // 		})
  // 		.catch((err) => {
  // 			setLoading(false);
  // 			localStorage.removeItem("reload");
  // 			localStorage.removeItem("quiz_id");
  // 			localStorage.removeItem("quiz_name");
  // 			localStorage.setItem("load", "false");
  // 			dispatch(
  // 				setOpen({
  // 					message: err.response.data.message,
  // 					open: true,
  // 					variant: "error",
  // 				})
  // 			);
  // 		});
  // };

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const reloaded = localStorage.getItem("reload");
    const id = localStorage.getItem("quiz_id");
    const n = localStorage.getItem("quiz_name");

    if (reloaded === "true" && id !== quiz_id) {
      alert(
        `You have a quiz to complete\nQuiz Name: ${n}.\nComplete the quiz before you can take another one`
      );
      if (role === "student") {
        navigate("/dashboard/student/quiz");
      } else if (role === "gstudent") {
        navigate("/dashboard/gstudent/quiz");
      }
    }

    if (role === "student") {
      API.getOrgNameStudent(token)
        .then((res) => {
          setOrgName(res.data.data.org_name);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          navigate(-1);
          // dispatch(
          //   setOpen({
          //     message: err.response.data.message,
          //     open: true,
          //     variant: "error",
          //   })
          // );
        });
    } else {
      setOrgName("Edusupport");
    }

    API.getStudentQuizData(token, quiz_id)
      .then((res) => {
        setQuizData(res.data.data);
        if (res.data.data.quiz_type === "spelling") {
          API.getSpellingQuestionsStudent(token, quiz_id)
            .then((res) => {
              setQuestions(res.data.data);
              setLoading2(false);
            })
            .catch((err) => {
              setLoading2(false);
              navigate(-1);
              // dispatch(
              //   setOpen({
              //     message: err.response.data.message,
              //     open: true,
              //     variant: "error",
              //   })
              // );
            });
        } else {
          API.getQuestionsStudent(token, quiz_id)
            .then((res) => {
              setQuestions(res.data.data);
              setLoading2(false);
            })
            .catch((err) => {
              setLoading2(false);
              navigate(-1);
              // dispatch(
              //   setOpen({
              //     message: err.response.data.message,
              //     open: true,
              //     variant: "error",
              //   })
              // );
            });
        }
      })
      .catch((err) => {
        navigate(-1);
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });

    API.getInStudentData(token)
      .then((res) => {
        setStudentData(res.data.data);
        setLoading1(false);
      })
      .catch((err) => {
        setLoading1(false);
        navigate(-1);
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });

    API.getResultStatus(token, quiz_id)
      .then((res) => {
        setStatus(res.data.data);
        setLoading1(false);
      })
      .catch((err) => {
        setLoading1(false);
        navigate(-1);
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
  }, [quiz_id, dispatch, navigate]);

  return (
    <>
      {next === "one" ? (
        <>
          {loading && loading1 && loading2 && <ReactLoader />}
          <Box sx={{ m: 6 }}>
            <Box textAlign={"left"}>
              <img src={logo} alt="logo" height={100} width={200} />
            </Box>

            <Grid container spacing={3} sx={{ mt: 7 }}>
              <Grid item md={4}>
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{
                    textAlign: "left",
                    mb: 3,

                    borderTop: "1px solid grey",
                    borderBottom: "1px solid grey",
                  }}
                >
                  <ArrowLeft /> Do you accept these terms?
                </Typography>
              </Grid>
              <Grid item md={4}></Grid>
              <Grid item md={4}>
                <Stack spacing={2}>
                  <Typography color="secondary">
                    {studentData
                      ? `${studentData.firstname} ${studentData.lastname}`
                      : ""}
                    &nbsp;(
                    {studentData ? studentData.email : ""})
                  </Typography>
                  <Stack spacing={5} direction={"row"}>
                    <Button
                      onClick={() => navigate(-1)}
                      variant="contained"
                      color="error"
                    >
                      No
                    </Button>

                    <Button
                      onClick={() => {
                        setNext("next");
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Yes
                    </Button>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item md={12}>
                <Divider />
              </Grid>
              <Grid item md={12}>
                <Box>
                  <p>Welcome to {orname} Examination.</p>
                  <br />

                  <p>By Starting an Examination, you confirm the Followings.</p>

                  <ul style={{ margin: "20px" }}>
                    <li>
                      You will comlete the examination alone and independently
                    </li>
                    <li>
                      you authorise {orname} to deploy tools to monitor your
                      behaviour during examination and any adult as deemed
                      neccessary.
                    </li>
                    <li>
                      you understand the time available to comlete the
                      examination is limited and can neither be interrupted or
                      extended.
                    </li>
                    <li>
                      {" "}
                      the examination questions and tasks are {orname} owned
                      intellectual property rights, and must not be copied,
                      written down,reproduced, or distributed to others in any
                      form.
                    </li>
                    <li>
                      In the event it is deemed that a member has seriously
                      infringed the examinations rules, any recognition the
                      member has been awarded will be invalidated and the member
                      will be excluded from the {orname} community
                    </li>
                    <li>
                      If the infringement is deemed minor, the member will be
                      assesed as failed and will need to retake the examination
                    </li>
                    <li>
                      infringement of copyright and any unlawful harm to
                      {orname} will be treated as Infringement
                    </li>
                    <li>
                      {orname} will have the final say in regards to fairness
                      and in deciding the severity of Infringement, the member
                      will be notified of any decision made by {orname}
                    </li>
                  </ul>
                  <br />

                  <p>
                    Should you encounter any issues during your examination,
                    Please contact or send us mail at{" "}
                    <a href="mailto:efosajoseph@gmail.com">
                      efosajoseph@gmail.com
                    </a>{" "}
                    and <a href="tel:+2348023537575">08023537575</a>
                  </p>
                  <br />

                  <p>Best Wishes!</p>
                  <br />
                </Box>
              </Grid>

              <Grid item md={12}>
                <Divider />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <StartQuizTwo
            questions={shuffleArray(questions)}
            quizData={quizData}
            studentData={studentData}
            status={status}
          />
        </>
      )}
    </>
  );
};

export default StartQuiz;
