import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { Button } from "@mui/material";
import { useState } from "react";

export default function TransferTable({
  title,
  rows,
  column,
  transferStudent,
  setToClassName,
  setName,
}) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    const newSelected = rows.map((n) => n?.student_id);
    setSelected(newSelected);
    // 	return;
    // }
    // setSelected([]);
  };

  function EnhancedTableToolbar({ title, numSelected }) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : null}

        {numSelected > 0 ? (
          <Tooltip title="Transfer Students">
            <Button
              variant={"contained"}
              onClick={() => {
                transferStudent(selected);
                setName("");
                setToClassName("");
                setSelected([]);
              }}
            >
              Transfer
            </Button>
          </Tooltip>
        ) : null}
      </Toolbar>
    );
  }

  function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
    return (
      <>
        <TableCell padding="checkbox">
          {/* <Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
					/> */}
          <Button
            size="small"
            variant="contained"
            sx={{ fontSize: 10 }}
            color={
              numSelected <= 0
                ? "primary"
                : numSelected >= rowCount
                ? "success"
                : "secondary"
            }
            onClick={() => {
              if (numSelected < rowCount) {
                onSelectAllClick();
              } else {
                setSelected([]);
              }
            }}
          >
            Check All
          </Button>
        </TableCell>
      </>
    );
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const errorText =
    "No data to display or Something could be wrong with your internet connection.";
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Typography
          sx={{ flex: "1 1 100%", p: "20px 10px" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>

        <Typography variant="subtitle1" component="div" sx={{ ml: "10px" }}>
          Click on any of the Students below to view other options and details
        </Typography>

        <EnhancedTableToolbar title={title} numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow>
                <EnhancedTableHead
                  numSelected={selected?.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows?.length}
                />
                {column?.map((col, i) => (
                  <TableCell key={i} align={"left"} padding={"normal"}>
                    <TableSortLabel>{col}</TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows !== null && rows?.length !== 0 ? (
                rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((d, index) => {
                    const isItemSelected = isSelected(d?.student_id);

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, d?.student_id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={isItemSelected} />
                        </TableCell>
                        <TableCell padding="normal" align="left">
                          {d.firstname}
                        </TableCell>
                        <TableCell padding="normal" align="left">
                          {d.lastname}
                        </TableCell>
                        <TableCell padding="normal" align="left">
                          {d.username}
                        </TableCell>
                        <TableCell padding="normal" align="left">
                          {d.additional}
                        </TableCell>
                        <TableCell padding="normal" align="left">
                          {d.email}
                        </TableCell>
                        <TableCell padding="normal" align="left">
                          {d.parent_email}
                        </TableCell>
                        <TableCell padding="normal" align="left">
                          {moment(d.date_created).format("DD-MM-YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow key={0}>
                  <TableCell colSpan={4} padding="normal" align="left">
                    {errorText}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={rows ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
