import React, { useEffect, useState } from "react";
import { Box, Stack, TextField, Paper } from "@mui/material";
import Api from "../../api/API";
import TeacherTable from "../../components/teacher/TeacherTable";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";
import SkeletonContainer from "../../components/SkeletonContainer";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ListOfQuizes = () => {
  const [quizzes, setQuizzes] = useState(null);
  const [loads, setLoad] = useState(false);
  const [searched, setSearched] = useState("");
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = React.useState("quiz_name");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.getAllTeacherQuizzes(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setQuizzes(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_created),
                db = new Date(b.date_created);
              return db - da;
            })
          );
        }
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, [setQuizzes]);
  useEffect(() => {
    setRows(quizzes);
  }, [quizzes]);

  const duplicate = (quiz_id) => {
    if (window.confirm(`Are you sure you want to duplicate this quiz?`)) {
      dispatch(setLoading(true));
      const API = new Api();
      const token = localStorage.getItem("token");

      API.duplicateQuiz(token, quiz_id)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };

  const leaveClass = (id) => {
    if (window.confirm(`Are you sure you want to delete this quiz?`)) {
      const API = new Api();
      const token = localStorage.getItem("token");
      dispatch(setLoading(true));
      API.deleteQuiz(token, id)
        .then((res) => {
          if (res.data.message === "Quiz Deleted Successfully!") {
            API.getAllTeacherQuizzes(token)
              .then((res) => {
                setQuizzes(res.data.data);
                dispatch(setLoading(false));
              })
              .catch((err) => {
                dispatch(setLoading(false));
                dispatch(
                  setOpen({
                    message: err.response.data.message,
                    open: true,
                    variant: "error",
                  })
                );
              });
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
          } else {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };

  const columns = [
    "Quiz Name",
    "Subject",
    "Status",
    "Quiz Type",
    "Date Created",
  ];

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "quiz_name") {
      const filteredRows = quizzes.filter((row) => {
        return row.quiz_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "subject") {
      const filteredRows = quizzes.filter((row) => {
        return row.subject?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "quiz_type") {
      const filteredRows = quizzes.filter((row) => {
        return row.quiz_type
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "status") {
      const filteredRows = quizzes.filter((row) => {
        return row.status?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "date") {
      const filteredRows = quizzes.filter((row) => {
        return row.date_created.includes(searchedVal);
      });
      setRows(filteredRows);
    }
  };

  return (
    <>
      <Box mt={10}>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"quiz_name"}>Quiz Name</MenuItem>
                      <MenuItem value={"status"}>Status</MenuItem>
                      <MenuItem value={"subject"}>Subject</MenuItem>
                      <MenuItem value={"date"}>Date</MenuItem>
                      <MenuItem value={"quiz_type"}>Quiz Type</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />
                </Stack>

                <TeacherTable
                  column={columns}
                  title={"Quizzes Created"}
                  rows={rows}
                  type="quiz"
                  leaveClass={leaveClass}
                  duplicate={duplicate}
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ListOfQuizes;
