import React, { useState, useEffect } from "react";
import { Box, TextField, Stack, Paper, Button } from "@mui/material";
import TeacherTable from "../../components/teacher/TeacherTable";
import Api from "../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";
import SkeletonContainer from "../../components/SkeletonContainer";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

const ClassOverview = () => {
  const [classes, setClasses] = useState(null);
  const [loads, setLoad] = useState(false);
  const [searched, setSearched] = useState("");
  const [filter, setFilter] = useState("class_name");
  const [role, setRole] = useState(null);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setLoad(true);
    const API = new Api();
    const token = localStorage.getItem("token");
    setRole(localStorage.getItem("role"));
    API.getAllTeacherClasses(token)
      .then((res) => {
        if (
          res.data.data !== undefined ||
          res.data.data !== null ||
          res.data.data.length > 0
        ) {
          setClasses(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_created),
                db = new Date(b.date_created);
              return db - da;
            })
          );
        }
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, [setClasses]);

  useEffect(() => {
    setRows(classes);
  }, [classes]);

  const leaveClass = (id) => {
    if (window.confirm(`Are you sure you want to delete this class?`)) {
      const API = new Api();
      const token = localStorage.getItem("token");
      dispatch(setLoading(true));
      API.deleteClass(token, id)
        .then((res) => {
          if (res.data.message === "Class Deleted Successfully!") {
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
            API.getAllTeacherClasses(token)
              .then((res) => {
                if (
                  res.data.data !== undefined &&
                  res.data.data !== null &&
                  res.data.data.length > 0
                ) {
                  setClasses(
                    res.data.data.sort((a, b) => {
                      let da = new Date(a.date_created),
                        db = new Date(b.date_created);
                      return db - da;
                    })
                  );
                }
                dispatch(setLoading(false));
              })
              .catch((err) => {
                dispatch(setLoading(false));
                dispatch(
                  setOpen({
                    message: err.response.data.message,
                    open: true,
                    variant: "error",
                  })
                );
              });
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };

  const columns = ["Class Name", "Status", "Subject", "Date Created"];
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "class_name") {
      const filteredRows = classes.filter((row) => {
        return row.class_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "subject") {
      const filteredRows = classes.filter((row) => {
        return row.subject?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "status") {
      const filteredRows = classes.filter((row) => {
        return row.status?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "date") {
      const filteredRows = classes.filter((row) => {
        return row.date_created.includes(searchedVal);
      });
      setRows(filteredRows);
    }
  };

  return (
    <>
      <Box mt={10}>
        <Button
          variant="contained"
          sx={{ ml: "5%" }}
          onClick={() =>
            role === "teacher"
              ? navigate("/dashboard/teacher/create-class")
              : navigate("/dashboard/admin/teacher/create-class")
          }
        >
          Create New Class
        </Button>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"class_name"}>Class Name</MenuItem>
                      <MenuItem value={"status"}>Status</MenuItem>
                      <MenuItem value={"subject"}>Subject</MenuItem>
                      <MenuItem value={"date"}>Date</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />{" "}
                </Stack>
                <TeacherTable
                  title={"Classes Created"}
                  rows={rows}
                  column={columns}
                  type="class"
                  leaveClass={leaveClass}
                  do_not_show={true}
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ClassOverview;
