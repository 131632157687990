import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Grid, Stack, Button } from "@mui/material";
import { Beforeunload } from "react-beforeunload";
import TimerContainer from "../../../components/TimerContainer";
import TimeTaken from "../../../components/TimeTaken";
import ReactLoader from "../../../components/ReactLoader";
import EndScreen2 from "./EndScreen2";
import Api from "../../../api/API";
import CheckBoxImpl from "./CheckBoxImpl";
import RadioButtonImpl from "./RadioButtonImpl";
import SingleRadioButtonImpl from "./SingleRadioButtonImpl";
import MatchImpl from "./MatchImpl";
import FillGapimpl from "./FillGapimpl";

const NormalQuizInterface = ({ quizData, questions, duration }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([]);
  const [opened, setOpened] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const ans = JSON.parse(localStorage.getItem("answers"));
    const val = JSON.parse(localStorage.getItem("values"));

    if (ans !== null && ans !== undefined) {
      setAnswers(ans);
    } else {
      localStorage.setItem("answers", JSON.stringify([]));
    }
    if (val !== null && val !== undefined) {
      setValues(val);
    } else {
      localStorage.setItem("answers", JSON.stringify([]));
    }
  }, []);
  const submit = () => {
    const token = localStorage.getItem("token");
    const time_taken = localStorage.getItem("time");

    let answers = JSON.parse(
      localStorage.getItem("answers").replaceAll("'", "~~")
    );
    const API = new Api();

    const values = JSON.parse(
      localStorage.getItem("values").replaceAll("'", "~~")
    );
    let answerArray = answers.filter((value) => value != null);
    let valueArray = values.filter((value) => value != null);

    valueArray.forEach((item) => {
      if (Array.isArray(item.options)) {
        item.options = item.options.filter((option) => option != null);
      }
    });

    let answer = valueArray.concat(answerArray);
    const Arr1 = answer.filter((elem) => elem.id !== "");
    answers = Arr1.filter((elem) => elem.options !== "");

    setLoading(true);

    API.scoreStudent(token, quizData.quiz_id, answers, time_taken)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("load", "false");
        localStorage.removeItem("reload");
        localStorage.removeItem("quiz_id");
        localStorage.removeItem("quiz_name");
        localStorage.removeItem("time_left");
        localStorage.removeItem("answers");
        localStorage.removeItem("values");
        localStorage.removeItem("type");
        localStorage.removeItem("time");
        alert(res.data.message);

        setState(res.data.data);
        setTimeout(() => {
          setOpened(true);
        }, 300);
      })
      .catch((err) => {
        setLoading(false);
        localStorage.removeItem("reload");
        localStorage.removeItem("quiz_id");
        localStorage.removeItem("quiz_name");
        localStorage.setItem("load", "false");
        alert(err.response.data.message);
      });
  };

  const handleNext = () => {
    localStorage.setItem("answers", JSON.stringify(answers));
    localStorage.setItem("values", JSON.stringify(values));
    let incrementCurrentQuestionIndex = pageIndex + 1;
    setPageIndex(incrementCurrentQuestionIndex);
  };

  const handlePrevious = () => {
    localStorage.setItem("answers", JSON.stringify(answers));
    localStorage.setItem("values", JSON.stringify(values));
    let incrementCurrentQuestionIndex = pageIndex - 1;
    setPageIndex(incrementCurrentQuestionIndex);
  };

  const finishQuiz = () => {
    localStorage.setItem("answers", JSON.stringify(answers));
    localStorage.setItem("values", JSON.stringify(values));
    if (window.confirm("Are you sure you want to submit?")) {
      submit();
    }
  };
  const onSetPageCount = (i) => {
    localStorage.setItem("answers", JSON.stringify(answers));
    localStorage.setItem("values", JSON.stringify(values));
    setPageIndex(i);
  };
  //this function will be call when time expired, use it to submit the data on time expired
  const timeExpire = () => {
    localStorage.setItem("answers", JSON.stringify(answers));
    localStorage.setItem("values", JSON.stringify(values));
    alert("Times Up!! your answer has been submitted automatically");
    submit();
  };

  useEffect(() => {
    localStorage.setItem("type", "normal");
    const reloadTime = setTimeout(() => {
      localStorage.setItem("reload", "false");
      localStorage.setItem("load", "true");
      localStorage.setItem("quiz_id", `${quizData.quiz_id}`);
      localStorage.setItem("quiz_name", `${quizData.quiz_name}`);
    }, 60000);
    return () => {
      clearTimeout(reloadTime);
    };
  }, [quizData]);

  const btn = [];

  for (let i = 0; i < questions?.length; i++) {
    btn.push({
      category: questions[i].category,
      btn: (
        <span
          key={i}
          style={{
            padding: "10px",
            cursor: "pointer",
            margin: "3px",
            width: "fit-content",
            height: "fit-content",
            borderRadius: "8px",
            color:
              pageIndex === i
                ? "white"
                : questions[i]?.type === "match" || questions[i]?.type === "fg"
                ? values !== undefined
                  ? values?.find((d) => d?.id === questions[i]?.id)?.options
                      ?.length > 0
                    ? "white"
                    : "#1976d2"
                  : null
                : answers !== undefined
                ? answers?.find((d) => d?.id === questions[i]?.id)?.options
                    ?.length > 0
                  ? "white"
                  : "#1976d2"
                : null,
            backgroundColor:
              pageIndex === i
                ? "red"
                : questions[i]?.type === "match" || questions[i]?.type === "fg"
                ? values !== undefined
                  ? values?.find((d) => d?.id === questions[i]?.id)?.options
                      ?.length > 0
                    ? "#1976d2"
                    : "#1976d21a"
                  : "#1976d21a"
                : answers !== undefined
                ? answers?.find((d) => d?.id === questions[i]?.id)?.options
                    ?.length > 0
                  ? "#1976d2"
                  : "#1976d21a"
                : "#1976d21a",
          }}
          onClick={() => onSetPageCount(i)}
        >
          {i + 1}
        </span>
      ),
    });
  }

  return (
    <>
      {loading && <ReactLoader />}
      <EndScreen2 opened={opened} setOpened={setOpened} state={state} />
      <Beforeunload
        onBeforeunload={(e) => {
          localStorage.setItem("load", "false");
          e.preventDefault();
          localStorage.setItem("reload", "true");
          localStorage.setItem("quiz_id", `${quizData.quiz_id}`);
          localStorage.setItem("quiz_name", `${quizData.quiz_name}`);
        }}
      >
        <Box sx={{ p: 3 }}>
          <div id="time" style={{ display: "none" }}>
            <TimeTaken />
          </div>
          <div id="error"></div>
          <span id="check">true</span>
          <Grid container spacing={2} sx={{ mt: 8 }}>
            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={7} sx={{ textAlign: "left" }}>
              <Box
                sx={{
                  width: "fit-content",
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <TimerContainer duration={duration} expire={timeExpire} />
                </Stack>
              </Box>
              <Stack
                direction={"row"}
                divider={<Divider orientation="vertical" flexItem />}
                sx={{ p: 2 }}
              >
                <Box>
                  <Typography
                    color="initial"
                    sx={{
                      width: "fit-content",
                    }}
                  >
                    Questions {`${pageIndex + 1} of ${questions.length}`}
                  </Typography>
                </Box>
              </Stack>

              <Divider sx={{ mb: 3 }} />
              <Stack spacing={5}>
                <Box sx={{ minHeight: "230px" }}>
                  <Stack spacing={5}>
                    {questions[pageIndex]?.type === "mc" ? (
                      <CheckBoxImpl
                        answers={answers}
                        setAnswers={setAnswers}
                        questions={questions[pageIndex]}
                      />
                    ) : questions[pageIndex]?.type === "tf" ? (
                      <RadioButtonImpl
                        answers={answers}
                        setAnswers={setAnswers}
                        questions={questions[pageIndex]}
                      />
                    ) : questions[pageIndex]?.type === "match" ? (
                      <MatchImpl
                        values={values}
                        setValues={setValues}
                        questions={questions[pageIndex]}
                      />
                    ) : questions[pageIndex]?.type === "fg" ? (
                      <FillGapimpl
                        values={values}
                        setValues={setValues}
                        questions={questions[pageIndex]}
                      />
                    ) : (
                      <SingleRadioButtonImpl
                        answers={answers}
                        setAnswers={setAnswers}
                        questions={questions[pageIndex]}
                      />
                    )}
                  </Stack>
                </Box>
                <Grid container>
                  <Grid item md={3}></Grid>
                  <Grid item md={6}>
                    <Stack direction={"row"} spacing={2}>
                      {pageIndex >= 1 && (
                        <Button variant="contained" onClick={handlePrevious}>
                          Previous
                        </Button>
                      )}

                      {pageIndex !== questions.length - 1 ? (
                        <Button variant="contained" onClick={handleNext}>
                          Next
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={finishQuiz}
                        >
                          Finish Quiz
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={2} xs={12} sm={12}>
              {quizData?.category?.map((cat, index) => {
                return (
                  <Box key={index}>
                    <Typography
                      marginY={1}
                      variant="h5"
                      component={"div"}
                      sx={{
                        padding: "5px",
                        backgroundColor: "#1680cc",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    >
                      {cat.text}
                    </Typography>
                    <Box
                      sx={{
                        overflowY: "auto",
                        display: "grid",
                        gridTemplateColumns: {
                          xs: "repeat(6, 1fr)",
                          sm: "repeat(15, 1fr)",
                          md: "repeat(3, 1fr)",
                          lg: "repeat(4, 1fr)",
                        },
                      }}
                    >
                      {btn.map((b) => {
                        if (b.category === cat.text) {
                          return b.btn;
                        }
                        return null;
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Beforeunload>
    </>
  );
};

export default NormalQuizInterface;
