import React, { useEffect } from "react";
import {
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Box,
  Tooltip,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setOpen, setOrg } from "../slices/StateManager";
import Api from "../api/API";
import AutoLogout from "../components/AutoLogout";

const Header = ({ handleDrawerToggle, drawerWidth }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orgName } = useSelector((state) => state.StateManager);

  const handleIdleLogout = () => {
    dispatch(setLoading(true));

    if (
      localStorage.getItem("role") === "parent" ||
      localStorage.getItem("role") === "super"
    ) {
      localStorage.removeItem("role");
      localStorage.removeItem("token");
      navigate("/");
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Logged out Successfully",
          open: true,
          variant: "success",
        })
      );
    } else {
      const API = new Api();
      API.logout().then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        navigate("/");
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: "Logged out Successfully",
            open: true,
            variant: "success",
          })
        );
      });
    }
  };

  useEffect(() => {
    const role = localStorage.getItem("role");
    const token = localStorage.getItem("token");
    if (role !== null || token !== null) {
      if (role === "admin") {
        const API = new Api();
        API.getOrgNameAdmin(token)
          .then((res) => {
            dispatch(setOrg({ orgName: res.data.data.org_name }));
          })
          .catch((err) => {
            dispatch(setOrg({ orgName: "" }));
          });
      } else if (role === "teacher") {
        const API = new Api();
        API.getOrgNameTeacher(token)
          .then((res) => {
            dispatch(setOrg({ orgName: res.data.data.org_name }));
          })
          .catch((err) => {
            dispatch(setOrg({ orgName: "" }));
          });
      } else if (role === "student") {
        const API = new Api();
        API.getOrgNameStudent(token)
          .then((res) => {
            dispatch(setOrg({ orgName: res.data.data.org_name }));
          })
          .catch((err) => {
            dispatch(setOrg({ orgName: "" }));
          });
      } else if (role === "gstudent") {
        dispatch(setOrg({ orgName: "Global Student" }));
      } else if (role === "super") {
        dispatch(setOrg({ orgName: "Super Admin" }));
      } else {
        dispatch(setOrg({ orgName: "Parent portal" }));
      }
    }
  }, []);
  const token = localStorage.getItem("token");
  return (
    <div className="appbar">
      {token && <AutoLogout handleIdleLogout={handleIdleLogout} />}

      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth})` },
          ml: { md: `${drawerWidth}` },
          height: "68px",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              className="header_word"
            >
              <span style={{ textTransform: "capitalize" }}>{orgName}</span>
            </Typography>
          </Box>

          <Box>
            <Tooltip title="Logout">
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "red",
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (window.confirm("Are you sure you want to logout?")) {
                    dispatch(setLoading(true));
                    if (
                      localStorage.getItem("role") === "parent" ||
                      localStorage.getItem("role") === "super"
                    ) {
                      localStorage.removeItem("role");
                      localStorage.removeItem("token");
                      navigate("/");
                      dispatch(setLoading(false));
                      dispatch(
                        setOpen({
                          message: "Logged out Successfully",
                          open: true,
                          variant: "success",
                        })
                      );
                    } else {
                      const API = new Api();
                      API.logout().then((res) => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("role");
                        navigate("/");
                        dispatch(setLoading(false));
                        dispatch(
                          setOpen({
                            message: "Logged out Successfully",
                            open: true,
                            variant: "success",
                          })
                        );
                      });
                    }
                  }
                }}
              >
                logout
              </Button>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
