import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import children from "../../images/children.jpg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function SectionDiv() {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <Box>
          <Grid
            container
            sx={{
              mt: 10,
            }}
          >
            <Grid item lg={6} xs={12} sm={12}>
              <Box
                sx={{
                  p: { xs: 2, md: 5 },
                }}
              >
                <motion.div
                  initial={{ y: 100 }}
                  whileInView={{ y: 0 }}
                  transition={{
                    duration: 1.5,
                    type: "ease-in",
                    stiffness: 100,
                  }}
                >
                  <Typography
                    variant="h5"
                    className="text-hd"
                    sx={{
                      textAlign: "left",

                      fontWeight: "bold",
                    }}
                  >
                    Welcome to EduSupport
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className="text-hd "
                    sx={{
                      textAlign: "left",
                      mt: 2,

                      display: { xs: "block", lg: "none" },
                    }}
                  >
                    Transform the way you learn and evaluate progress with our
                    comprehensive CBT Solution.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className="text-hd "
                    sx={{
                      textAlign: "left",
                      mt: 2,

                      display: { xs: "none", lg: "block" },
                    }}
                  >
                    Edusupport is a versatile, user friendly web app for
                    institutions, teachers, students and parents to enhance
                    learning outcomes. It offers Quiz and Spelling Bee tool
                    serving as a web based testing tool for class room and home
                    assignements.
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className="text-hd "
                    sx={{
                      textAlign: "left",
                      mt: 2,

                      display: { xs: "none", lg: "block" },
                    }}
                  >
                    Transform the way you learn and evaluate progress with our
                    comprehensive CBT Solution.
                  </Typography>
                </motion.div>
                <Link to="/signup">
                  <Button
                    variant="contained"
                    className="section-btn"
                    sx={{
                      flexGrow: 1,
                      my: 2,
                      p: 1,
                      display: { xs: "none", lg: "block" },
                      textTransform: "capitalize !important",
                      background:
                        "linear-gradient(90deg, #171E41 0%, #3C4DA7 100%)",
                    }}
                  >
                    Get Started For Free
                  </Button>
                </Link>
              </Box>
            </Grid>

            <Grid item lg={6}>
              <motion.div
                initial={{ x: 100 }}
                whileInView={{ x: 0 }}
                transition={{ duration: 1.5, type: "ease-in", stiffness: 100 }}
              >
                <div className="container">
                  <img
                    src={children}
                    alt="logo"
                    style={{
                      width: "100%",

                      height: "100%",
                    }}
                  />
                  {/* <div className="overlay"></div> */}
                </div>
              </motion.div>
            </Grid>

            <Grid item lg={6} sx={{ display: { xs: "block", lg: "none" } }}>
              <motion.div
                initial={{ x: -100 }}
                whileInView={{ x: 0 }}
                transition={{
                  duration: 1.5,
                  type: "ease-in",
                  stiffness: 100,
                }}
              >
                <Typography
                  variant="subtitle1"
                  className="text-hd "
                  sx={{
                    textAlign: "left",
                    mt: 2,
                    padding: "0px 30px",
                  }}
                >
                  Edusupport is a versatile, user friendly web app for
                  institutions, teachers, students and parents to enhance
                  learning outcomes. It offers Quiz and Spelling Bee tool
                  serving as a web based testing tool for class room and home
                  assignements.
                </Typography>
              </motion.div>

              <motion.div
                initial={{ x: 40, opacity: 0.5 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  duration: 1.5,
                  type: "ease-in",
                  stiffness: 100,
                }}
              >
                <Link to="/signup">
                  <Button
                    variant="contained"
                    className="section-btn"
                    sx={{
                      mx: "30px",
                      flexGrow: 1,
                      my: 2,
                      p: 1,
                      textTransform: "capitalize !important",
                      background:
                        "linear-gradient(90deg, #171E41 0%, #3C4DA7 100%)",
                    }}
                  >
                    Get Started For Free
                  </Button>
                </Link>
              </motion.div>
            </Grid>
          </Grid>
        </Box>
      </motion.div>
    </>
  );
}

export default SectionDiv;
