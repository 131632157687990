import React, { useEffect, useState } from "react";
import {
  Box,
  Backdrop,
  TextField,
  Typography,
  Modal,
  Fade,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stack,
} from "@mui/material";
import Api from "../api/API";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomTagInput from "./CustomTagInput";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../slices/StateManager";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95vw", sm: "90vw", md: 900 },
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};
const Edits = ({ open, handleClose, data, type }) => {
  const [status, setStatus] = useState("data.status");
  const [tags, setTags] = React.useState([]);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [subject, setSubject] = useState(null);
  const [point, setPoint] = useState(null);
  const [pass_mark, setPass_mark] = useState(null);
  const [typeq, setTypeq] = useState("normal");
  const [cname, setCname] = useState([]);
  const [dur_level, setDur_level] = useState("normal");
  const [class_name, setClass_name] = useState(null);
  const [start, setStart] = useState(moment().format());
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTags(data?.category === null ? [] : data?.category);
  }, [data?.category]);
  const handleType = (event) => {
    setTypeq(event.target.value);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (type === "class") {
      if (data !== null) {
        setId(data.class_id);
        setName(data.class_name);
        setSubject(data.subject);
        setStatus(data.status);
      }
    } else if (type === "quiz") {
      const token = localStorage.getItem("token");
      const API = new Api();
      API.getTeacherClassName(token)
        .then((res) => {
          setClass_name(res.data.data);
        })
        .catch((err) => {
          setClass_name(null);
        });

      if (data !== null) {
        setId(data.quiz_id);
        setName(data.quiz_name);
        setSubject(data.subject);
        setStatus(data.status);
        setPoint(data.point);
        setPass_mark(data.pass_mark);
        setTypeq(data.quiz_type);
        setDur_level(data.dur_level);
        setCname([...data.class_id]);
        setStart(
          moment(data?.release_date)
            .subtract(1, "hour")
            .format("YYYY-MM-DDTHH:mm")
        );
      }
    }
  }, [open, data, type]);

  const handleClass = (event) => {
    const { value } = event.target;
    setCname(value);
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleClassEdit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const API = new Api();
    API.updateClass(token, id, name, status, subject)
      .then((res) => {
        if (res.data.message === "Class Data Updated Successfully!") {
          alert(res.data.message);
          handleClose();
          window.location.reload();
        } else {
          alert(res.data.message);
          handleClose();
          window.location.reload();
        }
      })
      .catch((err) => alert(err.response.data.message));
  };

  const handleQuizEdit = (e) => {
    e.preventDefault();
    if (tags?.length < 1) {
      alert("Please add at leaset 1 Section");

      return;
    }

    const token = localStorage.getItem("token");
    const API = new Api();
    dispatch(setLoading(true));
    API.updateQuiz(
      token,
      id,
      name,
      dur_level,
      subject,
      point,
      pass_mark,
      cname,
      status,
      typeq,
      tags,
      moment(start).format("YYYY-MM-DD HH:mm:ss")
    )
      .then((res) => {
        if (res.data.message === "Quiz Data Updated Successfully!") {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "success",
            })
          );

          handleClose();
          window.location.reload();
        } else {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "error",
            })
          );

          handleClose();
          window.location.reload();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: err.response.data.message,
            open: true,
            variant: "error",
          })
        );
      });
  };

  return type === "class" ? (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </Box>

            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              <Box
                component="form"
                sx={{
                  p: 4,
                }}
                onSubmit={handleClassEdit}
              >
                <Stack direction={"column"} spacing={2}>
                  <Typography align="center" variant="h4" gutterBottom>
                    Edit Class
                  </Typography>

                  <TextField
                    required
                    value={id}
                    name="class_name"
                    fullWidth
                    id="standard-basic"
                    label="Class ID"
                  />

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="class_name"
                      fullWidth
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                      id="standard-basic"
                      label="Class Name"
                    />

                    <TextField
                      fullWidth
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                      name="subject"
                      id="standard-basic"
                      label="Class Subject"
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Class Status
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Class Status"
                        value={status}
                        onChange={handleStatus}
                      >
                        <MenuItem value={"active"}>Open</MenuItem>
                        <MenuItem value={"inactive"}>Closed</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box textAlign={"center"}>
                    <Button variant="contained" type="submit" size="large">
                      Save Changes
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : type === "quiz" ? (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </Box>

            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              <Box
                component="form"
                sx={{
                  p: 4,
                }}
                onSubmit={handleQuizEdit}
              >
                <Typography align="center" variant="h4" gutterBottom>
                  Edit Test
                </Typography>

                <TextField
                  fullWidth
                  required
                  value={id}
                  id="standard-basic"
                  name="quiz_name"
                  label="Test ID"
                />
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    fullWidth
                    required
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    id="standard-basic"
                    name="quiz_name"
                    label="Test Name"
                  />

                  <TextField
                    fullWidth
                    required
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    name="subject"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                    id="standard-basic"
                    label="Test Subject"
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    label="Schedule when to Publish Detailed Results."
                    fullWidth
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                    required
                    id="standard-basic"
                    name="start"
                    onChange={(e) => setStart(e.target.value)}
                    type="datetime-local"
                    value={start}
                  />

                  <TextField
                    fullWidth
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                    required
                    type={"number"}
                    onChange={(e) => setPoint(e.target.value)}
                    value={point}
                    name="point"
                    id="standard-basic"
                    label="Point Per Question"
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    required
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                    }}
                    fullWidth
                    type={"number"}
                    onChange={(e) => setPass_mark(e.target.value)}
                    value={pass_mark}
                    id="standard-basic"
                    name="pass_mark"
                    label="Pass Mark %"
                  />
                  <FormControl
                    sx={{ m: { xs: "5% 0", sm: "2% 0", md: "2% 0" } }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-label">
                      Class Name
                    </InputLabel>
                    <Select
                      name="opened"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Opened?"
                      value={cname}
                      required
                      multiple
                      onChange={handleClass}
                    >
                      {class_name === null ? (
                        <MenuItem value={""}>No Class Found</MenuItem>
                      ) : (
                        class_name.map((item) => {
                          return (
                            <MenuItem key={item.class_id} value={item.class_id}>
                              {item.class_name}
                            </MenuItem>
                          );
                        })
                        // <p></p>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <FormControl
                    sx={{ m: { xs: "5% 0", sm: "2% 0", md: "2% 0" } }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-label">
                      Quiz Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Class Status"
                      value={typeq}
                      required
                      onChange={handleType}
                    >
                      <MenuItem value="normal">Normal Quiz</MenuItem>
                      <MenuItem value="spelling">Spelling Quiz</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                    }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-label">
                      Quiz Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Class Status"
                      value={status}
                      required
                      defaultValue=""
                      onChange={handleStatus}
                    >
                      <MenuItem value="">Quiz Status</MenuItem>
                      <MenuItem value="onhold">Onhold</MenuItem>
                      <MenuItem value="publish">Publish</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <FormControl
                    fullWidth
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                    }}
                  >
                    <CustomTagInput tags={tags} setTags={setTags} />
                  </FormControl>

                  <TextField
                    sx={{
                      m: { xs: 0, sm: 0, md: "2% 0" },
                    }}
                    required
                    fullWidth
                    type={"number"}
                    onChange={(e) => setDur_level(e.target.value)}
                    value={dur_level}
                    id="standard-basic"
                    name="dur_level"
                    label="Duration Per Section (Minutes)"
                  />
                </Box>

                <Box
                  textAlign={"center"}
                  sx={{
                    m: { xs: "5% 0", sm: "2% 0", md: "0% 0" },
                  }}
                >
                  <Button variant="contained" type="submit" size="large">
                    Save Changes
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : null;
};

export default Edits;
