import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const role = localStorage.getItem("role");
  const auth = localStorage.getItem("token");
  if (!auth || auth === "undefined") {
    return children;
  }

  if (role === "admin") {
    return <Navigate to="/dashboard/admin" />;
  }

  if (role === "teacher") {
    return <Navigate to="/dashboard/teacher" />;
  }

  if (role === "student") {
    return <Navigate to="/dashboard/student" />;
  }
  if (role === "gstudent") {
    return <Navigate to="/dashboard/gstudent" />;
  }
  if (role === "parent") {
    return <Navigate to="/dashboard/parent" />;
  }
};

export default PublicRoute;
