import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { changePage, setAdminId } from "../../../slices/changePage";
import { Card, CardContent, Grid } from "@mui/material";

import logo from "../../../images/edu-dark.png";

import { motion } from "framer-motion";
import Api from "../../../api/API";

function SecondComponent() {
  const dispatch = useDispatch();
  const [orgId, setOrgId] = React.useState("");
  const [isExist, setIsExist] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const checkOrgId = () => {
    const API = new Api();
    API.checkOrgId(orgId)
      .then((res) => {
        if (res.data.data) {
          setIsError(false);
          setMessage(res.data.data.org_name);
          setIsExist(true);
        } else {
          setMessage("Organization Does Not Exist");
          setIsExist(false);
        }
      })
      .catch((err) => {
        setMessage("Organization Does Not Exist");
        setIsError(true);
        setIsExist(false);
      });
  };
  return (
    <>
      <Grid
        container
        mt={5}
        px={{ xs: 2, md: 0 }}
        justifyContent={"center"}
        alignContent={"center"}
        justifyItems={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} lg={3} md={2}></Grid>
        <Grid item xs={12} lg={6} md={8} mb={3}>
          <motion.div
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, type: "spring", stiffness: 100 }}
          >
            <Card
              sx={{
                borderRadius: "10px",
                background: "#F9F9F9",
                boxShadow: "0px 0px 3px 1px rgba(203, 203, 203, 1)",
              }}
            >
              <CardContent>
                <Stack direction="column">
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <img src={logo} alt="edusupport" />

                    <Typography>Step 2:</Typography>
                  </Stack>
                  <Stack direction="column" spacing={3} mt={3}>
                    <Typography variant="h5">Enter Organisation ID</Typography>
                    <FormControl>
                      <Input
                        error={isError}
                        variant="filled"
                        placeholder="Organisation ID"
                        helperText={message}
                        value={orgId}
                        onChange={(e) => {
                          setOrgId(e.target.value);
                        }}
                        endAdornment={
                          <Button
                            variant="contained"
                            onClick={checkOrgId}
                            color="primary"
                            sx={{ width: "100px", borderRadius: "5px", mb: 1 }}
                          >
                            Check
                          </Button>
                        }
                      />
                      <FormHelperText
                        id="my-helper-text"
                        sx={{
                          color: isError ? "red" : "green",
                          fontSize: "14pt",
                        }}
                      >
                        {message}
                      </FormHelperText>
                    </FormControl>

                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          width: "100px",
                          borderRadius: "5px",
                          mb: 1,
                          color: "black",
                        }}
                        onClick={() => dispatch(changePage({ page: "first" }))}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!isExist}
                        sx={{ width: "100px", borderRadius: "5px", mb: 1 }}
                        onClick={() => {
                          dispatch(setAdminId({ admin: orgId }));
                          dispatch(changePage({ page: "third" }));
                        }}
                      >
                        Next
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </motion.div>
        </Grid>
        <Grid item xs={12} lg={3} md={2}></Grid>
      </Grid>
    </>
  );
}

export default SecondComponent;
