import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  open: false,
  message: "",
  variant: "info",
  timer: 6000,
  orgName: "",
};

export const StateManager = createSlice({
  name: "option",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOpen: (state, action) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.variant = action.payload.variant;
      state.timer = 6000;
    },
    setOpenWarning: (state, action) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.variant = action.payload.variant;
      state.timer = action.payload.timer;
    },
    setOrg: (state, action) => {
      state.orgName = action.payload.orgName;
    },
  },
});

export const { setLoading, setOpen, setOrg, setOpenWarning } =
  StateManager.actions;

export default StateManager.reducer;
