import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import Api from "../../api/API";
import ReactLoader from "../../components/ReactLoader";
import QuestionInterface from "./QuestionInterface";
import SpellingChoice from "./QuizType/SpellingChoice";

const SetQuestionType = () => {
  const [quizData, setQuizData] = useState(null);

  const [load, setload] = useState(true);
  let { quiz_id } = useParams();

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    API.getQuizData(token, quiz_id)
      .then((res) => {
        setQuizData(res.data.data[0]);
        setload(false);
      })
      .catch((err) => {
        setload(false);
      });
  }, [quiz_id]);

  return (
    <>
      {load ? (
        <ReactLoader />
      ) : quizData !== null ? (
        quizData.quiz_type === "normal" ? (
          <QuestionInterface quizData={quizData} quiz_id={quiz_id} />
        ) : (
          <SpellingChoice quizData={quizData} quiz_id={quiz_id} />
        )
      ) : null}
    </>
  );
};

export default SetQuestionType;
