import React, { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { Box, Typography, Stack } from "@mui/material";

import { AccessAlarms } from "@mui/icons-material";

const TimerContainer = ({ expire, duration }) => {
	const timeString = localStorage.getItem("time_left");
	if (timeString !== null && timeString !== undefined) {
		const timeParts = timeString.split(",");
		const hours = parseInt(timeParts[0]);
		const minutes = parseInt(timeParts[1]);
		const seconds = parseInt(timeParts[2]);
		const totalTimeInMinutes = hours * 60 + minutes + seconds / 60;

		duration = totalTimeInMinutes;
	}
	const time = new Date();
	time.setMinutes(time.getMinutes() + duration);

	const { seconds, minutes, hours } = useTimer({
		expiryTimestamp: time,
		onExpire: expire,
	});
	useEffect(() => {}, []);
	return (
		<>
			<Box
				sx={{
					color: minutes <= 10 && hours <= 0 ? "red" : "black",

					width: "fit-content",
				}}
			>
				<Stack direction={"row"} spacing={1}>
					<AccessAlarms sx={{ alignSelf: "center", fontSize: 30 }} />
					<Typography fontSize={30}>
						{hours} hrs:{minutes} mins:{seconds} secs
						{localStorage.setItem(
							"time_left",
							`${hours},${minutes},${seconds}`
						)}
					</Typography>
				</Stack>
			</Box>
		</>
	);
};

export default TimerContainer;
