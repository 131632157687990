import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Radio,
  TextField,
  Typography,
  FormControl,
  Stack,
  Modal,
  Fade,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Api from "../../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../../slices/StateManager";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const SingleChoice = ({ data, style, lev, open, handleClose, Backdrop }) => {
  const [selectedValue, setSelectedValue] = useState(data.answers[0]);
  const [ckValue, setCkvalue] = useState(data?.question?.replaceAll("~~", "'"));
  const [options, setOptions] = useState(data?.options);
  const dispatch = useDispatch();
  const [category, setCategory] = useState(data?.category);
  const addOptions = () => {
    if (options.length > 5) {
      alert("you cannot add more than 6 options");
      return;
    } else {
      setOptions([...options, ""]);
    }
  };

  const removeOptions = (i) => {
    const list = [...options];
    list.splice(i, 1);
    setOptions(list);
  };

  const onChangeOption = (index, e) => {
    let getterr = [...options];
    getterr[index] = e.target.value;
    setOptions(getterr);
  };

  const onChangeRadio = (e) => {
    e.target.value.length > 0 && setSelectedValue(e.target.value);
  };

  let menu = [];
  for (let index = 0; index <= lev?.category?.length; index++) {
    menu.push(
      <MenuItem key={index} value={lev?.category[index]?.text}>
        {lev?.category[index]?.text}
      </MenuItem>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const token = localStorage.getItem("token");

    const value = ckValue;
    if (selectedValue === null) {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please select the correct option",
          open: true,
          variant: "error",
        })
      );
    } else {
      let arr = options.map((data) => {
        return data;
      });
      const optionsArr = JSON.stringify(arr).replaceAll("'", "~~");
      const answers = JSON.stringify([selectedValue]).replaceAll("'", "~~");
      const API = new Api();

      let newValue = value.replaceAll("'", "~~");
      API.updateQuestionNormal(
        token,
        data.id,
        data.quiz_id,
        newValue,
        "sc",
        optionsArr,
        answers,
        category
      )
        .then((res) => {
          if (res.data.message === "Question Updated Successfully!") {
            dispatch(setLoading(false));

            setSelectedValue(null);
            setOptions([...options, ""]);
            setCkvalue("");
            setCategory(category);
          }

          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "success",
            })
          );
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };
  const editorConfiguration = {
    removePlugins: ["Heading", "Link", "CKFinder", "Alignment"],
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component={"form"} onSubmit={handleSubmit} sx={style}>
            <Typography variant="h6" component="h6" sx={{ textAlign: "left" }}>
              Edit Single Choice Question
            </Typography>
            <Grid container spacing={4} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
                  <InputLabel id="demo-simple-select-label">
                    Question Section
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Question Section"
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                  >
                    {menu.map((item) => {
                      return item;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <CKEditor
                  config={editorConfiguration}
                  editor={DecoupledEditor}
                  onReady={(editor) => {
                    editor.ui
                      .getEditableElement()
                      .parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );
                  }}
                  data={ckValue}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setCkvalue(data);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={1} sx={{ mb: 2 }}>
                  {options.map((x, index) => (
                    <Grid container key={index} spacing={1} sx={{ mb: 2 }}>
                      <Grid item xs={2} md={1}>
                        {" "}
                        <Radio
                          size="small"
                          checked={selectedValue === options[index]}
                          value={options[index] || x}
                          onClick={onChangeRadio}
                        />
                      </Grid>
                      <Grid item xs={7} md={9}>
                        {" "}
                        <TextField
                          size="small"
                          fullWidth
                          multiline
                          required
                          id="standard-basic"
                          label={`Option ${index + 1}`}
                          value={options[index]?.replaceAll("~~", "'") || x}
                          onChange={(e) => onChangeOption(index, e)}
                        />
                      </Grid>
                      <Grid item xs={3} md={2}>
                        <Stack direction={"row"} spacing={2}>
                          {options.length !== 1 && (
                            <>
                              <IconButton
                                aria-label="delete"
                                onClick={() => removeOptions(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                          {options.length - 1 === index && (
                            <>
                              <Fab
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={addOptions}
                                sx={{
                                  width: { xs: "50px", sm: "auto" },
                                  height: "40px",
                                  p: 1,
                                }}
                              >
                                <AddIcon />
                              </Fab>
                            </>
                          )}
                        </Stack>{" "}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={4} md={4}></Grid>
              <Grid item md={4}></Grid>
              <Grid item xs={4} md={4}>
                <Stack direction="row" spacing={3}>
                  <Button type="submit" variant="contained" size="large">
                    Save Changes
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default SingleChoice;
