import { Quiz } from "@mui/icons-material";
import { Divider, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import reactStringReplace from "react-string-replace";
import { useEffect } from "react";
import { useState } from "react";

const FillGapimpl = ({ values, setValues, questions }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (values !== null && values !== undefined) {
			if (values?.find((a) => a?.id === questions?.id)) {
				setData(values?.find((a) => a?.id === questions?.id)?.options);
			} else {
				setData([]);
			}
		}
	}, [questions, values]);

	//fill in the blank related
	const onChangeFill = (index, e) => {
		let { value } = e.target;
		if (values !== null && values !== undefined) {
			if (values?.find((a) => a?.id === questions?.id)) {
				const dd = values?.find((a) => a?.id === questions?.id);
				let newOpt = dd?.options;

				newOpt[index] = value;
				const newValues = values?.map((ss) =>
					ss?.id === dd.id ? { ...dd, options: newOpt } : ss
				);
				setValues(newValues);
			} else {
				const arr = [];
				arr[index] = value;

				setValues([
					...values,
					{
						id: questions?.id,
						options: arr,
					},
				]);
			}
		}
	};
	//fill in the blank related
	const getFillInTheBlank = () => {
		let passage = parse(questions?.question?.replaceAll("~~", "'"));

		passage = reactStringReplace(passage, "____", (match, i) => (
			<TextField
				id="standard-basic"
				placeholder="fill the blank"
				variant="standard"
				multiline
				autoComplete={"off"}
				autoCorrect={"off"}
				autoCapitalize="off"
				spellCheck={false}
				onPaste={(e) => e.preventDefault()}
				lang="english"
				key={i}
				value={data[i] || ""}
				onChange={(e) => onChangeFill(i, e)}
				sx={{ ml: 1, mr: 1 }}
			/>
		));
		return passage;
	};
	return (
		<>
			<Stack direction={"row"} spacing={1}>
				<Quiz color="primary" />
				<Typography variant="h7">{getFillInTheBlank()}</Typography>
			</Stack>

			<Divider sx={{ mb: 5 }} />
		</>
	);
};

export default FillGapimpl;
