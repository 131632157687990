import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 'first',
  role: 'admin',
  admin_id:""
  
};

export const ChangePageSlices = createSlice({
  name: "page",
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page=action.payload.page
    },
    changeRole:(state, action) => {
      state.role=action.payload.role
    },
    setAdminId: (state, action) => {
      state.admin_id=action.payload.admin;
    },
  },
});

export const { changePage,changeRole,setAdminId} = ChangePageSlices.actions;

export default ChangePageSlices.reducer;
