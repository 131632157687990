import React from "react";
import { Card, CardContent, Stack, Typography, Radio } from "@mui/material";

function RoleCard({ role, value, onChange, imageUrl, title, description }) {
  return (
    <div onClick={() => onChange(value)} style={{ cursor: "pointer" }}>
      <Card
        className="signup-card"
        sx={{ border: value === role ? "1px solid blue" : "" }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Stack spacing={1} direction="column">
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Radio value={value} color="default" checked={value === role} />
            </Stack>

            <Stack
              direction={"row"}
              spacing={2}
              px={2}
              alignItems={"center"}
              justifyItems={"center"}
            >
              <img src={imageUrl} alt="icon" />
              <Typography variant="h6">{title}</Typography>
            </Stack>

            <Typography variant="body2" px={2} sx={{ textAlign: "left" }}>
              {description}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}

export default RoleCard;
