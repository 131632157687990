import React from "react";

import {
  Box,
  Backdrop,
  TextField,
  Typography,
  Modal,
  Stack,
  Fade,
  Avatar,
  Button,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95vw", sm: "90vw", md: "fit-content" },
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};

const Views = ({ open, handleClose, data, type }) => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  return type === "class" ? (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </Box>

            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              <Box p={4}>
                <Typography align="center" variant="h4" gutterBottom>
                  View Class
                </Typography>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={data ? data.admin_id : null}
                    color="text"
                    focused
                    label="Admin ID"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.teacher_id : null}
                    color="text"
                    focused
                    label="Teacher ID"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={data ? data.class_id : null}
                    color="text"
                    focused
                    label="Class Id"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.class_name : null}
                    color="text"
                    focused
                    label="Class Name"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={
                      data
                        ? data.status === "active"
                          ? "Open"
                          : "Closed"
                        : null
                    }
                    color="text"
                    focused
                    label="Status"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.subject : null}
                    color="text"
                    focused
                    label="Subject"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={data ? data.created_by : null}
                    color="text"
                    focused
                    label="Created By"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.date_created : null}
                    color="text"
                    focused
                    label="Date Created"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : type === "quiz" ? (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </Box>

            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              <Box p={4}>
                <Typography align="center" variant="h4" gutterBottom>
                  View Quiz
                </Typography>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={data ? data.admin_id : null}
                    color="text"
                    focused
                    label="Admin ID"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.teacher_id : null}
                    color="text"
                    focused
                    label="Teacher ID"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={data ? data.quiz_id : null}
                    color="text"
                    focused
                    label="Quiz ID"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.quiz_name : null}
                    color="text"
                    focused
                    label="Quiz Name"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={data ? data.dur_level : null}
                    color="text"
                    focused
                    label="Duration per Category"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.subject : null}
                    color="text"
                    focused
                    label="Subject"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={data ? data.point : null}
                    color="text"
                    focused
                    label="Point Per Question"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.pass_mark : null}
                    color="text"
                    focused
                    label="Pass Mark %"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={data ? data.class_id : null}
                    color="text"
                    focused
                    label="Class Name"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.status : null}
                    color="text"
                    focused
                    label="Status"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={data ? data.quiz_type : null}
                    color="text"
                    focused
                    label="Quiz Type"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.date_created : null}
                    color="text"
                    focused
                    label="Date Created"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={
                      data
                        ? moment(data.publish_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : null
                    }
                    color="text"
                    focused
                    label="Publish Date"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                  <TextField
                    value={data ? data.created_by : null}
                    color="text"
                    focused
                    label="Created By"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={
                      data
                        ? moment(data.closing_date).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                        : null
                    }
                    color="text"
                    focused
                    label="Closing Date"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />

                  <TextField
                    value={
                      data
                        ? moment(data?.release_date)
                            .subtract(1, "hour")
                            .format("YYYY-MM-DD hh:mm a")
                        : null
                    }
                    color="text"
                    focused
                    label="Scheduled when to Publish Detailed Results"
                    sx={{
                      m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                      width: { xs: "100%", md: "50%" },
                    }}
                  />
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  {" "}
                  <Stack spacing={1}>
                    <Typography>Sections</Typography>

                    {data?.category !== null && (
                      <>
                        <Stack
                          direction={"row"}
                          sx={{
                            border: "2px solid #dcdfe1",
                            borderRadius: "5px",
                          }}
                        >
                          {data?.category.map((cat, index) => {
                            return (
                              <Typography
                                key={index}
                                sx={{
                                  width: "fit-content",
                                  height: "fit-content",
                                  p: 1,
                                  backgroundColor: "rgb(251, 237, 237)",
                                  m: 1,
                                  borderRadius: "5px",
                                  color: "rgb(243, 90, 90)",
                                }}
                              >
                                {cat.text}
                              </Typography>
                            );
                          })}
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Box>

                {(role === "admin" || role === "teacher") && (
                  <Box sx={{ mt: 3, textAlign: "center" }}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        navigate(
                          `/quiz-question/${data.quiz_id}/${data.quiz_type}`
                        )
                      }
                    >
                      View Questions
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : type === "teacher" ? (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </Box>

            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              <Box p={4}>
                <Typography align="center" variant="h4" gutterBottom>
                  View Teacher
                </Typography>
                <>
                  <Avatar
                    sx={{
                      border: "1px solid gray",
                      m: "2% auto",
                      width: 150,
                      height: 150,
                    }}
                    src={data ? data.avatar : null}
                  />
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.teacher_id : null}
                      color="text"
                      focused
                      label="Teacher ID"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.admin_id : null}
                      color="text"
                      focused
                      label="Admin ID"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.firstname : null}
                      color="text"
                      focused
                      label="First Name"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.lastname : null}
                      color="text"
                      focused
                      label="Last Name"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.username : null}
                      color="text"
                      focused
                      label="Username"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.email : null}
                      color="text"
                      focused
                      label="Email"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.phone : null}
                      color="text"
                      focused
                      label="Phone Number"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.gender : null}
                      color="text"
                      focused
                      label="Gender"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.date_created : null}
                      color="text"
                      focused
                      label="Joined"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                </>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : type === "student" ? (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </Box>

            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              <Box p={4}>
                <Typography align="center" variant="h4" gutterBottom>
                  View Student
                </Typography>
                <>
                  <Avatar
                    sx={{
                      border: "1px solid gray",
                      m: "2% auto",
                      width: 150,
                      height: 150,
                    }}
                    src={data ? data.avatar : null}
                  />
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.student_id : null}
                      color="text"
                      focused
                      label="Student ID"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.admin_id : null}
                      color="text"
                      focused
                      label="Admin ID"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.firstname : null}
                      color="text"
                      focused
                      label="First Name"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.lastname : null}
                      color="text"
                      focused
                      label="Last Name"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.username : null}
                      color="text"
                      focused
                      label="Username"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.email : null}
                      color="text"
                      focused
                      label="Email"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.phone : null}
                      color="text"
                      focused
                      label="Phone Number"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.gender : null}
                      color="text"
                      focused
                      label="Gender"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={
                        data ? moment(data.dob).format("DD-MM-YYYY") : null
                      }
                      color="text"
                      focused
                      label="Date Of Birth"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.student_type : null}
                      color="text"
                      focused
                      label="Student Type"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.parent_email : null}
                      color="text"
                      focused
                      label="Parent Email"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.date_created : null}
                      color="text"
                      focused
                      label="Joined"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data?.additional : null}
                      color="text"
                      focused
                      label="Additional Information"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                </>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : type === "result" ? (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </Box>

            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              <Box p={4}>
                <Typography align="center" variant="h4" gutterBottom>
                  Quiz Result
                </Typography>
                <>
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.student_id : null}
                      color="text"
                      focused
                      label="Student ID"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.student_name : null}
                      color="text"
                      focused
                      label="Student Name"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.quiz_id : null}
                      color="text"
                      focused
                      label="Quiz ID"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.quiz_name : null}
                      color="text"
                      focused
                      label="Quiz Name"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.point : null}
                      color="text"
                      focused
                      label="Point"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? `${data.percent}%` : null}
                      color="text"
                      focused
                      label="Percentage"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.right_answers : null}
                      color="text"
                      focused
                      label="Right Answers"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.wrong_answers : null}
                      color="text"
                      focused
                      label="Wrong Answers"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.status : null}
                      color="text"
                      focused
                      label="Remark"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={
                        data
                          ? moment(data.date_taken).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : null
                      }
                      color="text"
                      focused
                      label="Date Taken"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.time_taken : null}
                      color="text"
                      focused
                      label="Time Taken"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                </>

                <Box textAlign={"center"}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(
                        `/view-answer/${data.quiz_id}/${data.student_id}`
                      )
                    }
                  >
                    view answers
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : type === "admin" ? (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </Box>

            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              <Box p={4}>
                <Typography align="center" variant="h4" gutterBottom>
                  View Admin
                </Typography>
                <>
                  <Avatar
                    sx={{
                      border: "1px solid gray",
                      m: "2% auto",
                      width: 150,
                      height: 150,
                    }}
                    src={data ? data.avatar : null}
                  />
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.admin_id : null}
                      color="text"
                      focused
                      label="Admin ID"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={
                        data ? (data.paid === "true" ? "Yes" : "No") : null
                      }
                      color="text"
                      focused
                      label="Paid"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.firstname : null}
                      color="text"
                      focused
                      label="First Name"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.lastname : null}
                      color="text"
                      focused
                      label="Last Name"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.username : null}
                      color="text"
                      focused
                      label="Username"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.email : null}
                      color="text"
                      focused
                      label="Email"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.phone : null}
                      color="text"
                      focused
                      label="Phone Number"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.gender : null}
                      color="text"
                      focused
                      label="Gender"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>

                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.date_created : null}
                      color="text"
                      focused
                      label="Joined"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                    <TextField
                      value={data ? data.org_name : null}
                      color="text"
                      focused
                      label="Joined"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                  <Box
                    display={{ xs: "block", md: "flex" }}
                    sx={{ columnGap: "2%" }}
                  >
                    <TextField
                      value={data ? data.org_address : null}
                      color="text"
                      focused
                      label="Joined"
                      sx={{
                        m: { xs: "5% 0", sm: "2% 0", md: "2% 0" },
                        width: { xs: "100%", md: "50%" },
                      }}
                    />
                  </Box>
                </>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  ) : null;
};

export default Views;
