import React, { useState, useEffect } from "react";
import { Box, Grid, Button, Paper, Stack } from "@mui/material";
import QuestionPage from "./QuestionPage";
import ReactLoader from "../../../components/ReactLoader";
import { useNavigate } from "react-router-dom";

const StartQuizTwo = ({ status, studentData, questions, quizData }) => {
  const [showQ, setShowQ] = useState(false);
  const navigate = useNavigate();
  const dur = quizData?.category?.length * quizData?.dur_level;
  useEffect(() => {}, []);

  return (
    <>
      {!status && !questions && !quizData && !studentData && <ReactLoader />}
      {!showQ ? (
        <>
          <Box>
            <Grid container spacing={3} sx={{ mt: "10%" }}>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Paper elevation={3} sx={{ height: "fit-content", p: 4 }}>
                  <p>
                    <b>Note:</b> The Examination is made up followings;
                  </p>
                  <br />
                  <ul style={{ margin: "20px" }}>
                    <>
                      <li>
                        <h3 style={{ color: "red" }}>
                          Trying to leave the Test Page automatically submits
                          your score!!!
                        </h3>
                      </li>
                      <br />
                    </>

                    {status ? (
                      <>
                        <li>
                          <h3 style={{ color: "red" }}>
                            This is a Retake Test. The scores will only be shown
                            but not be recorded.
                          </h3>
                        </li>
                        <br />
                      </>
                    ) : null}

                    <li>
                      Quiz Name: <b>{quizData?.quiz_name}</b>
                    </li>
                    <li>
                      Quiz Type: <b>{quizData?.quiz_type}</b>
                    </li>

                    <li>
                      Exam Duration: <b>{dur}</b> Minutes
                    </li>
                    <li>
                      Total Questions: <b>{questions?.length}</b>
                    </li>
                    <li>
                      Marking Scheme: <b>{quizData?.point}</b> point(s) is
                      assigned to every question answered correctly
                    </li>
                    <li>
                      Pass Mark: <b>{`${quizData?.pass_mark}%`}</b> is the pass
                      mark for the exam
                    </li>
                  </ul>
                  <br />

                  <p>
                    Click to <b>START EXAM</b> to Begin
                  </p>
                  <Stack
                    direction={"row"}
                    spacing={3}
                    sx={{ m: "10% 5% 5% 30%" }}
                  >
                    <Button
                      onClick={() => navigate(-1)}
                      color="error"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        setShowQ(true);
                      }}
                      variant="contained"
                    >
                      Start Exam
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item md={3}></Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <QuestionPage
            studentData={studentData}
            duration={dur}
            quizData={quizData}
            questions={questions}
          />
        </>
      )}
    </>
  );
};

export default StartQuizTwo;
