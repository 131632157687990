import React from "react";
import moment from "moment";
import {
  Modal,
  Fade,
  Backdrop,
  Box,
  Button,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Paper,
  TableCell,
  TableContainer,
} from "@mui/material";
function ViewLeaderBoard({ handleClose, open, data }) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95vw", sm: "90vw", md: "fit-content" },
    height: "90vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                close
              </Button>
            </Box>

            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              <Paper
                elevation={2}
                sx={{
                  padding: "20px",
                  margin: "30px 20px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="LeaderBoard"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: "#1976d2 !important",
                        }}
                      >
                        <TableCell>Rank</TableCell>

                        <TableCell align={"left"} padding={"normal"}>
                          Name
                        </TableCell>
                        <TableCell align={"left"} padding={"normal"}>
                          Gender
                        </TableCell>
                        <TableCell align={"left"} padding={"normal"}>
                          Score
                        </TableCell>
                        <TableCell align={"left"} padding={"normal"}>
                          Date
                        </TableCell>
                        <TableCell align={"left"} padding={"normal"}>
                          Time Taken
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((d, index) => (
                        <TableRow hover key={index}>
                          <TableCell padding={"normal"}>
                            {" "}
                            <span
                              style={{
                                fontWeight: "bolder",
                                color: "white",
                                backgroundColor: `${
                                  index === 0
                                    ? "green"
                                    : `${
                                        index === 1
                                          ? "#1976d2"
                                          : `${
                                              index === 2 ? "orange" : "black"
                                            }`
                                      }`
                                }`,
                                padding: "5px 10px",
                                borderRadius: "10px",
                              }}
                            >
                              {index + 1}
                            </span>
                          </TableCell>
                          <TableCell padding={"normal"}>
                            {d.student_name}
                          </TableCell>
                          <TableCell padding={"normal"}>{d.gender}</TableCell>
                          <TableCell padding={"normal"}>{d.point}</TableCell>
                          <TableCell padding={"normal"}>
                            {moment(d.date_taken).fromNow()}
                          </TableCell>
                          <TableCell padding={"normal"}>
                            {d.time_taken}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default ViewLeaderBoard;
