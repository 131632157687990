import React from "react";
import { Box, Paper, Button, Typography, Grid } from "@mui/material";
import { Error } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ mt: "5%" }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Paper sx={{ p: 4, textAlign: "center" }}>
            <Error sx={{ fontSize: "5rem", color: "red" }} />
            <Typography variant="h2" color="textPrimary" mt={2} mb={4}>
              404 - Page Not Found
            </Typography>
            <Typography variant="body1" color="textSecondary" mb={4}>
              Oops! The page you are looking for does not exist.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Error404;
