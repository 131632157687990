import React from 'react'
import FirstComponent from './components/FirstComponent'

function Signup() {
  return (
    <>
    <div>
 <FirstComponent/>
    </div>
   
    </>
  )
}

export default Signup