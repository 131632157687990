import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Paper,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Api from "../../api/API";
import SkeletonContainer from "../../components/SkeletonContainer";
import SuperAdminTable from "../../components/superadmin/SuperAdminTable";

const SuperAdminStudents = () => {
  const [student, setStudent] = useState(null);
  const [searched, setSearched] = useState("");
  const [loads, setLoad] = useState(false);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState("username");
  const [isPaid, setIsPaid] = useState("");
  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.superGetStudents(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setStudent(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_created),
                db = new Date(b.date_created);
              return db - da;
            })
          );
        }
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, [setStudent]);
  useEffect(() => {
    setRows(student);
  }, [student]);
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "firstname") {
      const filteredRows = student.filter((row) => {
        return row.firstname
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "additional") {
      const filteredRows = student.filter((row) => {
        return row.additional
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "lastname") {
      const filteredRows = student.filter((row) => {
        return row.lastname?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "username") {
      const filteredRows = student.filter((row) => {
        return row.username?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "email") {
      const filteredRows = student.filter((row) => {
        return row.email?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
  };
  const searchPaid = (search) => {
    const filteredRows = student.filter((row) => {
      return row.paid === (search === "Paid" ? "true" : "false");
    });
    setRows(filteredRows);
  };
  const columns = [
    "First Name",
    "Last Name",
    "Username",
    "Email",
    "Paid",
    "Plan",
    "Start Date",
    "Expiry Date",
  ];

  return (
    <>
      <Box mt={10}>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"firstname"}>First Name</MenuItem>
                      <MenuItem value={"lastname"}>Last Name</MenuItem>
                      <MenuItem value={"additional"}>
                        Student Additional
                      </MenuItem>
                      <MenuItem value={"username"}>Username</MenuItem>
                      <MenuItem value={"email"}>Email</MenuItem>
                      <MenuItem value={"Paid"}>Paid</MenuItem>
                    </Select>
                  </FormControl>
                  {filter === "Paid" ? (
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-select-small">Filter by</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={isPaid}
                        label="Filter by"
                        onChange={(event) => {
                          setIsPaid(event.target.value);
                          searchPaid(event.target.value);
                        }}
                      >
                        <MenuItem value={""}></MenuItem>
                        <MenuItem value={"Paid"}>True</MenuItem>
                        <MenuItem value={"Not Paid"}>False</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      placeholder={`Search by ${filter}`}
                      value={searched}
                      onChange={(event) => requestSearch(event.target.value)}
                    />
                  )}
                </Stack>
                <SuperAdminTable
                  title={"All Students"}
                  data={rows}
                  column={columns}
                  type="student"
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SuperAdminStudents;
