import React, { useState, useEffect } from "react";
import { Box, TextField, Paper } from "@mui/material";
import StudentTable from "../../components/student/StudentTable";
import SkeletonContainer from "../../components/SkeletonContainer";
import Api from "../../api/API";
import { Button, Stack } from "@mui/material";
import { CSVLink } from "react-csv";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import GStudentSub from "../../components/GStudentSub";
import GInfo from "../../components/GInfo";

const GStudentViewResult = () => {
  const [searched, setSearched] = useState("");
  const [quizzes, setQuizzes] = useState(null);
  const [rows, setRows] = useState([]);
  const [result, setResult] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSub, setOpenSub] = useState(false);
  const [loads, setLoad] = useState(false);
  const [filter, setFilter] = React.useState("quiz_name");
  const [paid, setPaid] = useState(false);
  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  const handleOpenSub = () => {
    setOpenSub(!openSub);
  };

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.getInStudentData(token)
      .then((res) => {
        setPaid(res?.data?.data?.paid === "true" ? true : false);
      })
      .catch((err) => console.log(err));
    API.getAllResult(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setResult(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_taken),
                db = new Date(b.date_taken);
              return db - da;
            })
          );
        }
        setLoad(false);
      })
      .catch((err) => {
        setResult(null);

        setLoad(false);
      });

    API.getGlobalStudentQuiz(token)
      .then((res) => {
        setQuizzes(res.data.data);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, []);
  const columns = [
    "Quiz Name",
    "Point",
    "Percentage",
    "Status",
    "Date Taken",
    "Time Taken",
  ];
  useEffect(() => {
    setRows(result);
  }, [result]);
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "quiz_name") {
      const filteredRows = result.filter((row) => {
        return row.quiz_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "student_name") {
      const filteredRows = result.filter((row) => {
        return row.student_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "remark") {
      const filteredRows = result.filter((row) => {
        return row.status?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "point") {
      const filteredRows = result.filter((row) => {
        return row.point <= searchedVal;
      });
      setRows(filteredRows);
    }
    if (filter === "percent") {
      const filteredRows = result.filter((row) => {
        return row.percent <= searchedVal;
      });
      setRows(filteredRows);
    }
    if (filter === "date") {
      const filteredRows = result.filter((row) => {
        return row.date_taken.includes(searchedVal);
      });
      setRows(filteredRows);
    }
  };
  var date = new Date();
  let count = 0;
  if (quizzes !== null && quizzes !== null) {
    quizzes.forEach((ele) => {
      ele?.taken && count++;
    });
  }
  return (
    <>
      <GStudentSub
        open={openSub}
        handleClose={handleOpenSub}
        current={"free"}
      />
      <GInfo
        opened={open}
        setOpened={setOpen}
        setOpenSub={handleOpenSub}
        type="result"
      />
      <Box mt={10}>
        {loads ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <Box
              sx={{
                mt: 3,

                p: "3% 5%",
              }}
            >
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"quiz_name"}>Quiz Name</MenuItem>
                      <MenuItem value={"remark"}>Remark</MenuItem>
                      <MenuItem value={"point"}>Point</MenuItem>
                      <MenuItem value={"date"}>Date</MenuItem>
                      <MenuItem value={"percent"}>Percentage</MenuItem>
                      <MenuItem value={"student_name"}>Student Name</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />{" "}
                </Stack>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    ml: "38%",
                    mb: 1,
                    mt: 2,
                  }}
                >
                  <CSVLink
                    data={rows || []}
                    filename={`data-${date}.csv`}
                    className="export"
                    target="_blank"
                  >
                    Export Csv
                  </CSVLink>
                </Button>

                <StudentTable
                  title={"Quiz Results"}
                  data={rows}
                  column={columns}
                  type="result"
                  paid={paid}
                  handleInfo={setOpen}
                  handleSub={handleOpenSub}
                />
              </Paper>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default GStudentViewResult;
