import React, { useState } from "react";

import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";

import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import API from "../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";

function RequestTable({ title, data, column, type }) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();

  const filter_data = data?.filter((item) => item.active === "false");
  const approveRequest = (id) => {
    const Api = new API();
    dispatch(setLoading(true));

    Api.approveRequest(selected.map((item) => item.user_id))
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: res.data.message,
            open: true,
            variant: "success",
          })
        );
        window.location.reload();
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: err.response.data.message,
            open: true,
            variant: "error",
          })
        );
      });
  };

  const deleteRequest = (id) => {
    if (window.confirm(`Are you sure you want to delete this ${type}?`)) {
      const Api = new API();
      dispatch(setLoading(true));
      const user_ids = selected.map((item) => item.user_id);
      Api.deleteRequest(user_ids)
        .then((res) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "success",
            })
          );
          window.location.reload();
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              open: true,
              message: err.response.data.message,
              variant: "error",
            })
          );
        });
    }
  };
  const EnhancedTableToolbar = ({ itemSelected }) => {
    let item_name = itemSelected.length > 0;

    return (
      <Toolbar
        sx={{
          position: item_name ? "sticky" : "initial",
          display: { xs: "block", sm: "grid" },
          gridTemplateColumns: "0.5fr 2fr",
          columnGap: 2,
          top: "68px",
          pl: { sm: 2 },
          paddingY: { xs: 1, sm: 0 },
          pr: { xs: 1, sm: 1 },
          zIndex: 2,
          ...(item_name && {
            bgcolor: (theme) => alpha("#7CB9E8", 1),
          }),
        }}
      >
        {item_name ? (
          <Typography
            // sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
            display={{ xs: "none", sm: "block" }}
          >
            selected
          </Typography>
        ) : null}

        {item_name ? (
          <Box
            sx={{
              display: "flex",
              columnGap: 2,
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            <Tooltip title="Delete">
              <Button
                variant="contained"
                color="error"
                sx={{ wordWrap: "break-word", fontSize: 12 }}
                onClick={() => {
                  deleteRequest(selected.user_id);
                }}
              >
                Delete {type}
              </Button>
            </Tooltip>

            <Tooltip title="Authorise">
              <Button
                variant="contained"
                color="success"
                sx={{ wordWrap: "break-word", fontSize: 12 }}
                onClick={() => {
                  approveRequest(selected.user_id);
                }}
              >
                Approve {type}
              </Button>
            </Tooltip>
          </Box>
        ) : null}
      </Toolbar>
    );
  };

  const handleClick = (event, item) => {
    // Check if the clicked item is already selected

    if (!Array.isArray(selected)) {
      setSelected([item]);

      return;
    }
    const selectedIndex = selected?.findIndex(
      (selectedItem) => selectedItem.user_id === item.user_id
    );

    // If already selected, remove it
    if (selectedIndex !== -1) {
      const updatedSelected = [...selected];
      updatedSelected?.splice(selectedIndex, 1);
      setSelected(updatedSelected);
    }
    // If not selected, add it
    else {
      setSelected([...selected, item]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let isSelected;
  isSelected = (row) => {
    return selected.findIndex((item) => item.user_id === row.user_id) !== -1;
  };

  var date = new Date();

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              ml: "38%",
              mb: 1,
              mt: 2,
            }}
          >
            <CSVLink
              data={data || []}
              filename={`data-${date}.csv`}
              className="export"
              target="_blank"
            >
              Export Csv
            </CSVLink>
          </Button>
          <Typography
            sx={{ flex: "1 1 100%", m: 1 }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
          <Typography
            sx={{ flex: "1 1 100%", m: 1 }}
            variant="subtitle1"
            id="tableTitle"
            component="div"
          >
            Click on any of the names below to approve or reject it
          </Typography>
          <EnhancedTableToolbar itemSelected={selected} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  {column.map((col) => (
                    <TableCell key={col} align={"left"} padding={"normal"}>
                      <TableSortLabel>{col}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filter_data !== null && filter_data?.length !== 0
                  ? filter_data
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((d, index) => {
                        const isItemSelected = isSelected(d.user_id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, d)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.user_id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isSelected(d)}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.name}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.username}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.email}
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.user_type}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={filter_data ? filter_data.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}

export default RequestTable;
