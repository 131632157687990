import React, { useState } from "react";
import parse from "html-react-parser";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Views from "../Views";
import Edits from "../Edits";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Stack } from "@mui/material";
import { CSVLink } from "react-csv";
import { WhatsappShareButton, EmailShareButton } from "react-share";
import EditInterface from "../../pages/teacher/EditQuestion/EditInterface";
import { useEffect } from "react";
import Api from "../../api/API";
import ViewLeaderBoard from "../ViewLeaderBoard";

import { useDispatch } from "react-redux";
import { setOpen as setOpenState } from "../../slices/StateManager";

const TeacherTable = ({
  title,
  rows,
  column,
  leaveClass,
  type,
  quizData,
  duplicate,
  do_not_show,
}) => {
  const [selected, setSelected] = useState([]);
  const [printRow, setPrintRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpened] = useState(false);
  const [openEDit, setOpenEdit] = useState(false);
  const [openEDitq, setOpenEditQ] = useState(false);
  const [leaderView, setLeaderView] = useState(false);
  const [leaderBoardData, setLeaderBoardData] = useState(null);
  const dispatch = useDispatch();
  const errorText =
    "No data to display or Something could be wrong with your internet connection.";
  const handleOpen = () => setOpened(true);
  const handleClose = () => setOpened(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const navigate = useNavigate();
  const handleCloseEditQ = () => {
    setOpenEditQ(false);

    setSelected([]);
  };
  const role = localStorage.getItem("role");

  const navigateForm = (item) => {
    if (item.status === "publish") {
      if (
        window.confirm(
          "Do you want to Edit question(s)? Note that the Test will be UNPUBLISHED."
        )
      ) {
        const API = new Api();
        API.unpublishQuiz(item.quiz_id)
          .then((res) => {
            if (role === "admin") {
              navigate(`/dashboard/admin/teacher/quiz/${selected.quiz_id}`);
            } else {
              navigate(`/dashboard/teacher/quiz/${selected.quiz_id}`);
            }
          })
          .catch((err) => alert(err.response.data.message));
      }
    } else {
      if (role === "admin") {
        navigate(`/dashboard/admin/teacher/quiz/${selected.quiz_id}`);
      } else {
        navigate(`/dashboard/teacher/quiz/${selected.quiz_id}`);
      }
    }
  };
  const handleLeaderBoardView = () => {
    const api = new Api();

    api
      .getLeaderBoard(selected.quiz_id)
      .then((res) => {
        setLeaderBoardData(res.data.data);
        setLeaderView(true);
      })
      .catch((err) => {
        dispatch(
          setOpenState({
            open: true,
            message: err.response.data.message,
            variant: "error",
          })
        );
      });
  };
  const EnhancedTableToolbar = ({ itemSelected }) => {
    let item_name;
    if (type === "result") {
      item_name = itemSelected.id;
    } else if (type === "class") {
      item_name = itemSelected.class_id;
    } else if (type === "quiz") {
      item_name = itemSelected.quiz_id;
    } else if (type === "student") {
      item_name = itemSelected.student_id;
    } else if (type === "question" || type === "spelling") {
      item_name = itemSelected.id;
    }

    return (
      <Toolbar
        sx={{
          position: item_name ? "sticky" : "initial",
          top: "68px",
          display: { xs: "block", sm: "grid" },
          gridTemplateColumns: "0.5fr 2fr",
          columnGap: 2,
          pl: { sm: 2 },
          paddingY: { xs: 1, sm: 0 },
          pr: { xs: 1, sm: 1 },
          zIndex: 2,
          ...(item_name && {
            bgcolor: (theme) => alpha("#7CB9E8", 1),
          }),
        }}
      >
        {item_name ? (
          type === "class" ? (
            <Box>
              <Typography
                // sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                <span>
                  Class ID: <b>{selected?.class_id}</b>
                </span>
              </Typography>
              <Typography
                // sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                Share Via:
              </Typography>
              <Box sx={{ display: "flex", columnGap: 2, mb: 2 }}>
                <Tooltip title="Share class to Mail">
                  <EmailShareButton
                    style={{
                      backgroundColor: "purple",
                      color: "white",
                      minWidth: "80px",
                      padding: "6px 16px",
                      lineHeight: 1.75,
                      borderRadius: 5,
                      boxShadow: "0px 1px 5px grey",
                    }}
                    subject={`Class Registration`}
                    body={`The ${itemSelected.class_name} ID To Be Used For Your Class Registration Is ${itemSelected.class_id}`}
                  >
                    Email
                  </EmailShareButton>
                </Tooltip>
                <Tooltip title="Share class to whatsapp">
                  <WhatsappShareButton
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      minWidth: "80px",
                      padding: "6px 16px",
                      lineHeight: 1.75,
                      borderRadius: 5,
                      boxShadow: "0px 1px 5px grey",
                      fontSize: 12,
                    }}
                    url={`The ${itemSelected.class_name} ID To Be Used For Your Class Registration Is ${itemSelected.class_id}`}
                  >
                    Whatsapp
                  </WhatsappShareButton>
                </Tooltip>
              </Box>
            </Box>
          ) : (
            <Typography
              // sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
              display={{ xs: "none", sm: "block", md: "block" }}
            >
              Set
            </Typography>
          )
        ) : null}

        {item_name ? (
          <Box
            sx={{
              display: { xs: "grid", sm: "flex" },
              gridTemplateColumns: { xs: "1fr 1fr" },
              columnGap: 1,
              rowGap: 2,
              justifyContent: { xs: "flex-start", sm: "flex-end" },
            }}
          >
            {type === "result" && (
              <Tooltip title="View LeaderBoard">
                <Button
                  variant="contained"
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={handleLeaderBoardView}
                >
                  View LeaderBoard
                </Button>
              </Tooltip>
            )}
            {type !== "result" ? (
              <Stack direction={"row"} spacing={1}>
                {(type === "question" || type === "spelling") &&
                  (role === "teacher" || role === "admin") &&
                  type !== "result" && (
                    <Tooltip title="Edit selected Question">
                      <Button
                        variant={"contained"}
                        color="warning"
                        sx={{
                          wordWrap: "break-word",
                          fontSize: 12,
                          pl: 1,
                          pr: 1,
                        }}
                        onClick={() => {
                          setOpenEditQ(true);
                        }}
                      >
                        Edit {type}
                      </Button>
                    </Tooltip>
                  )}

                {!do_not_show && (
                  <Tooltip title="Delete">
                    {(role === "teacher" || role === "admin") &&
                      type !== "result" && (
                        <Button
                          variant="contained"
                          color="error"
                          sx={{
                            wordWrap: "break-word",
                            width: { xs: "100%", sm: "fit-content" },
                            fontSize: 12,
                            pl: 1,
                            pr: 1,
                          }}
                          onClick={() => {
                            if (type === "class") {
                              setSelected([]);
                              return leaveClass(selected.class_id);
                            } else if (type === "quiz") {
                              setSelected([]);
                              return leaveClass(selected.quiz_id);
                            } else if (type === "student") {
                              setSelected([]);
                              return leaveClass(selected.student_id);
                            } else if (
                              type === "question" ||
                              type === "spelling"
                            ) {
                              setSelected([]);
                              return leaveClass(selected.id);
                            }
                          }}
                        >
                          Delete {type}
                        </Button>
                      )}
                  </Tooltip>
                )}
              </Stack>
            ) : null}

            {type !== "question" && type !== "spelling" ? (
              <>
                <Tooltip title="View">
                  <Button
                    variant={"contained"}
                    sx={{ wordWrap: "break-word", fontSize: 12, pl: 1, pr: 1 }}
                    onClick={handleOpen}
                  >
                    View {type === "quiz" ? "Questions / parameters" : type}
                  </Button>
                </Tooltip>
                {type !== "result" && type !== "student" ? (
                  <Tooltip title="Edit">
                    <Button
                      variant={"contained"}
                      color="warning"
                      sx={{
                        wordWrap: "break-word",
                        fontSize: 12,
                        pl: 1,
                        pr: 1,
                      }}
                      onClick={() => setOpenEdit(true)}
                    >
                      Edit{" "}
                      {type !== "class" && type !== "quiz"
                        ? type
                        : "parameters"}
                    </Button>
                  </Tooltip>
                ) : null}

                {type === "quiz" ? (
                  <>
                    <Tooltip title="Duplicate Quiz">
                      <Button
                        sx={{
                          // width: "100%",
                          wordWrap: "break-word",
                          fontSize: 12,
                          pl: 1,
                          pr: 1,
                        }}
                        variant="contained"
                        color="info"
                        onClick={() => duplicate(selected?.quiz_id)}
                      >
                        Duplicate Test
                      </Button>
                    </Tooltip>
                    <Tooltip title="Add Question">
                      <Button
                        sx={{
                          fontSize: 12,
                          pl: 1,
                          pr: 1,
                        }}
                        variant="contained"
                        onClick={() => navigateForm(selected)}
                      >
                        add/edit Question
                      </Button>
                    </Tooltip>
                  </>
                ) : null}
              </>
            ) : null}
          </Box>
        ) : null}
      </Toolbar>
    );
  };

  const handleClick = (event, item) => {
    if (type === "result") {
      if (selected.id === item.id) {
        setSelected([]);
      } else {
        setSelected(item);
      }
    } else if (type === "quiz") {
      if (selected.quiz_id === item.quiz_id) {
        setSelected([]);
      } else {
        setSelected(item);
      }
    } else if (type === "class") {
      if (selected.class_id === item.class_id) {
        setSelected([]);
      } else {
        setSelected(item);
      }
    } else if (type === "student") {
      if (selected.student_id === item.student_id) {
        setSelected([]);
      } else {
        setSelected(item);
      }
    } else if (type === "question" || type === "spelling") {
      if (selected.id === item.id) {
        setSelected([]);
      } else {
        setSelected(item);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let isSelected;
  if (type === "result") {
    isSelected = (id) => selected.id === id;
  } else if (type === "class") {
    isSelected = (class_id) => selected.class_id === class_id;
  } else if (type === "quiz") {
    isSelected = (quiz_id) => selected.quiz_id === quiz_id;
  } else if (type === "student") {
    isSelected = (student_id) => selected.student_id === student_id;
  } else if (type === "question" || type === "spelling") {
    isSelected = (id) => selected.id === id;
  }
  var date = new Date();
  useEffect(() => {
    let newRow = [];
    if (type === "spelling") {
      newRow = rows?.map((r) => {
        if (r?.type === "option1") {
          return {
            ...r,

            hint: r?.hint?.toString()?.replaceAll("~~", "'"),
            address: r?.address?.toString()?.replaceAll("~~", "'"),
            word: r?.word?.toString()?.replaceAll("~~", "'"),
          };
        } else {
          return {
            ...r,
            hint: r?.hint?.toString()?.replaceAll("~~", "'"),
            word: r?.word?.toString()?.replaceAll("~~", "'"),
          };
        }
      });
    } else if (type === "question") {
      newRow = rows?.map((r) => {
        if (r?.type === "match") {
          return {
            ...r,
            question: r?.question
              ?.toString()
              ?.replace(/<\/?[^>]+(>|$)/g, "")
              ?.replaceAll("~~", "'"),
            answers: JSON.stringify(r?.answers)?.replaceAll('"', "'"),
            options: JSON.stringify(r?.options)?.replaceAll('"', "'"),
          };
        } else {
          return {
            ...r,
            question: r?.question
              ?.toString()
              ?.replace(/<\/?[^>]+(>|$)/g, "")
              ?.replaceAll("\n", "")
              ?.replaceAll("~~", "'"),
            answers: r?.answers?.toString()?.replaceAll("~~", "'"),
            options: r?.options?.toString()?.replaceAll("~~", "'"),
          };
        }
      });
    } else {
      newRow = rows;
    }
    setPrintRow(newRow);
  }, [type, rows]);

  const handleExportCSV = () => {
    const csvData = printRow?.map((row) => ({
      ID: row?.id,
      "STUDENT ID": row?.student_id,
      "TEST ID": row?.quiz_id,
      "STUDENT NAME": row?.student_name,
      "QUIZ NAME": row?.quiz_name,
      "STUDENT ADDITIONAL": row?.student_additional,
      POINT: row?.point,
      PERCENTAGE: row?.percent,
      // ANSWERS: row?.answers?.map((item) => item?.options[0]).join(", "),
      STATUS: row?.status,
      "RIGHT ANSWERS": row?.right_answers,
      "WRONG ANSWERS": row?.wrong_answers,
      "TIME TAKEN": row?.time_taken,
      "DATE TAKEN": moment(row?.date_taken).format("DD/MM/YYYY: hh:mm"),
    }));

    return csvData;
  };

  return (
    <>
      <ViewLeaderBoard
        open={leaderView}
        data={leaderBoardData}
        handleClose={() => setLeaderView(false)}
      />
      <Views
        open={open}
        handleClose={handleClose}
        data={selected.length === 0 ? null : selected}
        type={type}
      />
      <Edits
        open={openEDit}
        handleClose={handleCloseEdit}
        data={selected.length === 0 ? null : selected}
        type={type}
      />
      <EditInterface
        open={openEDitq}
        handleClose={handleCloseEditQ}
        data={selected.length === 0 ? null : selected}
        quizData={quizData}
      />

      <Box sx={{ width: { md: "105%", lg: "100%" } }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              ml: "38%",
              mb: 1,
              mt: 2,
            }}
          >
            <CSVLink
              data={type === "result" ? handleExportCSV() || [] : rows || []}
              filename={`data-${date}.csv`}
              className="export"
              target="_blank"
            >
              Export Csv
            </CSVLink>
          </Button>
          <Typography
            sx={{ flex: "1 1 100%", m: 1 }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
          {type === "student" && (
            <Typography variant="subtitle1" component="div" sx={{ ml: "10px" }}>
              Click on any of the Students below to view other options and
              details
            </Typography>
          )}

          {type === "quiz" && (
            <Typography variant="subtitle1" component="div" sx={{ ml: "10px" }}>
              Click on any of the Tests below to access the option to view the
              questions, along with other options
            </Typography>
          )}

          {type === "class" && (
            <Typography variant="subtitle1" component="div" sx={{ ml: "10px" }}>
              Click on any of the listed classes to reveal options, including
              the "Class ID" and more details.
            </Typography>
          )}
          {type === "result" && (
            <Typography variant="subtitle1" component="div" sx={{ ml: "10px" }}>
              Click on any of the listed Results to reveal options, including
              more details.
            </Typography>
          )}

          <EnhancedTableToolbar itemSelected={selected} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  {column.map((col) => (
                    <TableCell key={col} align={"left"} padding={"normal"}>
                      <TableSortLabel>{col}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {type === "result" ? (
                <TableBody>
                  {rows !== null && rows.length !== 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, d)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row">
                              {d.quiz_name}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.student_name}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d?.student_additional}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.point}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.percent}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.status}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {moment(d.date_taken).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.time_taken}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : type === "student" ? (
                <TableBody>
                  {rows !== null && rows.length !== 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.student_id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, d)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.student_id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.firstname}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.lastname}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.username}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.additional}
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.email}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.parent_email}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {moment(d.date_created).format("DD-MM-YYYY")}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : type === "class" ? (
                <TableBody>
                  {rows !== null && rows.length !== 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.class_id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, d)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.class_id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.class_name}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.status === "active" ? "Open" : "Closed"}
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.subject}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {moment(d.date_created).format("DD-MM-YYYY")}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : type === "quiz" ? (
                <TableBody>
                  {rows !== null && rows.length !== 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.quiz_id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, d)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.quiz_id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.quiz_name}
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.subject}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.status}
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.quiz_type}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {moment(d.date_created).format("DD-MM-YYYY")}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : type === "question" ? (
                <TableBody>
                  {rows !== null && rows.length !== 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(e) =>
                              role === "teacher" || role === "admin"
                                ? handleClick(e, d)
                                : e.preventDefault()
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {parse(d.question?.replaceAll("~~", "'"))}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.type === "sc"
                                ? "Single Choice"
                                : d.type === "mc"
                                ? "Multiple Choice"
                                : d.type === "fg"
                                ? "Fill In The Blank"
                                : d.type === "tf"
                                ? "True or False"
                                : d.type}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d?.category}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.type !== "match"
                                ? d.options
                                    ?.map((o) => o?.replaceAll("~~", "'"))
                                    .join(", ")
                                : JSON.stringify(d.options)}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.type !== "match"
                                ? d.answers
                                    ?.map((o) => o?.replaceAll("~~", "'"))
                                    .join(", ")
                                : JSON.stringify(d.answers)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : type === "spelling" ? (
                <TableBody>
                  {rows !== null && rows.length !== 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(e) =>
                              role === "teacher" || role === "admin"
                                ? handleClick(e, d)
                                : e.preventDefault()
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.type}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d?.category}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.hint?.replaceAll("~~", "'")}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.type === "option1"
                                ? d.address?.replaceAll("~~", "'")
                                : d.address}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.word?.replaceAll("~~", "'")}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={rows ? rows.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default TeacherTable;
