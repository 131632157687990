import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DatePicking = ({ dob, setDob }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disableFuture
        label="Date of Birth"
        openTo="year"
        views={["year", "month", "day"]}
        value={dob}
        onChange={(newValue) => {
          setDob(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} required name="dob" variant="standard" />
        )}
      />
    </LocalizationProvider>
  );
};
export default DatePicking;
