import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Paper,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Api from "../../api/API";
import AdminTable from "../../components/admin/AdminTable";
import SkeletonContainer from "../../components/SkeletonContainer";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";

const AdminStudents = () => {
  const [student, setStudent] = useState(null);
  const [searched, setSearched] = useState("");
  const [loads, setLoad] = useState(false);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = React.useState("username");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.getAdminStudents(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setStudent(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_created),
                db = new Date(b.date_created);
              return db - da;
            })
          );
        }
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, [setStudent]);
  useEffect(() => {
    setRows(student);
  }, [student]);
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "firstname") {
      const filteredRows = student.filter((row) => {
        return row.firstname
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "lastname") {
      const filteredRows = student.filter((row) => {
        return row.lastname?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "additional") {
      const filteredRows = student.filter((row) => {
        return row.additional
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "username") {
      const filteredRows = student.filter((row) => {
        return row.username?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "email") {
      const filteredRows = student.filter((row) => {
        return row.email?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
  };

  const deleteUser = (id) => {
    if (window.confirm(`Are you sure you want to delete this Student?`)) {
      const API = new Api();
      const token = localStorage.getItem("token");
      dispatch(setLoading(true));
      API.deleteStudent(token, id)
        .then((res) => {
          if (res.data.message === "Student Deleted Successfully!") {
            API.getAdminStudents(token)
              .then((res) => {
                if (
                  res.data.data !== undefined &&
                  res.data.data !== null &&
                  res.data.data.length > 0
                ) {
                  setStudent(
                    res.data.data.sort((a, b) => {
                      let da = new Date(a.date_created),
                        db = new Date(b.date_created);
                      return db - da;
                    })
                  );
                }
                // setStudent(res.data.data);
                dispatch(setLoading(false));
              })
              .catch((err) => {
                dispatch(setLoading(false));
                dispatch(
                  setOpen({
                    message: err.response.data.message,
                    open: true,
                    variant: "error",
                  })
                );
              });

            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
          } else {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };

  const columns = [
    "First Name",
    "Last Name",
    "Username",
    "Student Additional",
    "Email",
    "Parent Email",
    "Date Created",
  ];

  return (
    <>
      <Box mt={10}>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"firstname"}>First Name</MenuItem>
                      <MenuItem value={"lastname"}>Last Name</MenuItem>
                      <MenuItem value={"additional"}>
                        Student Additonal
                      </MenuItem>
                      <MenuItem value={"username"}>Username</MenuItem>

                      <MenuItem value={"email"}>Email</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />{" "}
                </Stack>
                <AdminTable
                  title={"All Students"}
                  data={rows}
                  column={columns}
                  type="student"
                  deleteUser={deleteUser}
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminStudents;
