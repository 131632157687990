import { Box, Button, Typography, Grid, Stack } from "@mui/material";
import React from "react";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import logo from "././../../images/edu-dark.png";
import "./login.css";
import Footer from "./Footer";

import { Link, NavLink, useLocation } from "react-router-dom";

const drawerWidth = 240;

function About(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={logo} alt="logo" />
      <Divider />
      <List>
        <ListItem disablePadding>
          <Link to="/">
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Home" />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/about">
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="About Us" />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/contact">
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Contact" />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Grid container>
        <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
          <AppBar
            component="nav"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 0px 0px  0px",
              borderBottom: "6px solid  #3C4DA7",
              maxWidth: "1450px",
              marginLeft: "auto",
              marginRight: "auto",
              right: "auto",
            }}
          >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <img src={logo} alt="logo" />

              <Box
                sx={{
                  display: { sm: "flex" },
                  flexDirection: "row",
                  gap: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#3C4DA7",
                }}
              >
                <a href="/" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={`${
                      pathname === "/" ? "gradient-text text-bold" : ""
                    }`}
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", sm: "none", md: "block" },
                      fontWeight: `${pathname === "/" ? "bold" : "normal"}`,
                    }}
                  >
                    Home
                  </Typography>
                </a>
                <a href="/about" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={`${
                      pathname === "/about" ? "gradient-text text-bold" : ""
                    }`}
                    sx={{
                      flexGrow: 1,
                      fontWeight: `${
                        pathname === "/about" ? "bold" : "normal"
                      }`,
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  >
                    About Us
                  </Typography>
                </a>
                <a href="/contact" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={`${
                      pathname === "/contact" ? "gradient-text text-bold" : ""
                    }`}
                    sx={{
                      flexGrow: 1,
                      fontWeight: `${
                        pathname === "/contact" ? "bold" : "normal"
                      }`,
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  >
                    Contact
                  </Typography>
                </a>
                <NavLink to="/signin" style={{ textDecoration: "none" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      flexGrow: 1,
                      mx: 2,
                      color: "#3C4DA7",
                      borderColor: " #3C4DA7",
                    }}
                  >
                    Sign In
                  </Button>
                </NavLink>
                <NavLink to="/signup" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", sm: "none", md: "block" },
                      background:
                        "linear-gradient(90deg, #171E41 0%, #3C4DA7 100%)",
                    }}
                  >
                    Sign Up
                  </Button>
                </NavLink>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    color: "#3C4DA7",
                    mr: 2,
                    display: { md: "none", lg: "none" },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>

          <Drawer
            container={container}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>

          <Grid container p={5}>
            <Grid item md={2}></Grid>
            <Grid item md={8} sx={{ mt: 10 }} mb={10}>
              <Stack>
                <Stack spacing={2} mb={4}>
                  <Typography
                    variant="h4"
                    className="gradient-text"
                    sx={{
                      borderBottom: "5px solid #000369",
                      width: "fit-content",
                      paddingBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    About Us
                  </Typography>

                  <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                    Welcome to Edusupport Web App, your ultimate solution for
                    educational support and enhancement. Developed and owned by
                    Dovemax Consulting, Edusupport is dedicated to empowering
                    institutions and individuals with innovative tools for
                    interactive learning, assessment, and academic improvement.
                  </Typography>
                </Stack>

                <Stack spacing={2} mb={4}>
                  <Typography
                    variant="h4"
                    className="gradient-text"
                    sx={{
                      borderBottom: "5px solid #000369",
                      width: "fit-content",
                      paddingBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Our Mission
                  </Typography>

                  <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                    At Edusupport, our mission is to revolutionize the
                    educational landscape by providing a comprehensive platform
                    that fosters collaborative learning, facilitates effective
                    teaching methodologies, and promotes student engagement. We
                    aim to bridge the gap between traditional teaching methods
                    and modern technological advancements to ensure a seamless
                    educational experience for all users.
                  </Typography>
                </Stack>

                <Stack spacing={2} mb={4}>
                  <Typography
                    variant="h4"
                    className="gradient-text"
                    sx={{
                      borderBottom: "5px solid #000369",
                      width: "fit-content",
                      paddingBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Key Features
                  </Typography>
                  <List>
                    <ListItem>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "justify" }}
                      >
                        <b>User Account Types:</b> Edusupport supports various
                        account types, including Admin, Teacher, Student, Global
                        Student, and Parent, ensuring personalized access and
                        functionality for all users across devices.
                      </Typography>
                    </ListItem>

                    <ListItem>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "justify" }}
                      >
                        <b>Quiz Setting:</b> Edusupport allows educators to
                        effortlessly create and customize quizzes tailored to
                        their curriculum and learning objectives. With a
                        user-friendly interface and a wide range of question
                        formats, including single-choice, multiple-choice,
                        true/false, matching, and fill-in-the-blank, and
                        randomization of questions to maintain test integrity,
                        instructors can easily assess students' understanding
                        and track their progress.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "justify" }}
                      >
                        <b>Spelling Practice:</b> Enhance language skills and
                        literacy with our interactive spelling feature. Students
                        can practice spelling words and improve vocabulary
                        through fun and engaging exercises designed to reinforce
                        learning and boost retention.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "justify" }}
                      >
                        <b>Institutional Support:</b> Edusupport is designed to
                        cater to the needs of educational institutions of all
                        sizes, from schools to universities. Our platform offers
                        centralized administration, allowing administrators to
                        manage user accounts, assign roles and permissions, and
                        track usage metrics seamlessly.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "justify" }}
                      >
                        <b>Homework Assistance:</b> Simplify homework
                        assignments with our integrated homework feature.
                        Educators can create and distribute assignments, monitor
                        submission status, and provide feedback efficiently,
                        while students benefit from organized task management
                        and timely reminders.
                      </Typography>
                    </ListItem>
                  </List>
                </Stack>
                <Stack spacing={2} mb={4}>
                  <Typography
                    variant="h4"
                    className="gradient-text"
                    sx={{
                      borderBottom: "5px solid #000369",
                      width: "fit-content",
                      paddingBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Why Choose Edusupport?
                  </Typography>

                  <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                    <List>
                      <ListItem>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "justify" }}
                        >
                          <b>User-Friendly Interface:</b> Our intuitive
                          interface makes navigation and usage effortless for
                          both educators and students, ensuring a smooth and
                          hassle-free experience.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "justify" }}
                        >
                          <b>Customization Options:</b> Edusupport offers
                          extensive customization options, allowing users to
                          tailor the platform to their specific needs and
                          preferences.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "justify" }}
                        >
                          <b> Comprehensive Support:</b> From technical
                          assistance to educational guidance, our dedicated
                          support team is committed to providing prompt and
                          effective solutions to all inquiries and concerns.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "justify" }}
                        >
                          <b> Flexible Pricing Plans:</b> We offer flexible
                          pricing plans, catering to the needs of different
                          institutions and individuals, ensuring affordability
                          and accessibility for all users.
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "justify" }}
                        >
                          <b>Continuous Improvement:</b> We are constantly
                          updating and enhancing Edusupport based on user
                          feedback and emerging educational trends, ensuring
                          that our platform remains at the forefront of
                          educational innovation.
                        </Typography>
                      </ListItem>
                    </List>
                  </Typography>
                </Stack>

                <Stack spacing={2} mb={4}>
                  <Typography
                    variant="h4"
                    className="gradient-text"
                    sx={{
                      borderBottom: "5px solid #000369",
                      width: "fit-content",
                      paddingBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Join Us Today !!
                  </Typography>

                  <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                    Experience the future of education with Edusupport Web App.
                    Whether you're an educator looking to enhance your teaching
                    practices or a student striving for academic excellence, we
                    invite you to join our community and embark on a journey of
                    discovery and growth. Let Edusupport be your partner in
                    educational success
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </>
  );
}

About.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default About;
