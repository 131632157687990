import React, { useState } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import Views from "../Views";
import moment from "moment";
import { Button, Stack } from "@mui/material";
import ViewLeaderBoard from "../ViewLeaderBoard";
import Api from "../../api/API";
import { useDispatch } from "react-redux";
import { setOpen as setOpenState } from "../../slices/StateManager";

const StudentTable = ({
  title,
  data,
  column,
  leaveClass,
  type = "leader",
  paid,
  handleInfo,
  handleSub,
}) => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [leaderView, setLeaderView] = useState(false);
  const [leaderBoardData, setLeaderBoardData] = useState(null);
  const handleClose = () => setOpen(false);
  const errorText =
    "No data to display or Something could be wrong with your internet connection.";
  const role = localStorage.getItem("role");

  const dispatch = useDispatch();
  const EnhancedTableToolbar = ({ itemSelected }) => {
    let item_name;
    if (type === "result") {
      item_name = itemSelected.id;
    } else if (type === "class") {
      item_name = itemSelected.class_id;
    } else if (type === "quiz") {
      item_name = itemSelected.quiz_id;
    }
    const checkTaken = () => {
      const taken = data?.filter((d) => d.taken);
      if (taken?.length > 0) {
        return false;
      } else {
        return true;
      }
    };

    return (
      <Toolbar
        sx={{
          position: item_name ? "sticky" : "initial",
          top: "68px",
          pl: { sm: 2 },
          paddingY: { xs: 1, sm: 0 },
          pr: { xs: 1, sm: 1 },
          zIndex: 2,
          ...(item_name && {
            bgcolor: (theme) => alpha("#7CB9E8", 1),
          }),
        }}
      >
        {item_name ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
            display={{ xs: "none", sm: "block" }}
          >
            selected
          </Typography>
        ) : (
          <>
            <Stack direction={"column"}>
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
              >
                {title}
              </Typography>

              {type === "quiz" && (
                <Typography variant="subtitle1">
                  Click on any of the Tests below to access the option to view
                  the questions, along with other options
                </Typography>
              )}

              {type === "class" && (
                <Typography variant="subtitle1">
                  Click on any of the listed classes to reveal options,
                  including the "Class ID" and more details.
                </Typography>
              )}

              {type === "result" && (
                <Typography variant="subtitle1">
                  Click on any of the listed Results to reveal options,
                  including more details.
                </Typography>
              )}
            </Stack>
          </>
        )}

        {item_name ? (
          <>
            {type === "result" ? (
              role === "gstudent" ? (
                paid ? (
                  <>
                    <Stack direction={"row"} spacing={3}>
                      <Tooltip title="View LeaderBoard">
                        <Button
                          variant="contained"
                          sx={{ whiteSpace: "nowrap" }}
                          onClick={handleLeaderBoardView}
                        >
                          View LeaderBoard
                        </Button>
                      </Tooltip>
                      <Tooltip title="View">
                        <Button variant="contained" onClick={handleOpen}>
                          View
                        </Button>
                      </Tooltip>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack direction={"row"} spacing={3}>
                      <Tooltip title="View LeaderBoard">
                        <Button
                          variant="contained"
                          sx={{ whiteSpace: "nowrap" }}
                          onClick={handleLeaderBoardView}
                        >
                          View LeaderBoard
                        </Button>
                      </Tooltip>
                      <Tooltip title="View">
                        <Button
                          variant="contained"
                          onClick={() => handleInfo(true)}
                        >
                          View
                        </Button>
                      </Tooltip>
                    </Stack>
                  </>
                )
              ) : (
                <>
                  <Stack direction={"row"} spacing={3}>
                    <Tooltip title="View LeaderBoard">
                      <Button
                        variant="contained"
                        sx={{ whiteSpace: "nowrap" }}
                        onClick={handleLeaderBoardView}
                      >
                        View LeaderBoard
                      </Button>
                    </Tooltip>
                    <Tooltip title="View">
                      <Button variant="contained" onClick={handleOpen}>
                        View
                      </Button>
                    </Tooltip>
                  </Stack>
                </>
              )
            ) : type === "class" ? (
              <Tooltip title="Leave Class">
                <Button
                  sx={{ whiteSpace: "nowrap" }}
                  variant="contained"
                  color="error"
                  onClick={() => {
                    leaveClass(selected.class_id);
                    setSelected([]);
                  }}
                >
                  Leave Class
                </Button>
              </Tooltip>
            ) : type === "quiz" ? (
              role === "gstudent" ? (
                checkTaken() ? (
                  <Tooltip title="Start">
                    <Link className="take" to={`/quiz/${selected?.quiz_id}`}>
                      <Button variant="contained">Take</Button>
                    </Link>
                  </Tooltip>
                ) : paid ? (
                  <Tooltip title="Start">
                    <Link className="take" to={`/quiz/${selected?.quiz_id}`}>
                      <Button variant="contained">Take</Button>
                    </Link>
                  </Tooltip>
                ) : (
                  <Tooltip title="Start">
                    <Button
                      onClick={() => handleInfo(true)}
                      variant="contained"
                    >
                      Take
                    </Button>
                  </Tooltip>
                )
              ) : (
                <Tooltip title="Start">
                  <Link className="take" to={`/quiz/${selected?.quiz_id}`}>
                    <Button variant="contained">Take</Button>
                  </Link>
                </Tooltip>
              )
            ) : null}
          </>
        ) : null}
      </Toolbar>
    );
  };

  const handleClick = (event, item) => {
    if (type === "result") {
      if (selected.id === item.id) {
        setSelected([]);
      } else {
        setSelected(item);
      }
    } else if (type === "class") {
      if (selected.class_id === item.class_id) {
        setSelected([]);
      } else {
        setSelected(item);
      }
    } else if (type === "quiz") {
      if (selected.quiz_id === item.quiz_id) {
        setSelected([]);
      } else {
        setSelected(item);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // LEAVE CLASS

  let isSelected;
  if (type === "result") {
    isSelected = (id) => selected.id === id;
  } else if (type === "class") {
    isSelected = (class_id) => selected.class_id === class_id;
  } else if (type === "quiz") {
    isSelected = (quiz_id) => selected.quiz_id === quiz_id;
  }

  const handleLeaderBoardView = () => {
    const api = new Api();

    api
      .getLeaderBoard(selected.quiz_id)
      .then((res) => {
        setLeaderBoardData((prev) => {
          return res.data.data?.slice(0, 10);
        });
        setLeaderView(true);
      })
      .catch((err) => {
        dispatch(
          setOpenState({
            open: true,
            message: err.response.data.message,
            variant: "error",
          })
        );
      });
  };
  return (
    <>
      <ViewLeaderBoard
        open={leaderView}
        data={leaderBoardData}
        handleClose={() => setLeaderView(false)}
      />
      <Views
        open={open}
        handleClose={handleClose}
        data={selected.length === 0 ? null : selected}
        type={type}
      />
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar itemSelected={selected} />

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  {column.map((col) => (
                    <TableCell key={col} align={"left"} padding={"normal"}>
                      <TableSortLabel>{col}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {type === "result" ? (
                <TableBody>
                  {data !== null && data.length !== 0 ? (
                    data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, d)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row">
                              {d.quiz_name}
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row">
                              {d.student_additional}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.point}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.percent}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.status}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {moment(d.date_taken).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.time_taken}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : type === "class" ? (
                <TableBody>
                  {data !== null && data.length !== 0 ? (
                    data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.class_id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, d)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.class_id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.class_name}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.status === "inactive"
                                ? "Class Closed"
                                : d.status}
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.subject}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {moment(d.date_created).format("DD-MM-YYYY")}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : type === "quiz" ? (
                <TableBody>
                  {data !== null && data.length !== 0 ? (
                    data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.quiz_id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, d)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={d.quiz_id}
                            selected={isItemSelected}
                          >
                            <TableCell
                              sx={{
                                borderLeftStyle: "solid",
                                borderLeftWidth: 4,
                                borderLeftColor: d.taken
                                  ? "#4dff4d"
                                  : "#ff4d4d",
                              }}
                              padding="checkbox"
                            >
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.quiz_name}
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.subject}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.status}
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.quiz_type}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {moment(d.date_created).format("DD-MM-YYYY")}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={data ? data.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default StudentTable;
