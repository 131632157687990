import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Paper,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Api from "../../api/API";
import TeacherTable from "../../components/teacher/TeacherTable";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";
import SkeletonContainer from "../../components/SkeletonContainer";
import ReactLoader from "../../components/ReactLoader";

const StudentList = () => {
  const [student, setStudent] = useState(null);
  const [loads, setLoad] = useState(false);
  const [searched, setSearched] = useState("");
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [loading, setisLoading] = useState(false);
  const [class_name, setClass_name] = useState(null);
  const [name, setName] = useState("");
  const [filter, setFilter] = React.useState("student_name");
  const [className, setClassName] = useState(null);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  const handleClass = (event) => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setName(event.target.value);
    setClassName((prev) => {
      // Filter the array based on the condition
      const filteredArray = class_name.filter(
        (item) => item.class_id === event.target.value
      );
      // Set the state to the filtered array
      return filteredArray;
    });
    setLoad(true);
    API.getStudentTeacher(token, event.target.value)
      .then((res) => {
        setStudent(res.data.data);
        setLoad(false);
      })
      .catch((err) => {
        setStudent(err?.response?.data?.data);
        setLoad(false);
      });
  };
  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setisLoading(true);
    API.getTeacherClassName(token)
      .then((res) => {
        setisLoading(false);
        setClass_name(res.data.data);
      })
      .catch((err) => {
        setisLoading(false);
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
  }, [setStudent, dispatch]);
  useEffect(() => {
    setRows(student);
  }, [setStudent, student]);
  const leaveClass = (id) => {
    if (window.confirm(`Are you sure you want to Remove Student from class?`)) {
      const API = new Api();
      const token = localStorage.getItem("token");
      setLoading(true);
      API.removeStudent(token, name, id)
        .then(async (res) => {
          if (res.data.message === "Successfully Removed Student") {
            setLoading(false);
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
            await API.getStudentTeacher(token, name)
              .then((res) => {
                if (!res.data.data) {
                  setStudent(null);
                } else {
                  setStudent(res?.data?.data);
                }
              })
              .catch((err) => {});
          } else {
            setLoading(false);
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "error",
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };

  const columns = [
    "First Name",
    "Last Name",
    "username",
    "Student Additional",
    "Email",
    "Parent Email",
    "Date Created",
  ];

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "firstname") {
      const filteredRows = student.filter((row) => {
        return row.firstname
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "lastname") {
      const filteredRows = student.filter((row) => {
        return row.lastname?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "additional") {
      const filteredRows = student.filter((row) => {
        return row.additional
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "username") {
      const filteredRows = student.filter((row) => {
        return row.username?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "email") {
      const filteredRows = student.filter((row) => {
        return row.email?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
  };

  return (
    <>
      {loading && <ReactLoader />}
      <Box mt={10}>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Class Name</InputLabel>
            <Select
              name="opened"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Class Name"
              value={name}
              required
              onChange={handleClass}
            >
              {class_name === null ? (
                <MenuItem value={""}>No Class Found</MenuItem>
              ) : (
                class_name.map((item) => {
                  return (
                    <MenuItem key={item.class_id} value={item.class_id}>
                      {item.class_name}
                    </MenuItem>
                  );
                })
              )}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"firstname"}>First Name</MenuItem>
                      <MenuItem value={"lastname"}>Last Name</MenuItem>
                      <MenuItem value={"additional"}>
                        Student Additional
                      </MenuItem>
                      <MenuItem value={"username"}>Username</MenuItem>
                      <MenuItem value={"email"}>Email</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />
                </Stack>
                <TeacherTable
                  column={columns}
                  title={
                    name
                      ? `Students Who Joined  ${className[0]?.class_name}`
                      : "No Class Selected"
                  }
                  rows={rows}
                  type="student"
                  leaveClass={leaveClass}
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default StudentList;
