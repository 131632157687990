import React, { useEffect, useState } from "react";
import OverviewCard from "../../components/OverviewCard";
import { FaChalkboardTeacher } from "react-icons/fa";
import { Box } from "@mui/material";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Api from "../../api/API";
import SkeletonContainer from "../../components/SkeletonContainer";
import PeopleIcon from "@mui/icons-material/People";
const SuperAdminOverview = () => {
  const [result, setResult] = useState(null);

  const [load, setLoad] = useState(false);

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.superOverviewData(token)
      .then((res) => {
        setResult(res.data.data);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: " 1fr",
            sm: "1fr 1fr",
            mid: "1fr 1fr 1fr",
          },
          columnGap: "5%",
        }}
      >
        {load ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => (
                <AdminPanelSettingsIcon
                  sx={{ fontSize: 30 }}
                  color={"primary"}
                />
              )}
              text={"Total Admins"}
              amount={result ? result?.admin : 0}
            />
          </>
        )}
        {load ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => (
                <AdminPanelSettingsIcon
                  sx={{ fontSize: 30 }}
                  color={"primary"}
                />
              )}
              text={"Total Paid Admins"}
              amount={result ? result?.paid_admin : 0}
            />
          </>
        )}
        {load ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => (
                <SchoolRoundedIcon sx={{ fontSize: 30 }} color={"primary"} />
              )}
              text={"Total Students"}
              amount={result ? result?.students : 0}
            />
          </>
        )}
        {load ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => (
                <SchoolRoundedIcon sx={{ fontSize: 30 }} color={"primary"} />
              )}
              text={"Total Paid Students"}
              amount={result ? result?.paid_students : 0}
            />
          </>
        )}
        {load ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => <FaChalkboardTeacher size={30} color={"#1976d2"} />}
              text={"Total Teachers"}
              amount={result ? result?.teachers : 0}
            />
          </>
        )}
        {load ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => (
                <PeopleIcon sx={{ fontSize: 30 }} color={"primary"} />
              )}
              text={"Total Users"}
              amount={result ? result?.allusers : 0}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default SuperAdminOverview;
