import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import SpellingQuizInterface from "./SpellingQuizInterface";

import Api from "../../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../../slices/StateManager";

import NormalQuizInterface from "./NormalQuizInterface";

const QuestionPage = ({ questions, quizData, duration }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const submit = () => {
    const token = localStorage.getItem("token");
    const time_taken = localStorage.getItem("time");
    const type = localStorage.getItem("type");
    let answers = JSON.parse(localStorage.getItem("answers"));
    const API = new Api();

    if (type === "normal") {
      const values = JSON.parse(localStorage.getItem("values"));
      values?.forEach((item) => {
        if (Array.isArray(item?.options)) {
          item.options = item?.options?.filter((option) => option != null);
        }
      });
      let answer = values?.concat(answers);
      const Arr1 = answer?.filter((elem) => elem.id !== "");
      answers = Arr1?.filter((elem) => elem.options !== "");
    }

    setLoading(true);
    API.scoreStudent(token, quizData.quiz_id, answers, time_taken)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("load", "false");
        localStorage.removeItem("reload");
        localStorage.removeItem("quiz_id");
        localStorage.removeItem("quiz_name");

        localStorage.removeItem("time_left");
        localStorage.removeItem("answers");
        localStorage.removeItem("values");

        localStorage.removeItem("type");
        localStorage.removeItem("time");
        dispatch(
          setOpen({
            message: res.data.message,
            open: true,
            variant: "success",
          })
        );
        navigate("/end-screen", { state: { data: res.data.data } });
      })
      .catch((err) => {
        setLoading(false);
        localStorage.removeItem("reload");
        localStorage.removeItem("quiz_id");
        localStorage.removeItem("quiz_name");
        localStorage.setItem("load", "false");
        dispatch(
          setOpen({
            message: err.response.data.message,
            open: true,
            variant: "error",
          })
        );
      });
  };
  // const handler = (e) => {
  // 	submit();
  // };

  useEffect(() => {
    const reload = localStorage.getItem("reload");
    if (reload !== "true") {
      localStorage.setItem("load", "true");
    }

    window.onbeforeunload = function (event) {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
    });

    return () => {
      const load = localStorage.getItem("load");
      if (load === "true") {
        submit();
        localStorage.removeItem("load");
      }

      localStorage.removeItem("load");

      window.removeEventListener("beforeunload", (event) => {
        event.preventDefault();
      });
    };
  });

  const off = () => {
    const error = document.getElementById("error");
    const check = document.getElementById("check");

    if (check.textContent !== "false") {
      error.style.display = "block";
      error.style.maxWidth = "800px";
      error.style.backgroundColor = "#ff3d59";
      error.style.color = "white";
      error.textContent =
        "There seems to be an issue with your internet connection, please check your connection before proceeding.";
      check.textContent = "false";
    }
  };
  const on = () => {
    const error = document.getElementById("error");
    const check = document.getElementById("check");
    if (check.textContent !== "true") {
      error.style.display = "block";
      error.style.backgroundColor = " #008a00";
      error.style.color = "white";
      error.style.maxWidth = "fit-content";
      error.textContent = "You are back online.";
      check.textContent = "true";
      setTimeout(() => {
        error.style.display = "none";
      }, 3000);
    }
  };
  useEffect(() => {
    const API = new Api();
    const myInterval = setInterval(() => {
      API.check()
        .then((res) => {
          on();
        })
        .catch((err) => {
          off();
        });
    }, 15000);
    window.addEventListener("online", on);
    window.addEventListener("offline", off);
    window.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    return () => {
      window.removeEventListener("online", on);
      window.removeEventListener("offline", off);
      window.removeEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
      clearInterval(myInterval);
    };
  }, []);

  let q = [];
  quizData?.category?.map((cat) => {
    for (let index = 0; index < questions.length; index++) {
      if (cat.text === questions[index].category) {
        q.push(questions[index]);
      }
    }
    return null;
  });
  if (quizData?.quiz_type === "spelling") {
    return (
      <>
        <SpellingQuizInterface
          quizData={quizData}
          questions={q}
          duration={duration}
        />
      </>
    );
  }

  if (quizData?.quiz_type === "normal") {
    return (
      <>
        <NormalQuizInterface
          quizData={quizData}
          questions={q}
          duration={duration}
        />
      </>
    );
  }
};

export default QuestionPage;
