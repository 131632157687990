import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import {
  ArrowCircleRight,
  CalendarMonthSharp,
  DonutLarge,
  Quiz,
  Spellcheck,
} from "@mui/icons-material";
import { motion } from "framer-motion";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FeaturesDiv() {
  const [open, setOpen] = React.useState(false);

  const data = [
    {
      content:
        "Customizable test settings, including time limits, numbers of questions and question types",
    },
    {
      content:
        "Various question types,including single choice, multiple-choice, True/False, matching and fill in the blank",
    },
    {
      content:
        " Randomization of questions and answer choices to prevent cheating and enhance test integrity.",
    },
    {
      content:
        " Unanswered Question Review: Users can view a list of unanswered questions during a test,allowing them to easily identify which questions they have yet to address. ",
    },
    {
      content:
        " Question Navigation: Users have the option to navigate back to unanswered questions and provide responses, enhancing flexibility and reducing test anxiety.",
    },
    {
      content:
        " Unlimited test revision, allowing students to practise and improve thier undestanding of the material. ",
    },
    {
      content:
        " Real-time and accurate test results, providing instant feedback on performance and highlighting areas that require improvement. ",
    },
    {
      content:
        "Detailed exporting of results, enabling further analysis and review by teachers, institutions, or parents.",
    },
    {
      content:
        " Compatibility with all operating systems and smart devices, including laptops, smartphones, and tablets, ensuring easy access for all users.",
    },
    {
      content:
        " Seamless functionality on all browsers, making it easy to use for everyone.",
    },
    {
      content:
        " Secure and private data storage, ensuring the safety and confidentiality of student information and results. ",
    },
    {
      content:
        "24/7 customer support, ensuring a seamless experience for all users. ",
    },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <Grid item md={12} sx={{ textAlign: "center", mt: 10 }}>
          <Box px={5}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold" }}
              className="text-hd"
            >
              Features
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Edusupport offers wide range of features to enchance your teaching
              and learning experience.
            </Typography>
          </Box>

          <Grid
            container
            px={{ xs: 5, md: 15 }}
            py={{ xs: 2, md: 5 }}
            spacing={5}
          >
            <Grid item lg={6} xs={12}>
              <Card
                sx={{
                  borderRadius: "30px",
                  height: "100%",
                  textAlign: "center",
                  background:
                    "linear-gradient(180deg, rgba(224, 219, 219, 0.3) 0%, rgba(252, 249, 249, 0.3) 100%)",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <CardContent>
                  <Stack spacing={3} direction={"row"} alignItems={"center"}>
                    <Box>
                      <Quiz
                        className="icon-features"
                        sx={{ fontSize: "60pt", color: "#312070", mt: 4 }}
                      />
                    </Box>
                    <Box>
                      <Stack
                        spacing={2}
                        direction={"column"}
                        sx={{ textAlign: "left" }}
                      >
                        <Typography
                          variant="h5"
                          className="gradient-text"
                          sx={{ color: "#3C4DA7", fontWeight: "bold" }}
                        >
                          Interactive Quizzes
                        </Typography>
                        <Typography variant="subtitle1">
                          Engage in fun and educational quizzes to enchance your
                          knowledge.
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Card
                sx={{
                  borderRadius: "30px",
                  height: "100%",
                  textAlign: "center",
                  background:
                    "linear-gradient(180deg, rgba(224, 219, 219, 0.3) 0%, rgba(252, 249, 249, 0.3) 100%)",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <CardContent>
                  <Stack spacing={3} direction={"row"} alignItems={"center"}>
                    <Box>
                      <Spellcheck
                        className="icon-features"
                        sx={{ fontSize: "60pt", color: "#312070", mt: 4 }}
                      />
                    </Box>
                    <Box>
                      <Stack
                        spacing={2}
                        direction={"column"}
                        sx={{ textAlign: "left" }}
                      >
                        <Typography
                          variant="h5"
                          className="gradient-text"
                          sx={{ color: "#3C4DA7", fontWeight: "bold" }}
                        >
                          Spelling Bee Challenge
                        </Typography>
                        <Typography variant="subtitle1">
                          Test your speaking skills and vocabulary to compete
                          with peers .
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Card
                sx={{
                  borderRadius: "30px",
                  height: "100%",
                  textAlign: "center",
                  background:
                    "linear-gradient(180deg, rgba(224, 219, 219, 0.3) 0%, rgba(252, 249, 249, 0.3) 100%)",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <CardContent>
                  <Stack spacing={3} direction={"row"} alignItems={"center"}>
                    <Box>
                      <CalendarMonthSharp
                        className="icon-features"
                        sx={{ fontSize: "50pt", color: "#312070", mt: 4 }}
                      />
                    </Box>
                    <Box>
                      <Stack
                        spacing={2}
                        direction={"column"}
                        sx={{ textAlign: "left" }}
                      >
                        <Typography
                          variant="h5"
                          className="gradient-text"
                          sx={{ color: "#3C4DA7", fontWeight: "bold" }}
                        >
                          Test Scheduling
                        </Typography>
                        <Typography variant="subtitle1">
                          Conveniently schedule tests, dictate exactly when
                          students can start and end. will have access and
                          engage with them
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: "30px",
                  textAlign: "center",
                  background:
                    "linear-gradient(180deg, rgba(224, 219, 219, 0.3) 0%, rgba(252, 249, 249, 0.3) 100%)",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <CardContent>
                  <Stack spacing={3} direction={"row"} alignItems={"center"}>
                    <Box>
                      <DonutLarge
                        className="icon-features"
                        sx={{ fontSize: "60pt", color: "#312070", mt: 4 }}
                      />
                    </Box>
                    <Box>
                      <Stack
                        spacing={2}
                        direction={"column"}
                        sx={{ textAlign: "left" }}
                      >
                        <Typography
                          variant="h5"
                          className="gradient-text"
                          sx={{ color: "#3C4DA7", fontWeight: "bold" }}
                        >
                          Progress Tracking
                        </Typography>
                        <Typography variant="subtitle1">
                          Monitor your progress and see how far you've come.
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                className="sec-btn"
                sx={{
                  color: "#3C4DA7",
                  textTransform: "capitalize !important",
                  borderColor: " #3C4DA7",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  fontSize: "10pt",
                  p: 1,
                }}
                variant="outlined"
                onClick={handleClickOpen}
                endIcon={<ArrowCircleRight />}
              >
                More Features{" "}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </motion.div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Other Feature of Edusupport App"}</DialogTitle>
        <DialogContent sx={{ p: 5 }}>
          <ul>
            {data.map((da, i) => (
              <li key={i}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    p: 1,
                    listStyle: "square",
                  }}
                >
                  {da.content}
                </Typography>
              </li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            size="large"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FeaturesDiv;
