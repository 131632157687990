import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import Api from "../../api/API";
import ReactLoader from "../ReactLoader";
import AdminTable from "./AdminTable";
import TeacherTable from "../teacher/TeacherTable";
const ViewQuestion = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { quiz_id, type } = useParams();
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const API = new Api();
    if (type === "spelling") {
      if (role === "admin") {
        API.getSpellingQuestionsAdmin(token, quiz_id)
          .then((res) => {
            setQuestions(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            setQuestions(null);
            alert(err.response.data.message);
            setLoading(false);
          });
      } else {
        API.getSpellingQuestions(token, quiz_id)
          .then((res) => {
            setQuestions(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            setQuestions(null);
            alert(err.response.data.message);
            setLoading(false);
          });
      }
    } else {
      if (role === "admin") {
        API.getQuestionsAdmin(token, quiz_id)
          .then((res) => {
            setQuestions(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            setQuestions(null);
            alert(err.response.data.message);
            setLoading(false);
          });
      } else {
        API.getQuestions(token, quiz_id)
          .then((res) => {
            setQuestions(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            setQuestions(null);
            alert(err.response.data.message);
            setLoading(false);
          });
      }
    }
  }, [quiz_id, type]);

  const columns = ["Question", "Type", "Section", "Options", "Answers"];
  const column2 = ["Type", "Section", "Hint", "Address", "word"];

  const handleRedirect = () => {
    const role = localStorage.getItem("role");
    if (role === "admin") {
      navigate(`/dashboard/admin/quizzes`);
    }
    if (role === "teacher") {
      navigate(`/dashboard/teacher/quizzes`);
    }

    if (role === "student") {
      navigate(`/dashboard/student/quizzes`);
    }

    if (role === "parent") {
      navigate(`/dashboard/parent/quizzes`);
    }

    if (role === "gstudent") {
      navigate(`/dashboard/gstudent/result`);
    }
  };

  return (
    <>
      {loading && <ReactLoader />}
      <Box sx={{ m: "20px" }}>
        <Button variant="contained" onClick={handleRedirect}>
          Back
        </Button>
      </Box>
      <Box m={5}>
        {localStorage.getItem("role") === "admin" ? (
          <AdminTable
            column={type === "spelling" ? column2 : columns}
            title={`${type === "spelling" ? "Spelling" : "Quiz"} Questions`}
            data={questions}
            type={type === "spelling" ? "spelling" : "question"}
          />
        ) : (
          <TeacherTable
            column={type === "spelling" ? column2 : columns}
            title={`${type === "spelling" ? "Spelling" : "Quiz"} Questions`}
            rows={questions}
            type={type === "spelling" ? "spelling" : "question"}
          />
        )}
      </Box>
    </>
  );
};

export default ViewQuestion;
