import { Quiz } from "@mui/icons-material";
import { Box, Divider, Radio, Stack, Typography } from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import { useState } from "react";
import { useEffect } from "react";
const RadioButtonImpl = ({ answers, setAnswers, questions }) => {
	const [radio, setRadio] = useState([]);
	useEffect(() => {
		if (answers !== null && answers !== undefined) {
			if (answers?.find((a) => a?.id === questions?.id)) {
				setRadio(answers?.find((a) => a?.id === questions?.id)?.options);
			} else {
				setRadio([]);
			}
		}
	}, [questions, answers]);

	const onClickOption = (value) => {
		let newdd;

		newdd = [value];
		!radio?.includes(value) && setRadio([value]);

		if (answers !== null && answers !== undefined) {
			if (answers?.find((a) => a?.id === questions?.id)) {
				const dd = answers?.find((a) => a?.id === questions?.id);
				const newAnswers = answers?.map((ss) =>
					ss?.id === dd?.id ? { ...dd, options: newdd } : ss
				);
				setAnswers(newAnswers);
			} else {
				setAnswers([...answers, { id: questions?.id, options: newdd }]);
			}
		}
	};
	return (
		<>
			<Stack direction={"row"} spacing={1}>
				<Quiz color="primary" />
				<Typography variant="h7">
					{parse(questions?.question?.replaceAll("~~", "'"))}
				</Typography>
			</Stack>

			<Stack spacing={2}>
				<Stack direction={"row"} spacing={2}>
					<Box>
						<Radio
							checked={radio?.includes("true") || false}
							value={"true"}
							onClick={(evt) => onClickOption(evt.target.value)}
						/>

						<Typography variant="h7">{"True"}</Typography>
					</Box>
				</Stack>
				<Stack direction={"row"} spacing={2}>
					<Box>
						<Radio
							checked={radio?.includes("false") || false}
							value={"false"}
							onClick={(evt) => onClickOption(evt.target.value)}
						/>

						<Typography variant="h7">{"False"}</Typography>
					</Box>
				</Stack>
			</Stack>
			<Divider sx={{ mb: 5 }} />
		</>
	);
};

export default RadioButtonImpl;
