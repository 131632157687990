import "./styles/App.scss";
import React from "react";
import Routers from "./routes/Routers";
import ReactLoader from "./components/ReactLoader";
import { useSelector } from "react-redux";

const ScrollToTop = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};
function App() {
  const { loading } = useSelector((state) => state.StateManager);
  return (
    <>
      {loading && <ReactLoader />}
      {!loading && <Routers />}
    </>
  );
}

export default App;
