import React, { useState } from "react";

import {
  Box,
  Backdrop,
  Modal,
  Fade,
  Button,
  Typography,
  FormControl,
  FormLabel,
} from "@mui/material";
import Api from "../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../slices/StateManager";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95vw", sm: "90vw", md: "fit-content" },
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};
const Schedule = ({ opened, setOpened, quiz_id }) => {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const publish = () => {
    dispatch(setLoading(true));
    const token = localStorage.getItem("token");

    const API = new Api();
    API.publishQuiz(token, quiz_id)
      .then((res) => {
        if (res.data.message === "Quiz Published Successfully!") {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "success",
            })
          );

          navigate("/dashboard/teacher/quizzes");
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: err.response.data.message,
            open: true,
            variant: "error",
          })
        );
      });
  };

  const schedule = () => {
    dispatch(setLoading(true));
    const token = localStorage.getItem("token");

    const API = new Api();
    if (start === "" || end === "") {
      dispatch(
        setOpen({
          message: "Please Fill All Dates",
          open: true,
          variant: "error",
        })
      );
      dispatch(setLoading(false));
    } else {
      if (start >= end) {
        dispatch(
          setOpen({
            message: "Closing Date Must be After Publish Date",
            open: true,
            variant: "error",
          })
        );
        dispatch(setLoading(false));
      } else {
        API.updateDate(
          token,
          quiz_id,
          moment(end).format(),
          moment(start).format()
        )
          .then((res) => {
            if (res.data.message === "Quiz Scheduled Successfully!") {
              dispatch(setLoading(false));
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "success",
                })
              );
              navigate("/dashboard/teacher/quizzes");
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: err.response.data.message,
                open: true,
                variant: "error",
              })
            );
          });
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opened}
        onClose={() => setOpened(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opened}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpened(false)}
              >
                close
              </Button>
            </Box>

            <Box p={4}>
              <Box textAlign={"center"}>
                <Button onClick={publish} variant="contained">
                  publish quiz Now
                </Button>
              </Box>
              <Typography sx={{ mt: 3 }} variant="h6">
                Schedule Publishing
              </Typography>
              <Box
                display={{ xs: "block", lg: "flex" }}
                sx={{ mt: 3, columnGap: "2%" }}
              >
                <FormControl sx={{ mt: 2, width: { xs: "100%", lg: "50%" } }}>
                  <FormLabel>Publish Date</FormLabel>
                  <input
                    style={{
                      paddingBlock: 15,
                      paddingInline: 15,
                      fontSize: 18,
                    }}
                    onChange={(e) => setStart(e.target.value)}
                    type="datetime-local"
                    value={start}
                  />
                </FormControl>
                <FormControl sx={{ mt: 2, width: { xs: "100%", lg: "50%" } }}>
                  <FormLabel>Closing Date</FormLabel>
                  <input
                    pattern=""
                    style={{
                      paddingBlock: 15,
                      paddingInline: 15,
                      fontSize: 18,
                    }}
                    onChange={(e) => setEnd(e.target.value)}
                    type="datetime-local"
                    value={end}
                  />
                </FormControl>
              </Box>
              <Box sx={{ mt: 3 }} textAlign={"center"}>
                <Button onClick={schedule} variant={"contained"}>
                  schedule publishing
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Schedule;
