import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Button,
} from "@mui/material";
import SkeletonContainer from "../../components/SkeletonContainer";

import SingleChoice from "./QuizType/SingleChoice";
import MultiChoice from "./QuizType/MultiChoice";
import FillGapChoice from "./QuizType/FillGapChoice";
import TrueOrFalseChoice from "./QuizType/TrueOrFalseChoice";
import QuestionDisplay from "../../components/teacher/QuestionDisplay";
import { useDispatch } from "react-redux";
import { setOpen } from "../../slices/StateManager";
import Api from "../../api/API";
import Schedule from "../../components/Schedule";
import CreatePatten from "./QuizType/CreatePatten";

const QuestionInterface = ({ quiz_id, quizData }) => {
  const [changeDiv, setChangeDiv] = useState("single");
  const [loads, setLoad] = useState(false);
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();
  const onChangeDiv = (event) => {
    setChangeDiv(event);
  };
  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.getQuestions(token, quiz_id)
      .then((res) => {
        setQuestions(res.data.data);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, [setQuestions, quiz_id]);

  return (
    <>
      <Schedule opened={opened} setOpened={setOpened} quiz_id={quiz_id} />
      <Stack spacing={2}>
        <Box sx={{ p: 3 }}>
          <Stack spacing={1}>
            <Typography variant="h6" component="h6" sx={{ textAlign: "left" }}>
              Add Question
            </Typography>

            <Box
              sx={{
                "& > :not(style)": { mt: 1 },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Question Type
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Question Type"
                      onChange={(e) => onChangeDiv(e.target.value)}
                      value={changeDiv}
                    >
                      <MenuItem value={"single"}>Single Choice</MenuItem>
                      <MenuItem value={"multiple"}>Multiple Choice</MenuItem>
                      <MenuItem value={"fill"}>Fill in the Blank</MenuItem>
                      <MenuItem value={"bool"}>True or False</MenuItem>
                      <MenuItem value={"match"}>Word Matching</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} order={{ xs: -1, md: 0 }}>
                  <Box
                    sx={{
                      marginBlockEnd: { xs: 2, md: 0 },
                      textAlign: { xs: "left", md: "center" },
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (questions?.length < 1 || questions === null) {
                          dispatch(
                            setOpen({
                              message: "Please Add Questions To This Quiz",
                              open: true,
                              variant: "error",
                            })
                          );
                        } else {
                          setOpened(true);
                        }
                      }}
                      variant="contained"
                    >
                      publish quiz
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <>
                    {changeDiv === "single" && (
                      <SingleChoice
                        data={quizData}
                        setQuestions={setQuestions}
                      />
                    )}
                    {changeDiv === "multiple" && (
                      <MultiChoice
                        data={quizData}
                        setQuestions={setQuestions}
                      />
                    )}
                    {changeDiv === "fill" && (
                      <FillGapChoice
                        data={quizData}
                        setQuestions={setQuestions}
                      />
                    )}
                    {changeDiv === "bool" && (
                      <TrueOrFalseChoice
                        data={quizData}
                        setQuestions={setQuestions}
                      />
                    )}
                    {changeDiv === "match" && (
                      <CreatePatten
                        data={quizData}
                        setQuestions={setQuestions}
                      />
                    )}
                  </>
                </Grid>{" "}
              </Grid>
            </Box>
          </Stack>{" "}
        </Box>
        <Box>
          <Typography variant="h6">
            Total Questions {questions?.length || 0}
          </Typography>
        </Box>

        {loads ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <QuestionDisplay
              questions={questions}
              setQuestions={setQuestions}
              quiz_id={quiz_id}
              quizData={quizData}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default QuestionInterface;
