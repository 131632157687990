import React, { useState } from "react";
import { Box, TextField, Paper } from "@mui/material";

import StudentTable from "../../components/student/StudentTable";
import SkeletonContainer from "../../components/SkeletonContainer";
import Api from "../../api/API";
import { Button, Stack } from "@mui/material";
import { CSVLink } from "react-csv";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import moment from "moment";

const ViewResult = () => {
  const [loads, setLoad] = React.useState({
    loading1: true,
    loading2: true,
    loading3: true,
  });

  const [searched, setSearched] = useState("");

  const [rows, setRows] = useState([]);

  let dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [filter, setFilter] = React.useState("quiz_name");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  React.useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    API.getInStudentClasses(token)
      .then((res) => {
        setLoad({ loading1: false });
      })
      .catch((err) => {
        setLoad({ loading1: false });
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
    API.getAllResult(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setRows(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_taken),
                db = new Date(b.date_taken);
              return db - da;
            })
          );
        }
        setLoad({ loading2: false });
      })
      .catch((err) => {
        setResult(null);

        setLoad({ loading2: false });
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
    API.getStudentQuiz(token)
      .then((res) => {
        setLoad({ loading3: false });
      })
      .catch((err) => {
        setLoad({ loading3: false });
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
  }, [dispatch]);
  // React.useEffect(() => {
  //   setRows(result);
  // }, [result]);
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "quiz_name") {
      const filteredRows = result.filter((row) => {
        return row?.quiz_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "additional") {
      const filteredRows = result.filter((row) => {
        return row?.student_additional
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "remark") {
      const filteredRows = result.filter((row) => {
        return row?.status?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "point") {
      const filteredRows = result.filter((row) => {
        return row?.point <= searchedVal;
      });
      setRows(filteredRows);
    }
    if (filter === "percent") {
      const filteredRows = result.filter((row) => {
        return row?.percent <= searchedVal;
      });
      setRows(filteredRows);
    }
    if (filter === "date") {
      const filteredRows = result.filter((row) => {
        return row?.date_taken.includes(searchedVal);
      });
      setRows(filteredRows);
    }
  };
  const columns = [
    "Quiz Name",
    "Student Additional",
    "Point",
    "Percentage",
    "Status",
    "Date Taken",
    "Time Taken",
  ];
  var date = new Date();

  const handleExportCSV = () => {
    const csvData = rows?.map((row) => ({
      ID: row?.id,
      "STUDENT ID": row?.student_id,
      "TEST ID": row?.quiz_id,
      "STUDENT NAME": row?.student_name,
      "QUIZ NAME": row?.quiz_name,
      "STUDENT ADDITIONAL": row?.student_additional,
      POINT: row?.point,
      PERCENTAGE: row?.percent,
      // ANSWERS: row?.answers?.map((item) => item?.options[0]).join(", "),
      STATUS: row?.status,
      "RIGHT ANSWERS": row?.right_answers,
      "WRONG ANSWERS": row?.wrong_answers,
      "TIME TAKEN": row?.time_taken,
      "DATE TAKEN": moment(row?.date_taken).format("DD/MM/YYYY: hh:mm"),
    }));

    return csvData;
  };

  return (
    <>
      <Box mt={10}>
        {loads.loading1 && loads.loading3 && loads.loading2 ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <Box
              sx={{
                mt: 3,

                p: "3% 5%",
              }}
            >
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"quiz_name"}>Quiz Name</MenuItem>
                      <MenuItem value={"remark"}>Remark</MenuItem>
                      <MenuItem value={"point"}>Point</MenuItem>
                      <MenuItem value={"date"}>Date</MenuItem>
                      <MenuItem value={"percent"}>Percentage</MenuItem>
                      <MenuItem value={"additional"}>
                        Student Additional
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />
                </Stack>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    ml: "38%",
                    mb: 1,
                    mt: 2,
                  }}
                >
                  <CSVLink
                    data={handleExportCSV()}
                    filename={`data-${date}.csv`}
                    className="export"
                    target="_blank"
                  >
                    Export Csv
                  </CSVLink>
                </Button>
                <StudentTable
                  title={"Quiz Results"}
                  data={rows}
                  column={columns}
                  type="result"
                />
              </Paper>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ViewResult;
