import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Drawer,
  List,
  ListItemButton,
  Collapse,
} from "@mui/material";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChalkboardTeacher, FaCheckCircle, FaSchool } from "react-icons/fa";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Assessment from "@mui/icons-material/Assessment";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import logo from "../images/edu-dark.png";
import GroupIcon from "@mui/icons-material/Group";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
const activeStyle = {
  backgroundColor: "#e4edf4",
  color: "#1976d2",
  "&:hover": {
    cursor: "pointer",
  },
};

export const TeacherBar = ({
  setMobileOpen,
  handleDrawerToggle,
  mobileOpen,
  drawerWidth,
  window,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const meniItems = [
    {
      text: "Overview",
      icon: <GridViewRoundedIcon sx={{ fontSize: 35 }} color="primary" />,
      path: "/dashboard/teacher",
    },
    {
      text: "Create Quiz/Spelling",
      icon: <NoteAddRoundedIcon sx={{ fontSize: 35 }} color="primary" />,
      path: "/dashboard/teacher/create-quiz",
    },
    {
      text: "Quizzes/Spellings",
      icon: <QuizRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/teacher/quizzes",
    },
    {
      text: "My Classes",
      icon: <FaSchool size={35} color={"#1976d2"} />,
      path: "/dashboard/teacher/classes",
    },

    {
      text: "Student In Class",
      icon: <SchoolRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/teacher/student-list",
    },
    {
      text: "Transfer Student",
      icon: <MoveUpIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/teacher/student-transfer",
    },
    {
      text: "Profile",
      icon: <PersonRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/teacher/profile",
    },
    {
      text: "Quiz/Spelling Results",
      icon: <ArticleRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/teacher/result",
    },
    {
      text: "My Organisation",
      icon: <FaSchool size={35} color={"#1976d2"} />,
      path: "/dashboard/teacher/organisation",
    },
  ];

  const drawer = (
    <div>
      <Toolbar sx={{ height: "68px", borderBottom: "1px solid #E0E0E0" }}>
        <img src={logo} alt="edulogo" />
      </Toolbar>
      {/* <Divider /> */}
      <List>
        {meniItems.map((item) => (
          <ListItem
            button
            key={item.text}
            sx={
              location.pathname === item.path
                ? { ...activeStyle, m: "8% 0", p: "8%" }
                : { m: "8% 0", p: "8%" }
            }
            onClick={() => {
              navigate(item.path);
              setMobileOpen(false);
            }}
          >
            <ListItemIcon sx={location.pathname === item.path ? {} : {}}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              sx={{ textTransform: "uppercase" }}
              primary={item.text}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className="sidebar">
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};

export const StudentBar = ({
  setMobileOpen,
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
  window,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const meniItems = [
    {
      text: "Overview",
      icon: <GridViewRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/student",
    },
    {
      text: "Classes",
      icon: <FaSchool size={35} color={"#1976d2"} />,
      path: "/dashboard/student/class",
    },
    {
      text: "View Results",
      icon: <Assessment sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/student/results",
    },
    {
      text: "Profile",
      icon: <PersonRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/student/profile",
    },
    {
      text: "Take Quiz/Spelling Bee",
      icon: <QuizRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/student/quiz",
    },
    {
      text: "My Organisation",
      icon: <FaSchool size={35} color={"#1976d2"} />,
      path: "/dashboard/student/organisation",
    },
  ];

  const drawer = (
    <div>
      <Toolbar sx={{ height: "68px", borderBottom: "1px solid #E0E0E0" }}>
        <img src={logo} alt="edulogo" />
      </Toolbar>
      {/* <Divider /> */}
      <List>
        {" "}
        {meniItems.map((item) => (
          <ListItem
            title={item.text}
            button
            key={item.text}
            sx={
              location.pathname === item.path
                ? { ...activeStyle, m: "10% 0", p: "10%" }
                : { m: "10% 0", p: "10%" }
            }
            onClick={() => {
              navigate(item.path);
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              sx={{ textTransform: "uppercase" }}
              primary={item.text}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className="sidebar">
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};

export const GStudentBar = ({
  setMobileOpen,
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
  window,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const meniItems = [
    {
      text: "Overview",
      icon: <GridViewRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/gstudent",
    },

    {
      text: "Profile",
      icon: <PersonRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/gstudent/profile",
    },
    {
      text: "View Results",
      icon: <Assessment sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/gstudent/results",
    },
    {
      text: "Take Quiz/Spelling Bee",
      icon: <QuizRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/gstudent/quiz",
    },
  ];

  const drawer = (
    <div>
      <Toolbar sx={{ height: "68px", borderBottom: "1px solid #E0E0E0" }}>
        <img src={logo} alt="edulogo" />
      </Toolbar>
      {/* <Divider /> */}
      <List>
        {meniItems.map((item) => (
          <ListItem
            title={item.text}
            button
            key={item.text}
            sx={
              location.pathname === item.path
                ? { ...activeStyle, m: "10% 0", p: "10%" }
                : { m: "10% 0", p: "10%" }
            }
            onClick={() => {
              navigate(item.path);
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              sx={{ textTransform: "uppercase" }}
              primary={item.text}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className="sidebar">
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};

export const AdminBar = ({
  setMobileOpen,
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
  window,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const meniItems = [
    {
      text: "Overview",
      icon: <GridViewRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/admin",
    },

    {
      text: "Teacher Operations",
      icon: <GroupIcon sx={{ fontSize: 35 }} color={"secondary"} />,
    },
    {
      text: "Teachers",
      icon: <FaChalkboardTeacher size={35} color={"#1976d2"} />,
      path: "/dashboard/admin/teachers",
    },
    {
      text: "Students",
      icon: <SchoolRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/admin/students",
    },
    {
      text: "Transfer Student",
      icon: <MoveUpIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/admin/student-transfer",
    },
    {
      text: "Quizzes/Spellings",
      icon: <QuizRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/admin/quizzes",
    },
    {
      text: "All Classes",
      icon: <FaSchool size={35} color={"#1976d2"} />,
      path: "/dashboard/admin/classes",
    },
    {
      text: "Approve Request",
      icon: <FaCheckCircle size={35} color={"#1976d2"} />,
      path: "/dashboard/admin/requests",
    },
    {
      text: "Profile",
      icon: <PersonRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/admin/profile",
    },
    {
      text: "Quiz/Spelling Results",
      icon: <ArticleRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/admin/result",
    },
  ];

  //   TEACHER ADMIN
  const teacherSubMenu = [
    {
      text: "Overview",
      icon: <GridViewRoundedIcon sx={{ fontSize: 35 }} color="secondary" />,
      path: "/dashboard/admin/teacher",
    },
    {
      text: "Create Quiz/Spelling",
      icon: <NoteAddRoundedIcon sx={{ fontSize: 35 }} color="secondary" />,
      path: "/dashboard/admin/teacher/create-quiz",
    },
    {
      text: "Quizzes/Spellings",
      icon: <QuizRoundedIcon sx={{ fontSize: 35 }} color={"secondary"} />,
      path: "/dashboard/admin/teacher/quizzes",
    },
    {
      text: "Classes",
      icon: <FaSchool size={35} color={"#9c27b0"} />,
      path: "/dashboard/admin/teacher/classes",
    },

    {
      text: "Student In Class",
      icon: <SchoolRoundedIcon sx={{ fontSize: 35 }} color={"secondary"} />,
      path: "/dashboard/admin/teacher/student-list",
    },
    {
      text: "Transfer Student",
      icon: <MoveUpIcon sx={{ fontSize: 35 }} color={"secondary"} />,
      path: "/dashboard/admin/teacher/student-transfer",
    },

    {
      text: "Quiz/Spelling Results",
      icon: <ArticleRoundedIcon sx={{ fontSize: 35 }} color={"secondary"} />,
      path: "/dashboard/admin/teacher/result",
    },
  ];
  const drawer = (
    <div>
      <Toolbar sx={{ height: "68px", borderBottom: "1px solid #E0E0E0" }}>
        <img src={logo} alt="edulogo" />
      </Toolbar>
      {/* <Divider /> */}
      <List>
        {meniItems.map((item) =>
          item.text === "Teacher Operations" ? (
            <React.Fragment key={item.text}>
              <ListItemButton
                sx={
                  location.pathname === item.path
                    ? { ...activeStyle, m: "8% 0", p: "8%" }
                    : { m: "8% 0", p: "8%" }
                }
                onClick={() => setOpen(!open)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  sx={{ textTransform: "uppercase" }}
                  primary={item.text}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {teacherSubMenu?.map((titem) => (
                    <ListItemButton
                      sx={
                        location.pathname === titem.path
                          ? { ...activeStyle, m: "6% 0", p: "6% 10%" }
                          : { m: "6% 0", p: "6% 10%" }
                      }
                      key={titem.text}
                      onClick={() => {
                        navigate(titem.path);
                        setMobileOpen(false);
                      }}
                    >
                      <ListItemIcon>{titem.icon}</ListItemIcon>
                      <ListItemText
                        sx={{ textTransform: "uppercase" }}
                        primary={titem.text}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ) : (
            <ListItemButton
              key={item.text}
              sx={
                location.pathname === item.path
                  ? { ...activeStyle, m: "8% 0", p: "8%" }
                  : { m: "8% 0", p: "8%" }
              }
              onClick={() => {
                navigate(item.path);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                sx={{ textTransform: "uppercase" }}
                primary={item.text}
              />
            </ListItemButton>
          )
        )}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className="sidebar">
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};

export const SuperAdminBar = ({
  setMobileOpen,
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
  window,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const meniItems = [
    {
      text: "Overview",
      icon: <GridViewRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/super",
    },
    {
      text: "Admins",
      icon: <AdminPanelSettingsIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/super/admins",
    },
    {
      text: "Teachers",
      icon: <FaChalkboardTeacher size={35} color={"#1976d2"} />,
      path: "/dashboard/super/teachers",
    },
    {
      text: "Students",
      icon: <SchoolRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/super/students",
    },
    {
      text: "Change Password",
      icon: <PersonRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/super/change-password",
    },
  ];

  const drawer = (
    <div>
      <Toolbar sx={{ height: "68px", borderBottom: "1px solid #E0E0E0" }}>
        <img src={logo} alt="edulogo" />
      </Toolbar>
      {/* <Divider /> */}
      <List>
        {meniItems.map((item) => (
          <ListItem
            button
            key={item.text}
            sx={
              location.pathname === item.path
                ? { ...activeStyle, m: "8% 0", p: "8%" }
                : { m: "8% 0", p: "8%" }
            }
            onClick={() => {
              navigate(item.path);
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              sx={{ textTransform: "uppercase" }}
              primary={item.text}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className="sidebar">
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};

export const ParentBar = ({
  setMobileOpen,
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
  window,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const meniItems = [
    {
      text: "Quiz/Spelling Results",
      icon: <ArticleRoundedIcon sx={{ fontSize: 35 }} color={"primary"} />,
      path: "/dashboard/parent",
    },
  ];

  const drawer = (
    <div>
      <Toolbar sx={{ height: "68px", borderBottom: "1px solid #E0E0E0" }}>
        <img src={logo} alt="edulogo" />
      </Toolbar>
      {/* <Divider /> */}
      <List>
        {meniItems.map((item) => (
          <ListItem
            button
            key={item.text}
            sx={
              location.pathname === item.path
                ? { ...activeStyle, m: "10% 0", p: "10%" }
                : { m: "10% 0", p: "10%" }
            }
            onClick={() => {
              navigate(item.path);
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              sx={{ textTransform: "uppercase" }}
              primary={item.text}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className="sidebar">
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};
