import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { Button, Stack } from "@mui/material";
import { useState } from "react";
import parse from "html-react-parser";
import EditInterface from "../pages/teacher/EditQuestion/EditInterface";

export default function QuestionTable({
  title,
  rows,
  column,
  quizData,
  deletQuiz,
  type,
}) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openEDitq, setOpenEditQ] = useState(false);

  const handleSelectAllClick = () => {
    const newSelected = rows.map((n) => n?.id);
    setSelected(newSelected);
  };

  function EnhancedTableToolbar({ title, numSelected }) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <>
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
            <Stack direction={"row"} spacing={1}>
              {numSelected === 1 && (
                <Tooltip title={`Edit selected  ${type}`}>
                  <Button
                    variant={"contained"}
                    color="warning"
                    sx={{
                      wordWrap: "break-word",
                      fontSize: 12,
                      pl: 1,
                      pr: 1,
                    }}
                    onClick={() => {
                      setOpenEditQ(true);
                    }}
                  >
                    Edit {type}
                  </Button>
                </Tooltip>
              )}

              <Tooltip title="Delete">
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    wordWrap: "break-word",
                    width: { xs: "100%", sm: "fit-content" },
                    fontSize: 12,
                    pl: 1,
                    pr: 1,
                  }}
                  onClick={() => {
                    deletQuiz(selected);
                    setSelected([]);
                  }}
                >
                  Delete {type}
                </Button>
              </Tooltip>
            </Stack>
          </>
        ) : null}
      </Toolbar>
    );
  }

  function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
    return (
      <>
        <TableCell padding="checkbox">
          {/* <Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
					/> */}
          <Button
            size="small"
            variant="contained"
            sx={{ fontSize: 10 }}
            color={
              numSelected <= 0
                ? "primary"
                : numSelected >= rowCount
                ? "success"
                : "secondary"
            }
            onClick={() => {
              if (numSelected < rowCount) {
                onSelectAllClick();
              } else {
                setSelected([]);
              }
            }}
          >
            Check All
          </Button>
        </TableCell>
      </>
    );
  }
  const handleCloseEditQ = () => {
    setOpenEditQ(false);

    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const errorText =
    "No data to display or Something could be wrong with your internet connection.";
  return (
    <>
      <EditInterface
        open={openEDitq}
        handleClose={handleCloseEditQ}
        data={
          selected.length === 0 ? null : rows?.find((r) => r.id === selected[0])
        }
        quizData={quizData}
      />

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Typography
            sx={{ flex: "1 1 100%", p: "20px 10px" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
          <EnhancedTableToolbar title={title} numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow>
                  <EnhancedTableHead
                    numSelected={selected?.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows?.length}
                  />
                  {column?.map((col, i) => (
                    <TableCell key={i} align={"left"} padding={"normal"}>
                      <TableSortLabel>{col}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {type === "question" ? (
                <TableBody>
                  {rows !== null && rows.length !== 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.id);

                        return (
                          <TableRow
                            hover
                            onClick={(e) => handleClick(e, d?.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {parse(d.question?.replaceAll("~~", "'"))}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.type === "sc"
                                ? "Single Choice"
                                : d.type === "mc"
                                ? "Multiple Choice"
                                : d.type === "fg"
                                ? "Fill In The Blank"
                                : d.type === "tf"
                                ? "True or False"
                                : d.type}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d?.category}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.type !== "match"
                                ? d.options
                                    ?.map((o) => o?.replaceAll("~~", "'"))
                                    .join(", ")
                                : JSON.stringify(d.options)}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.type !== "match"
                                ? d.answers
                                    ?.map((o) => o?.replaceAll("~~", "'"))
                                    .join(", ")
                                : JSON.stringify(d.answers)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : type === "spelling" ? (
                <TableBody>
                  {rows !== null && rows.length !== 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const isItemSelected = isSelected(d.id);

                        return (
                          <TableRow
                            hover
                            onClick={(e) => handleClick(e, d?.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                              />
                            </TableCell>

                            <TableCell padding="normal" align="left">
                              {d.type}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d?.category}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.hint?.replaceAll("~~", "'")}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.type === "option1"
                                ? d.address?.replaceAll("~~", "'")
                                : d.address}
                            </TableCell>
                            <TableCell padding="normal" align="left">
                              {d.word?.replaceAll("~~", "'")}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} padding="normal" align="left">
                        {errorText}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={rows ? rows.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
