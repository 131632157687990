
import { useNavigate } from 'react-router-dom';
import React from 'react';
const ScrollToTop = ({ history }) => {
  


                const navigate = useNavigate();

               React.useEffect(() => {
                const unmountListener = navigate((location) => {
                        window.scrollTo(0, 0);
                      });
                  
                      return unmountListener;
                }, [navigate]);
              
      
        return null;
      }

      export default ScrollToTop