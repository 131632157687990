import React from 'react'
import SectionDiv from "./SectionDiv";
import FeaturesDiv from "./FeaturesDiv";
import UserRoleDiv from "./UserRoleDiv";
import TestimonyDiv from "./testimonyDiv";
import FAQDiv from "./FAQDiv";
import HowItWorksDiv from "./HowItWorksDiv";
import { Grid } from '@mui/material';

function Home() {
  return (
    <>
<Grid item md={12} xs={12}>

<SectionDiv/>
        <FeaturesDiv/>
        <UserRoleDiv/>
        <TestimonyDiv/>
        <FAQDiv/>
        <HowItWorksDiv/>
</Grid>
    
    </>
  )
}

export default Home