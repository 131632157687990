import React from "react";
import { Button } from "@mui/material";
import Api from "../../api/API";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router-dom";
// const publicKey = process.env.REACT_APP_PAYSTACK_TEST_KEY;
const publicKey = process.env.REACT_APP_PAYSTACK_LIVE_KEY;

const Payment = ({ dur, color, config, plan }) => {
  const navigate = useNavigate();
  const initializePayment = usePaystackPayment({ ...config, publicKey });
  const onClose = () => {
    alert("Payment Cancelled!");
  };
  const onSuccess = (reference) => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const API = new Api();
    role === "admin"
      ? API.postSub(token, dur, plan, reference.reference)
          .then((res) => {
            alert(res.data.message);
            navigate("/admin");
          })
          .catch((err) => {
            alert(err.response.data.message);
          })
      : API.gpostSub(token, dur, plan, reference.reference)
          .then((res) => {
            alert(res.data.message);
            navigate("/gstudent");
          })
          .catch((err) => {
            alert(err.response.data.message);
          });
  };
  return (
    <div
      onClick={() => {
        initializePayment(onSuccess, onClose);
      }}
    >
      <Button color={color ? color : "secondary"} variant="contained">
        Subscribe
      </Button>
    </div>
  );
};

export default Payment;
