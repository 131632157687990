import React, { useState, useEffect } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  Box,
  Avatar,
  Input,
  InputLabel,
  FormControl,
  Button,
  Select,
  MenuItem,
  Typography,
  ButtonBase,
} from "@mui/material";

import Api from "../../api/API";
import { useNavigate } from "react-router-dom";
import storage from "../../fire";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const TeacherEditProfile = () => {
  const [show, setShow] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const dispatch = useDispatch();
  const [src, setSrc] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const navigate = useNavigate();

  const SubmitUploadedFile = (file) => {
    if (file === null) {
      dispatch(
        setOpen({
          message: "Please select an image",
          open: true,
          variant: "error",
        })
      );
    } else {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        const storageRef = ref(
          storage,
          "profilePics/" + Date.now() + "_" + file.name
        );
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.floor(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            document.getElementById("progress").innerHTML = progress + "%";
          },
          (error) => {
            dispatch(
              setOpen({ message: error.code, open: true, variant: "error" })
            );
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                setPhotoUrl(downloadURL);
                setSrc(URL.createObjectURL(file));
              })
              .catch((error) => {
                dispatch(
                  setOpen({ message: error.code, open: true, variant: "error" })
                );
              });
          }
        );
      } else {
        dispatch(
          setOpen({
            message: "Please select an image file",
            open: true,
            variant: "error",
          })
        );
      }
    }
  };

  useEffect(() => {
    const API = new Api();

    const token = localStorage.getItem("token");
    API.getTeacherData(token)
      .then((res) => {
        const data = res.data;
        if (data) {
          setFirstname(data.firstname);
          setLastname(data.lastname);
          setEmail(data.email);
          setPhone(data.phone);
          setGender(data.gender);
          setSrc(data.avatar);
          setPhotoUrl(data.avatar);
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  }, []);

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  const handleSubmit = (e) => {
    dispatch(setLoading(true));
    e.preventDefault();
    const token = localStorage.getItem("token");

    const API = new Api();
    if (photoUrl === "" || photoUrl === null) {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please upload your Profile Picture",
          open: true,
          variant: "error",
        })
      );
    } else {
      API.updateTeacher(
        token,
        firstname,
        lastname,
        gender,
        email,
        phone,
        photoUrl
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
            navigate("/dashboard/teacher/profile");
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };
  const handlePassword = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (e.target.password.value.length <= 7) {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Password must be at least 8 characters",
          open: true,
          variant: "error",
        })
      );
    } else {
      if (e.target.password.value === e.target.repassword.value) {
        const API = new Api();
        dispatch(setLoading(true));
        API.changepasswordTeacher(token, e.target.password.value)
          .then((res) => {
            if (res.data.message === "Password Updated Successfully") {
              e.target.password.value = "";
              e.target.repassword.value = "";
            }

            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: err.response.data.message,
                open: true,
                variant: "error",
              })
            );
          });
      } else {
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: "Password Mismatch",
            open: true,
            variant: "error",
          })
        );
      }
    }
  };
  return (
    <>
      <Avatar
        sx={{
          border: "1px solid gray",
          margin: "2% auto",
          width: 150,
          height: 150,
        }}
        src={src ? src : ""}
      />
      <form onSubmit={handleSubmit}>
        <Box sx={{ m: "3% 0" }} className="upload-pic">
          <Box className="upload-btn-wrapper">
            <button className="btn">Upload</button>
            <p id="progress"></p>

            <input
              accept="image/*"
              type="file"
              name="avatar"
              onChange={(e) => {
                const f = e.target.files[0];
                if (f && f.type.match("image.*")) {
                  SubmitUploadedFile(f);
                } else {
                  dispatch(
                    setOpen({
                      message: "Please select an image",
                      open: true,
                      variant: "error",
                    })
                  );
                }
              }}
            />
          </Box>
        </Box>

        <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
          <FormControl focused fullWidth sx={{ m: "1% 0" }} variant="standard">
            <InputLabel htmlFor="firstname">First Name</InputLabel>
            <Input
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
              name="firstname"
              id="firstname"
            />
          </FormControl>
          <FormControl focused fullWidth sx={{ m: "1% 0" }} variant="standard">
            <InputLabel htmlFor="lastname">Last Name</InputLabel>
            <Input
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
              name="lastname"
              id="lastname"
            />
          </FormControl>
        </Box>

        <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
          <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              name="email"
              type="email"
              id="email"
            />
          </FormControl>
          <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
            <InputLabel htmlFor="phone">Phone Number</InputLabel>

            <Input
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              required
              name="phone"
              id="phone"
            />
          </FormControl>
        </Box>

        <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
          <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
            <InputLabel htmlFor="gender">Gender</InputLabel>
            <Select
              value={gender}
              onChange={handleChange}
              labelId="gender"
              id="gender"
              required
              label="Gender"
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button
          sx={{ m: "2% 0" }}
          fullWidth
          variant="contained"
          type="submit"
          component="button"
        >
          Submit
        </Button>
      </form>

      <Box component={"form"} onSubmit={handlePassword} mt={5}>
        <Typography variant="h5" gutterBottom>
          CHANGE PASSWORD
        </Typography>
        <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
          <Box width={"100%"} sx={{ display: "flex" }}>
            <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                required
                type={!show ? "password" : "text"}
                id="password"
              />
            </FormControl>
            <ButtonBase onClick={() => setShow(!show)}>
              {!show ? (
                <VisibilityOutlinedIcon sx={{ alignSelf: "center" }} />
              ) : (
                <VisibilityOffOutlinedIcon sx={{ alignSelf: "center" }} />
              )}
            </ButtonBase>
          </Box>
          <Box width={"100%"} sx={{ display: "flex" }}>
            <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
              <InputLabel htmlFor="repassword">Confirm Password</InputLabel>
              <Input
                name="repassword"
                required
                type={!show ? "password" : "text"}
                id="repassword"
              />
            </FormControl>
            <ButtonBase onClick={() => setShow(!show)}>
              {!show ? (
                <VisibilityOutlinedIcon sx={{ alignSelf: "center" }} />
              ) : (
                <VisibilityOffOutlinedIcon sx={{ alignSelf: "center" }} />
              )}
            </ButtonBase>
          </Box>
        </Box>
        <Button
          sx={{ m: "2% 0" }}
          fullWidth
          variant="contained"
          type="submit"
          component="button"
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

export default TeacherEditProfile;
