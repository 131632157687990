import React from "react";

import {
  Box,
  Backdrop,
  ButtonBase,
  Modal,
  Fade,
  Typography,
  Button,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  height: "300px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: "0.3%",
  overflowY: "auto",
};
const GInfo = ({ opened, setOpened, type, setOpenSub }) => {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opened}
        onClose={() => setOpened(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opened}>
          <Box className="class-view" sx={style}>
            <ButtonBase
              sx={{
                fontSize: 50,
                position: "sticky",
                top: 0,
                left: "100%",
              }}
              onClick={() => setOpened(false)}
            >
              &times;
            </ButtonBase>

            <Box
              sx={{
                p: "0 20px",
              }}
            >
              <Typography
                textAlign={"center"}
                sx={{ mt: 0, fontWeight: "bold" }}
                variant="h5"
              >
                NOT SUBSCRIBED
              </Typography>
              {type === "test" ? (
                <Box textAlign={"center"} mt={2}>
                  <Typography
                    sx={{ mt: 3, display: "block" }}
                    fontSize={18}
                    variant="p"
                  >
                    You are on the free plan. you can only take a single quiz.
                  </Typography>
                  <Typography
                    sx={{ mt: 3, display: "block" }}
                    fontSize={18}
                    variant="p"
                  >
                    To take another quiz or retake a quiz, Please subscribe.
                  </Typography>
                </Box>
              ) : (
                <Box textAlign={"center"} mt={2}>
                  <Typography
                    sx={{ mt: 3, display: "block" }}
                    fontSize={18}
                    variant="p"
                  >
                    You are on the free plan, you cannot view result summary
                  </Typography>
                  <Typography
                    sx={{ mt: 3, display: "block" }}
                    fontSize={18}
                    variant="p"
                  >
                    To view result summary, Please subscribe.
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  textAlign: "center",
                  mb: 2,
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenSub(true);
                    setOpened(false);
                  }}
                >
                  Subscribe Now
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default GInfo;
