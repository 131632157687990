import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  FormControl,
  Stack,
  Select,
  MenuItem,
  Fade,
  Modal,
  InputLabel,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Api from "../../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../../slices/StateManager";

const CreatePattenEdit = ({
  data,
  style,
  lev,
  open,
  handleClose,
  Backdrop,
}) => {
  const dispatch = useDispatch();

  const [ckValue, setCkvalue] = useState(data?.question?.replaceAll("~~", "'"));
  const [options, setOptions] = useState(data.options);
  const [category, setCategory] = useState(data?.category);
  const addOptions = () => {
    if (options.length > 5) {
      alert("you cannot add more than 6 options");
      return;
    } else {
      setOptions([...options, { options: "", match: "" }]);
    }
  };

  const removeOptions = (i) => {
    const list = [...options];
    list.splice(i, 1);
    setOptions(list);
  };

  const onChangeOption = (index, e) => {
    let getterr = [...options];
    if (e.target.name === "options") {
      getterr[index].options = e.target.value;
    }

    if (e.target.name === "match") {
      getterr[index].match = e.target.value;
    }

    setOptions(getterr);
  };

  let menu = [];
  for (let index = 0; index <= lev?.category?.length; index++) {
    menu.push(
      <MenuItem key={index} value={lev?.category[index]?.text}>
        {lev?.category[index]?.text}
      </MenuItem>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const token = localStorage.getItem("token");
    if (ckValue === "") {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please Type Instruction!",
          open: true,
          variant: "error",
        })
      );
    } else {
      const value = ckValue;
      const answers = JSON.stringify(options).replaceAll("'", "~~");
      let op = JSON.stringify(options).replaceAll("'", "~~");
      const API = new Api();

      let newValue = value.replaceAll("'", "~~");
      API.updateQuestionNormal(
        token,
        data.id,
        data.quiz_id,
        newValue,
        "match",
        op,
        answers,
        category
      )
        .then((res) => {
          if (res.data.message === "Question Added Successfully!") {
            dispatch(setLoading(false));

            setOptions([...options, { options: "" }]);
            setCkvalue("");
            setCategory(category);
          }

          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "success",
            })
          );
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component={"form"} onSubmit={handleSubmit} sx={style}>
            <Typography variant="h6" component="h6" sx={{ textAlign: "left" }}>
              Word Matching
            </Typography>
            <Grid container spacing={4} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
                  <InputLabel id="demo-simple-select-label">
                    Question Section
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Question Section"
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                  >
                    {menu.map((item) => {
                      return item;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  multiline
                  fullWidth
                  label="Instructions"
                  rows={4}
                  value={ckValue}
                  onChange={(event) => setCkvalue(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={1} sx={{ mb: 2 }}>
                  {options.map((x, index) => (
                    <Grid container key={index} spacing={1} sx={{ mb: 2 }}>
                      <Grid item xs={4} md={5}>
                        {" "}
                        <TextField
                          name="options"
                          size="small"
                          multiline
                          fullWidth
                          label={`Option ${index + 1}`}
                          value={options[index].options}
                          onChange={(e) => onChangeOption(index, e)}
                        />
                      </Grid>
                      <Grid item xs={4} md={5}>
                        {" "}
                        <TextField
                          name="match"
                          fullWidth
                          multiline
                          size="small"
                          required
                          id="standard-basic"
                          label={`Match ${index + 1}`}
                          value={options[index].match}
                          onChange={(e) => onChangeOption(index, e)}
                        />
                      </Grid>
                      <Grid item xs={4} md={2}>
                        <Stack direction={"row"} spacing={{ md: 2, xs: 0 }}>
                          {options.length !== 1 && (
                            <>
                              <IconButton
                                aria-label="delete"
                                onClick={() => removeOptions(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                          {options.length - 1 === index && (
                            <>
                              <Fab
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={addOptions}
                                sx={{
                                  width: { xs: "50px", sm: "auto" },
                                  height: "40px",
                                  p: 1,
                                }}
                              >
                                <AddIcon />
                              </Fab>
                            </>
                          )}
                        </Stack>{" "}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={4} md={4}></Grid>
                <Grid item md={4}></Grid>
                <Grid item xs={4} md={4}>
                  <Stack direction="row" spacing={3}>
                    <Button type="submit" variant="contained" size="large">
                      Save Changes
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CreatePattenEdit;
