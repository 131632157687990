import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";
import Api from "../../api/API";

const CreateClass = () => {
  const [status, setStatus] = useState("active");
  const dispatch = useDispatch();

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    const token = localStorage.getItem("token");
    const API = new Api();
    API.createClass(
      token,
      e.target.class_name.value,
      status,

      e.target.subject.value
    )
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(
          setOpen({ message: res.data.message, open: true, variant: "success" })
        );
        e.target.class_name.value = "";
        e.target.subject.value = "";
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: err.response.data.message,
            open: true,
            variant: "error",
          })
        );
      });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Typography variant="h4" component="h4" sx={{ textAlign: "left" }}>
          Create Class
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            "& > :not(style)": { mt: 1 },
          }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} md={5}>
              <TextField
                required
                name="class_name"
                fullWidth
                id="standard-basic"
                label="Class Name"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                required
                name="subject"
                id="standard-basic"
                label="Class Subject"
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <FormControl fullWidth>
                <InputLabel id="  Class Status">Class Status</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Class Status"
                  value={status}
                  onChange={handleStatus}
                >
                  <MenuItem value={"active"}>Open</MenuItem>
                  <MenuItem value={"inactive"}>Closed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item md={4}></Grid>
            <Grid item md={4}>
              <Button variant="contained" type="submit" size="large">
                Create Class
              </Button>
            </Grid>
            <Grid item md={4}></Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreateClass;
