import React, { useEffect, useState } from "react";
import { Box, Stack, Button, Typography, Paper } from "@mui/material";
import OverviewCard from "../../components/OverviewCard";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import SkeletonContainer from "../../components/SkeletonContainer";
import Api from "../../api/API";
import moment from "moment";
import GStudentSub from "../../components/GStudentSub";
import { useDispatch } from "react-redux";

const GStudentOverview = () => {
  const [quizzes, setQuizzes] = useState(null);
  const [result, setResult] = useState(null);
  const [loads, setLoad] = useState(false);
  const [load1, setLoad1] = useState(false);
  const [sub, setSub] = useState(null);
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    setLoad1(true);
    API.getAllResult(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setResult(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_taken),
                db = new Date(b.date_taken);
              return db - da;
            })
          );
        }
        setLoad(false);
      })
      .catch((err) => {
        setResult(null);

        setLoad(false);
      });

    API.getGlobalStudentQuiz(token)
      .then((res) => {
        setQuizzes(res.data.data);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });

    API.ggetSub(token)
      .then((res) => {
        if (res.data) {
          setSub(res.data);
          setLoad1(false);
        }
      })
      .catch((err) => {
        setLoad1(false);
      });
  }, []);

  let count = 0;
  if (quizzes !== null && quizzes !== null) {
    quizzes.forEach((ele) => {
      ele?.taken && count++;
    });
  }

  const handleSwitch = () => {
    if (
      window.confirm(
        "You will be switched to global student interface. Are you sure?"
      )
    ) {
      const api = new Api();
      api
        .switchBack()
        .then((res) => {
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("us", res.data.extra);
          window.location.href = "/dashboard/student";
        })
        .catch((err) => {
          dispatch(
            setOpen({
              open: true,
              message: err.response.data.message,
              variant: "error",
            })
          );
        });
    }
  };
  useEffect(() => {
    const taken = quizzes?.filter((d) => !d.taken);
    if (taken?.length > 0) {
      setNotification(true);
    }
    localStorage.setItem("load", "false");
    localStorage.removeItem("reload");
    localStorage.removeItem("quiz_id");
    localStorage.removeItem("quiz_name");
    localStorage.removeItem("time_left");
    localStorage.removeItem("answers");
    localStorage.removeItem("values");
    localStorage.removeItem("type");
    localStorage.removeItem("time");
  }, [quizzes]);
  return (
    <>
      <GStudentSub current={sub?.plan} open={open} handleClose={handleClose} />
      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: " 1fr",
              sm: "1fr 1fr",
              mid: "1fr 1fr 1fr",
            },
            columnGap: "5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <OverviewCard
                icon={() => (
                  <QuizRoundedIcon sx={{ fontSize: 30 }} color={"primary"} />
                )}
                text={"Quiz Results"}
                amount={result ? result?.length : 0}
              />
            </>
          )}

          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <OverviewCard
                icon={() => (
                  <QuizRoundedIcon sx={{ fontSize: 30 }} color={"primary"} />
                )}
                text={"Quizzes Available"}
                amount={quizzes ? quizzes?.length : 0}
              />
            </>
          )}
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <OverviewCard
                icon={() => (
                  <QuizRoundedIcon sx={{ fontSize: 30 }} color={"primary"} />
                )}
                text={"Quizzes Not Taken"}
                amount={quizzes ? quizzes.length - count : 0}
              />
            </>
          )}
        </Box>

        <Box mt={5}>
          <Stack spacing={5} direction={{ md: "row", xs: "column", sm: "row" }}>
            <Button
              href="/dashboard/gstudent/quiz"
              variant="contained"
              color="success"
            >
              Take Test
            </Button>
            <Button href="/dashboard/gstudent/results" variant="contained">
              View Results
            </Button>
            {localStorage.getItem("us") === "IN" && (
              <Button
                variant="contained"
                color="warning"
                onClick={handleSwitch}
              >
                Switch To Student Interface
              </Button>
            )}
          </Stack>
        </Box>
        {notification && window?.navigator?.vibrate(600) && (
          <Box sx={{ mt: 3, maxWidth: { xs: "100%", md: "65%", lg: "50%" } }}>
            <Paper sx={{ p: 3, bgcolor: "red" }}>
              <Typography variant="h4" color={"white"}>
                You have some Quiz/Spelling Bee to take
              </Typography>
            </Paper>
          </Box>
        )}
        <Box mt={5}>
          {load1 ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Typography variant="h4">Subscription Details</Typography>
              <Box
                sx={{
                  mt: 3,
                  borderRadius: 5,
                  backgroundColor: "#1976d21a",
                  p: "3% 5%",
                  display: { xs: "block", sm: "flex" },
                  columnGap: "20%",
                }}
              >
                <Box>
                  <Typography m="10% 0" variant="h6">
                    Subscribed:&nbsp;
                    {sub !== null ? (
                      <span style={{ textTransform: "capitalize" }}>
                        {sub.paid}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </Typography>
                  <Typography m="10% 0" variant="h6">
                    Plan:&nbsp;
                    {sub !== null ? (
                      <span style={{ textTransform: "capitalize" }}>
                        {sub.plan}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </Typography>
                  <Typography m="10% 0" variant="h6">
                    Date Subscribed:&nbsp;
                    {sub !== null ? (
                      <span style={{ textTransform: "capitalize" }}>
                        {moment(sub.date_sub).format("DD-MM-YYYY")}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </Typography>
                  <Typography m="10% 0" variant="h6">
                    Expiry Date:&nbsp;
                    {sub !== null ? (
                      <span style={{ textTransform: "capitalize" }}>
                        {moment(sub.date_end).format("DD-MM-YYYY")}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </Typography>
                </Box>
                {sub !== null ? (
                  sub.paid === "false" ? (
                    <Box alignSelf={"center"}>
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        sx={{ borderRadius: 1, width: "100%" }}
                      >
                        Subscribe
                      </Button>
                    </Box>
                  ) : (
                    <Box alignSelf={"center"}>
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        sx={{ borderRadius: 1, width: "100%" }}
                      >
                        Upgrade
                      </Button>
                    </Box>
                  )
                ) : null}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default GStudentOverview;
