import React from 'react'
import { useSelector } from "react-redux"
import TeacherSignup from '../../teacher/TeacherSignup'
import StudentSignup from '../../student/StudentSignup'
import AdminSignup from '../../admin/AdminSignup'
import GStudentSignup from '../../student/GStudentSignup'


function ThirdComponent() {
  const {role}=useSelector((state) => state.changePage)
  return (
    <>
    {role==="admin" && (<AdminSignup/>)}
    {role==="student" && (<StudentSignup/>)}
    {role==="teacher" && (<TeacherSignup/>)}
    {role==="global" && (<GStudentSignup/>)}
    
    </>
  )
}

export default ThirdComponent