import React from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import arrow from "../../images/Arrow.png";

function HowItWorksDiv() {
  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          background:
            "linear-gradient(180deg, rgba(242, 239, 239, 0.42) 0%, #FFFFFF 100%)",
        }}
      >
        <Grid container spacing={1} py={5}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography
              variant="h4"
              className="gradient-text"
              sx={{fontWeight: "bold" }}
            >
              How It Works
            </Typography>
          </Grid>


          <Grid item md={2} ></Grid>
          <Grid item md={8} my={5} >
          <Box >
          <Stack direction="column" spacing={5}
          
          
          px={5}>
          <Stack direction={'column'} spacing={5}>
         
            <Stack direction="row" spacing={4}
             justifyItems={"center"}
             alignItems={"center"}
            >
              <img src={arrow} alt="" className="arrow" />

              <Typography variant="h6" 
              sx={{color: "#3C4DA7"}}> Sign Up for an account </Typography>
            </Stack>

          </Stack>
          <Stack direction={'column'} >
          <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: "black" }}
            />
            <Stack direction="row" spacing={4}
             justifyItems={"center"}
             alignItems={"center"}
            >
              <img src={arrow} alt="" className="arrow" />

              <Typography variant="h6" sx={{color: "#3C4DA7"}}> Choose your user role</Typography>
            </Stack>
            </Stack>
            <Stack direction={'column'}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: "black" }}
            />
            <Stack direction="row" spacing={4}
             justifyItems={"center"}
             alignItems={"center"}
            >
              <img src={arrow} alt="" className="arrow" />

              <Typography variant="h6" sx={{color: "#3C4DA7"}}>
                {" "}
                Explore quizzes and spelling bee challenges
              </Typography>
            </Stack>
              </Stack>
           
              <Stack direction={'column'}>
              <Divider
              orientation="vertical"
              flexItem
              sx={{ backgroundColor: "black" }}
            />
            <Stack direction="row" spacing={4} 
             justifyItems={"center"}
             alignItems={"center"}
            >
              <img src={arrow} alt="" className="arrow" />

              <Typography variant="h6" 
              sx={{color: "#3C4DA7"}}
              > Track your progress</Typography>
            </Stack>
                </Stack>
           
          </Stack>
        </Box>
        
          </Grid>
           
          <Grid item md={2} ></Grid>

         
        </Grid>
       
      </Grid>
    </>
  );
}

export default HowItWorksDiv;
