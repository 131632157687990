import React, { useEffect, useState } from "react";
import { Box, Paper, Stack, Typography, styled } from "@mui/material";
import OverviewCard from "../../components/OverviewCard";
import { FaSchool } from "react-icons/fa";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";

import SkeletonContainer from "../../components/SkeletonContainer";
import Api from "../../api/API";
import { Button } from "@mui/material";

import { setOpen } from "../../slices/StateManager";
import { useDispatch } from "react-redux";
import Instruction from "../../components/Instruction";
import { useNavigate } from "react-router";

export const CustomButton = styled(Button)({
  width: "100% !important",
});

const StudentOverview = () => {
  const [classes, setClasses] = useState(null);

  const [quizzes, setQuizzes] = useState(null);
  const [opened, setOpened] = useState(false);
  const [notification, setNotification] = useState(false);
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [loads, setLoad] = useState({
    loading1: true,
    loading2: true,
    loading3: true,
  });

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    API.getInStudentClasses(token)
      .then((res) => {
        setClasses(res.data.data);
        setLoad({ loading1: false });
      })
      .catch((err) => {
        setLoad({ loading1: false });
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
    API.getAllResult(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setResult(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_taken),
                db = new Date(b.date_taken);
              return db - da;
            })
          );
        }
        setLoad({ loading2: false });
      })
      .catch((err) => {
        setResult(null);

        setLoad({ loading2: false });
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
    API.getStudentQuiz(token)
      .then((res) => {
        setQuizzes(res.data.data);
        setLoad({ loading3: false });
      })
      .catch((err) => {
        setLoad({ loading3: false });
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
  }, [dispatch]);

  let count = 0;
  if (quizzes !== null && quizzes !== null) {
    quizzes.forEach((ele) => {
      ele?.taken && count++;
    });
  }

  const handleSwitch = () => {
    if (
      window.confirm(
        "You will be switched to global student interface. Are you sure?"
      )
    ) {
      const api = new Api();
      api
        .switch()
        .then((res) => {
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("us", res.data.extra);
          window.location.href = "/dashboard/gstudent";
        })
        .catch((err) => {
          dispatch(
            setOpen({
              open: true,
              message: err.response.data.message,
              variant: "error",
            })
          );
        });
    }
  };
  useEffect(() => {
    const taken = quizzes?.filter((d) => !d.taken);
    if (taken?.length > 0) {
      setNotification(true);
    }
    const reload = localStorage.getItem("reload");
    if (reload !== "true") {
      localStorage.setItem("load", "false");
      localStorage.removeItem("reload");
      localStorage.removeItem("quiz_id");
      localStorage.removeItem("quiz_name");
      localStorage.removeItem("time_left");
      localStorage.removeItem("answers");
      localStorage.removeItem("values");
      localStorage.removeItem("type");
      localStorage.removeItem("time");
    }
  }, [quizzes]);

  return (
    <>
      <Box>
        <Instruction opened={opened} setOpened={setOpened} />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: " 1fr",
              sm: "1fr 1fr",
              mid: "1fr 1fr 1fr",
            },
            columnGap: "5%",
          }}
        >
          {loads.loading1 && loads.loading3 && loads.loading2 ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <OverviewCard
                icon={() => (
                  <QuizRoundedIcon sx={{ fontSize: 30 }} color={"primary"} />
                )}
                text={"Quiz Results"}
                amount={result ? result.length : 0}
              />
            </>
          )}

          {loads.loading1 && loads.loading3 && loads.loading2 ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <OverviewCard
                icon={() => <FaSchool size={30} color={"#1976d2"} />}
                text={"Classes Joined"}
                amount={classes ? classes.length : 0}
              />
            </>
          )}
          {loads.loading1 && loads.loading3 && loads.loading2 ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <OverviewCard
                icon={() => <FaSchool size={30} color={"#1976d2"} />}
                text={"Quizzes Available"}
                amount={quizzes ? quizzes.length : 0}
              />
            </>
          )}
          {loads.loading1 && loads.loading3 && loads.loading2 ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <OverviewCard
                icon={() => (
                  <QuizRoundedIcon sx={{ fontSize: 30 }} color={"primary"} />
                )}
                text={"Quizzes Not Taken"}
                amount={quizzes ? quizzes.length - count : 0}
              />
            </>
          )}
        </Box>
        <Box mt={5}>
          <Stack spacing={5} direction={{ md: "row", xs: "column", sm: "row" }}>
            <Button onClick={() => setOpened(true)} variant="contained">
              View Instructions
            </Button>
          </Stack>
          <Box sx={{ marginBlock: 2 }}>
            <Typography variant="h4">Quick Actions</Typography>
            <Box
              sx={{
                marginBlock: 2,
                display: "grid",
                gap: 2,
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "repeat(2,1fr)",
                  md: "repeat(2,1fr)",
                  lg: "repeat(4,1fr)",
                },
              }}
            >
              <CustomButton
                variant="contained"
                color="success"
                onClick={() => navigate("/dashboard/student/quiz")}
              >
                Take Test
              </CustomButton>

              <CustomButton
                onClick={() => navigate("/dashboard/student/results")}
                variant="contained"
              >
                View Results
              </CustomButton>

              <CustomButton variant="contained" onClick={handleSwitch}>
                Take Global Quiz
              </CustomButton>

              <CustomButton
                onClick={() => navigate("/dashboard/student/class")}
                variant="contained"
                color="success"
              >
                Join Class
              </CustomButton>
            </Box>
          </Box>
        </Box>
        {notification && window?.navigator?.vibrate(600) && (
          <Box sx={{ mt: 3, maxWidth: { xs: "100%", md: "65%", lg: "50%" } }}>
            <Paper sx={{ p: 3, bgcolor: "red" }}>
              <Typography variant="h4" color={"white"}>
                You have some Quiz/Spelling Bee to take
              </Typography>
            </Paper>
          </Box>
        )}
      </Box>
    </>
  );
};

export default StudentOverview;
