import { configureStore } from "@reduxjs/toolkit";
import OptionReducer from "./slices/OptionSlices";
import StateManager from "./slices/StateManager";
import EditableState from "./slices/EditableState";
import changePage from "./slices/changePage";


export const store = configureStore({
  reducer: { OptionReducer,StateManager,EditableState,changePage },
});
