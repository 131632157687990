import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  options: [],
  score:0,
};

export const OptionSlices = createSlice({
  name: "option",
  initialState,
  reducers: {
    add: (state, action) => {
      state.options.push(action.payload);
    },
    remove: (state, action) => {
      state.options.splice(action.payload, 1);
    },
  },
});

export const { add, remove } = OptionSlices.actions;

export default OptionSlices.reducer;
