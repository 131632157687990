import React, { useState, useEffect, useRef, Suspense } from "react";
import {
  Box,
  Tooltip,
  Typography,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Button,
  Dialog,
} from "@mui/material";
import Api from "../../../api/API";
import { Quiz, VolumeUp, TipsAndUpdatesTwoTone } from "@mui/icons-material";
import { useSpeechSynthesis } from "react-speech-kit";
import { Beforeunload } from "react-beforeunload";
import TimerContainer from "../../../components/TimerContainer";
import TimeTaken from "../../../components/TimeTaken";
import EndScreen2 from "./EndScreen2";
import ReactLoader from "../../../components/ReactLoader";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const SpellingQuizInterface = ({ quizData, questions, duration }) => {
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([]);
  const [opened, setOpened] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const { speak, speaking, voices } = useSpeechSynthesis();
  const [dt, setDt] = useState([]);
  const [voiceIndex, setVoiceIndex] = useState([]);

  const [showKey, setShowKey] = useState(false);

  // Keyboard

  const keyboard = useRef();

  const onKeyPress = (button) => {
    if (button === "{bksp}") {
      let set = [...answers];

      set[pageIndex] = {
        id: questions[pageIndex].id,
        word: answers[pageIndex]?.word.slice(0, -1),
      };
      setAnswers(set);
    } else if (button === "{clky}") {
      setShowKey(false);
    } else {
      let set = [...answers];

      set[pageIndex] = {
        id: questions[pageIndex].id,
        word: answers[pageIndex]?.word
          ? answers[pageIndex]?.word + button
          : button,
      };
      setAnswers(set);
    }
  };

  // End Keyboard
  const isDesktop = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return false;
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    const ans = JSON.parse(localStorage.getItem("answers"));

    if (ans !== null && ans !== undefined) {
      setAnswers(ans);
    } else {
      localStorage.setItem("answers", JSON.stringify([]));
    }
  }, []);
  useEffect(() => {
    setDt(voices.filter((value) => value.lang.startsWith("en")));
    setVoiceIndex(
      voices.filter(
        (values) => values.lang === "en-US" || values.lang === "en-GB"
      )
    );
  }, [voices]);

  const submit = () => {
    const token = localStorage.getItem("token");
    const time_taken = localStorage.getItem("time");
    let dt = JSON.parse(localStorage.getItem("answers").replaceAll("'", "~~"));

    let dm = dt.filter((item) => item?.word !== "");

    let answers = dm.filter((value) => value != null || undefined);
    const API = new Api();

    setLoading(true);
    API.scoreStudent(token, quizData.quiz_id, answers, time_taken)
      .then((res) => {
        setLoading(false);
        alert(res.data.message);
        setState(res.data.data);
        setTimeout(() => {
          setOpened(true);
        }, 300);
        localStorage.setItem("load", "false");
        localStorage.removeItem("reload");
        localStorage.removeItem("quiz_id");
        localStorage.removeItem("quiz_name");

        localStorage.removeItem("time_left");
        localStorage.removeItem("answers");
        localStorage.removeItem("values");

        localStorage.removeItem("type");
        localStorage.removeItem("time");
      })
      .catch((err) => {
        setLoading(false);
        localStorage.removeItem("reload");
        localStorage.removeItem("quiz_id");
        localStorage.removeItem("quiz_name");
        localStorage.setItem("load", "false");
        alert(err.response.data.message);
      });
  };

  const onChange = (value) => {
    let set = [...answers];

    set[pageIndex] = {
      id: questions[pageIndex].id,
      word: value,
    };

    setAnswers(set);
  };

  const handleNext = () => {
    let incrementCurrentQuestionIndex = pageIndex + 1;
    setPageIndex(incrementCurrentQuestionIndex);
    localStorage.setItem("answers", JSON.stringify(answers));
  };

  // localStorage.setItem("answers", JSON.stringify(answers));

  const handlePrevious = () => {
    let incrementCurrentQuestionIndex = pageIndex - 1;
    setPageIndex(incrementCurrentQuestionIndex);
    localStorage.setItem("answers", JSON.stringify(answers));
  };

  const finishQuiz = () => {
    localStorage.setItem("answers", JSON.stringify(answers));
    if (window.confirm("Are you sure you want to submit?")) {
      submit();
    }
  };
  const onSetPageCount = (i) => {
    setPageIndex(i);
    localStorage.setItem("answers", JSON.stringify(answers));
  };

  const btn = [];
  for (let i = 0; i < questions.length; i++) {
    btn.push({
      category: questions[i].category,
      btn: (
        <span
          key={i}
          style={{
            padding: "10px",
            cursor: "pointer",
            margin: "3px",
            width: "fit-content",
            height: "fit-content",
            borderRadius: "8px",
            color:
              pageIndex === i
                ? "white"
                : answers !== undefined
                ? answers?.map((d) => d?.id)?.includes(questions[i]?.id)
                  ? "white"
                  : "#1976d2"
                : null,
            backgroundColor:
              pageIndex === i
                ? "red"
                : answers !== undefined
                ? answers?.map((d) => d?.id)?.includes(questions[i]?.id)
                  ? "#1976d2"
                  : "#1976d21a"
                : "#1976d21a",
          }}
          onClick={() => onSetPageCount(i)}
        >
          {i + 1}
        </span>
      ),
    });
  }
  //this function will be call when time expired, use it to submit the data on time expired
  const timeExpire = () => {
    alert("Times Up!! your answer will submitted automatically");
    submit();
  };
  useEffect(() => {
    localStorage.setItem("type", "spelling");
    const reloadTime = setTimeout(() => {
      localStorage.setItem("reload", "false");
      localStorage.setItem("load", "true");
      localStorage.setItem("quiz_id", `${quizData.quiz_id}`);
      localStorage.setItem("quiz_name", `${quizData.quiz_name}`);
    }, 60000);
    return () => {
      clearTimeout(reloadTime);
    };
  }, [quizData]);

  // const voice = dt.filter((value) => value.name ===voiceIndex);
  const desktop = isDesktop();

  return (
    <>
      {loading && <ReactLoader />}
      <EndScreen2 opened={opened} setOpened={setOpened} state={state} />
      <Beforeunload
        onBeforeunload={(e) => {
          localStorage.setItem("load", "false");
          e.preventDefault();
          localStorage.setItem("reload", "true");
          localStorage.setItem("quiz_id", `${quizData.quiz_id}`);
          localStorage.setItem("quiz_name", `${quizData.quiz_name}`);
          localStorage.setItem("answers", JSON.stringify(answers));
        }}
      >
        <Box sx={{ p: 3 }}>
          <div id="time" style={{ display: "none" }}>
            <TimeTaken />
          </div>
          <div id="error"></div>
          <span id="check">true</span>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={7} sx={{ textAlign: "left" }}>
              <Box
                sx={{
                  width: "fit-content",
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <TimerContainer duration={duration} expire={timeExpire} />
                </Stack>
              </Box>
              <Stack
                direction={"row"}
                divider={<Divider orientation="vertical" flexItem />}
                sx={{ p: 2 }}
              >
                <Box>
                  <Typography
                    color="initial"
                    sx={{
                      width: "fit-content",
                    }}
                  >
                    Question {`${pageIndex + 1} of ${questions.length}`}
                  </Typography>
                </Box>
              </Stack>

              <Divider sx={{ mb: 3 }} />
              <Stack spacing={1} sx={{ textAlign: "left" }}>
                {questions[pageIndex]?.type === "option1" ? (
                  <>
                    <Typography variant="h6">
                      <Quiz color="primary" />
                      Spelling Quiz, Choose the voive pannel to Pronounce and
                      type the correct word as it is pronounced.
                    </Typography>
                    <Tooltip title="Hints">
                      <Typography
                        variant="p"
                        sx={{ color: "#000", fontWeight: "bold" }}
                      >
                        <TipsAndUpdatesTwoTone color="success" />{" "}
                        {questions[pageIndex]?.hint?.replaceAll("~~", "'")}
                      </Typography>
                    </Tooltip>
                    <Box>
                      <Grid container spacing={1} sx={{ mb: 6, mt: 3 }}>
                        <Grid item xs={12} md={12}>
                          <input
                            className="input-tag"
                            onClick={() => {
                              !desktop && setShowKey(true);
                            }}
                            id="word"
                            placeholder={`Enter what you hear in this field!! `}
                            value={
                              answers?.find(
                                (dd) => dd?.id === questions[pageIndex]?.id
                              )?.word || ""
                            }
                            type="text"
                            readOnly={desktop ? false : true}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            onChange={(e) => {
                              desktop && onChange(e.target.value);
                            }}
                            spellCheck={false}
                            onPaste={(e) => e.preventDefault()}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Stack direction={{ xs: "column", md: "row" }}>
                            <Stack direction={"row"}>
                              <Tooltip title="click here to Listen">
                                <VolumeUp
                                  onClick={() =>
                                    speak({
                                      text: questions[
                                        pageIndex
                                      ].word.replaceAll("~~", "'"),
                                      voice: voiceIndex[0],
                                    })
                                  }
                                  color={speaking ? "success" : "primary"}
                                  sx={{ fontSize: "80pt", cursor: "pointer" }}
                                />
                              </Tooltip>

                              <Stack
                                direction={"row"}
                                spacing={2}
                                sx={{
                                  display: { xs: "flex", sm: "none" }, // Display in xs (mobile) and hide in other viewports
                                }}
                              >
                                {pageIndex >= 1 && (
                                  <Button
                                    size="small"
                                    sx={{
                                      height: "40px",
                                      justifyItems: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      alignContent: "center",
                                    }}
                                    variant="contained"
                                    onClick={handlePrevious}
                                  >
                                    Previous
                                  </Button>
                                )}
                                {pageIndex !== questions.length - 1 ? (
                                  <Button
                                    size="small"
                                    sx={{
                                      height: "40px",
                                      justifyItems: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      alignContent: "center",
                                    }}
                                    variant="contained"
                                    onClick={handleNext}
                                  >
                                    Next
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    sx={{
                                      height: "40px",
                                      justifyItems: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      alignContent: "center",
                                    }}
                                    variant="contained"
                                    color="error"
                                    onClick={finishQuiz}
                                  >
                                    Finish Quiz
                                  </Button>
                                )}
                              </Stack>
                            </Stack>
                            <FormControl fullWidth sx={{ mt: 6 }}>
                              <InputLabel id="demo-simple-select-label">
                                Choice a Voice to Listen On
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Choice a Voice to Listen With"
                                value={voiceIndex[0]?.name}
                                onChange={(event) => {
                                  setVoiceIndex(
                                    voices.filter(
                                      (value) =>
                                        value?.name === event.target.value
                                    )
                                  );
                                }}
                              >
                                {dt?.map((option, index) => (
                                  <MenuItem key={index} value={option?.name}>
                                    {`${option?.name}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mb: 5 }} />
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography variant="h6">
                      <Quiz color="primary" /> Spelling Quiz, Choose the voive
                      pannel to Pronounce and type the correct word as it is
                      pronounced.
                    </Typography>
                    <Tooltip color="primary" title="Hints">
                      <Typography
                        variant="p"
                        sx={{ color: "#000", fontWeight: "bold" }}
                      >
                        <TipsAndUpdatesTwoTone color="success" />{" "}
                        {questions[pageIndex]?.hint?.replaceAll("~~", "'")}
                      </Typography>
                    </Tooltip>

                    <Box>
                      <Grid container spacing={1} sx={{ mb: 6, mt: 3 }}>
                        <Grid item xs={12} md={12}>
                          <input
                            className="input-tag"
                            onClick={() => {
                              !desktop && setShowKey(true);
                            }}
                            id="word"
                            placeholder={`Enter what you hear in this field!! `}
                            value={
                              answers?.find(
                                (dd) => dd?.id === questions[pageIndex]?.id
                              )?.word || ""
                            }
                            type="text"
                            readOnly={desktop ? false : true}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            onChange={(e) => {
                              desktop && onChange(e.target.value);
                            }}
                            spellCheck={false}
                            onPaste={(e) => e.preventDefault()}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Stack direction={{ xs: "row" }} spacing={3}>
                            <audio controls controlsList="nodownload">
                              <source
                                src={questions[pageIndex]?.address}
                                type="audio/mpeg"
                              />
                              Your browser does not support the audio element.
                            </audio>
                            <Stack
                              direction={"row"}
                              spacing={2}
                              sx={{
                                display: { xs: "flex", sm: "none" }, // Display in xs (mobile) and hide in other viewports
                              }}
                            >
                              {pageIndex >= 1 && (
                                <Button
                                  size="small"
                                  sx={{
                                    height: "40px",
                                  }}
                                  variant="contained"
                                  onClick={handlePrevious}
                                >
                                  Previous
                                </Button>
                              )}
                              {pageIndex !== questions.length - 1 ? (
                                <Button
                                  size="small"
                                  sx={{
                                    height: "40px",
                                  }}
                                  variant="contained"
                                  onClick={handleNext}
                                >
                                  Next
                                </Button>
                              ) : (
                                <Button
                                  size="small"
                                  sx={{
                                    height: "40px",
                                  }}
                                  variant="contained"
                                  color="error"
                                  onClick={finishQuiz}
                                >
                                  Finish Quiz
                                </Button>
                              )}
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mb: 5 }} />
                    </Box>
                  </>
                )}
                <Grid container>
                  <Grid item md={3}></Grid>
                  <Grid item md={6}>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        display: { xs: "none", sm: "flex" }, // Display in xs (mobile) and hide in other viewports
                      }}
                    >
                      {pageIndex >= 1 && (
                        <Button variant="contained" onClick={handlePrevious}>
                          Previous
                        </Button>
                      )}
                      {pageIndex !== questions.length - 1 ? (
                        <Button variant="contained" onClick={handleNext}>
                          Next
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={finishQuiz}
                        >
                          Finish Quiz
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item md={2} xs={12} sm={12}>
              {quizData?.category?.map((cat, index) => {
                return (
                  <Box key={index}>
                    <Typography
                      marginY={1}
                      variant="h5"
                      component={"div"}
                      sx={{
                        padding: "5px",
                        backgroundColor: "#1680cc",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    >
                      {cat.text}
                    </Typography>
                    <Box
                      sx={{
                        overflowY: "auto",
                        display: "grid",
                        gridTemplateColumns: {
                          xs: "repeat(6, 1fr)",
                          sm: "repeat(15, 1fr)",
                          md: "repeat(3, 1fr)",
                          lg: "repeat(4, 1fr)",
                        },
                      }}
                    >
                      {btn.map((b) => {
                        if (b.category === cat.text) {
                          return b.btn;
                        }
                        return null;
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </Box>
        <Suspense>
          <Dialog open={showKey} onClose={() => setShowKey(false)} hideBackdrop>
            {showKey && !desktop && (
              <Box
                sx={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  left: 0,
                  zIndex: 100,
                }}
              >
                <Keyboard
                  keyboardRef={(r) => (keyboard.current = r)}
                  layout={{
                    default: [
                      "{clky}",
                      "1 2 3 4 5 6 7 8 9 0",
                      "q w e r t y u i o p",
                      "a s d f g h j k l {bksp}",
                      "z x c v b n m - '",
                    ],
                  }}
                  display={{
                    "{bksp}": "⇦",
                    "{clky}": "Close Keyboard",
                  }}
                  buttonTheme={[
                    {
                      class: "back",
                      buttons: "{bksp}",
                    },
                    {
                      class: "close",
                      buttons: "{clky}",
                    },
                  ]}
                  disableRowButtonContainers={true}
                  useButtonTag={true}
                  onKeyPress={onKeyPress}
                />
              </Box>
            )}
          </Dialog>
        </Suspense>
      </Beforeunload>
    </>
  );
};

export default SpellingQuizInterface;
