import React, { useState } from "react";

import {
  Box,
  Input,
  InputLabel,
  FormControl,
  Button,
  Typography,
  ButtonBase,
} from "@mui/material";

import Api from "../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const ChangeSuperPassword = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const handlePassword = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    dispatch(setLoading(true));
    if (e.target.password.value.length <= 7) {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Password must be at least 8 characters",
          open: true,
          variant: "error",
        })
      );
    } else {
      if (e.target.password.value === e.target.repassword.value) {
        const API = new Api();

        API.superChangePassword(token, e.target.password.value)
          .then((res) => {
            if (res.data.message === "Password Updated Successfully") {
              e.target.password.value = "";
              e.target.repassword.value = "";
            }
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: err.response.data.message,
                open: true,
                variant: "error",
              })
            );
          });
      } else {
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: "Password Mismatch",
            open: true,
            variant: "error",
          })
        );
      }
    }
  };
  return (
    <Box component={"form"} onSubmit={handlePassword} mt={5}>
      <Typography variant="h5" gutterBottom>
        CHANGE PASSWORD
      </Typography>
      <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
        <Box width={"100%"} sx={{ display: "flex" }}>
          <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              required
              type={!show ? "password" : "text"}
              id="password"
            />
          </FormControl>
          <ButtonBase onClick={() => setShow(!show)}>
            {!show ? (
              <VisibilityOutlinedIcon sx={{ alignSelf: "center" }} />
            ) : (
              <VisibilityOffOutlinedIcon sx={{ alignSelf: "center" }} />
            )}
          </ButtonBase>
        </Box>
        <Box width={"100%"} sx={{ display: "flex" }}>
          <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
            <InputLabel htmlFor="repassword">Confirm Password</InputLabel>
            <Input
              name="repassword"
              required
              type={!show ? "password" : "text"}
              id="repassword"
            />
          </FormControl>
          <ButtonBase onClick={() => setShow(!show)}>
            {!show ? (
              <VisibilityOutlinedIcon sx={{ alignSelf: "center" }} />
            ) : (
              <VisibilityOffOutlinedIcon sx={{ alignSelf: "center" }} />
            )}
          </ButtonBase>
        </Box>
      </Box>
      <Button
        sx={{ m: "2% 0" }}
        fullWidth
        variant="contained"
        type="submit"
        component="button"
      >
        Submit
      </Button>
    </Box>
  );
};

export default ChangeSuperPassword;
