import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 isEditable: false,
 data:{},
 type:""

};

export const EditableState = createSlice({
  name: "edit",
  initialState,
  reducers: {
    setEditable: (state, action) => {
      state.isEditable=action.payload.isEditable;
      state.data=action.payload.data
      state.type=action.payload.type
    },
    
  },
});

export const { setEditable} = EditableState.actions;

export default EditableState.reducer;
