import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import React from "react";
import Api from "../api/API";
import logo from "../images/edu-dark.png";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../slices/StateManager";
const ResetPage = () => {
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const API = new Api();
    dispatch(setLoading(true));
    API.resetPassword(e.target.email.value)
      .then((res) => {
        dispatch(setLoading(false));

        dispatch(
          setOpen({
            message: res.data.message,
            open: true,
            variant: res.data.message?.includes("Error") ? "error" : "success",
          })
        );
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: error.message,
            open: true,
            variant: "error",
          })
        );
      });
  };

  return (
    <Box
      sx={{
        p: " 3% 5% ",
        width: { xs: "80%", md: "50%" },
        margin: "20vh auto",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px #000000",
      }}
    >
      <Box textAlign={"center"}>
        <img src={logo} alt="logo" height={100} width={200} />
        <Typography variant="h4">Password Reset</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <FormControl
          fullWidth
          sx={{ m: "3% 0", fontSize: "10rem" }}
          variant="standard"
        >
          <InputLabel htmlFor="email">Your Username</InputLabel>
          <Input name="email" required type="text" id="email" />
        </FormControl>

        <Box sx={{ m: "3% 0" }}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            sx={{}}
          >
            submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ResetPage;
