import React, { useState, useEffect } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  FormControl,
  Select,
  Modal,
  Fade,
  MenuItem,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { VolumeUp } from "@mui/icons-material";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import Api from "../../../api/API";

import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../../slices/StateManager";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../fire";

const SpellingChoiceEdit = ({
  data,
  style,
  lev,
  open,
  handleClose,
  Backdrop,
}) => {
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState(
    data?.word?.replaceAll("~~", "'")
  );
  const [hint, setHint] = useState(data?.hint?.replaceAll("~~", "'"));
  const [address, setAddress] = useState(data?.address?.replaceAll("~~", "'"));
  const [category, setCategory] = useState(data?.category);
  const [cb1, setcb1] = useState(false);
  const [cb2, setcb2] = useState(false);
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [isdisabled1, setIsdisabled1] = useState(false);
  const [isdisabled2, setIsdisabled2] = useState(false);
  const onChangeOption = (e) => {
    if (e.target.name === "Option1") {
      setOption1(e.target.value);
      setSelectedValue(e.target.value);
    }

    if (e.target.name === "Option2") {
      setOption2(e.target.value);
      setSelectedValue(e.target.value);
    }
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked === true) {
      if (e.target.name === "Option1cb") {
        setSelectedValue(option1);
        setIsdisabled1(true);
        setcb2(false);
        setIsdisabled2(true);
      } else if (e.target.name === "Option2cb") {
        setSelectedValue(option2);
        setIsdisabled2(true);
        setcb1(false);
        setIsdisabled1(true);
      }
    } else {
      if (e.target.name === "Option1cb") {
        setSelectedValue(null);
        setIsdisabled1(false);
        setIsdisabled2(false);
      } else if (e.target.name === "Option2cb") {
        setSelectedValue(null);
        setIsdisabled2(false);
        setIsdisabled1(false);
      }
    }
  };

  useEffect(() => {
    if (data?.type === "option1") {
      setOption1(data?.word?.replaceAll("~~", "'"));
      setcb1(true);
      setcb2(false);
      setIsdisabled1(false);
      setIsdisabled2(true);
    }

    if (data?.type === "option2") {
      setOption2(data?.word?.replaceAll("~~", "'"));
      setcb1(false);
      setcb2(true);
      setIsdisabled1(true);
      setIsdisabled2(false);
    }
  }, [data?.word, data?.type]);

  const { speak } = useSpeechSynthesis();
  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });

  const handleAudioStop = (data) => {
    setAudioDetails(data);
  };

  const handleAudioUpload = (file) => {
    SubmitUploadedFile(file);
  };

  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioDetails(reset);
  };

  let menu = [];
  for (let index = 0; index <= 3; index++) {
    menu.push(
      <MenuItem key={index} value={lev?.category[index]?.text}>
        {lev?.category[index]?.text}
      </MenuItem>
    );
  }
  const SubmitUploadedFile = (file) => {
    if (file === null) {
      dispatch(
        setOpen({
          message: "Please record your voice",
          open: true,
          variant: "error",
        })
      );
    } else {
      const storageRef = ref(
        storage,
        `spellings/${Date.now()}_${Date.now()}.mp3`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          document.getElementById("progress").innerHTML = progress + "%";
        },
        (error) => {
          alert(error.code);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              setAddress(downloadURL);
              document.getElementById("progress").innerHTML = "";
            })
            .catch((error) => {
              alert(error.code);
            });
        }
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const token = localStorage.getItem("token");

    if (selectedValue === null) {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please select the correct option",
          open: true,
          variant: "error",
        })
      );
    } else {
      const API = new Api();
      if (cb1) {
        let hints = hint.replaceAll("'", "~~");
        API.updateSpellingQuestion(
          token,
          data.id,
          data.quiz_id,
          "option1",
          selectedValue.replaceAll("'", "~~"),
          selectedValue.replaceAll("'", "~~"),
          hints,
          category
        )
          .then((res) => {
            if (res.data.message === "Question Updated Successfully!") {
              setSelectedValue(null);
              setOption1("");
              setOption2("");
              setAddress("");
            }

            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: err.response.data.message,
                open: true,
                variant: "error",
              })
            );
          });
      } else if (cb2) {
        let hints = hint.replaceAll("'", "~~");
        if (address === "") {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: "Please record your voice and upload!",
              open: true,
              variant: "error",
            })
          );
        } else {
          API.updateSpellingQuestion(
            token,
            data.id,
            data.quiz_id,
            "option2",
            address,
            selectedValue.replaceAll("'", "~~"),
            hints,
            category
          )
            .then((res) => {
              if (res.data.message === "Question Updated Successfully!") {
                setSelectedValue(null);
                setOption1("");
                setOption2("");
                setAddress("");
                setHint("");
              }

              dispatch(setLoading(false));
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "success",
                })
              );
            })
            .catch((err) => {
              dispatch(setLoading(false));
              dispatch(
                setOpen({
                  message: err.response.data.message,
                  open: true,
                  variant: "error",
                })
              );
            });
        }
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "1fr 1fr",
                  md: "1fr 1fr",
                },
                columnGap: 3,
              }}
            >
              <Box>
                <Box
                  component={"form"}
                  onSubmit={handleSubmit}
                  container
                  spacing={4}
                  sx={{ mb: 2 }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ textAlign: "left", mb: 2 }}
                  >
                    Spelling Question
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                    }}
                  >
                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <InputLabel id="demo-simple-select-label">
                        Question Section
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Question Section"
                        onChange={(e) => setCategory(e.target.value)}
                        value={category}
                      >
                        {menu.map((item) => {
                          return item;
                        })}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    <TextField
                      size="small"
                      fullWidth
                      multiline
                      required
                      id="standard-basic"
                      name={`hint`}
                      label={`Provide a Hint`}
                      value={hint}
                      onChange={(e) => setHint(e.target.value)}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ textAlign: "left", mt: 2 }}
                  >
                    Computer Voice Option
                  </Typography>

                  <Box sx={{ display: "flex", m: "1em 0" }}>
                    <Checkbox
                      checked={cb1}
                      onClick={() => setcb1(!cb1)}
                      value={option1}
                      name={`Option1cb`}
                      onChange={onChangeCheckbox}
                      disabled={option1 === "" ? true : false}
                    />

                    <TextField
                      size="small"
                      fullWidth
                      multiline
                      id="standard-basic"
                      name={`Option1`}
                      label={`Computer Voice Option Only`}
                      value={option1}
                      disabled={isdisabled1}
                      onChange={onChangeOption}
                    />

                    <VolumeUp
                      color="primary"
                      sx={{ fontSize: "40pt", cursor: "pointer" }}
                      onClick={() => {
                        speak({ text: option1 });
                      }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{ textAlign: "left", mt: 2 }}
                  >
                    Teacher Voice Record Option
                  </Typography>
                  <Box sx={{ display: "flex", m: "10px 0" }}>
                    <Checkbox
                      checked={cb2}
                      onClick={() => setcb2(!cb2)}
                      value={option2}
                      name={`Option2cb`}
                      onChange={onChangeCheckbox}
                      disabled={option2 === "" ? true : false}
                    />

                    <TextField
                      size="small"
                      fullWidth
                      multiline
                      value={option2}
                      disabled={isdisabled2}
                      id="standard-basic"
                      name={`Option2`}
                      label={`Voice Recording Option Only`}
                      onChange={onChangeOption}
                    />

                    <Stack direction="row" spacing={3}>
                      <VolumeUp
                        color="primary"
                        sx={{ fontSize: "40pt", cursor: "pointer" }}
                        onClick={() => {
                          speak({ text: option2 });
                        }}
                      />
                    </Stack>
                  </Box>

                  <Box textAlign={"center"}>
                    <Stack direction="row" spacing={3} mt={2}>
                      <Button type="submit" variant="contained" size="large">
                        Save Changes
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        size="large"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Recorder
                  record={true}
                  title={"Record Voice For Option 2"}
                  audioURL={audioDetails.url}
                  showUIAudio
                  handleAudioStop={(data) => handleAudioStop(data)}
                  handleAudioUpload={(data) => handleAudioUpload(data)}
                  handleReset={() => handleReset()}
                />
                <p id="progress"></p>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default SpellingChoiceEdit;
