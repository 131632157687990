import React from "react";
import {
  Grid,
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import "./login.css";
import { Email, Facebook, LinkedIn, Phone, Twitter } from "@mui/icons-material";
import logo from "../../images/Logo_light.png";

function Footer() {
  return (
    <>
      <Grid item md={12} xs={12} lg={12}>
        <Stack direction={"column"}>
          <svg
            style={{
              marginBottom: "-5px",
            }}
            className="footer-svg"
            viewBox="0 0 1450 160"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M334.429 17.0015C199.705 -24.4987 89.4647 15.9086 0 74.3923V160H1450V5.52317C1450 5.52317 1232.04 57.1007 1096.18 57.6531C952.82 58.2359 807.799 -19.8241 697.939 5.52317L697.068 5.72415C587.449 31.0159 468.797 58.3921 334.429 17.0015Z"
              fill="url(#paint0_linear_102_338)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_102_338"
                x1="0"
                y1="80"
                x2="1450"
                y2="80"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#171E41" />
                <stop offset="100" stopColor="#3C4DA7" />
              </linearGradient>
            </defs>
          </svg>

          <Box
            sx={{
              p: { xs: 5, md: 7 },
              background: "linear-gradient(90deg, #171E41 0%, #3C4DA7 100%)",
            }}
          >
            <img
              src={logo}
              className="logo-bottom"
              alt="logo"
              style={{ margin: "10px 0px" }}
            />
            <Divider
              sx={{
                height: "3px",

                backgroundColor: "#fff",
              }}
            />

            <Grid container spacing={10} mt={1}>
              <Grid item md={4} xs={12}>
                <Stack direction={"column"} spacing={1}>
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    News Letter
                  </Typography>

                  <p
                    style={{
                      textAlign: "left",
                      color: "#F0DFDF",
                      marginBottom: "20px",
                    }}
                  >
                    Subscribe to our newsletter for updates. We will never send
                    you spam or pass on your email address.
                  </p>
                  <Stack direction={"row"}>
                    <TextField
                      variant="outlined"
                      sx={{
                        width: "100%",
                        outline: "none",
                        backgroundColor: "#fff",
                        borderStartStartRadius: "10px",
                        borderEndStartRadius: "10px",
                        ":focus": {
                          outline: "none",
                          border: "none",
                        },
                      }}
                      placeholder="Enter your email address"
                    />
                    <Button
                      size="sm"
                      variant="contained"
                      sx={{
                        width: "fit-content",
                        backgroundColor: "#fff",
                        color: "#000369",
                        fontWeight: "bold",
                        ":hover": {
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      Subscribe
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={4} xs={4} sm={6}>
                <Stack direction={"column"} spacing={1}>
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: "left",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    Quick Links
                  </Typography>
                  <ul
                    style={{
                      listStyle: "square",

                      paddingLeft: "20px",
                      color: "#F0DFDF",
                      textAlign: "left",
                    }}
                  >
                    <li style={{ marginBottom: 1 }}>
                      <a
                        href="/"
                        style={{
                          textDecoration: "none",
                          color: "#F0DFDF",
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li style={{ marginBottom: 1 }}>
                      {" "}
                      <a
                        href="/about"
                        style={{
                          textDecoration: "none",
                          color: "#F0DFDF",
                        }}
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        href="/contact"
                        style={{
                          textDecoration: "none",
                          color: "#F0DFDF",
                        }}
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </Stack>
              </Grid>
              <Grid item md={4} xs={5} sm={6} sx={{ textAlign: "left" }}>
                <Stack direction={"column"} spacing={3}>
                  <Stack direction={"row"} spacing={3}>
                    <Phone
                      className="logo-icon"
                      sx={{ fontSize: "2rem", color: "#fff" }}
                    />
                    <Stack direction={"column"}>
                      <Typography
                        variant="h4"
                        sx={{ color: "#fff", fontWeight: "bold" }}
                      >
                        Phone
                      </Typography>

                      <a
                        href="tel:+2348023537575
"
                        style={{ color: "#F0DFDF" }}
                      >
                        +234 8023537575
                      </a>
                    </Stack>
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <Email
                      className="logo-icon"
                      sx={{ fontSize: "2rem", color: "#fff" }}
                    />
                    <Stack direction={"column"} spacing={1}>
                      <Typography
                        variant="h4"
                        className="logo-icon"
                        sx={{ color: "#fff", fontWeight: "bold" }}
                      >
                        Email
                      </Typography>
                      <Typography sx={{ color: "#fff" }}>
                        <a
                          href="mailto:info@edusupport.com"
                          style={{ color: "#F0DFDF" }}
                        >
                          {" "}
                          info@edusupport.com
                        </a>
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item md={12} xs={12} sm={12}>
                <Grid container spacing={{ xs: 3, md: 0 }}>
                  <Grid item md={6} xs={12} sm={12}>
                    <Stack
                      direction={"row"}
                      spacing={5}
                      sx={{
                        justifyContent: { xs: "center", md: "flex-start" },
                        alignContent: { xs: "center", md: "flex-start" },
                      }}
                    >
                      <Typography
                        onClick={() => {
                          window.open(
                            "https://firebasestorage.googleapis.com/v0/b/edu-support-f9e95.appspot.com/o/files%2FEduSupport%20Terms%20and%20Conditions.pdf?alt=media&token=0532a887-db08-4024-8309-69811ae16faf",
                            "_blank"
                          );
                        }}
                        variant="h6"
                        sx={{ color: "#fff", cursor: "pointer" }}
                      >
                        Terms of service
                      </Typography>

                      <Typography
                        onClick={() => {
                          window.open(
                            "https://firebasestorage.googleapis.com/v0/b/edu-support-f9e95.appspot.com/o/files%2FEduSupport%20Privacy%20Policy%20-%20rev.pdf?alt=media&token=5882655b-27e8-4e50-974d-fc0954d1243f"
                          );
                        }}
                        variant="h6"
                        sx={{ color: "#fff", cursor: "pointer" }}
                      >
                        Privacy policy
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        justifyContent: { xs: "center", md: "flex-end" },
                        alignContent: { xs: "center", md: "flex-end" },
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(
                            "https://web.facebook.com/profile.php?id=100064165955002&mibextid=kFxxJD&_rdc=1&_rdr"
                          )
                        }
                      >
                        <Facebook
                          className="logo-icon"
                          sx={{ fontSize: "30pt", color: "#fff" }}
                        />
                      </Typography>
                      <Divider
                        orientation="vertical"
                        sx={{ backgroundColor: "#fff" }}
                        flexItem
                      />
                      <Twitter
                        className="logo-icon"
                        sx={{
                          fontSize: "30pt",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open("https://www.x.com/edusupportapp")
                        }
                      />
                      <Divider
                        orientation="vertical"
                        sx={{ backgroundColor: "#fff" }}
                        flexItem
                      />
                      <LinkedIn
                        className="logo-icon"
                        onClick={() =>
                          window.open("https://www.linkedin.com/edusupportapp/")
                        }
                        sx={{
                          fontSize: "30pt",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Grid>
    </>
  );
}

export default Footer;
