import React, { useEffect, useState } from "react";
import AdminTable from "../../components/admin/AdminTable";
import { Box, Stack, TextField, Paper } from "@mui/material";
import SkeletonContainer from "../../components/SkeletonContainer";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Api from "../../api/API";
const AdminClasses = () => {
  const [classes, setClasses] = useState(null);
  const [searched, setSearched] = useState("");
  const [loads, setLoad] = useState(false);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = React.useState("class_name");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.getAdminClasses(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setClasses(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_created),
                db = new Date(b.date_created);
              return db - da;
            })
          );
        }
        // setClasses(res.data.data);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, []);
  useEffect(() => {
    setRows(classes);
  }, [classes]);
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "class_name") {
      const filteredRows = classes.filter((row) => {
        return row.class_name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "subject") {
      const filteredRows = classes.filter((row) => {
        return row.subject.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "status") {
      const filteredRows = classes.filter((row) => {
        return row.status.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "date") {
      const filteredRows = classes.filter((row) => {
        return row.date_created.includes(searchedVal);
      });
      setRows(filteredRows);
    }
  };
  const columns = [
    "Class Name",
    "Status",
    "Subject",
    "Date Created",
    "Created By",
  ];

  return (
    <>
      <Box mt={10}>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"class_name"}>Class Name</MenuItem>
                      <MenuItem value={"status"}>Status</MenuItem>
                      <MenuItem value={"subject"}>Subject</MenuItem>
                      <MenuItem value={"date"}>Date</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />{" "}
                </Stack>
                <AdminTable
                  title={"All Classes"}
                  data={rows}
                  column={columns}
                  type="class"
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminClasses;
