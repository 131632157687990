import React from "react";
import ReactLoading from "react-loading";
import { Box, Stack, Typography, Grid } from "@mui/material";

const ReactLoader = () => {
	return (
		<>
			<Box
				sx={{
					backgroundColor: "#ffffff",
					height: "100vh",
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 9999,
				}}
			>
				<Grid container spacing={1} sx={{ mt: "20%" }}>
					<Grid item xs={3} md={5}></Grid>
					<Grid item xs={9} md={4}>
						<Stack>
							<ReactLoading
								type="bars"
								color="#1976d2"
								height={200}
								width={200}
								className="loader"
							/>

							<Typography variant="h5" sx={{ color: "#1976d2", ml: "10%" }}>
								Please Wait...
							</Typography>
						</Stack>
					</Grid>
					<Grid item md={4}></Grid>
				</Grid>
			</Box>
		</>
	);
};

export default ReactLoader;
