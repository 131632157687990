import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Avatar,
  Tooltip,
  Stack,
  Typography,
} from "@mui/material";
import SkeletonContainer from "./../SkeletonContainer";
import Skeleton from "@mui/material/Skeleton";
import { WhatsappShareButton, EmailShareButton } from "react-share";

import Api from "../../api/API";
const AdminProfileDetails = () => {
  const [userData, setUserData] = useState(null);
  const [loads, setLoad] = useState(false);
  useEffect(() => {
    const API = new Api();

    const token = localStorage.getItem("token");
    setLoad(true);
    API.getAdminData(token)
      .then((res) => {
        setUserData(res.data);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, []);

  return (
    <>
      {!loads ? (
        <>
          <Avatar
            sx={{
              border: "1px solid gray",
              m: "2% auto",
              width: 150,
              height: 150,
            }}
            src={userData ? userData.avatar : ""}
          />
          <Box
            sx={{
              m: "1% 0",
            }}
          >
            <Typography variant="h5">
              ORGANISATION ID:&nbsp;<b>{userData ? userData.admin_id : ""}</b>
            </Typography>
          </Box>
          <Box
            sx={{
              m: "10px 0",
            }}
          >
            <Typography variant="h5">Share Via:</Typography>
          </Box>
          <Stack direction={"row"} spacing={3} sx={{ mb: 1 }}>
            <Tooltip title="share Organisation ID with Mail">
              <EmailShareButton
                style={{
                  backgroundColor: "purple",
                  color: "white",
                  minWidth: "80px",
                  padding: "6px 16px",
                  lineHeight: 1.75,
                  borderRadius: 5,
                  boxShadow: "0px 1px 5px grey",
                }}
                subject={`Organisation ID`}
                body={`The Organisation ID to be use for Other Sub-Registration is ${userData?.admin_id}`}
              >
                Email
              </EmailShareButton>
            </Tooltip>
            <Tooltip title="share Organisation ID to whatsapp">
              <WhatsappShareButton
                style={{
                  backgroundColor: "green",
                  color: "white",
                  minWidth: "80px",
                  padding: "6px 16px",
                  lineHeight: 1.75,
                  borderRadius: 5,
                  boxShadow: "0px 1px 5px grey",
                }}
                url={`The Organisation ID to be use for Other Sub-Registration is ${userData?.admin_id}`}
              >
                Whatsapp
              </WhatsappShareButton>
            </Tooltip>
          </Stack>

          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <TextField
              value={userData ? userData.firstname : ""}
              color="text"
              focused
              label="First Name"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
            <TextField
              value={userData ? userData.lastname : ""}
              color="text"
              focused
              label="Last Name"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
          </Box>

          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <TextField
              value={userData ? userData.username : ""}
              color="text"
              focused
              label="Username"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
            <TextField
              value={userData ? userData.email : ""}
              color="text"
              focused
              label="Organisation Email"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
          </Box>

          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <TextField
              value={userData ? userData.phone : ""}
              color="text"
              focused
              label="Phone Number"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
            <TextField
              value={userData ? userData.gender : ""}
              color="text"
              focused
              label="Gender"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
          </Box>

          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <TextField
              value={userData ? userData.org_name : ""}
              color="text"
              focused
              label="Organisation Name"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
            <TextField
              value={userData ? userData.org_address : ""}
              color="text"
              focused
              label="Organisation Address"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
          </Box>

          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <TextField
              value={userData ? userData.date_created : ""}
              color="text"
              focused
              label="Joined"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
            <TextField
              value={userData ? userData.paid : ""}
              color="text"
              focused
              label="Subscribed"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
          </Box>
        </>
      ) : (
        <>
          <Skeleton
            variant="circular"
            width={200}
            height={200}
            sx={{ ml: "38%", mb: 1, bgcolor: "#dde9f3" }}
          />
          <SkeletonContainer />
          <Skeleton sx={{ bgcolor: "#dde9f3" }} />{" "}
        </>
      )}
    </>
  );
};

export default AdminProfileDetails;
