import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Api from "../../api/API";
import { useDispatch } from "react-redux";
import { setOpen } from "../../slices/StateManager";
import ReactLoader from "../../components/ReactLoader";
import { useNavigate } from "react-router";
import moment from "moment";
import CustomTagInput from "../../components/CustomTagInput";

const CreateQuiz = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [class_ids, setClass_name] = useState(null);
  const [name, setName] = useState([]);
  const [start, setStart] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [tags, setTags] = useState([]);

  const dispatch = useDispatch();
  const handleClass = (event) => {
    setName(event.target.value);
  };
  const handleType = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");

    const API = new Api();
    API.getTeacherClassName(token)
      .then((res) => {
        setLoading(false);

        setClass_name(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
  }, [setLoading, dispatch]);

  const handleSubmit = (e) => {
    const role = localStorage.getItem("role");
    e.preventDefault();
    if (tags.length < 1) {
      dispatch(
        setOpen({
          message: "Please add at leaset 1 Section",
          open: true,
          variant: "error",
        })
      );
      return;
    }
    const token = localStorage.getItem("token");

    setLoading(true);
    const API = new Api();
    if (name !== "") {
      API.createQuiz(
        token,
        e.target.quiz_name.value,
        e.target.dur_level.value,
        e.target.subject.value,
        e.target.point.value,
        e.target.pass_mark.value,
        name,
        type,
        tags,
        moment(start).format("YYYY-MM-DD HH:mm:ss")
      )
        .then((res) => {
          if (res.data.message === "Quiz Created Successfully!") {
            navigate(
              role === "admin"
                ? `/dashboard/admin/teacher/quiz/${res.data.data[0].quiz_id}`
                : `/dashboard/teacher/quiz/${res.data.data[0].quiz_id}`
            );
            setLoading(false);
            e.target.quiz_name.value = "";
            e.target.dur_level.value = "";
            e.target.subject.value = "";
            e.target.point.value = "";
            e.target.pass_mark.value = "";
            setName("");
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    } else {
      setLoading(false);
      dispatch(
        setOpen({
          message: "Please select class name",
          open: true,
          variant: "error",
        })
      );
    }
  };

  return (
    <>
      {loading && <ReactLoader />}
      <Stack spacing={6}>
        <Box sx={{ p: 3 }}>
          <Stack spacing={1}>
            <Typography variant="h4" component="h4" sx={{ textAlign: "left" }}>
              Create Test/Spelling
            </Typography>

            <Box
              component="form"
              sx={{
                "& > :not(style)": { mt: 1 },
              }}
              onSubmit={handleSubmit}
            >
              <Grid container spacing={5}>
                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    required
                    id="standard-basic"
                    name="quiz_name"
                    label="Test/Spelling Name"
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Test Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Class Status"
                      value={type}
                      required
                      onChange={handleType}
                    >
                      <MenuItem value="normal">Normal Test</MenuItem>
                      <MenuItem value="spelling">Spelling Test</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    required
                    name="subject"
                    id="standard-basic"
                    label="Test Subject"
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    name="point"
                    id="standard-basic"
                    label="Point Per Question"
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    required
                    fullWidth
                    id="standard-basic"
                    type="number"
                    name="pass_mark"
                    label="Pass Mark %"
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Class(es)
                    </InputLabel>
                    <Select
                      name="opened"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Class Name"
                      value={name}
                      required
                      multiple
                      onChange={handleClass}
                    >
                      {class_ids === null ? (
                        <MenuItem value={""}>No Class Found</MenuItem>
                      ) : (
                        class_ids?.map((item) => {
                          return (
                            <MenuItem key={item.class_id} value={item.class_id}>
                              {item.class_name}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={5}>
                  <FormControl fullWidth>
                    <TextField
                      label="Schedule when to Publish Detailed Results."
                      required
                      fullWidth
                      id="standard-basic"
                      name="start"
                      onChange={(e) => setStart(e.target.value)}
                      type="datetime-local"
                      value={start}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={5}>
                  <CustomTagInput tags={tags} setTags={setTags} />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    required
                    fullWidth
                    id="standard-basic"
                    type="number"
                    name="dur_level"
                    label="Duration Per Section (Minutes)"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item md={4}></Grid>
                <Grid item md={4}>
                  <Button variant="contained" type="submit" size="large">
                    {type !== ""
                      ? type === "normal"
                        ? `Create Test`
                        : `Create Spelling Test`
                      : "Create Test"}
                  </Button>
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default CreateQuiz;
