import {
  Box,
  FormControl,
  InputLabel,
  Input,
  Button,
  TextField,
  Stack,
  Paper,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Api from "../../api/API";
import StudentTable from "../../components/student/StudentTable";
import SkeletonContainer from "../../components/SkeletonContainer";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";

import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";

const StudentClasses = () => {
  const [classes, setClasses] = useState(null);
  const [loads, setLoad] = useState(false);
  const [searched, setSearched] = useState("");
  const [filter, setFilter] = React.useState("class_name");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);

    API.getInStudentClasses(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setClasses(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_created),
                db = new Date(b.date_created);
              return db - da;
            })
          );
        }

        setLoad(false);
      })
      .catch((err) => {
        setClasses([]);
        setLoad(false);
      });
  }, [setClasses]);

  const columns = ["Class Name", "Status", "Subject", "Date Created"];
  useEffect(() => {
    setRows(classes);
  }, [classes]);
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "class_name") {
      const filteredRows = classes.filter((row) => {
        return row.class_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "subject") {
      const filteredRows = classes.filter((row) => {
        return row.subject?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "status") {
      const filteredRows = classes.filter((row) => {
        return row.status?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "date") {
      const filteredRows = classes.filter((row) => {
        return row.date_created.includes(searchedVal);
      });
      setRows(filteredRows);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const API = new Api();
    const token = localStorage.getItem("token");
    dispatch(setLoading(true));
    API.joinClass(token, e.target.class_id.value)
      .then((res) => {
        if (res.data.message === "Class Joined Successfully") {
          const API = new Api();
          const token = localStorage.getItem("token");
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "success",
            })
          );
          API.getInStudentClasses(token)
            .then((res) => {
              if (
                res.data.data !== undefined &&
                res.data.data !== null &&
                res.data.data.length > 0
              ) {
                setClasses(
                  res.data.data.sort((a, b) => {
                    let da = new Date(a.date_created),
                      db = new Date(b.date_created);
                    return db - da;
                  })
                );
              }
              setLoad(false);
            })
            .catch((err) => {
              setClasses([]);
              console.log(err);
              setLoad(false);
            });

          e.target.class_id.value = "";
        } else {
          dispatch(setLoading(false));
          dispatch(
            setOpen({ message: res.data.message, open: true, variant: "error" })
          );
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setOpen({
            message: err.response.data.message,
            open: true,
            variant: "error",
          })
        );
      });
  };

  const leaveClass = (id) => {
    if (window.confirm("Are you sure you want to leave this class?")) {
      const API = new Api();
      const token = localStorage.getItem("token");

      API.deleteInStudentClass(token, id)
        .then((res) => {
          if (res.data.message === "Successfully Left Class") {
            API.getInStudentClasses(token)
              .then((res) => {
                if (
                  res.data.data !== undefined &&
                  res.data.data !== null &&
                  res.data.data.length > 0
                ) {
                  setClasses(
                    res.data.data.sort((a, b) => {
                      let da = new Date(a.date_created),
                        db = new Date(b.date_created);
                      return db - da;
                    })
                  );
                }
              })
              .catch((err) => {
                setClasses([]);
                console.log(err);
              });
            alert(res.data.message);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          setClasses([]);
          console.log(err.response);
        });
    }
  };
  return (
    <>
      <Box m="40px 0">
        <form onSubmit={handleSubmit}>
          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <FormControl
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
              variant="standard"
            >
              <InputLabel htmlFor="full_name">Enter Class ID here</InputLabel>
              <Input name="class_id" required id="full_name" />
            </FormControl>
            <Button
              sx={{ m: "2% 0" }}
              variant="contained"
              type="submit"
              component="button"
            >
              Join Class
            </Button>
          </Box>
        </form>
      </Box>
      <Box>
        {loads ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <Paper>
              <Stack direction={"row"}>
                <FormControl sx={{ minWidth: 150 }} size="small">
                  <InputLabel id="demo-select-small">Filter by</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={filter}
                    label="Filter by"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"class_name"}>Class Name</MenuItem>
                    <MenuItem value={"status"}>Status</MenuItem>
                    <MenuItem value={"subject"}>Subject</MenuItem>
                    <MenuItem value={"date"}>Date</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  size="small"
                  placeholder={`Search by ${filter}`}
                  value={searched}
                  onChange={(event) => requestSearch(event.target.value)}
                />{" "}
              </Stack>
              <StudentTable
                title={"Classes Joined"}
                data={rows}
                column={columns}
                type="class"
                leaveClass={leaveClass}
              />
            </Paper>
          </>
        )}
      </Box>
    </>
  );
};

export default StudentClasses;
