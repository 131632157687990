import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import { Stack } from '@mui/material';

const SkeletonContainer = () => {
  return (
  <>
  <Stack>
  <Skeleton variant="rectangular" width={'100%'} height={118}  animation="wave"  sx={{ bgcolor: '#dde9f3' }} /> 
  <Skeleton animation="wave"  sx={{ bgcolor: '#dde9f3' }} /> 
  </Stack>
  </>
  )
}

export default SkeletonContainer