import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { PricingTable, PricingSlot, PricingDetail } from "react-pricing-table";
import Payment from "./payment/Payment";
import Api from "../api/API";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GStudentSub = ({ current, open, handleClose }) => {
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const API = new Api();
    API.getInStudentData(token)
      .then((res) => {
        setEmail(res?.data?.data?.email);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  }, []);
  const config = {
    // reference: new Date().getTime().toString(),
    email: email !== null ? email : "test@gmail.com",
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <PricingTable highlightColor="#1976D2">
            <PricingSlot
              title="FREE"
              highlighted={current === "free" ? true : false}
              priceText="#0/month"
              shouldDisplayButton={false}
            >
              <PricingDetail>
                Take <b>1</b> Quiz
              </PricingDetail>
              <PricingDetail>
                <b>No</b> Retake
              </PricingDetail>
              <PricingDetail>
                <b>No</b> Result Summary
              </PricingDetail>
            </PricingSlot>
            <PricingSlot
              shouldDisplayButton={false}
              highlighted={current === "starter" ? true : false}
              priceText="#1000/6 months"
              title="STARTER"
            >
              <PricingDetail>
                Take <b>All</b> Quiz
              </PricingDetail>
              <PricingDetail>
                <b>unlimited</b> Retake
              </PricingDetail>
              <PricingDetail>
                <b>6</b> Months
              </PricingDetail>
              <PricingDetail>
                <b>View</b> Result Summary
              </PricingDetail>

              <Box m={"10px 0"} p={"15px"} textAlign={"left"}>
                <div
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <Payment
                    dur={"6month"}
                    config={{ ...config, amount: 1000 * 100 }}
                    color={current === "starter" ? "primary" : null}
                    plan={"starter"}
                  />
                </div>
              </Box>
            </PricingSlot>
            <PricingSlot
              shouldDisplayButton={false}
              highlighted={current === "premium" ? true : false}
              title="PREMIUM"
              priceText="#1750/year"
            >
              <PricingDetail>
                Take <b>All</b> Quiz
              </PricingDetail>
              <PricingDetail>
                <b>unlimited</b> Retake
              </PricingDetail>
              <PricingDetail>
                <b>1</b> Year
              </PricingDetail>
              <PricingDetail>
                <b>View</b> Result Summary
              </PricingDetail>
              <Box m={"10px 0"} p={"15px"} textAlign={"left"}>
                <div
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <Payment
                    config={{ ...config, amount: 1750 * 100 }}
                    color={current === "premium" ? "primary" : null}
                    plan={"premium"}
                    dur={"1year"}
                  />
                </div>
              </Box>
            </PricingSlot>
          </PricingTable>

          <Box sx={{ textAlign: "center" }}>
            <Button
              sx={{ mt: "10%", mb: "30px" }}
              color="error"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default GStudentSub;
