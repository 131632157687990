import { Quiz } from "@mui/icons-material";
import { Box, Checkbox, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import parse from "html-react-parser";
import { useEffect } from "react";

const CheckBoxImpl = ({ answers, setAnswers, questions }) => {
	const [status, setStatus] = useState([]);
	useEffect(() => {
		if (answers !== null && answers !== undefined) {
			if (answers?.find((a) => a?.id === questions?.id)) {
				setStatus(answers?.find((a) => a?.id === questions?.id)?.options);
			} else {
				setStatus([]);
			}
		}
	}, [questions, answers]);

	const onClickMultiple = (value) => {
		const dd = status;
		let newdd;
		if (dd?.includes(value)) {
			newdd = dd?.filter((sa) => sa !== value);
		} else {
			newdd = [...dd, value];
		}
		status?.includes(value)
			? setStatus(status?.filter((sa) => sa !== value))
			: setStatus([...status, value]);

		if (answers !== null && answers !== undefined) {
			if (answers?.find((a) => a?.id === questions?.id)) {
				const dd = answers?.find((a) => a?.id === questions?.id);
				const newAnswers = answers?.map((ss) =>
					ss?.id === dd?.id ? { ...dd, options: newdd } : ss
				);
				setAnswers(newAnswers);
			} else {
				setAnswers([...answers, { id: questions?.id, options: newdd }]);
			}
		}
	};

	return (
		<>
			<Stack direction={"row"} spacing={1}>
				<Quiz color="primary" />
				<Typography variant="h7">
					{parse(questions?.question?.replaceAll("~~", "'"))}
				</Typography>
			</Stack>

			<Stack spacing={2}>
				{questions?.options.map((value, index) => (
					<Stack direction={"row"} spacing={2} key={index}>
						<Box>
							<Checkbox
								checked={status?.includes(value) || false}
								value={value}
								onClick={(evt) => onClickMultiple(evt.target.value)}
							/>

							<Typography variant="h7">
								{value?.replaceAll("~~", "'")}
							</Typography>
						</Box>
					</Stack>
				))}
			</Stack>
			<Divider sx={{ mb: 5 }} />
		</>
	);
};

export default CheckBoxImpl;
