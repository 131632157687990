import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import API from "../../api/API";
import { useDispatch } from "react-redux";
import { setOpen, setOrg } from "../../slices/StateManager";
import ReactLoader from "../../components/ReactLoader";

function SwitchComponent() {
  const [data, setData] = useState([]);
  const [activeOrgans, setActiveOrgans] = useState(null);
  const [load, setLoad] = useState(true);
  const [load2, setLoad2] = useState(true);
  const [load3, setLoad3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgRole, setOrgansRole] = useState("");
  const dispatch = useDispatch();
  const Api = new API();
  useEffect(() => {
    Api.getAllOrgans()
      .then((res) => {
        setData(res.data.data);
        setLoad2(false);
      })
      .catch((err) => {
        console.log(err);
      });

    Api.getOrgNameTeacher()
      .then((res) => {
        setActiveOrgans(res?.data?.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleJoin = async (e) => {
    e.preventDefault();
    setLoad3(true);
    await Api.joinOrg(e.target.admin_id.value)
      .then((res) => {
        if (res.data.message.includes("joined")) {
        }
        setLoad3(false);
        dispatch(setOrg({ orgName: res.data.data.org_name }));
        dispatch(
          setOpen({
            message: res.data.message,
            open: true,
            variant: "success",
          })
        );
        window.location.reload();
      })
      .catch((err) => {
        setLoad3(false);
        dispatch(
          setOpen({
            message: err.response.data.message,
            open: true,
            variant: "error",
          })
        );
      });
  };

  const handleSwitch = (admin_id) => {
    setLoading(true);
    Api.switchOrg(admin_id)
      .then((res) => {
        setOrgansRole(res.data.role);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("role", res.data.role);

        if (res.data.role === "student") {
          window.location.href = "/dashboard/student";
        } else {
          window.location.href = "/dashboard/teacher";
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        dispatch(
          setOpen({
            message: err.response.data.message,
            open: true,
            variant: "error",
          })
        );
      });
  };
  const filter_data = data?.filter(
    (d) => d.admin_id !== activeOrgans?.admin_id
  );
  return (
    <>
      {loading ? (
        <ReactLoader />
      ) : (
        <Box sx={{ width: "100%", height: "90vh" }}>
          <Grid container>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={10}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={1}></Grid>

            <Grid item xs={12} md={8}>
              <Stack
                component={"form"}
                onSubmit={handleJoin}
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                m={1}
                mb={3}
              >
                <TextField
                  variant="outlined"
                  required
                  id="admin_id"
                  placeholder="Enter Organisation ID"
                  sx={{
                    width: "100%",
                  }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ width: { xs: "100%" } }}
                  type="submit"
                >
                  {load3 && (
                    <CircularProgress
                      size={24}
                      color="inherit"
                      sx={{ mr: 3 }}
                    />
                  )}
                  Join Organisation
                </Button>
              </Stack>

              <Card
                sx={{
                  boxShadow: "0px 0px 3px 1px rgba(203, 203, 203, 1)",
                }}
              >
                <CardContent>
                  <Stack direction={"column"} spacing={2}>
                    <Typography variant="h5">Current Organisation</Typography>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <Box
                        className="flex flex-column"
                        sx={{
                          width: "100%",
                          background: "#d3e8f7",
                          borderRadius: "10px",
                          padding: "15px",
                        }}
                      >
                        <Typography variant="h6">Organisation Name:</Typography>
                        {load ? (
                          <Skeleton width={100} />
                        ) : (
                          <>
                            <Typography variant="subtitle1">
                              {activeOrgans?.org_name}
                            </Typography>
                          </>
                        )}
                      </Box>
                      {/* <Box
                        className="flex flex-column"
                        sx={{
                          width: "100%",
                          background: "#d3e8f7",
                          borderRadius: "10px",
                          padding: "15px",
                        }}
                      >
                        <Typography variant="h6">Organisation ID:</Typography>
                        {load ? (
                          <Skeleton width={100} />
                        ) : (
                          <>
                            <Typography variant="subtitle1">
                              {activeOrgans?.admin_id}
                            </Typography>
                          </>
                        )}
                      </Box> */}
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>

              <Stack direction={"column"} mt={2}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", margin: "4px 20px" }}
                >
                  Other Organisation(s):
                </Typography>

                <Grid
                  container
                  sx={{
                    width: "100%",

                    justifyContent: "flex-start",
                  }}
                  spacing={4}
                >
                  {filter_data.map((item, index) => (
                    <Grid item xs={12} md={5} key={index}>
                      {load2 ? (
                        <Skeleton
                          sx={{ height: "100px" }}
                          variant="rectangular"
                        />
                      ) : (
                        <>
                          <Card
                            key={index}
                            sx={{
                              background: "white",

                              boxShadow:
                                "0px 0px 3px 1px rgba(203, 203, 203, 1)",
                              borderRadius: "10px",
                              padding: "5px",
                              marginBottom: "10px",
                            }}
                          >
                            <CardContent>
                              <Stack
                                direction="column"
                                spacing={2}
                                sx={{ width: "fit-content" }}
                              >
                                <Typography variant="h6">
                                  {item.org_name}
                                </Typography>
                                {/* <Typography variant="subtitle1">
                                  {item.admin_id}
                                </Typography> */}

                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  className="flex flex-row space-between"
                                >
                                  <Typography variant="h6">Status:</Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color={`${
                                      item.active === "true" ? "green" : "red"
                                    }`}
                                  >
                                    {item.active === "true"
                                      ? "Authorised"
                                      : "Unauthorised"}
                                  </Typography>
                                </Box>

                                <Button
                                  variant="contained"
                                  sx={{
                                    width: "fit-content",
                                    textTransform: "capitalize",
                                    float: "right",
                                  }}
                                  onClick={() => handleSwitch(item.admin_id)}
                                  disabled={
                                    item.last_login === "false" ? false : true
                                  }
                                >
                                  Switch
                                </Button>
                              </Stack>
                            </CardContent>
                          </Card>
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default SwitchComponent;
