import React, { useState } from "react";
function Test() {
	const [items, setItems] = useState([
		{ id: 1, name: "item 1", value: false },
		{ id: 2, name: "item 2", value: true },
		{ id: 3, name: "item 3", value: false },
	]);

	const updateItem = (id, value) => {
		const index = items.findIndex((item) => item.id === id);
		if (index !== -1) {
			// if item exists, update the value
			const updatedItems = [...items];
			updatedItems[index].value = value;
			setItems(updatedItems);
		} else {
			// if item doesn't exist, add a new item
			const newItem = { id, name: `item ${id}`, value };
			setItems([...items, newItem]);
		}
	};

	return (
		<div>
			{items.map((item) => (
				<div key={item.id}>
					<span>{item.name}</span>
					<input
						type="checkbox"
						checked={item.value}
						onChange={(e) => updateItem(item.id, e.target.checked)}
					/>
				</div>
			))}
		</div>
	);
}

export default Test;
