import React from "react";
import { Box } from "@mui/material";
import Api from "../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";
import QuestionTable from "../QuestionTable";

const QuestionDisplay = ({
  type,
  questions,
  setQuestions,
  quiz_id,
  quizData,
}) => {
  const dispatch = useDispatch();

  const deleteQuestion = (id) => {
    if (type === "spelling") {
      if (window.confirm(`Are you sure you want to delete this Question?`)) {
        const API = new Api();
        const token = localStorage.getItem("token");
        dispatch(setLoading(true));
        API.deleteSpellingQuestion(token, quiz_id, id)
          .then((res) => {
            if (res.data.message === "Question Deleted Successfully!") {
              API.getSpellingQuestions(token, quiz_id)
                .then((res) => {
                  setQuestions(res.data.data);
                  dispatch(setLoading(false));
                })
                .catch((err) => {
                  dispatch(setLoading(false));
                  dispatch(
                    setOpen({
                      message: err.response.data.message,
                      open: true,
                      variant: "error",
                    })
                  );
                });

              dispatch(setLoading(false));
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "success",
                })
              );
            } else {
              dispatch(setLoading(false));
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "error",
                })
              );
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: err.response.data.message,
                open: true,
                variant: "error",
              })
            );
          });
      }
    } else {
      if (window.confirm(`Are you sure you want to delete this Question?`)) {
        const API = new Api();
        const token = localStorage.getItem("token");
        dispatch(setLoading(true));
        API.deleteQuestion(token, quiz_id, id)
          .then((res) => {
            if (res.data.message === "Question Deleted Successfully!") {
              API.getQuestions(token, quiz_id)
                .then((res) => {
                  setQuestions(res.data.data);
                  dispatch(setLoading(false));
                })
                .catch((err) => {
                  dispatch(setLoading(false));
                  dispatch(
                    setOpen({
                      message: err.response.data.message,
                      open: true,
                      variant: "error",
                    })
                  );
                });

              dispatch(setLoading(false));
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "success",
                })
              );
            } else {
              dispatch(setLoading(false));
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "error",
                })
              );
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: err.response.data.message,
                open: true,
                variant: "error",
              })
            );
          });
      }
    }
  };

  const columns = ["Question", "Type", "Section", "Options", "Answers"];
  const column2 = ["Type", "Section", "Hint", "Address", "word"];

  return (
    <>
      <Box
        sx={{
          p: "1% 0%",
        }}
      >
        <QuestionTable
          column={type === "spelling" ? column2 : columns}
          title={`${type === "spelling" ? "Spelling" : "Quiz"} Questions`}
          rows={questions}
          type={type === "spelling" ? "spelling" : "question"}
          deletQuiz={deleteQuestion}
          quizData={quizData}
        />
      </Box>
    </>
  );
};

export default QuestionDisplay;
