import React, { useEffect, useState } from "react";
import OverviewCard from "../../components/OverviewCard";
import { FaChalkboardTeacher, FaSchool } from "react-icons/fa";
import { Box, Button, Typography } from "@mui/material";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import QuizRoundedIcon from "@mui/icons-material/QuizRounded";
import moment from "moment";
import Api from "../../api/API";
import ModalComponent from "../../components/ModalComponent";
import SkeletonContainer from "../../components/SkeletonContainer";
import Instruction from "../../components/Instruction";

const AdminOverview = () => {
  const [teacher, setTeacher] = useState(null);
  const [student, setStudent] = useState(null);
  const [classes, setClasses] = useState(null);
  const [quizzes, setQuizzes] = useState(null);
  const [sub, setSub] = useState(null);
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [loads, setLoad] = useState({
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    loading5: false,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad({
      loading1: true,
      loading2: true,
      loading3: true,
      loading4: true,
      loading5: true,
    });
    API.getAdminTeachers(token)
      .then((res) => {
        setTeacher(res.data.data);
        setLoad({ loading1: false });
      })
      .catch((err) => {
        setLoad({ loading1: false });
      });
    API.getAdminStudents(token)
      .then((res) => {
        setStudent(res.data.data);
        setLoad({ loading2: false });
      })
      .catch((err) => {
        setLoad({ loading2: false });
      });
    API.getAdminClasses(token)
      .then((res) => {
        setClasses(res.data.data);
        setLoad({ loading3: false });
      })
      .catch((err) => {
        setLoad({ loading3: false });
      });
    API.getAdminQuizzes(token)
      .then((res) => {
        setQuizzes(res.data.data);
        setLoad({ loading4: false });
      })
      .catch((err) => {
        setLoad({ loading4: false });
      });
    API.getSub(token)
      .then((res) => {
        if (res.data) {
          setSub(res.data);
          setLoad({ loading5: false });
        }
      })
      .catch((err) => {
        setLoad({ loading5: false });
      });
  }, []);
  return (
    <>
      <ModalComponent
        current={sub !== null ? sub.plan : "premium"}
        open={open}
        handleClose={handleClose}
      />
      <Instruction opened={opened} setOpened={setOpened} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: " 1fr",
            sm: "1fr 1fr",
            mid: "1fr 1fr 1fr",
          },
          columnGap: "5%",
        }}
      >
        {loads.loading1 ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => <FaChalkboardTeacher size={30} color={"#1976d2"} />}
              text={"Total Teachers"}
              amount={teacher ? teacher.length : 0}
            />
          </>
        )}
        {loads.loading2 ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => (
                <SchoolRoundedIcon sx={{ fontSize: 30 }} color={"primary"} />
              )}
              text={"Total Students"}
              amount={student ? student.length : 0}
            />
          </>
        )}
        {loads.loading3 ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => (
                <QuizRoundedIcon sx={{ fontSize: 30 }} color={"primary"} />
              )}
              text={"Total Quizzes"}
              amount={quizzes ? quizzes.length : 0}
            />
          </>
        )}
        {loads.loading4 ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <OverviewCard
              icon={() => <FaSchool size={30} color={"#1976d2"} />}
              text={"Total Classes"}
              amount={classes ? classes.length : 0}
            />
          </>
        )}
      </Box>
      <Box mt={5}>
        <Button onClick={() => setOpened(true)} variant="contained">
          View Instructions
        </Button>
      </Box>
      <Box>
        <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
          Teacher Operations
        </Typography>
        <ul style={{ marginLeft: 30 }}>
          <li className="list">
            <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
              To Create Classes and Set Questions, simply click on the
              <b> SIDEBAR</b> located on the left side of the screen and click
              on <b>TEACHERS OPERATIONS</b>
            </Typography>
          </li>
        </ul>
      </Box>
      <Box mt={3}>
        {loads.loading1 ? (
          <>
            <SkeletonContainer />
          </>
        ) : (
          <>
            <Typography variant="h4">Subscription Details</Typography>
            <Box
              sx={{
                mt: 3,
                borderRadius: 5,
                backgroundColor: "#1976d21a",
                p: "3% 5%",
                display: { xs: "block", sm: "flex" },
                columnGap: "20%",
              }}
            >
              <Box>
                <Typography m="10% 0" variant="h6">
                  Subscribed:&nbsp;
                  {sub !== null ? (
                    <span style={{ textTransform: "capitalize" }}>
                      {sub.paid}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </Typography>
                <Typography m="10% 0" variant="h6">
                  Plan:&nbsp;
                  {sub !== null ? (
                    <span style={{ textTransform: "capitalize" }}>
                      {sub.plan}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </Typography>
                <Typography m="10% 0" variant="h6">
                  Date Subscribed:&nbsp;
                  {sub !== null ? (
                    <span style={{ textTransform: "capitalize" }}>
                      {moment(sub.date_sub).format("DD-MM-YYYY")}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </Typography>
                <Typography m="10% 0" variant="h6">
                  Expiry Date:&nbsp;
                  {sub !== null ? (
                    <span style={{ textTransform: "capitalize" }}>
                      {moment(sub.date_end).format("DD-MM-YYYY")}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </Typography>
              </Box>
              {sub !== null ? (
                sub.paid === "false" ? (
                  <Box alignSelf={"center"}>
                    <Button
                      onClick={handleOpen}
                      variant="contained"
                      sx={{ borderRadius: 1, width: "100%" }}
                    >
                      Subscribe
                    </Button>
                  </Box>
                ) : (
                  <Box alignSelf={"center"}>
                    <Button
                      onClick={handleOpen}
                      variant="contained"
                      sx={{ borderRadius: 1, width: "100%" }}
                    >
                      Upgrade
                    </Button>
                  </Box>
                )
              ) : null}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default AdminOverview;
