// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyDoVBOMbQsjAWtIci7tZiw1Eg8Q5k02Lpk",
	authDomain: "edu-support-f9e95.firebaseapp.com",
	databaseURL: "https://edu-support-f9e95.firebaseio.com",
	projectId: "edu-support-f9e95",
	storageBucket: "edu-support-f9e95.appspot.com",
	messagingSenderId: "551944062323",
	appId: "1:551944062323:web:61b1ee16bac956781ff106",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export default storage;
