import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Stack,
  Divider,
  Box,
} from "@mui/material";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import { useState } from "react";
import { motion } from "framer-motion";

function TestimonyDiv() {
  const [animationProps, setAnimationProps] = useState({});

  const animateCard = () => {
    setAnimationProps({
      opacity: 0,
      x: -40,
    });

    setTimeout(() => {
      setAnimationProps({
        opacity: 1,
        x: 0,
        transition: { duration: 1.5, type: "spring", stiffness: 100 },
      });
    }, 100);
  };

  const data = [
    {
      content:
        "Edusupport is a must-have for educators. It's user-friendly and has enriched our classroom activities",
      name: " - David R., School Administrator",
    },
    {
      content:
        "As a student, Edusupport has made learning interactive and enjoyable. I look forward to every quiz session!",
      name: " - Sarah M., Student",
    },
    {
      content:
        "Using Edusupport has significantly boosted my students' confidence in spelling. The quiz feature is fun and educational!",
      name: "- James L., Parent",
    },
    {
      content:
        "Edusupport has revolutionized how we engage students in quizzes and spelling challenges. It's an invaluable tool for enhancing learning!",
      name: " - Maria S., Teacher",
    },
    {
      content:
        "Thanks to Edusupport, our students are not only learning, but they're also having fun while doing it. A fantastic tool for educators!",
      name: "- John M., Teacher",
    },
    {
      content:
        "Thanks to Edusupport, our students are not only learning, but they're also having fun while doing it. A fantastic tool for educators!",
      name: "Ron K., Teacher",
    },
    {
      content:
        "As an educator, Edusupport has become my go-to tool for making learning enjoyable and impactful. It's a win-win for both teachers and students",
      name: " - Susan M., Teacher",
    },
    {
      content:
        "Edusupport Web App has become an essential part of our curriculum. It's intuitive, engaging, and highly effective.",
      name: "- Mary P., Teacher",
    },
    {
      content:
        "Our students have shown remarkable improvement in spelling and quiz scores since implementing Edusupport. It's a must-have for any educational institution!",
      name: " - Joseph P., Teacher",
    },
  ];

  const itemsPerPage = 1;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(data.length / itemsPerPage) - 1)
    );
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);
  return (
    <>
      <Grid item xs={12} m={5}>
        <Grid
          container
          spacing={2}
          justifyItems={"center"}
          justifyContent={"center"}
          direction={"row"}
        >
          {paginatedData.map((testimonial, index) => (
            <Grid item key={index} lg={6} xs={12} sx={{ textAlign: "center" }}>
              <Stack
                direction={"row"}
                spacing={3}
                justifyContent={"center"}
                alignItems={"center"}
                justifyItems={"center"}
                alignContent={"center"}
              >
                <ArrowBackIos
                  onClick={() => {
                    handlePrevPage();
                    animateCard();
                  }}
                  disabled={currentPage === 0}
                  sx={{
                    display: {
                      xs: "none",
                      md: "block",
                    },
                    fontSize: "40pt",
                    color: "#3C4DA7",
                    cursor: "pointer",
                  }}
                />
                <Stack direction={"column"} spacing={2}>
                  <motion.div
                    initial={animationProps}
                    animate={animationProps}
                    transition={animationProps.transition}
                  >
                    <Card
                      sx={{
                        background:
                          "linear-gradient(90deg, #171E41 0%, #3C4DA7 100%)",
                        color: "#fff",
                        borderRadius: 3,
                      }}
                    >
                      <CardContent>
                        <Stack direction={"column"} spacing={4}>
                          <Typography variant="h5">
                            {testimonial.content}
                          </Typography>
                          <Divider sx={{ bgcolor: "#fff", height: "3px" }} />
                          <Typography variant="h4">
                            {testimonial.name}
                          </Typography>
                        </Stack>
                      </CardContent>
                    </Card>
                  </motion.div>

                  <Box sx={{ display: { xs: "block", md: "none" } }}>
                    <ArrowBackIos
                      className="icon-test"
                      onClick={() => {
                        handlePrevPage();
                        animateCard();
                      }}
                      disabled={currentPage === 0}
                      sx={{
                        fontSize: "40pt",
                        color: "#3C4DA7",
                        cursor: "pointer",
                      }}
                    />

                    <ArrowForwardIos
                      className="icon-test"
                      onClick={() => {
                        handleNextPage();
                        animateCard();
                      }}
                      disabled={
                        currentPage ===
                        Math.ceil(data.length / itemsPerPage) - 1
                      }
                      sx={{
                        fontSize: "40pt",
                        color: "#3C4DA7",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Stack>

                <ArrowForwardIos
                  onClick={() => {
                    handleNextPage();
                    animateCard();
                  }}
                  disabled={
                    currentPage === Math.ceil(data.length / itemsPerPage) - 1
                  }
                  sx={{
                    fontSize: "40pt",
                    color: "#3C4DA7",
                    cursor: "pointer",

                    display: {
                      xs: "none",
                      md: "block",
                    },
                  }}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default TestimonyDiv;
