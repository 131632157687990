import axios from "axios";

export default class Api {
  constructor() {
    this.base_url = process.env.REACT_APP_BASE_URL;
   // this.base_url = "https://seashell-app-l3te8.ondigitalocean.app";
    this.role = localStorage.getItem("role");
    this.token = localStorage.getItem("token");
    this.axiosInstance = axios.create({
      baseURL: this.base_url,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    this.axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response?.data?.tokenExpired === true) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          window.location.reload();
        }
        return Promise.reject(error);
      }
    );
  }

  check() {
    return this.axiosInstance.head(`${this.base_url}/`);
  }

  // PARENT REQUESTS
  loginParent(email) {
    return this.axiosInstance.post(`${this.base_url}/parent`, {
      email,
    });
  }
  checkUsername(username) {
    return this.axiosInstance.post(`${this.base_url}/username`, {
      username,
    });
  }
  getAllResultParent(token) {
    return this.axiosInstance.get(`${this.base_url}/parent`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // ADMIN REQUESTS

  getAdminData(token) {
    return this.axiosInstance.get(`${this.base_url}/admin/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  getQuestionsAdmin(token, id) {
    return this.axiosInstance.get(`${this.base_url}/admin/question/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  getAdminClassName(token) {
    return this.axiosInstance.get(`${this.base_url}/admin/classname`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  getStudentInClassAdmin(token, class_name) {
    return this.axiosInstance.post(
      `${this.base_url}/admin/student`,
      {
        class_name,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  transferStudentAdmin(token, students, fromClassName, toClassName) {
    return this.axiosInstance.put(
      `${this.base_url}/admin/transfer`,
      { students, fromClassName, toClassName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  getSpellingQuestionsAdmin(token, id) {
    return this.axiosInstance.get(`${this.base_url}/admin/spelling/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getAdminTeachers(token) {
    return this.axiosInstance.get(`${this.base_url}/admin/teacher`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getAdminStudents(token) {
    return this.axiosInstance.get(`${this.base_url}/admin/student`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getAdminQuizzes(token) {
    return this.axiosInstance.get(`${this.base_url}/admin/quiz`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getAdminClasses(token) {
    return this.axiosInstance.get(`${this.base_url}/admin/class`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createAdmin(
    firstname,
    lastname,
    username,
    gender,
    email,
    phone,
    org_name,
    org_address,
    password
  ) {
    return this.axiosInstance.post(`${this.base_url}/admin`, {
      firstname,
      lastname,
      username,
      gender,
      email,
      phone,
      org_name,
      org_address,
      password,
    });
  }

  loginAdmin(email, password) {
    return this.axiosInstance.post(`${this.base_url}/admin/login`, {
      email,
      password,
    });
  }

  login(username, password) {
    return this.axiosInstance.post(`${this.base_url}`, {
      username,
      password,
    });
  }

  updateAdmin(
    token,
    id,
    firstname,
    lastname,
    gender,
    email,
    phone,
    org_name,
    org_address,

    avatar
  ) {
    return this.axiosInstance.put(
      `${this.base_url}/admin`,
      {
        id,
        firstname,
        lastname,
        gender,
        email,
        phone,
        org_name,
        org_address,
        avatar,
      },
      {
        headers: {
          ContentType: "multipart-form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  changepasswordAdmin(token, password) {
    return this.axiosInstance.put(
      `${this.base_url}/admin/login`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  deleteTeacher(token, teacher_id) {
    return this.axiosInstance.delete(
      `${this.base_url}/admin/teacher/${teacher_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  deleteStudent(token, student_id) {
    return this.axiosInstance.delete(
      `${this.base_url}/admin/student/${student_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  deleteScore(token, id, sid) {
    return this.axiosInstance.delete(
      `${this.base_url}/admin/score/${id}/${sid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getSub(token) {
    return this.axiosInstance.get(`${this.base_url}/admin/sub`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  postSub(token, dur, plan, ref) {
    return this.axiosInstance.post(
      `${this.base_url}/admin/sub`,
      { dur, plan, ref },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getAllResultAdmin(token) {
    return this.axiosInstance.get(`${this.base_url}/admin/score`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  // END ADMIN REQUESTS

  // STUDENT REQUEST

  joinOrgStudent(admin_id) {
    return this.axiosInstance.post(`${this.base_url}/student/manage`, {
      admin_id,
    });
  }

  getInStudentData(token) {
    return this.axiosInstance.get(`${this.base_url}/student/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getInStudentClasses(token) {
    return this.axiosInstance.get(`${this.base_url}/student/class`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  joinClass(token, class_id) {
    return this.axiosInstance.post(
      `${this.base_url}/student/class`,
      {
        class_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  deleteInStudentClass(token, class_id) {
    return this.axiosInstance.delete(
      `${this.base_url}/student/class/${class_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  createInStudent(
    admin_id,
    firstname,
    lastname,
    username,
    gender,
    email,
    phone,
    dob,
    student_type,
    password,
    parent_email,
    additional
  ) {
    return this.axiosInstance.post(`${this.base_url}/student`, {
      admin_id,
      firstname,
      lastname,
      username,
      gender,
      email,
      phone,
      dob,
      student_type,
      password,
      parent_email,
      additional,
    });
  }

  createGlobalStudent(
    firstname,
    lastname,
    username,
    gender,
    email,
    phone,
    dob,
    student_type,
    password,
    parent_email
  ) {
    return this.axiosInstance.post(`${this.base_url}/gstudent`, {
      firstname,
      lastname,
      username,
      gender,
      email,
      phone,
      dob,
      student_type,
      password,
      parent_email,
    });
  }

  loginInStudent(email, password) {
    return this.axiosInstance.post(`${this.base_url}/student/login`, {
      email,
      password,
    });
  }

  loginGlobalStudent(email, password) {
    return this.axiosInstance.post(`${this.base_url}/gstudent/login`, {
      email,
      password,
    });
  }
  updateInStudent(
    token,
    firstname,
    lastname,
    gender,
    email,
    phone,
    dob,
    parent_email,
    avatar,
    additional
  ) {
    return this.axiosInstance.put(
      `${this.base_url}/student`,
      {
        firstname,
        lastname,
        gender,
        email,
        phone,
        dob,
        parent_email,
        avatar,
        additional,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  updateGlobalStudent(
    token,
    firstname,
    lastname,
    gender,
    email,
    phone,
    dob,
    parent_email,
    avatar
  ) {
    return this.axiosInstance.put(
      `${this.base_url}/gstudent`,
      {
        firstname,
        lastname,
        gender,
        email,
        phone,
        dob,
        parent_email,
        avatar,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  changepasswordGlobalStudent(token, password) {
    return this.axiosInstance.put(
      `${this.base_url}/gstudent/login`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  changepasswordStudent(token, password) {
    return this.axiosInstance.put(
      `${this.base_url}/student/login`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getStudentQuiz(token) {
    return this.axiosInstance.get(
      `${this.base_url}/student/quiz/`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getGlobalStudentQuiz(token) {
    return this.axiosInstance.get(
      `${this.base_url}/gstudent/quiz/`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getStudentQuizData(token, quiz_id) {
    return this.axiosInstance.get(
      `${this.base_url}/student/quiz/${quiz_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getQuestionsStudent(token, quiz_id) {
    return this.axiosInstance.get(
      `${this.base_url}/student/question/${quiz_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getSpellingQuestionsStudent(token, quiz_id) {
    return this.axiosInstance.get(
      `${this.base_url}/student/spelling/${quiz_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  scoreStudent(token, quiz_id, answers, time_taken) {
    return this.axiosInstance.post(
      `${this.base_url}/student/score`,
      {
        quiz_id,
        answers,
        time_taken,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getAllResult(token) {
    return this.axiosInstance.get(`${this.base_url}/student/score`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getResultStatus(token, quiz_id) {
    return this.axiosInstance.get(`${this.base_url}/student/score/${quiz_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  ggetSub(token) {
    return this.axiosInstance.get(`${this.base_url}/gstudent/sub`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  gpostSub(token, dur, plan, ref) {
    return this.axiosInstance.post(
      `${this.base_url}/gstudent/sub`,
      { dur, plan, ref },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  // END STUDENT REQUEST

  // TEACHER REQUEST

  logInTeacher(email, password) {
    return this.axiosInstance.post(`${this.base_url}/teacher/login`, {
      email,
      password,
    });
  }

  createTeacher(
    admin_id,
    firstname,
    lastname,
    username,
    gender,
    email,
    phone,
    password
  ) {
    return this.axiosInstance.post(`${this.base_url}/teacher`, {
      admin_id,
      firstname,
      lastname,
      username,
      gender,
      email,
      phone,
      password,
    });
  }

  updateTeacher(token, firstname, lastname, gender, email, phone, avatar) {
    return this.axiosInstance.put(
      `${this.base_url}/teacher`,
      {
        firstname,
        lastname,
        gender,
        email,
        phone,
        avatar,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  changepasswordTeacher(token, password) {
    return this.axiosInstance.put(
      `${this.base_url}/teacher/login`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getTeacherData(token) {
    return this.axiosInstance.get(`${this.base_url}/teacher/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // TEACHER/ADMIN START

  getStudentTeacher(token, class_id) {
    return this.axiosInstance.post(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/student`
        : `${this.base_url}/teacher/student`,
      {
        class_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  removeStudent(token, class_id, student_id) {
    return this.axiosInstance.put(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/student`
        : `${this.base_url}/teacher/student`,
      {
        class_id,
        student_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getTeacherClassName(token) {
    return this.axiosInstance.get(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/classname`
        : `${this.base_url}/teacher/classname`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  createQuiz(
    token,
    quiz_name,
    dur_level,
    subject,
    point,
    pass_mark,
    class_ids,
    quiz_type,
    category,
    release_date
  ) {
    return this.axiosInstance.post(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/quiz`
        : `${this.base_url}/teacher/quiz`,
      {
        quiz_name,
        dur_level,
        subject,
        point,
        pass_mark,
        class_ids,
        quiz_type,
        category,
        release_date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getAllTeacherQuizzes(token) {
    return this.axiosInstance.get(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/quiz`
        : `${this.base_url}/teacher/quiz`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  updateQuiz(
    token,
    quiz_id,
    quiz_name,
    dur_level,
    subject,
    point,
    pass_mark,
    class_ids,
    status,
    quiz_type,
    category,
    release_date
  ) {
    return this.axiosInstance.put(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/quiz`
        : `${this.base_url}/teacher/quiz`,
      {
        quiz_id,
        quiz_name,
        dur_level,
        subject,
        point,
        pass_mark,
        class_ids,
        status,
        quiz_type,
        category,
        release_date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  deleteQuiz(token, quiz_id) {
    return this.axiosInstance.delete(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/quiz/${quiz_id}`
        : `${this.base_url}/teacher/quiz/${quiz_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  createClass(token, class_name, status, subject) {
    return this.axiosInstance.post(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/class`
        : `${this.base_url}/teacher/class`,
      {
        class_name,
        status,
        subject,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getAllTeacherClasses(token) {
    return this.axiosInstance.get(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/class`
        : `${this.base_url}/teacher/class`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  updateClass(token, class_id, class_name, status, subject) {
    return this.axiosInstance.put(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/class`
        : `${this.base_url}/teacher/class`,
      {
        class_id,
        class_name,
        status,

        subject,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getQuizData(token, id) {
    return this.axiosInstance.get(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/quiz/${id}`
        : `${this.base_url}/teacher/quiz/${id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  deleteClass(token, class_id) {
    return this.axiosInstance.delete(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/class/${class_id}`
        : `${this.base_url}/teacher/class/${class_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  setQuestion(token, quiz_id, question, type, options, answers, category) {
    return this.axiosInstance.post(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/question`
        : `${this.base_url}/teacher/question`,
      {
        quiz_id,
        question,
        type,
        options,
        answers,
        category,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  updateQuestionNormal(
    token,
    id,
    quiz_id,
    question,
    type,
    options,
    answers,
    category
  ) {
    return this.axiosInstance.put(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/question`
        : `${this.base_url}/teacher/question`,
      {
        id,
        quiz_id,
        question,
        type,
        options,
        answers,
        category,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getQuestions(token, quiz_id) {
    return this.axiosInstance.get(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/question/${quiz_id}`
        : `${this.base_url}/teacher/question/${quiz_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  deleteQuestion(token, quiz_id, question_id) {
    return this.axiosInstance.delete(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/question/${quiz_id}/${question_id}`
        : `${this.base_url}/teacher/question/${quiz_id}/${question_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  setSpellingQuestion(token, quiz_id, type, address, word, hint, category) {
    return this.axiosInstance.post(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/spelling`
        : `${this.base_url}/teacher/spelling`,
      {
        quiz_id,
        type,
        address,
        word,
        hint,
        category,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  updateSpellingQuestion(
    token,
    id,
    quiz_id,
    type,
    address,
    word,
    hint,
    category
  ) {
    return this.axiosInstance.put(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/spelling`
        : `${this.base_url}/teacher/spelling`,
      {
        id,
        quiz_id,
        type,
        address,
        word,
        hint,
        category,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getSpellingQuestions(token, quiz_id) {
    return this.axiosInstance.get(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/spelling/${quiz_id}`
        : `${this.base_url}/teacher/spelling/${quiz_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  deleteSpellingQuestion(token, quiz_id, question_id) {
    return this.axiosInstance.delete(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/spelling/${quiz_id}/${question_id}`
        : `${this.base_url}/teacher/spelling/${quiz_id}/${question_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getAllResultTeacher(token) {
    return this.axiosInstance.get(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/score`
        : `${this.base_url}/teacher/score`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  publishQuiz(token, quiz_id) {
    return this.axiosInstance.post(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/publish`
        : `${this.base_url}/teacher/publish`,
      { quiz_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  updateDate(token, quiz_id, closing, start) {
    return this.axiosInstance.put(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/publish`
        : `${this.base_url}/teacher/publish`,
      { quiz_id, closing, start },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  transferStudent(token, students, fromClassId, toClassId) {
    return this.axiosInstance.put(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/transfer`
        : `${this.base_url}/teacher/transfer`,
      { students, fromClassId, toClassId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  duplicateQuiz(token, quiz_id) {
    return this.axiosInstance.post(
      this.role === "admin"
        ? `${this.base_url}/admin/teach/duplicate`
        : `${this.base_url}/teacher/duplicate`,
      {
        quiz_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  // END TEACHER REQUEST
  viewAnswer(id, sid) {
    return this.axiosInstance.get(`${this.base_url}/score/${id}/${sid}`);
  }
  // SUPERADMIN REQUESTS
  superOverviewData(token) {
    return this.axiosInstance.get(`${this.base_url}/super`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  superGetAdmins(token) {
    return this.axiosInstance.get(`${this.base_url}/super/admins`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  superGetTeachers(token) {
    return this.axiosInstance.get(`${this.base_url}/super/teachers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  superGetStudents(token) {
    return this.axiosInstance.get(`${this.base_url}/super/students`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  superLogin(email, password) {
    return this.axiosInstance.post(`${this.base_url}/super/login`, {
      email,
      password,
    });
  }

  superChangePassword(token, password) {
    return this.axiosInstance.put(
      `${this.base_url}/super/login`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  // END SUPER ADMIN REQUESTS

  // RESET PASSWORD
  resetPassword(username) {
    return this.axiosInstance.post(`${this.base_url}/reset/`, {
      username,
    });
  }

  // ORG NAME
  getOrgNameAdmin(token) {
    return this.axiosInstance.get(
      `${this.base_url}/admin/org`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  getOrgNameTeacher() {
    if (this.role === "student") {
      return this.axiosInstance.get(`${this.base_url}/student/org`);
    } else {
      return this.axiosInstance.get(`${this.base_url}/teacher/org`);
    }
  }

  getOrgNameStudent() {
    return this.axiosInstance.get(`${this.base_url}/student/org`);
  }

  checkOrgId(orgId) {
    return axios.post(`${this.base_url}/org`, {
      admin_id: orgId,
    });
  }
  logout() {
    if (this.role === "parent" || this.role === "super") {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      window.location.reload();
    } else {
      return this.axiosInstance.post(`${this.base_url}/logout`);
    }
  }
  approveRequest(id) {
    return this.axiosInstance.put(`${this.base_url}/admin/manage`, {
      user_ids: id,
    });
  }

  getRequest() {
    return this.axiosInstance.get(`${this.base_url}/admin/manage`);
  }
  getAllOrgans() {
    if (this.role === "student") {
      return this.axiosInstance.get(`${this.base_url}/student/manage`);
    } else {
      return this.axiosInstance.get(`${this.base_url}/teacher/manage`);
    }
  }

  joinOrg(admin_id) {
    if (this.role === "teacher") {
      return this.axiosInstance.post(`${this.base_url}/teacher/manage`, {
        admin_id,
      });
    } else {
      return this.axiosInstance.post(`${this.base_url}/student/manage`, {
        admin_id,
      });
    }
  }

  switchOrg(admin_id) {
    if (this.role === "teacher") {
      return this.axiosInstance.put(`${this.base_url}/teacher/manage`, {
        admin_id,
      });
    } else {
      return this.axiosInstance.put(`${this.base_url}/student/manage`, {
        admin_id,
      });
    }
  }
  deleteRequest(id) {
    console.log({ user_ids: id });
    return this.axiosInstance.post(`${this.base_url}/admin/manage`, {
      user_ids: id,
    });
  }
  unpublishQuiz(quiz_id) {
    return this.axiosInstance.patch(`${this.base_url}/teacher/publish`, {
      quiz_id,
    });
  }
  switch() {
    return this.axiosInstance.post(`${this.base_url}/student/switch`, {});
  }
  switchBack() {
    return this.axiosInstance.put(`${this.base_url}/student/switch`, {});
  }
  getLeaderBoard(quiz_id) {
    return this.axiosInstance.post(`${this.base_url}/leaderboard`, {
      quiz_id,
    });
  }
}
