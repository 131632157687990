import React, { useState, useEffect } from "react";
import { Box, TextField, Avatar } from "@mui/material";
import Api from "../../api/API";
import SkeletonContainer from "./../SkeletonContainer";

import Skeleton from "@mui/material/Skeleton";

const TeacherProfileDetails = () => {
  const [userData, setUserData] = useState(null);
  const [loads, setLoad] = useState(false);

  useEffect(() => {
    const API = new Api();
    setLoad(true);
    const token = localStorage.getItem("token");
    API.getTeacherData(token)
      .then((res) => {
        setUserData(res.data);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        alert(err?.message);
      });
  }, []);

  return (
    <>
      {!loads ? (
        <>
          <Avatar
            sx={{
              border: "1px solid gray",
              m: "2% auto",
              width: 150,
              height: 150,
            }}
            src={userData ? userData.avatar : ""}
          />
          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <TextField
              value={userData ? userData.teacher_id : ""}
              color="text"
              focused
              label="Teacher ID"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
            <TextField
              value={userData ? userData.firstname : ""}
              color="text"
              focused
              label="First Name"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
          </Box>
          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <TextField
              value={userData ? userData.lastname : ""}
              color="text"
              focused
              label="Last Name"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
            <TextField
              value={userData ? userData.username : ""}
              color="text"
              focused
              label="Username"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
          </Box>

          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <TextField
              value={userData ? userData.email : ""}
              color="text"
              focused
              label="Email"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
            <TextField
              value={userData ? userData.phone : ""}
              color="text"
              focused
              label="Phone Number"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
          </Box>

          <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
            <TextField
              value={userData ? userData.gender : ""}
              color="text"
              focused
              label="Gender"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
            <TextField
              value={userData ? userData.date_created : ""}
              color="text"
              focused
              label="Joined"
              sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
            />
          </Box>
        </>
      ) : (
        <>
          <Skeleton
            variant="circular"
            width={200}
            height={200}
            sx={{ ml: "38%", mb: 1, bgcolor: "#dde9f3" }}
          />
          <SkeletonContainer />
          <Skeleton sx={{ bgcolor: "#dde9f3" }} />{" "}
        </>
      )}
    </>
  );
};

export default TeacherProfileDetails;
