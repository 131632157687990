import React, { useState } from "react";
import { Box, ButtonBase } from "@mui/material";
import AdminProfileDetails from "../../components/admin/AdminPorfileDetails";
import AdminEditProfile from "../../components/admin/AdminEditProfile";
import StudentEditProfile from "../../components/student/StudentEditProfile";
import StudentProfileDetails from "../../components/student/StudentProfileDetails";
import TeacherProfileDetails from "../../components/teacher/TeacherProfileDetails";
import TeacherEditProfile from "../../components/teacher/TeacherEditProfile";

const Profile = ({ type }) => {
	const [profile, setProfile] = useState(true);
	const style = {
		backgroundColor: "#1976d2",

		p: "1% 2%",
		fontSize: 18,
		color: "#ffffff",
	};
	return (
		<>
			<Box sx={{ display: "flex", columnGap: "10px" }}>
				<ButtonBase
					sx={
						profile
							? style
							: {
									transition: "all ease-in-out  0.2s",
									p: " 1% 2%",

									fontSize: 18,
							  }
					}
					onClick={() => setProfile(true)}
				>
					Profile
				</ButtonBase>
				<ButtonBase
					sx={
						!profile
							? style
							: {
									transition: "all ease-in-out 0.2s",
									p: "1% 2%",
									fontSize: 18,
							  }
					}
					onClick={() => setProfile(false)}
				>
					Edit Profile
				</ButtonBase>
			</Box>
			{type === "admin" ? (
				<Box>{profile ? <AdminProfileDetails /> : <AdminEditProfile />}</Box>
			) : type === "teacher" ? (
				<Box>
					{profile ? <TeacherProfileDetails /> : <TeacherEditProfile />}
				</Box>
			) : type === "student" ? (
				<Box>
					{profile ? <StudentProfileDetails /> : <StudentEditProfile />}
				</Box>
			) : null}
		</>
	);
};

export default Profile;
