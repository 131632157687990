import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import Api from "../../api/API";
import SkeletonContainer from "../../components/SkeletonContainer";
import { TextField, Paper, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../slices/StateManager";
import AdminTable from "../../components/admin/AdminTable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const QuizResultAdmin = () => {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [loads, setLoad] = useState(false);
  const [searched, setSearched] = useState("");
  const [rows, setRows] = useState(null);
  const [filter, setFilter] = React.useState("quiz_name");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.getAllResultAdmin(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setResult(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_taken),
                db = new Date(b.date_taken);
              return db - da;
            })
          );
          setRows(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_taken),
                db = new Date(b.date_taken);
              return db - da;
            })
          );
        }
        setLoad(false);
      })
      .catch((err) => {
        setResult(null);
        console.log(err);
        setLoad(false);
      });
  }, []);

  const deleteResult = (id, sid) => {
    if (window.confirm(`Are you sure you want to delete this Result?`)) {
      const API = new Api();
      const token = localStorage.getItem("token");
      dispatch(setLoading(true));
      API.deleteScore(token, id, sid)
        .then((res) => {
          if (res.data.message === "Result Deleted Successfully!") {
            API.getAllResultAdmin(token)
              .then((res) => {
                if (
                  res.data.data !== undefined &&
                  res.data.data !== null &&
                  res.data.data.length > 0
                ) {
                  setResult(
                    res.data.data.sort((a, b) => {
                      let da = new Date(a.date_taken),
                        db = new Date(b.date_taken);
                      return db - da;
                    })
                  );
                  setRows(
                    res.data.data.sort((a, b) => {
                      let da = new Date(a.date_taken),
                        db = new Date(b.date_taken);
                      return db - da;
                    })
                  );
                }
                dispatch(setLoading(false));
              })
              .catch((err) => {
                dispatch(setLoading(false));
                dispatch(
                  setOpen({
                    message: err.response.data.message,
                    open: true,
                    variant: "error",
                  })
                );
              });

            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
          } else {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "quiz_name") {
      const filteredRows = result.filter((row) => {
        return row.quiz_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "student_name") {
      const filteredRows = result.filter((row) => {
        return row.student_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "additional") {
      const filteredRows = result.filter((row) => {
        return row.student_additional
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "remark") {
      const filteredRows = result.filter((row) => {
        return row?.status?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "point") {
      const filteredRows = result.filter((row) => {
        return row.point <= searchedVal;
      });
      setRows(filteredRows);
    }
    if (filter === "percent") {
      const filteredRows = result.filter((row) => {
        return row.percent <= searchedVal;
      });
      setRows(filteredRows);
    }
    if (filter === "date") {
      const filteredRows = result.filter((row) => {
        return row.date_taken.includes(searchedVal);
      });
      setRows(filteredRows);
    }
  };

  const columns = [
    "Quiz Name",
    "Student Name",
    "Student Additional",
    "Point",
    "Percentage",
    "Remark",
    "Date Taken",
    "Time Taken",
  ];
  return (
    <>
      <Box mt={10}>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"quiz_name"}>Quiz Name</MenuItem>
                      <MenuItem value={"remark"}>Remark</MenuItem>
                      <MenuItem value={"point"}>Point</MenuItem>
                      <MenuItem value={"date"}>Date</MenuItem>
                      <MenuItem value={"percent"}>Percentage</MenuItem>
                      <MenuItem value={"student_name"}>Student Name</MenuItem>
                      <MenuItem value={"additional"}>
                        Student Additional
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />{" "}
                </Stack>

                <AdminTable
                  title={`Quiz Results `}
                  data={rows}
                  column={columns}
                  type="result"
                  deleteUser={deleteResult}
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default QuizResultAdmin;
