import React, { useState } from "react";
import {
  Box,
  Input,
  InputLabel,
  FormControl,
  Button,
  Typography,
  FormHelperText,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  ButtonBase,
} from "@mui/material";
import logo from "../../images/edu-dark.png";
import { useNavigate } from "react-router-dom";
import DatePicking from "../../components/DatePicking";
import Api from "../../api/API";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../slices/StateManager";
import { changePage } from "../../slices/changePage";
import { Stack } from "@mui/material";
import { motion } from "framer-motion";
import ReactLoader from "../../components/ReactLoader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
function GStudentSignup() {
  const [gender, setGender] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userValue, setUserValue] = useState("");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const [dob, setDob] = useState(moment());
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.changePage);

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const API = new Api();
    setLoading(true);
    if (e.target.password.value.length <= 7) {
      setLoading(false);
      dispatch(
        setOpen({
          message: "Password must be at least 8 characters long",
          open: true,
          variant: "error",
        })
      );
    } else {
      if (e.target.password.value === e.target.repassword.value) {
        API.createGlobalStudent(
          e.target.firstname.value.trim(),
          e.target.lastname.value.trim(),
          e.target.username.value.trim(),
          gender,
          e.target.email.value.trim(),
          e.target.phone.value.trim(),
          moment(dob).format("YYYY-MM-DD"),
          "GLOBAL",
          e.target.password.value,
          e.target.pemail.value.trim()
        )
          .then((res) => {
            if (res.data.message === "Account Created Successfully") {
              setLoading(false);
              window.location.href = "/signin";
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "success",
                })
              );
            } else {
              setLoading(false);

              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "error",
                })
              );
            }
          })
          .catch((error) => {
            setLoading(false);
            dispatch(
              setOpen({
                message: error.response
                  ? error.response.data.message
                  : error.message,
                open: true,
                variant: "error",
              })
            );
          });
      } else {
        setLoading(false);
        dispatch(
          setOpen({
            message: "Password Mismatch",
            open: true,
            variant: "error",
          })
        );
      }
    }
  };
  const checkUsername = (username) => {
    if (username.length > 3) {
      const API = new Api();

      API.checkUsername(username)
        .then((res) => {
          if (res.data.message === "Username Available") {
            setIsError(false);
          } else {
            setIsError(true);
          }
        })
        .catch((err) => {
          setIsError(true);
        });
    }
  };

  return (
    <>
      {loading && <ReactLoader />}
      <motion.div
        initial={{ opacity: 0, x: -40 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1.5, type: "spring", stiffness: 100 }}
      >
        <Box
          sx={{
            p: "2% 2% ",
            width: { xs: "80%", md: "70%", lg: "50%" },
            margin: "10vh auto",
            borderRadius: "10px",
            boxShadow: "0px 0px 3px 1px rgba(203, 203, 203, 1)",
          }}
        >
          <Box textAlign={"center"}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <img src={logo} alt="imag" />

              <Typography>Step 3:</Typography>
            </Stack>
            <Typography variant="h5">Register As A Global Student</Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
              <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
                <InputLabel htmlFor="firstname">First Name</InputLabel>
                <Input name="firstname" required id="firstname" />
              </FormControl>
              <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
                <InputLabel htmlFor="lastname">Last Name</InputLabel>
                <Input name="lastname" required id="lastname" />
              </FormControl>
            </Box>

            <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
              <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
                <InputLabel htmlFor="additional">
                  Additional Information (Optional)
                </InputLabel>
                <Input name="additional" type="text" id="additional" />
              </FormControl>
              <FormControl
                error={userValue.length > 3 && (isError ? true : false)}
                fullWidth
                sx={{ m: "1% 0" }}
                variant="standard"
              >
                <InputLabel htmlFor="username">Username</InputLabel>
                <Input
                  name="username"
                  required
                  id="username"
                  type="text"
                  onChange={(e) => setUserValue(e.target.value)}
                  onKeyUp={(e) => checkUsername(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      {userValue.length > 3 && (
                        <IconButton edge="end">
                          {isError == null ? (
                            ""
                          ) : isError ? (
                            <ErrorIcon color="error" />
                          ) : (
                            <CheckCircleIcon color="success" />
                          )}
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
                {userValue.length > 3 && (
                  <>
                    {isError ? (
                      <FormHelperText color="error" id="component-error-text">
                        username exist already
                      </FormHelperText>
                    ) : (
                      <FormHelperText
                        color="success"
                        sx={{
                          color: "green",
                        }}
                        id="component-success-text"
                      >
                        Available
                      </FormHelperText>
                    )}{" "}
                  </>
                )}
              </FormControl>
            </Box>

            <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
              <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input type="email" name="email" required id="email" />
              </FormControl>
              <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
                <InputLabel htmlFor="phone">Phone Number</InputLabel>
                <Input name="phone" required id="phone" />
              </FormControl>
            </Box>

            <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
              <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Select
                  value={gender}
                  onChange={handleChange}
                  id="gender"
                  required
                  name="gender"
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ m: "1% 0" }} variant="filled">
                <DatePicking dob={dob} setDob={setDob} />
              </FormControl>
            </Box>
            <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
              <FormControl fullWidth sx={{ m: "1% 0" }} variant="standard">
                <InputLabel htmlFor="pemail">Parent Email</InputLabel>
                <Input name="pemail" required type="email" id="pemail" />
              </FormControl>
            </Box>

            <Box display={{ xs: "block", md: "flex" }} sx={{ columnGap: "2%" }}>
              <Box width={"100%"} sx={{ display: "flex" }}>
                <FormControl sx={{ m: "1% 0", flexGrow: 1 }} variant="standard">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    name="password"
                    type={!show ? "password" : "text"}
                    required
                    id="password"
                    endAdornment={
                      <ButtonBase onClick={() => setShow(!show)}>
                        {!show ? (
                          <VisibilityOutlinedIcon
                            sx={{ alignSelf: "center" }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            sx={{ alignSelf: "center" }}
                          />
                        )}
                      </ButtonBase>
                    }
                  />
                </FormControl>
              </Box>
              <Box width={"100%"} sx={{ display: "flex" }}>
                <FormControl sx={{ m: "1% 0", flexGrow: 1 }} variant="standard">
                  <InputLabel htmlFor="repassword">Confirm Password</InputLabel>
                  <Input
                    name="repassword"
                    type={!show ? "password" : "text"}
                    required
                    id="repassword"
                    endAdornment={
                      <ButtonBase onClick={() => setShow(!show)}>
                        {!show ? (
                          <VisibilityOutlinedIcon
                            sx={{ alignSelf: "center" }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            sx={{ alignSelf: "center" }}
                          />
                        )}
                      </ButtonBase>
                    }
                  />
                </FormControl>
              </Box>
            </Box>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ m: "3% 0" }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  width: "100px",
                  borderRadius: "5px",
                  mb: 1,
                  color: "black",
                }}
                onClick={() =>
                  dispatch(
                    changePage({
                      page: `${
                        role === "student" || role === "teacher"
                          ? "second"
                          : "first"
                      }`,
                    })
                  )
                }
              >
                Back
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                sx={{ width: "100px", borderRadius: "5px", mb: 1 }}
              >
                Register
              </Button>
            </Stack>
          </form>
        </Box>
      </motion.div>
    </>
  );
}

export default GStudentSignup;
