import React from "react";

import SingleChoice from "./SingleChoice";
import MultiChoice from "./MultiChoice";
import FillGapChoice from "./FillGapChoice";
import TrueOrFalseChoice from "./TrueOrFalseChoice";
import SpellingChoiceEdit from "./SpellingChoiceEdit";
import CreatePattenEdit from "./CreatePattenEdit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80vw", sm: "80vw", md: "87vw" },
  height: "70vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: { xs: "5%", sm: "5%", md: "4%" },
  overflowY: "auto",
};
const EditInterface = ({ quizData, open, handleClose, data }) => {
  return (
    <>
      {open && (
        <>
          {data?.type !== ("sc" || "mc" || "tf" || "fg" || "match") && (
            <SpellingChoiceEdit
              data={data}
              lev={quizData}
              open={open}
              style={style}
              handleClose={handleClose}
            />
          )}

{data?.type === "match" && (
            <CreatePattenEdit
              data={data}
              lev={quizData }
              open={open}
              handleClose={handleClose}
              style={style}
            />
          )}
          {data?.type === "sc" && (
            <SingleChoice
              data={data}
              lev={quizData}
              open={open}
              handleClose={handleClose}
              style={style}
            />
          )}
          {data?.type === "mc" && (
            <MultiChoice
              data={data}
              lev={quizData }
              open={open}
              handleClose={handleClose}
              style={style}
            />
          )}
          {data?.type === "fg" && (
            <FillGapChoice
              data={data}
              lev={quizData }
              open={open}
              handleClose={handleClose}
              style={style}
            />
          )}
          {data?.type === "tf" && (
            <TrueOrFalseChoice
              data={data}
              lev={quizData}
              open={open}
              style={style}
              handleClose={handleClose}
            />
          )}
          
        </>
      )}
    </>
  );
};

export default EditInterface;
