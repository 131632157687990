import React, { useEffect, useState } from "react";
import Api from "../../api/API";
import { useParams } from "react-router-dom";
import ReactLoader from "../ReactLoader";
import {
  Stack,
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  Button,
} from "@mui/material";
import { Clear, Check, ArrowRightAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import parse from "html-react-parser";
const ViewAnswers = () => {
  const [result, setResult] = useState(null);
  const [quiz_type, setQuizType] = useState("");
  const [load, setLoad] = useState(true);
  const { id, sid } = useParams();
  const [quizData, setQuizData] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    const API = new Api();

    API.viewAnswer(id, sid)
      .then((res) => {
        setLoad(false);
        setQuizData(res.data.category);
        setQuizType(res.data.quiz_type);
        setResult(res.data.data);
      })
      .catch((err) => {
        alert(err.response.data.message);
        navigate(-1);
      });
  }, [id, sid, navigate]);

  let data = [];
  for (let i = 0; i < result?.length; i++) {
    data.push({
      category: result[i].category,
      data: result[i],
    });
  }
  let count = 1;

  const handleRedirect = () => {
    const role = localStorage.getItem("role");
    if (role === "admin") {
      navigate(`/dashboard/admin/result`);
    }
    if (role === "teacher") {
      navigate(`/dashboard/teacher/result`);
    }

    if (role === "student") {
      navigate(`/dashboard/student/results`);
    }

    if (role === "parent") {
      navigate(`/dashboard/parent`);
    }

    if (role === "gstudent") {
      navigate(`/dashboard/gstudent/results`);
    }
  };

  return (
    <>
      {load ? (
        <ReactLoader />
      ) : (
        <>
          <Container>
            <Grid container sx={{ mt: 5 }}>
              <Grid item md={2}>
                <Button
                  onClick={handleRedirect}
                  variant="contained"
                  color="primary"
                >
                  Go back
                </Button>
              </Grid>

              <Grid item md={8} sm={12} xs={12}>
                {quiz_type === "normal" ? (
                  <>
                    {quizData?.map((cat, index) => {
                      return (
                        <Box key={index}>
                          <Typography
                            marginY={1}
                            variant="h5"
                            component={"div"}
                            sx={{
                              padding: "5px",
                              backgroundColor: "#1680cc",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            {cat.text}
                          </Typography>
                          <Stack spacing={6}>
                            {data?.map((da, ix) => {
                              if (da?.data?.category === cat.text) {
                                return (
                                  <Paper sx={{ p: 3 }} key={ix} square>
                                    <Stack direction={"row"} spacing={2}>
                                      <Typography variant="h6">
                                        {count++ + "."}
                                      </Typography>
                                      <Typography variant="h6">
                                        {parse(
                                          da?.data?.question?.replaceAll(
                                            "~~",
                                            "'"
                                          ) || ""
                                        )}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      sx={{ flexWrap: "wrap" }}
                                      spacing={2}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{ color: "#98a1a0" }}
                                      >
                                        Answers:
                                      </Typography>

                                      <Stack
                                        spacing={2}
                                        direction={"row"}
                                        sx={{
                                          flexWrap: "wrap",
                                          rowGap: "20px",
                                        }}
                                      >
                                        {da?.data?.type === "fg" && (
                                          <>
                                            {da?.data?.correct_ans?.map(
                                              (d, i) => (
                                                <Typography
                                                  key={i}
                                                  sx={{
                                                    p: 1,
                                                    backgroundColor: "#eff5f9",
                                                    borderRadius: "15px",
                                                    color: "#1976d2",
                                                    width: "fit-content",
                                                    height: "fit-content",
                                                  }}
                                                >
                                                  {d?.replaceAll("~~", "'")}
                                                </Typography>
                                              )
                                            )}
                                          </>
                                        )}
                                        {da?.data?.type === "mc" && (
                                          <>
                                            {da?.data?.correct_ans.map(
                                              (d, ind) => (
                                                <Typography
                                                  key={ind}
                                                  sx={{
                                                    p: 1,
                                                    width: "fit-content",
                                                    height: "fit-content",
                                                    backgroundColor: "#eff5f9",
                                                    borderRadius: "15px",
                                                    color: "#1976d2",
                                                  }}
                                                >
                                                  {d?.replaceAll("~~", "'")}
                                                </Typography>
                                              )
                                            )}
                                          </>
                                        )}

                                        {da?.data?.type === "match" && (
                                          <>
                                            <Stack
                                              spacing={1}
                                              sx={{ flexWrap: "wrap" }}
                                            >
                                              {da?.data?.correct_ans.map(
                                                (d, x) => (
                                                  <Stack
                                                    key={x}
                                                    direction={"row"}
                                                    spacing={1}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        p: 1,
                                                        width: "fit-content",
                                                        height: "fit-content",
                                                        backgroundColor:
                                                          "#eff5f9",
                                                        borderRadius: "15px",
                                                        color: "#1976d2",
                                                      }}
                                                    >
                                                      {d?.options?.replaceAll(
                                                        "~~",
                                                        "'"
                                                      )}
                                                    </Typography>
                                                    <ArrowRightAlt />
                                                    <Typography
                                                      sx={{
                                                        p: 1,
                                                        width: "fit-content",
                                                        height: "fit-content",
                                                        backgroundColor:
                                                          "#eff5f9",
                                                        borderRadius: "15px",
                                                        color: "#1976d2",
                                                      }}
                                                    >
                                                      {d?.match?.replaceAll(
                                                        "~~",
                                                        "'"
                                                      )}
                                                    </Typography>
                                                  </Stack>
                                                )
                                              )}
                                            </Stack>
                                          </>
                                        )}
                                        {da?.data?.type === "tf" && (
                                          <>
                                            <Typography
                                              key={index}
                                              sx={{
                                                p: 1,
                                                backgroundColor: "#eff5f9",
                                                borderRadius: "15px",
                                                color: "#1976d2",
                                              }}
                                            >
                                              {da?.data?.correct_ans}
                                            </Typography>
                                          </>
                                        )}

                                        {da?.data?.type === "sc" && (
                                          <>
                                            <Typography
                                              key={index}
                                              sx={{
                                                p: 1,
                                                backgroundColor: "#eff5f9",
                                                borderRadius: "15px",
                                                color: "#1976d2",
                                              }}
                                            >
                                              {da?.data?.correct_ans[0]?.replaceAll(
                                                "~~",
                                                "'"
                                              )}
                                            </Typography>
                                          </>
                                        )}
                                      </Stack>
                                    </Stack>
                                    <Stack spacing={2} sx={{ mt: 2 }}>
                                      <Typography
                                        variant="h6"
                                        sx={{ color: "#98a1a0" }}
                                      >
                                        Choose:
                                      </Typography>

                                      {da?.data?.type === "fg" && (
                                        <Stack
                                          direction={"row"}
                                          spacing={2}
                                          sx={{
                                            flexWrap: "wrap",
                                            rowGap: "20px",
                                          }}
                                        >
                                          {da?.data?.student_ans.length < 1 && (
                                            <Typography
                                              sx={{
                                                p: 1,
                                                width: "fit-content",
                                                height: "fit-content",
                                                backgroundColor: "#dfa29b",
                                                borderRadius: "15px",
                                                color: "#c03d2f",
                                              }}
                                            >
                                              Not Provided
                                            </Typography>
                                          )}
                                          {da?.data?.student_ans?.map(
                                            (d, ex) => (
                                              <Stack
                                                direction={"row"}
                                                spacing={2}
                                                key={ex}
                                                sx={{ flexWrap: "wrap" }}
                                              >
                                                <Typography
                                                  sx={{
                                                    p: 1,
                                                    width: "fit-content",
                                                    height: "fit-content",

                                                    backgroundColor: da?.data
                                                      ?.correct
                                                      ? "#e1f4ef"
                                                      : "#dfa29b",
                                                    borderRadius: "15px",
                                                    color: da?.data?.correct
                                                      ? "#128466"
                                                      : "#c03d2f",
                                                  }}
                                                >
                                                  {d?.replaceAll("~~", "'")}
                                                </Typography>
                                              </Stack>
                                            )
                                          )}
                                          {da?.data?.correct ? (
                                            <Check
                                              sx={{
                                                color: "#128466",
                                                fontSize: "30pt",
                                              }}
                                            />
                                          ) : (
                                            <Clear
                                              sx={{
                                                fontSize: "30pt",
                                                color: "#c03d2f",
                                              }}
                                            />
                                          )}
                                        </Stack>
                                      )}

                                      {da?.data?.type === "match" && (
                                        <>
                                          <Stack
                                            key={index}
                                            spacing={1}
                                            sx={{ flexWrap: "wrap" }}
                                          >
                                            {da?.data?.student_ans?.length <=
                                            0 ? (
                                              <Typography
                                                sx={{
                                                  p: 1,
                                                  backgroundColor: "#dfa29b",
                                                  borderRadius: "15px",
                                                  color: "#c03d2f",
                                                }}
                                              >
                                                Not Provided
                                              </Typography>
                                            ) : (
                                              <>
                                                <Stack
                                                  direction={"row"}
                                                  sx={{ flexWrap: "wrap" }}
                                                >
                                                  <Stack spacing={1}>
                                                    {da?.data?.student_ans?.map(
                                                      (d, dex) => (
                                                        <Stack
                                                          key={dex}
                                                          direction={"row"}
                                                          spacing={1}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              p: 1,
                                                              width:
                                                                "fit-content",
                                                              height:
                                                                "fit-content",
                                                              backgroundColor:
                                                                da?.data
                                                                  ?.correct
                                                                  ? "#e1f4ef"
                                                                  : "#dfa29b",
                                                              borderRadius:
                                                                "15px",
                                                              color: da?.data
                                                                ?.correct
                                                                ? "#128466"
                                                                : "#c03d2f",
                                                            }}
                                                          >
                                                            {d?.options?.replaceAll(
                                                              "~~",
                                                              "'"
                                                            )}
                                                          </Typography>
                                                          <ArrowRightAlt />
                                                          <Typography
                                                            sx={{
                                                              p: 1,
                                                              width:
                                                                "fit-content",
                                                              height:
                                                                "fit-content",
                                                              backgroundColor:
                                                                da?.data
                                                                  ?.correct
                                                                  ? "#e1f4ef"
                                                                  : "#dfa29b",
                                                              borderRadius:
                                                                "15px",
                                                              color: da?.data
                                                                ?.correct
                                                                ? "#128466"
                                                                : "#c03d2f",
                                                            }}
                                                          >
                                                            {d?.match?.replaceAll(
                                                              "~~",
                                                              "'"
                                                            )}
                                                          </Typography>
                                                        </Stack>
                                                      )
                                                    )}
                                                  </Stack>
                                                  {da?.data?.correct ? (
                                                    <Check
                                                      sx={{
                                                        color: "#128466",
                                                        fontSize: "30pt",
                                                      }}
                                                    />
                                                  ) : (
                                                    <Clear
                                                      sx={{
                                                        fontSize: "30pt",
                                                        color: "#c03d2f",
                                                      }}
                                                    />
                                                  )}
                                                </Stack>
                                              </>
                                            )}
                                          </Stack>
                                        </>
                                      )}
                                      {da?.data?.type === "mc" && (
                                        <Stack
                                          direction={"row"}
                                          spacing={2}
                                          sx={{ flexWrap: "wrap" }}
                                        >
                                          {da?.data?.student_ans?.length <
                                            1 && (
                                            <Typography
                                              sx={{
                                                p: 1,
                                                backgroundColor: "#dfa29b",
                                                borderRadius: "15px",
                                                color: "#c03d2f",
                                              }}
                                            >
                                              Not Provided
                                            </Typography>
                                          )}
                                          {da?.data?.student_ans?.map(
                                            (d, ndex) => (
                                              <Stack
                                                direction={"row"}
                                                spacing={2}
                                                key={ndex}
                                              >
                                                <Typography
                                                  sx={{
                                                    p: 1,
                                                    width: "fit-content",
                                                    height: "fit-content",
                                                    backgroundColor: da?.data
                                                      ?.correct
                                                      ? "#e1f4ef"
                                                      : "#dfa29b",
                                                    borderRadius: "15px",
                                                    color: da?.data?.correct
                                                      ? "#128466"
                                                      : "#c03d2f",
                                                  }}
                                                >
                                                  {d?.replaceAll("~~", "'")}
                                                </Typography>
                                              </Stack>
                                            )
                                          )}
                                          {da?.data?.correct ? (
                                            <Check
                                              sx={{
                                                color: "#128466",
                                                fontSize: "30pt",
                                              }}
                                            />
                                          ) : (
                                            <Clear
                                              sx={{
                                                fontSize: "30pt",
                                                color: "#c03d2f",
                                              }}
                                            />
                                          )}
                                        </Stack>
                                      )}

                                      {da?.data?.type === "sc" && (
                                        <>
                                          <Stack direction={"row"} spacing={2}>
                                            <Typography
                                              sx={{
                                                p: 1,
                                                backgroundColor: da?.data
                                                  ?.correct
                                                  ? "#e1f4ef"
                                                  : "#dfa29b",
                                                borderRadius: "15px",
                                                color: da?.data?.correct
                                                  ? "#128466"
                                                  : "#c03d2f",
                                              }}
                                            >
                                              {da?.data?.student_ans === "" ||
                                              da?.data?.student_ans?.length <= 0
                                                ? "Not Provided"
                                                : typeof da?.data
                                                    ?.student_ans === "string"
                                                ? da?.data?.student_ans?.replaceAll(
                                                    "~~",
                                                    "'"
                                                  )
                                                : da?.data?.student_ans[0]?.replaceAll(
                                                    "~~",
                                                    "'"
                                                  )}
                                            </Typography>

                                            {da?.data?.correct ? (
                                              <Check
                                                sx={{
                                                  color: "#128466",
                                                  fontSize: "30pt",
                                                }}
                                              />
                                            ) : (
                                              <Clear
                                                sx={{
                                                  fontSize: "30pt",
                                                  color: "#c03d2f",
                                                }}
                                              />
                                            )}
                                          </Stack>
                                        </>
                                      )}

                                      {da?.data?.type === "tf" && (
                                        <>
                                          <Stack
                                            direction={"row"}
                                            spacing={2}
                                            key={index}
                                          >
                                            <Typography
                                              sx={{
                                                p: 1,
                                                backgroundColor: da?.data
                                                  ?.correct
                                                  ? "#e1f4ef"
                                                  : "#dfa29b",
                                                borderRadius: "15px",
                                                color: da?.data?.correct
                                                  ? "#128466"
                                                  : "#c03d2f",
                                              }}
                                            >
                                              {da?.data?.student_ans === "" ||
                                              da?.data?.student_ans.length <= 0
                                                ? "Not Provided"
                                                : typeof da?.data
                                                    ?.student_ans === "string"
                                                ? da?.data?.student_ans?.replaceAll(
                                                    "~~",
                                                    "'"
                                                  )
                                                : da?.data?.student_ans[0]?.replaceAll(
                                                    "~~",
                                                    "'"
                                                  )}
                                            </Typography>

                                            {da?.data?.correct ? (
                                              <Check
                                                sx={{
                                                  color: "#128466",
                                                  fontSize: "30pt",
                                                }}
                                              />
                                            ) : (
                                              <Clear
                                                sx={{
                                                  fontSize: "30pt",
                                                  color: "#c03d2f",
                                                }}
                                              />
                                            )}
                                          </Stack>
                                        </>
                                      )}
                                    </Stack>
                                  </Paper>
                                );
                              }
                              return null;
                            })}
                          </Stack>
                        </Box>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {quizData?.map((cat, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Box key={index}>
                            <Typography
                              marginY={1}
                              variant="h5"
                              component={"div"}
                              sx={{
                                padding: "5px",
                                backgroundColor: "#1680cc",
                                color: "white",
                                borderRadius: "5px",
                              }}
                            >
                              {cat.text}
                            </Typography>
                            <Stack spacing={6}>
                              {data?.map((da, k) => {
                                if (da?.data?.category === cat.text) {
                                  return (
                                    <Paper sx={{ p: 3 }} key={k} square>
                                      <Stack direction={"row"} spacing={2}>
                                        <Typography variant="h6">
                                          {count++ + "."}
                                        </Typography>
                                        <Typography variant="h6">
                                          {parse(
                                            da?.data?.question?.replaceAll(
                                              "~~",
                                              "'"
                                            )
                                          )}
                                        </Typography>
                                      </Stack>
                                      <Stack
                                        direction={"row"}
                                        sx={{ mt: 3 }}
                                        spacing={2}
                                      >
                                        <Typography
                                          variant="h6"
                                          sx={{ color: "#98a1a0" }}
                                        >
                                          Answer:
                                        </Typography>
                                        <Stack direction={"row"} spacing={2}>
                                          <Typography
                                            sx={{
                                              p: 1,
                                              backgroundColor: "#eff5f9",
                                              borderRadius: "15px",
                                              color: "#1976d2",
                                            }}
                                          >
                                            {da?.data?.correct_ans?.replaceAll(
                                              "~~",
                                              "'"
                                            )}
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                      <Stack
                                        direction={"row"}
                                        spacing={2}
                                        sx={{ mt: 2 }}
                                      >
                                        <Typography
                                          variant="h6"
                                          sx={{ color: "#98a1a0" }}
                                        >
                                          Choose:
                                        </Typography>
                                        <Typography
                                          sx={{
                                            p: 1,
                                            backgroundColor: da?.data?.correct
                                              ? "#e1f4ef"
                                              : "#dfa29b",
                                            borderRadius: "15px",
                                            color: da?.data?.correct
                                              ? "#128466"
                                              : "#c03d2f",
                                          }}
                                        >
                                          {da?.data?.student_ans === ""
                                            ? "Not Provided"
                                            : da?.data?.student_ans?.replaceAll(
                                                "~~",
                                                "'"
                                              )}
                                        </Typography>

                                        {da?.data?.correct ? (
                                          <Check
                                            sx={{
                                              color: "#128466",
                                              fontSize: "30pt",
                                            }}
                                          />
                                        ) : (
                                          <Clear
                                            sx={{
                                              fontSize: "30pt",
                                              color: "#c03d2f",
                                            }}
                                          />
                                        )}
                                      </Stack>
                                    </Paper>
                                  );
                                }
                                return null;
                              })}
                            </Stack>
                          </Box>
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default ViewAnswers;
