import { Box, Button, Typography, Grid, Stack } from "@mui/material";
import React from "react";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import logo from "././../../images/edu-dark.png";
import "./login.css";
import Footer from "./Footer";

import { Link, NavLink, useLocation } from "react-router-dom";

const drawerWidth = 240;

function Contact(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={logo} alt="logo" />
      <Divider />
      <List>
        <ListItem disablePadding>
          <Link to="/">
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Home" />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/about">
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="About Us" />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/contact">
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Contact" />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Grid container>
        <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
          <AppBar
            component="nav"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 0px 0px  0px",
              borderBottom: "6px solid  #3C4DA7",
              maxWidth: "1450px",
              marginLeft: "auto",
              marginRight: "auto",
              right: "auto",
            }}
          >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <img src={logo} alt="logo" />

              <Box
                sx={{
                  display: { sm: "flex" },
                  flexDirection: "row",
                  gap: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#3C4DA7",
                }}
              >
                <a href="/" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={`${
                      pathname === "/" ? "gradient-text text-bold" : ""
                    }`}
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", sm: "none", md: "block" },
                      fontWeight: `${pathname === "/" ? "bold" : "normal"}`,
                    }}
                  >
                    Home
                  </Typography>
                </a>
                <a href="/about" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={`${
                      pathname === "/about" ? "gradient-text text-bold" : ""
                    }`}
                    sx={{
                      flexGrow: 1,
                      fontWeight: `${
                        pathname === "/about" ? "bold" : "normal"
                      }`,
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  >
                    About Us
                  </Typography>
                </a>
                <a href="/contact" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={`${
                      pathname === "/contact" ? "gradient-text text-bold" : ""
                    }`}
                    sx={{
                      flexGrow: 1,
                      fontWeight: `${
                        pathname === "/contact" ? "bold" : "normal"
                      }`,
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  >
                    Contact
                  </Typography>
                </a>
                <NavLink to="/signin" style={{ textDecoration: "none" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      flexGrow: 1,
                      mx: 2,
                      color: "#3C4DA7",
                      borderColor: " #3C4DA7",
                    }}
                  >
                    Sign In
                  </Button>
                </NavLink>
                <NavLink to="/signup" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", sm: "none", md: "block" },
                      background:
                        "linear-gradient(90deg, #171E41 0%, #3C4DA7 100%)",
                    }}
                  >
                    Sign Up
                  </Button>
                </NavLink>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    color: "#3C4DA7",
                    mr: 2,
                    display: { md: "none", lg: "none" },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>

          <Drawer
            container={container}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>

          <Grid container p={5}>
            <Grid item md={2}></Grid>
            <Grid item md={8} sx={{ mt: 10 }} mb={10}>
              <Stack spacing={2} mb={4}>
                <Typography
                  variant="h4"
                  className="gradient-text"
                  sx={{
                    borderBottom: "5px solid #000369",
                    width: "fit-content",
                    paddingBottom: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Contact Us
                </Typography>

                <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                  Thank you for your interest in Edusupport Web App! We are
                  dedicated to providing exceptional support and assistance to
                  all our users. Whether you have inquiries, feedback, or
                  require technical assistance, we are here to help. Please feel
                  free to reach out to us using the contact information provided
                  below:
                </Typography>

                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    width: "fit-content",
                    paddingBottom: "10px",
                  }}
                >
                  Customer Support
                </Typography>

                <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                  For general inquiries, technical assistance, or feedback,
                  please contact our customer support team:
                </Typography>

                <ul style={{ margin: "20px 70px" }}>
                  <li>Email: support@edusupportwebapp.com</li>
                  <li> Phone: (+234) 802 353 7575</li>
                </ul>

                <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                  Our customer support representatives are available to assist
                  you Monday through Friday, from 9:00 AM to 5:00 PM (WAT).
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    width: "fit-content",
                    paddingBottom: "10px",
                  }}
                >
                  Feedback and Suggestions
                </Typography>

                <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                  We value your feedback and suggestions to continuously improve
                  our platform. Please feel free to share your thoughts with us:
                </Typography>
                <ul style={{ margin: "20px 70px" }}>
                  <li>Email: support@edusupportwebapp.com</li>
                </ul>

                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    width: "fit-content",
                    paddingBottom: "10px",
                  }}
                >
                  Address
                </Typography>

                <address style={{ margin: "20px 70px" }}>
                  <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                    Dovemax Consulting H12/R4 Peace Estate, Baruwa Lagos, Zip
                    Code 100276 Nigeria.
                  </Typography>
                </address>
                <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                  We look forward to hearing from you and assisting you with
                  your educational needs. Thank you for choosing Edusupport Web
                  App!
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </Grid>
        <Footer />
      </Grid>
    </>
  );
}
Contact.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default Contact;
