import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Api from "../api/API";
import ReactLoader from "./ReactLoader";
import SkeletonContainer from "./SkeletonContainer";
import TransferTable from "./TransferTable";
import { setLoading, setOpen } from "../slices/StateManager";

const StudentTransfer = () => {
  const [student, setStudent] = useState(null);
  const [loads, setLoad] = useState(false);

  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [loading, setisLoading] = useState(false);
  const [class_name, setClass_name] = useState(null);
  const [toClassName, setToClassName] = useState("");
  const [name, setName] = useState("");
  const [className, setClassName] = useState(null);

  const handleClass = (event) => {
    const API = new Api();
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    setName(event.target.value);
    setClassName((prev) => {
      // Filter the array based on the condition
      const filteredArray = class_name.filter(
        (item) => item.class_id === event.target.value
      );
      // Set the state to the filtered array
      return filteredArray;
    });
    setLoad(true);
    if (role === "teacher") {
      API.getStudentTeacher(token, event.target.value)
        .then((res) => {
          setStudent(res.data.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err.response.data);
          setLoad(false);
        });
    } else {
      API.getStudentInClassAdmin(token, event.target.value)
        .then((res) => {
          setStudent(res.data.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err.response.data);
          setLoad(false);
        });
    }
  };
  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    setisLoading(true);
    if (role === "teacher") {
      API.getTeacherClassName(token)
        .then((res) => {
          setisLoading(false);
          setClass_name(res.data.data);
        })
        .catch((err) => {
          setisLoading(false);
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    } else {
      API.getAdminClassName(token)
        .then((res) => {
          setisLoading(false);
          setClass_name(res.data.data);
        })
        .catch((err) => {
          setisLoading(false);
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  }, [setStudent, dispatch]);
  useEffect(() => {
    setRows(student);
  }, [setStudent, student]);
  const transferStudent = (students) => {
    if (
      window.confirm(`Are you sure you want to continue with the transfer `)
    ) {
      const API = new Api();
      const token = localStorage.getItem("token");
      const role = localStorage.getItem("role");
      setLoading(true);
      if (role === "teacher") {
        API.transferStudent(token, students, name, toClassName)
          .then((res) => {
            if (
              res?.status === 200 &&
              res.data.message?.includes("Successfully")
            ) {
              setStudent(res.data?.data);
              setLoading(false);
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "success",
                })
              );
            } else {
              setLoading(false);
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "error",
                })
              );
            }
          })
          .catch((err) => {
            setLoading(false);
            dispatch(
              setOpen({
                message: err.response.data.message,
                open: true,
                variant: "error",
              })
            );
          });
      } else {
        API.transferStudentAdmin(token, students, name, toClassName)
          .then((res) => {
            if (
              res?.status === 200 &&
              res.data.message?.includes("Successfully")
            ) {
              setStudent(res.data?.data);
              setLoading(false);
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "success",
                })
              );
              window.location.reload();
            } else {
              setLoading(false);
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "error",
                })
              );
            }
          })
          .catch((err) => {
            setLoading(false);
            dispatch(
              setOpen({
                message: err.response.data.message,
                open: true,
                variant: "error",
              })
            );
          });
      }
    }
  };

  const columns = [
    "First Name",
    "Last Name",
    "username",
    "Student Additional",
    "Email",
    "Parent Email",
    "Date Created",
  ];

  return (
    <>
      {loading && <ReactLoader />}
      <Box mt={10}>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              TRANSFER FROM CLASS
            </InputLabel>
            <Select
              name="opened"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="TRANSFER FROM CLASS"
              value={name}
              required
              onChange={handleClass}
            >
              {class_name === null ? (
                <MenuItem value={""}>No Class Found</MenuItem>
              ) : (
                class_name.map((item) => {
                  return (
                    <MenuItem key={item.class_name} value={item.class_id}>
                      {item.class_name}
                    </MenuItem>
                  );
                })
              )}
            </Select>
          </FormControl>
          <FormControl sx={{ mt: 4 }} fullWidth>
            <InputLabel id="demo-simple-select-label">
              TRANSFER TO CLASS
            </InputLabel>
            <Select
              name="opened"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="TRANSFER TO CLASS"
              value={toClassName}
              required
              onChange={(event) => setToClassName(event.target.value)}
            >
              {class_name === null ? (
                <MenuItem value={""}>No Class Found</MenuItem>
              ) : (
                class_name.map((item) => {
                  return (
                    name &&
                    name !== item.class_name && (
                      <MenuItem key={item.class_name} value={item.class_id}>
                        {item.class_name}
                      </MenuItem>
                    )
                  );
                })
              )}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <TransferTable
                  column={columns}
                  title={
                    name
                      ? `Students In ${className[0]?.class_name}`
                      : "No Class Selected"
                  }
                  rows={rows}
                  transferStudent={transferStudent}
                  setToClassName={setToClassName}
                  setName={setName}
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default StudentTransfer;
