import React, { useState, useEffect } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { VolumeUp } from "@mui/icons-material";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import Api from "../../../api/API";
import SkeletonContainer from "../../../components/SkeletonContainer";
import QuestionDisplay from "../../../components/teacher/QuestionDisplay";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../../slices/StateManager";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../fire";
import Schedule from "../../../components/Schedule";

const SpellingChoice = ({ quizData, quiz_id }) => {
  const [category, setCategory] = useState(
    localStorage.getItem("category") || quizData?.category[0]?.text
  );
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [hint, setHint] = useState();
  const [address, setAddress] = useState("");
  const [questions, setQuestions] = useState(null);
  const [loads, setLoad] = useState(false);
  const [cb1, setcb1] = useState(false);
  const [cb2, setcb2] = useState(false);
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [isdisabled1, setIsdisabled1] = useState(false);
  const [isdisabled2, setIsdisabled2] = useState(false);
  const onChangeOption = (e) => {
    e.target.name === "Option1" && setOption1(e.target.value.trim());
    e.target.name === "Option2" && setOption2(e.target.value.trim());
  };

  const onChangeCheckbox = (e) => {
    if (e.target.checked === true) {
      if (e.target.name === "Option1cb") {
        setSelectedValue(option1);
        setIsdisabled1(true);
        setcb2(false);
        setIsdisabled2(true);
      } else if (e.target.name === "Option2cb") {
        setSelectedValue(option2);
        setIsdisabled2(true);
        setcb1(false);
        setIsdisabled1(true);
      }
    } else {
      if (e.target.name === "Option1cb") {
        setSelectedValue(null);
        setIsdisabled1(false);
        setIsdisabled2(false);
      } else if (e.target.name === "Option2cb") {
        setSelectedValue(null);
        setIsdisabled2(false);
        setIsdisabled1(false);
      }
    }
  };

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.getSpellingQuestions(token, quiz_id)
      .then((res) => {
        if (res.data.data !== null) {
          setQuestions(res.data.data);
        } else {
          setQuestions(null);
        }

        setLoad(false);
      })
      .catch((err) => {
        setQuestions(null);
        setLoad(false);
      });
  }, [setQuestions, quiz_id]);

  const { speak } = useSpeechSynthesis();
  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {
      h: 0,
      m: 0,
      s: 0,
    },
  });

  const handleAudioStop = (data) => {
    setAudioDetails(data);
  };

  const handleAudioUpload = (file) => {
    SubmitUploadedFile(file);
  };

  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    setAudioDetails(reset);
  };

  let menu = [];
  for (let index = 0; index <= quizData?.category?.length; index++) {
    menu.push(
      <MenuItem key={index} value={quizData?.category[index]?.text}>
        {quizData?.category[index]?.text}
      </MenuItem>
    );
  }

  const SubmitUploadedFile = (file) => {
    if (file === null) {
      dispatch(
        setOpen({
          message: "Please record your voice",
          open: true,
          variant: "error",
        })
      );
    } else {
      const storageRef = ref(
        storage,
        `spellings/${Date.now()}_${Date.now()}.mp3`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          document.getElementById(
            "progress"
          ).innerHTML = `Uploading... ${progress}%`;
        },
        (error) => {
          alert(error.code);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              setAddress(downloadURL);
              document.getElementById("progress").innerHTML = "";
            })
            .catch((error) => {
              alert(error.code);
            });
        }
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const token = localStorage.getItem("token");

    if (selectedValue === null) {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please select the correct option",
          open: true,
          variant: "error",
        })
      );
    } else {
      const API = new Api();
      if (cb1) {
        let hints = hint.replaceAll("'", "~~");
        API.setSpellingQuestion(
          token,
          quiz_id,
          "option1",
          selectedValue.replaceAll("'", "~~"),
          selectedValue.replaceAll("'", "~~"),
          hints,
          category
        )
          .then((res) => {
            if (res.data.message === "Question Added Successfully!") {
              setQuestions(res.data.data);
              setSelectedValue(null);
              setOption1("");
              setOption2("");
              setAddress("");
              setCategory(localStorage.getItem("category"));
            }

            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: res.data.message,
                open: true,
                variant: "success",
              })
            );
          })
          .catch((err) => {
            dispatch(setLoading(false));
            dispatch(
              setOpen({
                message: err.response.data.message,
                open: true,
                variant: "error",
              })
            );
          });
      } else if (cb2) {
        let hints = hint.replaceAll("'", "~~");
        if (address === "") {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: "Please record your voice and upload!",
              open: true,
              variant: "error",
            })
          );
        } else {
          API.setSpellingQuestion(
            token,
            quiz_id,
            "option2",
            address,
            selectedValue.replaceAll("'", "~~"),
            hints,
            category
          )
            .then((res) => {
              if (res.data.message === "Question Added Successfully!") {
                setQuestions(res.data.data);
                setSelectedValue(null);
                setOption1("");
                setOption2("");
                setAddress("");
                setHint("");
                setCategory(localStorage.getItem("category"));
              }

              dispatch(setLoading(false));
              dispatch(
                setOpen({
                  message: res.data.message,
                  open: true,
                  variant: "success",
                })
              );
            })
            .catch((err) => {
              dispatch(setLoading(false));
              dispatch(
                setOpen({
                  message: err.response.data.message,
                  open: true,
                  variant: "error",
                })
              );
            });
        }
      }
    }
  };

  return (
    <>
      <>
        <Schedule opened={opened} setOpened={setOpened} quiz_id={quiz_id} />
        <Box
          sx={{
            "& > :not(style)": { mt: 1 },
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "1fr 1fr",
                md: "1fr 1fr",
              },
              columnGap: 3,
            }}
          >
            <Box>
              <Box
                component={"form"}
                onSubmit={handleSubmit}
                container
                spacing={4}
                sx={{ mb: 2 }}
              >
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ textAlign: "left", mb: 2 }}
                >
                  Spelling Question
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                  }}
                >
                  <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel id="demo-simple-select-label">
                      Question Section
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Question Section"
                      onChange={(e) => {
                        setCategory(e.target.value);
                        localStorage.setItem("category", e.target.value);
                      }}
                      value={category}
                    >
                      {menu.map((item) => {
                        return item;
                      })}
                    </Select>
                  </FormControl>

                  <Box mb={{ xs: 3, sm: 0 }} textAlign={"center"}>
                    <Button
                      onClick={() => {
                        if (questions?.length < 1 || questions === null) {
                          dispatch(
                            setOpen({
                              message: "Please Add Questions To This Quiz",
                              open: true,
                              variant: "error",
                            })
                          );
                        } else {
                          setOpened(true);
                        }
                      }}
                      variant="contained"
                    >
                      publish quiz
                    </Button>
                  </Box>
                </Box>

                <Box>
                  <TextField
                    size="small"
                    fullWidth
                    multiline
                    required
                    id="standard-basic"
                    name={`hint`}
                    label={`Provide a Hint`}
                    value={hint}
                    onChange={(e) => setHint(e.target.value)}
                  />
                </Box>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ textAlign: "left", mt: 2 }}
                >
                  Computer Voice Option
                </Typography>

                <Box sx={{ display: "flex", m: "1em 0" }}>
                  <Checkbox
                    checked={cb1}
                    onClick={() => setcb1(!cb1)}
                    value={option1}
                    name={`Option1cb`}
                    onChange={onChangeCheckbox}
                    disabled={option1 === "" ? true : false}
                  />

                  <TextField
                    size="small"
                    fullWidth
                    multiline
                    id="standard-basic"
                    name={`Option1`}
                    label={`Computer Voice Option Only`}
                    value={option1}
                    disabled={isdisabled1}
                    onChange={onChangeOption}
                  />

                  <VolumeUp
                    color="primary"
                    sx={{ fontSize: "40pt", cursor: "pointer" }}
                    onClick={() => {
                      speak({ text: option1 });
                    }}
                  />
                </Box>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ textAlign: "left", mt: 2 }}
                >
                  Teacher Voice Record Option
                </Typography>
                <Box sx={{ display: "flex", m: "10px 0" }}>
                  <Checkbox
                    checked={cb2}
                    onClick={() => setcb2(!cb2)}
                    value={option2}
                    name={`Option2cb`}
                    onChange={onChangeCheckbox}
                    disabled={option2 === "" ? true : false}
                  />

                  <TextField
                    size="small"
                    fullWidth
                    value={option2}
                    multiline
                    disabled={isdisabled2}
                    id="standard-basic"
                    name={`Option2`}
                    label={`Voice Recording Option Only`}
                    onChange={onChangeOption}
                  />

                  <Stack direction="row" spacing={3}>
                    <VolumeUp
                      color="primary"
                      sx={{ fontSize: "40pt", cursor: "pointer" }}
                      onClick={() => {
                        speak({ text: option2 });
                      }}
                    />
                  </Stack>
                </Box>

                <Box textAlign={"center"}>
                  <Button type="submit" variant="contained" size="large">
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              <Recorder
                record={true}
                title={"Record Voice For Option 2"}
                audioURL={audioDetails.url}
                showUIAudio
                handleAudioStop={(data) => handleAudioStop(data)}
                handleAudioUpload={(data) => handleAudioUpload(data)}
                handleReset={() => handleReset()}
              />
              <p id="progress"></p>
            </Box>
          </Box>
        </Box>
      </>

      <Box>
        <Typography variant="h6">
          Total Qestions {questions?.length || 0}
        </Typography>
      </Box>
      {loads ? (
        <>
          <SkeletonContainer />
        </>
      ) : (
        <>
          <QuestionDisplay
            questions={questions}
            setQuestions={setQuestions}
            quiz_id={quiz_id}
            type="spelling"
            quizData={quizData}
          />
        </>
      )}
    </>
  );
};

export default SpellingChoice;
