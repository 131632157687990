import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import {
  Accessibility,
  Book,
  Person,
  SupervisedUserCircle,
} from "@mui/icons-material";

const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          padding: "40px",
          backgroundColor:" #0b67a8",
          height:"103vh",
        }}
      >
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 2,
              borderRadius: "10px",
              backgroundColor: "white",
              height: "200px",

              boxShadow: "0px 2px 10px  rgba(0,0,0,0.3)",

              "&:hover": {
                boxShadow: "0px 4px 15px  rgba(0,0,0,0.3)",
              },
            }}
          >
            <Stack spacing={3} alignItems="center">
              <Person sx={{ fontSize: "40pt", color: "#1976d2" }} />
              <Typography textAlign={"center"} variant="h7">
                Admin Of An Organisation
              </Typography>
              <br />

              <Button
                variant="contained"
                onClick={() => navigate("/admin")}
                sx={{ textTransform: "capitalize" }}
              >
                Click here
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 2,
              borderRadius: "10px",

              height: "200px",
              backgroundColor: "white",

              boxShadow: "0px 2px 10px  rgba(0,0,0,0.3)",

              "&:hover": {
                boxShadow: "0px 4px 15px  rgba(0,0,0,0.3)",
              },
            }}
          >
            <Stack spacing={3} alignItems="center">
              <Book sx={{ fontSize: "40pt", color: "#1976d2" }} />
              <Typography textAlign={"center"} variant="h7">
                Teachers Under An Organisation
              </Typography>

              <Button
                variant="contained"
                onClick={() => navigate("/teacher")}
                sx={{ textTransform: "capitalize" }}
              >
                Click here
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 2,
              borderRadius: "10px",

              height: "200px",
              backgroundColor: "white",

              boxShadow: "0px 2px 10px  rgba(0,0,0,0.3)",

              "&:hover": {
                boxShadow: "0px 4px 15px  rgba(0,0,0,0.3)",
              },
            }}
          >
            <Stack spacing={3} alignItems="center">
              <SupervisedUserCircle
                sx={{ fontSize: "40pt", color: "#1976d2" }}
              />
              <Typography textAlign={"center"} variant="h7">
                Students Under An Organisation
              </Typography>

              <Button
                variant="contained"
                onClick={() => navigate("/student")}
                sx={{ textTransform: "capitalize" }}
              >
                Click here
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 2,
              borderRadius: "10px",

              height: 200,
              backgroundColor: "white",

              boxShadow: "0px 2px 10px  rgba(0,0,0,0.3)",

              "&:hover": {
                boxShadow: "0px 4px 15px  rgba(0,0,0,0.3)",
              },
            }}
          >
            <Stack spacing={3} alignItems="center">
              <Accessibility sx={{ fontSize: "40pt", color: "#1976d2" }} />
              <Typography textAlign={"center"} variant="h7">
                Global Students
              </Typography>
              <br />

              <Button
                variant="contained"
                onClick={() => navigate("/gstudent")}
                sx={{ textTransform: "capitalize" }}
              >
                Click here
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 2,
              borderRadius: "10px",
              height: 200,
              backgroundColor: "white",

              boxShadow: "0px 2px 10px  rgba(0,0,0,0.3)",

              "&:hover": {
                boxShadow: "0px 4px 15px  rgba(0,0,0,0.3)",
              },
            }}
          >
            <Stack spacing={3} alignItems="center">
              <EscalatorWarningIcon
                sx={{ fontSize: "40pt", color: "#1976d2" }}
              />
              <Typography textAlign={"center"} variant="h7">
                Student's Parent
              </Typography>
              <br />

              <Button
                variant="contained"
                onClick={() => navigate("/parent")}
                sx={{ textTransform: "capitalize" }}
              >
                Click here
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPage;
