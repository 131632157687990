import React, { useEffect, useState } from "react";
import { Box, TextField, Paper, Stack } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TeacherTable from "../../components/teacher/TeacherTable";
import Api from "../../api/API";
import SkeletonContainer from "../../components/SkeletonContainer";

const ParentResult = () => {
  const [result, setResult] = useState(null);
  const [searched, setSearched] = useState("");
  const [loads, setLoad] = useState(false);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = React.useState("quiz_name");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.getAllResultParent(token)
      .then((res) => {
        if (
          res.data.data !== undefined &&
          res.data.data !== null &&
          res.data.data.length > 0
        ) {
          setResult(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_taken),
                db = new Date(b.date_taken);
              return db - da;
            })
          );
        }
        setLoad(false);
      })
      .catch((err) => {
        setResult(null);
        alert(err.response ? err.response.data.message : err.message);
        setLoad(false);
      });
  }, []);
  useEffect(() => {
    setRows(result);
  }, [result]);
  const columns = [
    "Quiz Name",
    "Student Name",
    "Point",
    "Percentage",
    "Remark",
    "Date Taken",
    "Time Taken",
  ];
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "quiz_name") {
      const filteredRows = result.filter((row) => {
        return row.quiz_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "student_name") {
      const filteredRows = result.filter((row) => {
        return row.student_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }

    if (filter === "remark") {
      const filteredRows = result.filter((row) => {
        return row.status?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "point") {
      const filteredRows = result.filter((row) => {
        return row.point <= searchedVal;
      });
      setRows(filteredRows);
    }
    if (filter === "percent") {
      const filteredRows = result.filter((row) => {
        return row.percent <= searchedVal;
      });
      setRows(filteredRows);
    }
    if (filter === "date") {
      const filteredRows = result.filter((row) => {
        return row.date_taken.includes(searchedVal);
      });
      setRows(filteredRows);
    }
  };
  return (
    <>
      <Box mt={10}>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"quiz_name"}>Quiz Name</MenuItem>
                      <MenuItem value={"remark"}>Remark</MenuItem>
                      <MenuItem value={"point"}>Point</MenuItem>
                      <MenuItem value={"date"}>Date</MenuItem>
                      <MenuItem value={"percent"}>Percentage</MenuItem>
                      <MenuItem value={"student_name"}>Student Name</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />{" "}
                </Stack>

                <TeacherTable
                  title={"Quiz Results"}
                  rows={rows}
                  column={columns}
                  type="result"
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ParentResult;
