export const areEqual = (first, second) => {
	if (first?.length !== second?.length) {
		return false;
	}
	for (let i = 0; i < first?.length; i++) {
		if (!second.includes(first[i])) {
			return false;
		}
	}
	return true;
};

export const shuffleArray=(array)=>{
	let curId = array.length;
	// There remain elements to shuffle
	while (0 !== curId) {
	  // Pick a remaining element
	  let randId = Math.floor(Math.random() * curId);
	  curId -= 1;
	  // Swap it with the current element.
	  let tmp = array[curId];
	  array[curId] = array[randId];
	  array[randId] = tmp;
	}
	return array;
      }
     