import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";

const CustomTagInput = ({ tags, setTags }) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addTag();
    }
  };

  const handleAddButton = () => {
    addTag();
  };

  const addTag = () => {
    const trimmedValue = inputValue.trim();
    if (
      trimmedValue !== "" &&
      !tags.find((tag) => tag.text.toLowerCase() === trimmedValue.toLowerCase())
    ) {
      const newTag = {
        id: trimmedValue.toLowerCase(),
        text: trimmedValue,
      };
      setTags([...tags, newTag]);
      setInputValue("");
    }
  };

  const handleDelete = (id) => {
    setTags(tags.filter((t) => t.id !== id));
  };

  return (
    <Box>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="section">
          Type new Section. Press ENTER to add
        </InputLabel>
        <OutlinedInput
          id="section"
          label="Type new Section. Press ENTER to add"
          onKeyDown={(e) => handleKeyDown(e)}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          endAdornment={
            inputValue.length > 2 && (
              <InputAdornment position="end">
                <Button variant="contained" onClick={handleAddButton}>
                  Add
                </Button>
              </InputAdornment>
            )
          }
        />
      </FormControl>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        sx={{ marginBlock: 1.5, gap: 1 }}
      >
        {tags.map((tag, index) => (
          <Chip
            color="primary"
            key={index}
            label={tag.text}
            onDelete={() => handleDelete(tag.id)}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default CustomTagInput;
