import React, { useState, useEffect } from "react";
import { Box, TextField, Avatar } from "@mui/material";
import Api from "../../api/API";
import SkeletonContainer from "./../SkeletonContainer";
import Skeleton from "@mui/material/Skeleton";

const StudentProfileDetails = () => {
  const [userData, setUserData] = useState(null);
  const [loads, setLoad] = useState(false);

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);
    API.getInStudentData(token)
      .then((res) => {
        setUserData(res.data.data);
        setLoad(false);
      })
      .catch((err) => {
        alert(err?.message);
        setLoad(false);
      });
  }, []);
  return (
    <>
      {!loads ? (
        <>
          {userData !== null ? (
            userData?.student_type === "IN" ? (
              <>
                <Avatar
                  sx={{
                    border: "1px solid gray",
                    m: "2% auto",
                    width: 150,
                    height: 150,
                  }}
                  src={userData ? userData.avatar : ""}
                />
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.student_id : ""}
                    color="text"
                    focused
                    label="Student ID"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={userData ? userData.firstname : ""}
                    color="text"
                    focused
                    label="First Name"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.lastname : ""}
                    color="text"
                    focused
                    label="Last Name"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={userData ? userData.username : ""}
                    color="text"
                    focused
                    label="Username"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.email : ""}
                    color="text"
                    focused
                    label="Email"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={userData ? userData.phone : ""}
                    color="text"
                    focused
                    label="Phone Number"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.gender : ""}
                    color="text"
                    focused
                    label="Gender"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={
                      userData
                        ? new Date(userData.dob).toLocaleDateString()
                        : ""
                    }
                    color="text"
                    focused
                    label="Date Of Birth"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.student_type : ""}
                    color="text"
                    focused
                    label="Student Type"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={userData ? userData.parent_email : ""}
                    color="text"
                    focused
                    label="Parent Email"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.additional : ""}
                    color="text"
                    focused
                    label="Student Additional"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={userData ? userData.date_created : ""}
                    color="text"
                    focused
                    label="Joined"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>
              </>
            ) : (
              <>
                <Avatar
                  sx={{
                    border: "1px solid gray",
                    m: "2% auto",
                    width: 150,
                    height: 150,
                  }}
                  src={userData ? userData.avatar : ""}
                />
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.student_id : ""}
                    color="text"
                    focused
                    label="Student ID"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={userData ? userData.firstname : ""}
                    color="text"
                    focused
                    label="First Name"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.lastname : ""}
                    color="text"
                    focused
                    label="Last Name"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={userData ? userData.username : ""}
                    color="text"
                    focused
                    label="Username"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.email : ""}
                    color="text"
                    focused
                    label="Email"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={userData ? userData.phone : ""}
                    color="text"
                    focused
                    label="Phone Number"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.gender : ""}
                    color="text"
                    focused
                    label="Gender"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={
                      userData
                        ? new Date(userData.dob).toLocaleDateString()
                        : ""
                    }
                    color="text"
                    focused
                    label="Date Of Birth"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>

                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.student_type : ""}
                    color="text"
                    focused
                    label="Student Type"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                  <TextField
                    value={userData ? userData.parent_email : ""}
                    color="text"
                    focused
                    label="Parent Email"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  sx={{ columnGap: "2%" }}
                >
                  <TextField
                    value={userData ? userData.date_created : ""}
                    color="text"
                    focused
                    label="Joined"
                    sx={{ m: "1% 0", width: { xs: "100%", md: "50%" } }}
                  />
                </Box>
              </>
            )
          ) : null}
        </>
      ) : (
        <>
          <Skeleton
            variant="circular"
            width={200}
            height={200}
            sx={{ ml: "38%", mb: 1, bgcolor: "#dde9f3" }}
          />
          <SkeletonContainer />
          <Skeleton sx={{ bgcolor: "#dde9f3" }} />{" "}
        </>
      )}
    </>
  );
};

export default StudentProfileDetails;
