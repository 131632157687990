import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import { setOpen } from "./../slices/StateManager";

const SnackBarComponent = () => {
  const { message, open, variant, timer } = useSelector(
    (state) => state.StateManager
  );
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setOpen({ message: "", open: false, variant: "info" }));
  };
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={timer}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={variant} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackBarComponent;
