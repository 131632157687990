import {
  Box,
  Button,
  Typography,
  ButtonBase,
  Stack,
  TextField,
  Divider,
} from "@mui/material";
import logo from "../../images/edu-dark.png";
import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import Api from "../../api/API";
import { useDispatch } from "react-redux";
import { setOpen } from "../../slices/StateManager";
import ReactLoader from "../../components/ReactLoader";
import { motion } from "framer-motion";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const Signin = () => {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const API = new Api();
    setLoading(true);
    API.login(e.target.email.value.trim(), e.target.password.value)
      .then((res) => {
        if (res.data.token !== null) {
          setRole(res.data.role);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", res.data.role);
          setLoading(false);
          if (role === "admin") {
            return <Navigate to="/dashboard/admin" />;
          }

          if (role === "teacher") {
            return <Navigate to="/dashboard/teacher" />;
          }

          if (role === "student") {
            return <Navigate to="/dashboard/student" />;
          }
          if (role === "gstudent") {
            return <Navigate to="/dashboard/gstudent" />;
          }
          if (role === "parent") {
            return <Navigate to="/dashboard/parent" />;
          }
          dispatch(
            setOpen({ message: `Welcome back`, open: true, variant: "success" })
          );
        } else {
          setLoading(false);
          dispatch(
            setOpen({
              message: `Invalid Credentials`,
              open: true,
              variant: "error",
            })
          );
        }
      })
      .catch((error) => {
        setLoading(false);

        dispatch(
          setOpen({
            message: error.response
              ? error.response.data.message
              : error.message,
            open: true,
            variant: "error",
          })
        );
      });
  };
  return (
    <>
      {loading && <ReactLoader />}
      <motion.div
        initial={{ opacity: 0, x: -40 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1.5, type: "spring", stiffness: 100 }}
      >
        <Box
          sx={{
            p: " 3% 5% ",
            width: { xs: "80%", md: "40%" },
            margin: "5vh auto",
            background: "#F9F9F9",
            borderRadius: "10px",
            boxShadow: "0px 0px 3px 1px rgba(203, 203, 203, 1)",
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <img src={logo} alt="edusupport" />

            <Typography>Sign In</Typography>
          </Stack>

          <form onSubmit={handleSubmit}>
            <Stack direction={"column"} spacing={5} mt={6}>
              <TextField label="Username" fullWidth required id="email" />

              <TextField
                required
                fullWidth
                variant="outlined"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                InputProps={{
                  endAdornment: (
                    <ButtonBase onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </ButtonBase>
                  ),
                }}
              />

              <Box sx={{ m: "3% 0" }}>
                <Button
                  className="pull-right"
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: "capitalize", float: "right", mb: 3 }}
                >
                  Sign In
                </Button>
              </Box>
            </Stack>
            <Box
              sx={{
                m: "3% 0",
                clear: "both",
                display: { xs: "block", md: "flex" },
                justifyContent: "space-between",
              }}
            >
              <Typography>
                No Account?&nbsp;
                <Link
                  to={"/signup"}
                  style={{ color: "blue" }}
                  title="Dont have An Account?"
                >
                  Sign Up
                </Link>
              </Typography>
              <Typography>
                <Link
                  to={"/reset"}
                  style={{ color: "blue" }}
                  title="Forgotten Password?"
                >
                  Forgotten Password?
                </Link>
              </Typography>
            </Box>
          </form>

          <Box>
            <Divider sx={{ m: "3% 0" }} />
            <Typography textAlign={"center"}>OR</Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/parent")}
              sx={{
                textTransform: "capitalize",
                marginLeft: { xs: "25%", md: "37%" }, //"35%",
                marginTop: "20px",
              }}
            >
              Signin as a Parent
            </Button>
          </Box>
        </Box>

        <Box mt="-2vh" textAlign={"center"}>
          <Button
            onClick={() => {
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/edu-support-f9e95.appspot.com/o/files%2FEduSupport%20Terms%20and%20Conditions.pdf?alt=media&token=0532a887-db08-4024-8309-69811ae16faf",
                "_blank"
              );
            }}
          >
            Terms & Conditions
          </Button>

          <Button
            onClick={() => {
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/edu-support-f9e95.appspot.com/o/files%2FEduSupport%20Privacy%20Policy%20-%20rev.pdf?alt=media&token=5882655b-27e8-4e50-974d-fc0954d1243f"
              );
            }}
          >
            Privacy policy
          </Button>
        </Box>
        <Box textAlign={"center"}>
          <Button>Developed By Hi-Dev Solutions</Button>
        </Box>
      </motion.div>
    </>
  );
};

export default Signin;
