import {
  Box,
  Button,
  FormControl,
  ButtonBase,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import logo from "../../images/edu-dark.png";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../api/API";
import { useDispatch } from "react-redux";
import { setOpen } from "../../slices/StateManager";
import ReactLoader from "../../components/ReactLoader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const TeacherSignin = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const API = new Api();
    setLoading(true);
    API.logInTeacher(e.target.email.value, e.target.password.value)
      .then((res) => {
        if (res.data.token !== null) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("role", res.data.role);
          setLoading(false);
          navigate("/dashboard/teacher");
          dispatch(
            setOpen({ message: `Welcome back`, open: true, variant: "success" })
          );
        } else {
          setLoading(false);
          dispatch(
            setOpen({ message: res.data.message, open: true, variant: "error" })
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          setOpen({
            message: error.response
              ? error.response.data.message
              : error.message,
            open: true,
            variant: "error",
          })
        );
      });
  };
  return (
    <>
      {loading && <ReactLoader />}
      <Box
        sx={{
          p: " 3% 5% ",
          width: { xs: "80%", md: "50%" },
          margin: "20vh auto",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px #000000",
        }}
      >
        <Box textAlign={"center"}>
          <img src={logo} alt="logo" height={100} width={200} />
          <Typography variant="h4">Teacher Login</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <FormControl
            fullWidth
            sx={{ m: "3% 0", fontSize: "10rem" }}
            variant="standard"
          >
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input name="email" required type="email" id="email" />
          </FormControl>
          <Box sx={{ display: "flex" }}>
            <FormControl sx={{ m: "3% 0", flexGrow: 1 }} variant="standard">
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                required
                type={!show ? "password" : "text"}
                id="password"
              />
            </FormControl>
            <ButtonBase onClick={() => setShow(!show)}>
              {!show ? (
                <VisibilityOutlinedIcon sx={{ alignSelf: "center" }} />
              ) : (
                <VisibilityOffOutlinedIcon sx={{ alignSelf: "center" }} />
              )}
            </ButtonBase>
          </Box>
          <Box sx={{ m: "3% 0" }}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              sx={{}}
            >
              log in
            </Button>
          </Box>

          <Box
            sx={{
              m: "3% 0",
              display: { xs: "block", md: "flex" },
              justifyContent: "space-between",
            }}
          >
            <Typography>
              No Account?&nbsp;
              <Link to={"/teacher/signup"} title="Dont have An Account?">
                Sign Up
              </Link>
            </Typography>
            <Typography>
              <Link to={"/reset"} title="Forgotten Password?">
                Forgotten Password?
              </Link>
            </Typography>
          </Box>
        </form>
      </Box>
      <Box mt="-12vh" textAlign={"center"}>
        <Button
          onClick={() => {
            window.open(
              "https://firebasestorage.googleapis.com/v0/b/edu-support-f9e95.appspot.com/o/files%2FEduSupport%20Terms%20and%20Conditions.pdf?alt=media&token=0532a887-db08-4024-8309-69811ae16faf",
              "_blank"
            );
          }}
        >
          Terms & Conditions
        </Button>

        <Button
          onClick={() => {
            window.open(
              "https://firebasestorage.googleapis.com/v0/b/edu-support-f9e95.appspot.com/o/files%2FEduSupport%20Privacy%20Policy%20-%20rev.pdf?alt=media&token=5882655b-27e8-4e50-974d-fc0954d1243f"
            );
          }}
        >
          Privacy policy
        </Button>
      </Box>
      <Box textAlign={"center"}>
        <Button>Developed By Hi-Dev Solutions</Button>
      </Box>
    </>
  );
};

export default TeacherSignin;
