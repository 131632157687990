import { Box } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import {
  TeacherBar,
  AdminBar,
  StudentBar,
  GStudentBar,
  ParentBar,
  SuperAdminBar,
} from "./Sidebar";

const drawerWidth = "260px";

export const TeacherLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className="layout">
      <TeacherBar
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Box
        sx={{
          mt: "12vh",
          marginLeft: { md: `calc(100% - ${drawerWidth} + 10%)` },
          ml: { xs: "2%", md: `calc(${drawerWidth} + 2%)` },
          mr: "2%",
        }}
        className="right-content"
      >
        <Outlet />
      </Box>
    </div>
  );
};

export const StudentLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [quizIcon, setQuizIcon] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className="layout">
      <StudentBar
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Header
        quizIcon={quizIcon}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Box
        sx={{
          mt: "12vh",
          marginLeft: { md: `calc(100% - ${drawerWidth} + 10%)` },
          ml: { xs: "2%", md: `calc(${drawerWidth} + 2%)` },
          mr: "2%",
        }}
        className="right-content"
      >
        <Outlet context={[setQuizIcon]} />
      </Box>
    </div>
  );
};

export const GStudentLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className="layout">
      <GStudentBar
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Box
        sx={{
          mt: "12vh",
          marginLeft: { md: `calc(100% - ${drawerWidth} + 10%)` },
          ml: { xs: "2%", md: `calc(${drawerWidth} + 2%)` },
          mr: "2%",
        }}
        className="right-content"
      >
        <Outlet />
      </Box>
    </div>
  );
};

export const AdminLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className="layout">
      <AdminBar
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        // userData={userData !== null ? userData : null}
      />
      <Box
        sx={{
          mt: "12vh",
          marginLeft: { md: `calc(100% - ${drawerWidth} + 10%)` },
          ml: { xs: "2%", md: `calc(${drawerWidth} + 2%)` },
          mr: "2%",
        }}
        className="right-content"
      >
        <Outlet />
      </Box>
    </div>
  );
};

export const SuperAdminLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className="layout">
      <SuperAdminBar
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        // userData={userData !== null ? userData : null}
      />
      <Box
        sx={{
          mt: "12vh",
          marginLeft: { md: `calc(100% - ${drawerWidth} + 10%)` },
          ml: { xs: "2%", md: `calc(${drawerWidth} + 2%)` },
          mr: "2%",
        }}
        className="right-content"
      >
        <Outlet />
      </Box>
    </div>
  );
};

export const ParentLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className="layout">
      <ParentBar
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Box
        sx={{
          mt: "12vh",
          marginLeft: { md: `calc(100% - ${drawerWidth} + 10%)` },
          ml: { xs: "2%", md: `calc(${drawerWidth} + 2%)` },
          mr: "2%",
        }}
        className="right-content"
      >
        <Outlet />
      </Box>
    </div>
  );
};
