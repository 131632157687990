import React from "react";
import { Grid, Typography, Stack, Card, CardContent } from "@mui/material";
import {
  Person,
  Public,
  School,
  SupervisedUserCircle,
  SupervisorAccount,
} from "@mui/icons-material";

function UserRoleDiv() {
  return (
    <>
      <Grid container sx={{ background: "#EBE8E866" }}>
        <Grid item xs={12} p={5}>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            User Roles
          </Typography>
          <Typography
            variant="subtitle1"
            className="text-gray-900"
            sx={{ textAlign: "center", mt: 2 }}
          >
            Explore a dynamic educational platform designed to cater to diverse
            user roles.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            alignContent={"center"}
            justifyContent={"center"}
            sx={{ px: { xs: 5, lg: 15 }, mb: 4 }}
            spacing={3}
          >
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card
                sx={{
                  borderRadius: "10px",
                  height: "100%",
                  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
                }}
              >
                <CardContent>
                  <Stack
                    spacing={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"column"}
                    sx={{ textAlign: "center" }}
                  >
                    <Person
                      className="icon"
                      sx={{ fontSize: "50pt", color: "#818181" }}
                    />
                    <Typography variant="h5" sx={{ mt: 5, fontWeight: "bold" }}>
                      Admin
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#000000B2",
                      }}
                    >
                      Oversee educational content and user accounts
                      effortlessly.
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card
                sx={{
                  borderRadius: "10px",
                  height: "100%",
                  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
                }}
              >
                <CardContent>
                  <Stack
                    spacing={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"column"}
                    sx={{ textAlign: "center" }}
                  >
                    <SupervisedUserCircle
                      className="icon"
                      sx={{ fontSize: "50pt", color: "#818181" }}
                    />
                    <Typography variant="h5" sx={{ mt: 5, fontWeight: "bold" }}>
                      Teachers
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#000000B2",
                      }}
                    >
                      Facilitate Learning and monitor Students progress with
                      ease.
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card
                sx={{
                  borderRadius: "10px",
                  height: "100%",
                  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
                }}
              >
                <CardContent>
                  <Stack
                    spacing={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"column"}
                    sx={{ textAlign: "center" }}
                  >
                    <School
                      className="icon"
                      sx={{ fontSize: "50pt", color: "#818181" }}
                    />
                    <Typography variant="h5" sx={{ mt: 5, fontWeight: "bold" }}>
                      Students
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#000000B2",
                      }}
                    >
                      Engage in interactive quizzes and spelling bee challenges
                      for both learning and enjoyment.
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card
                sx={{
                  borderRadius: "10px",
                  height: "100%",
                  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
                }}
              >
                <CardContent>
                  <Stack
                    spacing={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"column"}
                    sx={{ textAlign: "center" }}
                  >
                    <Public
                      className="icon"
                      sx={{ fontSize: "50pt", color: "#818181" }}
                    />
                    <Typography variant="h5" sx={{ mt: 5, fontWeight: "bold" }}>
                      Global Students
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#000000B2",
                      }}
                    >
                      Connect with learners wordwide and partake in simulating
                      global challenges
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
                }}
              >
                <CardContent>
                  <Stack
                    spacing={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    direction={"column"}
                    sx={{ textAlign: "center" }}
                  >
                    <SupervisorAccount
                      className="icon"
                      sx={{ fontSize: "50pt", color: "#818181" }}
                    />
                    <Typography variant="h5" sx={{ mt: 5, fontWeight: "bold" }}>
                      Parents
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#000000B2",
                      }}
                    >
                      Stay informed about your child's and ward's academic
                      journey and offer invaluable support
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default UserRoleDiv;
