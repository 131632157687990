import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Stack,
  Modal,
  TextField,
  Fade,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import Api from "../../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../../slices/StateManager";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const FillGapChoice = ({ data, style, lev, open, handleClose, Backdrop }) => {
  const [question, setQuestion] = useState(
    data?.question?.replaceAll("~~", "'")
  );
  const [category, setCategory] = useState(data?.category);
  const [options, setOptions] = useState(data.answers);

  const dispatch = useDispatch();

  const onChangeOption = (index, e) => {
    let getterr = [...options];
    getterr[index] = e.target.value;
    setOptions(getterr);
  };
  const addOptions = () => {
    if (options.length > 5) {
      alert("you cannot add more than 6 options");
      return;
    } else {
      setOptions([...options, ""]);
    }
  };

  const removeOptions = (i) => {
    const list = [...options];
    list.splice(i, 1);
    setOptions(list);
  };

  const onChangeQuestion = (e) => {
    var text = e.target.value.replace("?", " ____ ");
    setQuestion(text);
  };

  let menu = [];
  for (let index = 0; index <= lev?.category?.length; index++) {
    menu.push(
      <MenuItem key={index} value={lev?.category[index]?.text}>
        {lev?.category[index]?.text}
      </MenuItem>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const token = localStorage.getItem("token");
    if (question === "") {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please Type Question!",
          open: true,
          variant: "error",
        })
      );
    } else if ((question.match(/____/g) || []).length !== options.length) {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please make sure options is equal to the number of (?)",
          open: true,
          variant: "error",
        })
      );
    } else {
      let arr = options.map((data) => {
        return data;
      });
      const optionsArr = JSON.stringify(arr).replaceAll("'", "~~");

      const answers = JSON.stringify([]).replaceAll("'", "~~");
      let newValue = question.replaceAll("'", "~~");
      const API = new Api();
      API.updateQuestionNormal(
        token,
        data.id,
        data.quiz_id,
        newValue,
        "fg",
        answers,
        optionsArr,
        category
      )
        .then((res) => {
          if (res.data.message === "Question Updated Successfully!") {
            dispatch(setLoading(false));

            setOptions([...options, ""]);
            setQuestion("");
            setCategory(category);
          }

          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "success",
            })
          );
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component={"form"} onSubmit={handleSubmit} sx={style}>
            <Typography variant="h6" component="h6" sx={{ textAlign: "left" }}>
              Fill the Gap Question
            </Typography>
            <Grid container spacing={4} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
                  <InputLabel id="demo-simple-select-label">
                    Question Section
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Question Section"
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                  >
                    {menu.map((item) => {
                      return item;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  size="small"
                  id="standard-basic"
                  label="Question-> Write (?) to denote fill position"
                  placeholder="write (?) to denote the blank position e.g Ade ? to school"
                  multiline
                  rows={7}
                  value={question || data.question}
                  onChange={(e) => onChangeQuestion(e)}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={1} sx={{ mb: 2 }}>
                  {options.map((x, index) => (
                    <Grid container key={index} spacing={1} sx={{ mb: 2 }}>
                      <Grid item xs={2} md={1}>
                        {" "}
                      </Grid>
                      <Grid item xs={7} md={9}>
                        {" "}
                        <TextField
                          size="small"
                          fullWidth
                          multiline
                          required
                          id="standard-basic"
                          label={`Missing ${index + 1}`}
                          value={options[index]?.replaceAll("~~", "'") || x}
                          onChange={(e) => onChangeOption(index, e)}
                        />
                      </Grid>
                      <Grid item xs={3} md={2}>
                        <Stack direction={"row"} spacing={2}>
                          {options.length !== 1 && (
                            <>
                              <IconButton
                                aria-label="delete"
                                onClick={() => removeOptions(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                          {options.length - 1 === index && (
                            <>
                              <Fab
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={addOptions}
                                sx={{
                                  width: { xs: "50px", sm: "auto" },
                                  height: "40px",
                                  p: 1,
                                }}
                              >
                                <AddIcon />
                              </Fab>
                            </>
                          )}
                        </Stack>{" "}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={4} md={4}></Grid>
              <Grid item md={4}></Grid>
              <Grid item xs={4} md={4}>
                <Stack direction="row" spacing={3}>
                  <Button type="submit" variant="contained" size="large">
                    Save Changes
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default FillGapChoice;
