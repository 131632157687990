import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Button, Typography } from "@mui/material";
import OverviewCard from "../../components/OverviewCard";
import { FaChalkboardTeacher } from "react-icons/fa";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import Api from "../../api/API";
import SkeletonContainer from "../../components/SkeletonContainer";
import { useDispatch } from "react-redux";
import Instruction from "../../components/Instruction";
import { CustomButton } from "../student/StudentOverview";
import { useNavigate } from "react-router";

const TeacherOverview = () => {
  const [opened, setOpened] = useState(false);
  const [quizzes, setQuizzes] = useState(null);
  const [classes, setClasses] = useState(null);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loads, setLoad] = useState({
    loading1: true,
    loading2: true,
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    setRole(localStorage.getItem("role"));
    const API = new Api();
    API.getAllTeacherQuizzes(token)
      .then((res) => {
        setQuizzes(res.data.data);
        setLoad({ loading1: false });
      })
      .catch((err) => {
        setLoad({ loading1: false });
        setQuizzes(null);
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
    API.getAllTeacherClasses(token)
      .then((res) => {
        setClasses(res.data.data);
        setLoad({ loading2: false });
      })
      .catch((err) => {
        setLoad({ loading2: false });
        setClasses(null);
        // dispatch(
        //   setOpen({
        //     message: err.response.data.message,
        //     open: true,
        //     variant: "error",
        //   })
        // );
      });
  }, [dispatch]);

  return (
    <>
      <Instruction opened={opened} setOpened={setOpened} />
      <Stack spacing={6}>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              {loads.loading1 ? (
                <>
                  <SkeletonContainer />
                </>
              ) : (
                <>
                  <OverviewCard
                    icon={() => (
                      <FaChalkboardTeacher size={30} color={"#1976d2"} />
                    )}
                    text={"Quizzes Created"}
                    amount={quizzes ? quizzes.length : 0}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              {loads.loading2 ? (
                <>
                  <SkeletonContainer />
                </>
              ) : (
                <>
                  <OverviewCard
                    icon={() => (
                      <SchoolRoundedIcon
                        sx={{ fontSize: 30 }}
                        color={"primary"}
                      />
                    )}
                    text={"Classes Created"}
                    amount={classes ? classes.length : 0}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box mt={5}>
          <Button onClick={() => setOpened(true)} variant="contained">
            View Instructions
          </Button>
        </Box>
        <Box sx={{ marginBlock: 2 }}>
          <Typography variant="h4">Quick Actions</Typography>
          <Box
            sx={{
              marginBlock: 2,
              display: "grid",
              gap: 2,
              gridTemplateColumns: {
                xs: "1fr",
                sm: "repeat(2,1fr)",
                md: "repeat(2,1fr)",
                lg: "repeat(4,1fr)",
              },
            }}
          >
            <CustomButton
              onClick={() =>
                role === "teacher"
                  ? navigate("/dashboard/teacher/create-class")
                  : navigate("/dashboard/admin/teacher/create-class")
              }
              variant="contained"
              color="success"
            >
              Create Class
            </CustomButton>

            <CustomButton
              onClick={() =>
                role === "teacher"
                  ? navigate("/dashboard/teacher/create-quiz")
                  : navigate("/dashboard/admin/teacher/create-quiz")
              }
              variant="contained"
            >
              CREAT QUIZ / SPELLING BEE
            </CustomButton>

            <CustomButton
              variant="contained"
              onClick={() =>
                role === "teacher"
                  ? navigate("/dashboard/teacher/classes")
                  : navigate("/dashboard/admin/teacher/classes")
              }
            >
              My Classes
            </CustomButton>

            <CustomButton
              onClick={() =>
                role === "teacher"
                  ? navigate("/dashboard/teacher/result")
                  : navigate("/dashboard/admin/teacher/result")
              }
              variant="contained"
              color="success"
            >
              View Results
            </CustomButton>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default TeacherOverview;
