import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { Box, Button, RadioGroup } from "@mui/material";
import { motion } from "framer-motion";
import logo from "../../../images/edu-dark.png";
import React from "react";
import teacher from "../../../images/teacher.png";
import student from "../../../images/graduate.png";
import global from "../../../images/global.png";
import user from "../../../images/user.png";
import { useDispatch } from "react-redux";
import { changePage, changeRole } from "../../../slices/changePage";
import { NavLink, useNavigate } from "react-router-dom";
import RoleCard from "./RoleCard";

function Mainpage() {
  const [role, setRole] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (value) => {
    setRole(value);
    dispatch(changeRole({ role: value }));
  };
  return (
    <>
      <Grid
        container
        mt={5}
        px={{ xs: 2, md: 0 }}
        justifyItems={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} lg={3} md={2}></Grid>
        <Grid item xs={12} lg={6} md={8} mb={3}>
          <Button
            variant="contained"
            sx={{ mb: 1, mt: -3 }}
            onClick={() => navigate("/")}
          >
            Return Home
          </Button>
          <motion.div
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, type: "spring", stiffness: 100 }}
          >
            <Card
              sx={{
                borderRadius: "10px",
                background: "#F9F9F9",
                boxShadow: "0px 0px 3px 1px rgba(203, 203, 203, 1)",
              }}
            >
              <CardContent>
                <Stack direction="column">
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <img src={logo} alt="edusupport" />

                    <Typography>Step 1:</Typography>
                  </Stack>
                  <Box width={"100%"}>
                    <Stack direction="column">
                      <Stack direction={"column"} spacing={3}>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          Sign Up
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "center" }}
                        >
                          Welcome to EduSupport, Sign up now to embark on a
                          journey of knowledge and discover lots of educational
                          resources tailored just for you.
                        </Typography>

                        <Stack
                          spacing={3}
                          direction="row"
                          justifyContent={"center"}
                        >
                          <div class="text-container">
                            <p className="middle-text">Select User Role</p>
                          </div>
                        </Stack>

                        <RadioGroup
                          value={role}
                          onChange={(e) => handleChange(e.target.value)}
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                        >
                          <Grid container spacing={4} px={{ xs: 2, md: 5 }}>
                            <Grid item xs={12} md={6}>
                              <RoleCard
                                role={role}
                                value="admin"
                                onChange={handleChange}
                                imageUrl={user}
                                title="Admin"
                                description="Administrator of an Organisation"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <RoleCard
                                role={role}
                                value="teacher"
                                onChange={handleChange}
                                imageUrl={teacher}
                                title="Teacher"
                                description="Teacher under an Organisation"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <RoleCard
                                role={role}
                                value="student"
                                onChange={handleChange}
                                imageUrl={student}
                                title="Student"
                                description="Student Under an Organisation"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <RoleCard
                                role={role}
                                value="global"
                                onChange={handleChange}
                                imageUrl={global}
                                title="Global Student"
                                description="Student not under an Organisation"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>

                        <Stack direction="row" justifyContent={"space-between"}>
                          <Typography>
                            Already Have An Account?{" "}
                            <NavLink style={{ color: "blue" }} to="/signin">
                              Sign In
                            </NavLink>
                          </Typography>

                          <Button
                            variant="outlined"
                            sx={{
                              color: "black",
                              width: "fit-content",
                            }}
                            onClick={() =>
                              dispatch(
                                changePage({
                                  page: `${
                                    role === "student" || role === "teacher"
                                      ? "second"
                                      : "third"
                                  }`,
                                })
                              )
                            }
                            disabled={role === ""}
                          >
                            Next
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </motion.div>
        </Grid>
        <Grid item xs={12} lg={3} md={2}></Grid>
      </Grid>
    </>
  );
}

export default Mainpage;
