import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { PricingTable, PricingSlot, PricingDetail } from "react-pricing-table";
import Payment from "./payment/Payment";
import Api from "../api/API";

import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalComponent = ({ current, open, handleClose }) => {
  const [email, setEmail] = useState(null);
  const [premium, setPremium] = React.useState(0);
  const [deluxe, setDeluxe] = React.useState(0);
  const [professional, setProfessional] = React.useState(0);
  const [supreme, setSupreme] = React.useState(0);

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "premium") {
      setPremium(value);
    }

    if (name === "deluxe") {
      setDeluxe(value);
    }
    if (name === "professional") {
      setProfessional(value);
    }
    if (name === "supreme") {
      setSupreme(value);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const API = new Api();
    API.getAdminData(token)
      .then((res) => {
        setEmail(res.data.email);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  }, []);
  const config = {
    // reference: new Date().getTime().toString(),
    email: email !== null ? email : "test@gmail.com",
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <PricingTable highlightColor="#1976D2">
            <PricingSlot
              title="FREE"
              highlighted={current === "free" ? true : false}
              priceText="#0/month"
              shouldDisplayButton={false}
            >
              <PricingDetail>
                {" "}
                <b>2 </b> Classes
              </PricingDetail>

              <PricingDetail>
                {" "}
                <b>10</b> Students
              </PricingDetail>
            </PricingSlot>
            <PricingSlot
              shouldDisplayButton={false}
              highlighted={current === "premium" ? true : false}
              title="PREMIUM"
            >
              <PricingDetail>
                {" "}
                <b>Max 100</b> Students
              </PricingDetail>
              <Box m={"10px 0"} p={"15px"} textAlign={"left"}>
                <FormControl sx={{ m: 1 }} size="small">
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Select Term
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="premium"
                    value={premium}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={5500}
                      control={<Radio />}
                      label="₦5,500.00/Monthly"
                    />
                    <FormControlLabel
                      value={10700}
                      control={<Radio />}
                      label="₦10,700.00/Three(3) Months"
                    />
                    <FormControlLabel
                      value={26600}
                      control={<Radio />}
                      label="₦26,600.00/yearly"
                    />
                  </RadioGroup>
                </FormControl>
                <div
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <Payment
                    config={{ ...config, amount: Number(premium) * 100 }}
                    color={current === "premium" ? "primary" : null}
                    plan={"premium"}
                    dur={
                      Number(premium) === 5500
                        ? "1month"
                        : Number(premium) === 10700
                        ? "3month"
                        : Number(premium) === 26600
                        ? "1year"
                        : null
                    }
                  />
                </div>
              </Box>
            </PricingSlot>
            <PricingSlot
              shouldDisplayButton={false}
              highlighted={current === "deluxe" ? true : false}
              title="DELUXE"
            >
              <PricingDetail>
                {" "}
                <b>Max 300</b> Students
              </PricingDetail>

              <Box m={"10px 0"} p={"15px"} textAlign={"left"}>
                <FormControl sx={{ m: 1 }} size="small">
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Select Term
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="deluxe"
                    value={deluxe}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={10700}
                      control={<Radio />}
                      label="₦10,700.00/Monthly"
                    />
                    <FormControlLabel
                      value={23800}
                      control={<Radio />}
                      label="₦23,800.00/Three(3) Months"
                    />
                    <FormControlLabel
                      value={58700}
                      control={<Radio />}
                      label="₦58,700.00/yearly"
                    />
                  </RadioGroup>
                </FormControl>
                <div
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <Payment
                    dur={
                      Number(deluxe) === 10700
                        ? "1month"
                        : Number(deluxe) === 23800
                        ? "3month"
                        : Number(deluxe) === 58700
                        ? "1year"
                        : null
                    }
                    config={{ ...config, amount: Number(deluxe) * 100 }}
                    color={current === "deluxe" ? "primary" : null}
                    plan={"deluxe"}
                  />
                </div>
              </Box>
            </PricingSlot>
            <PricingSlot
              shouldDisplayButton={false}
              highlighted={current === "professional" ? true : false}
              title="PROFESSIONAL"
            >
              <PricingDetail>
                {" "}
                <b>Max 1000</b> Students
              </PricingDetail>
              <Box m={"10px 0"} p={"15px"} textAlign={"left"}>
                <FormControl sx={{ m: 1 }} size="small">
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Select Term
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="professional"
                    value={professional}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={15700}
                      control={<Radio />}
                      label="₦15,700.00/Monthly"
                    />
                    <FormControlLabel
                      value={33800}
                      control={<Radio />}
                      label="₦33,800.00/Three(3) Months"
                    />
                    <FormControlLabel
                      value={102600}
                      control={<Radio />}
                      label="₦102,600.00/yearly"
                    />
                  </RadioGroup>
                </FormControl>
                <div
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <Payment
                    dur={
                      Number(professional) === 15700
                        ? "1month"
                        : Number(professional) === 33800
                        ? "3month"
                        : Number(professional) === 102600
                        ? "1year"
                        : null
                    }
                    config={{ ...config, amount: Number(professional) * 100 }}
                    color={current === "professional" ? "primary" : null}
                    plan={"professional"}
                  />
                </div>
              </Box>
            </PricingSlot>
            <PricingSlot
              shouldDisplayButton={false}
              highlighted={current === "supreme" ? true : false}
              buttonText="SELECT"
              title="SUPREME"
            >
              <PricingDetail>
                {" "}
                <b>Unlimited</b>{" "}
              </PricingDetail>
              <Box m={"10px 0"} p={"15px"} textAlign={"left"}>
                <FormControl sx={{ m: 1 }} size="small">
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Select Term
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="supreme"
                    value={supreme}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={250000}
                      control={<Radio />}
                      label="₦250,000.00/Yearly"
                    />
                  </RadioGroup>
                </FormControl>
                <div
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <Payment
                    config={{ ...config, amount: Number(supreme) * 100 }}
                    color={current === "supreme" ? "primary" : null}
                    plan={"supreme"}
                    dur={`1year`}
                  />
                </div>
              </Box>
            </PricingSlot>
          </PricingTable>

          <Button
            sx={{ ml: "40%", mt: "10%", mb: "30px" }}
            color="error"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalComponent;
