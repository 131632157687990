import { Quiz } from "@mui/icons-material";
import {
	Box,
	Divider,
	FormControl,
	InputLabel,
	Stack,
	Typography,
} from "@mui/material";
import React from "react";
import parse from "html-react-parser";
import { shuffleArray } from "../../../api/constant";
import { useState } from "react";
import { useEffect } from "react";
import SelectMatch from "./SelectMatch";

const MatchImpl = ({ values, setValues, questions }) => {
	const [arr, setArr] = useState([]);
	const [data, setData] = useState([]);
	useEffect(() => {
		if (values !== null && values !== undefined) {
			if (values?.find((a) => a?.id === questions?.id)) {
				setData(values?.find((a) => a?.id === questions?.id)?.options);
			} else {
				setData([]);
			}
		}
	}, [questions, values]);
	const handleChange = (event, index, option, len) => {
		if (values !== null && values !== undefined) {
			if (values?.find((a) => a?.id === questions?.id)) {
				const dd = values?.find((a) => a?.id === questions?.id);
				let newOpt = dd?.options;

				newOpt[index] = { options: option, match: event.target.value };
				const newValues = values?.map((ss) =>
					ss?.id === dd.id ? { ...dd, options: newOpt } : ss
				);
				setValues(newValues);
			} else {
				const arr = new Array(len).fill({ options: "", match: "" });
				arr[index] = {
					options: option,
					match: event.target.value,
				};
				setValues([
					...values,
					{
						id: questions?.id,
						options: arr,
					},
				]);
			}
		}
	};

	const onFocus = (e) => {
		if (questions?.type === "match") {
			setArr(
				shuffleArray(
					questions?.options?.map((data) => {
						return data.match;
					})
				)
			);
		}
	};
	return (
		<>
			<Stack spacing={1}>
				<Quiz color="primary" />
				<Typography variant="h7">
					{parse(questions?.question?.replaceAll("~~", "'"))}
				</Typography>
			</Stack>
			<Box sx={{ width: "100%" }}>
				<Stack spacing={3}>
					{questions?.options?.map((item, index, M) => {
						return (
							<Stack
								key={index}
								spacing={2}
								alignItems="center"
								direction={"row"}
								sx={{
									borderRadius: "10px",
									width: "100%",
									p: "2px",

									backgroundColor: "#dbf3ea",
								}}
							>
								<div
									style={{
										fontSize: 15,
										width: "50%",
										margin: "10px",
										backgroundColor: "#74eebf",
										padding: "10px",
										borderRadius: "10px",
										color: "#0b5742",
									}}
								>
									{item.options}
								</div>

								<FormControl
									variant="filled"
									sx={{
										fontSize: 15,
										width: "50%",
										color: "#0b5742 !important",
									}}
								>
									<InputLabel id="demo-simple-select-filled-label">
										Select Match
									</InputLabel>
									<SelectMatch
										arr={arr}
										onFocus={onFocus}
										M={M}
										data={data}
										index={index}
										handleChange={handleChange}
										item={item}
									/>
								</FormControl>
							</Stack>
						);
					})}
				</Stack>
			</Box>

			<Divider sx={{ mb: 5 }} />
		</>
	);
};

export default MatchImpl;
