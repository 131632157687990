import React, { useState } from "react";
import { Box, ButtonBase } from "@mui/material";

import StudentEditProfile from "../../components/student/StudentEditProfile";
import StudentProfileDetails from "../../components/student/StudentProfileDetails";

const GProfile = () => {
	const [profile, setProfile] = useState(true);
	const style = {
		backgroundColor: "#1976d2",

		p: "1% 2%",
		fontSize: 18,
		color: "#ffffff",
	};
	return (
		<>
			<Box sx={{ display: "flex", columnGap: "10px" }}>
				<ButtonBase
					sx={
						profile
							? style
							: {
									transition: "all ease-in-out  0.2s",
									p: " 1% 2%",

									fontSize: 18,
							  }
					}
					onClick={() => setProfile(true)}
				>
					Profile
				</ButtonBase>
				<ButtonBase
					sx={
						!profile
							? style
							: {
									transition: "all ease-in-out 0.2s",
									p: "1% 2%",
									fontSize: 18,
							  }
					}
					onClick={() => setProfile(false)}
				>
					Edit Profile
				</ButtonBase>
			</Box>

			<Box>{profile ? <StudentProfileDetails /> : <StudentEditProfile />}</Box>
		</>
	);
};

export default GProfile;
