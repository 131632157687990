import React, { useEffect, useState } from "react";
import { Stack, Box, TextField, Paper } from "@mui/material";
import Api from "../../api/API";
import SkeletonContainer from "../../components/SkeletonContainer";
import StudentTable from "../../components/student/StudentTable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const QuizList = () => {
  const [quizzes, setQuizzes] = useState(null);
  const [loads, setLoad] = useState(false);
  const [searched, setSearched] = useState("");

  const [rows, setRows] = useState([]);

  const [filter, setFilter] = React.useState("quiz_name");

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    const API = new Api();
    const token = localStorage.getItem("token");
    setLoad(true);

    API.getStudentQuiz(token)
      .then((res) => {
        if (
          res.data.data !== undefined ||
          res.data.data !== null ||
          res.data.data.length > 0
        ) {
          setQuizzes(
            res.data.data.sort((a, b) => {
              let da = new Date(a.date_created),
                db = new Date(b.date_created);
              return db - da;
            })
          );
        }

        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  }, [setQuizzes]);
  useEffect(() => {
    setRows(quizzes);
  }, [quizzes]);
  const columns = [
    "Quiz Name",
    "Subject",
    "Status",
    "Quiz Type",
    "Date Created",
  ];

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    if (filter === "quiz_name") {
      const filteredRows = quizzes.filter((row) => {
        return row.quiz_name
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "subject") {
      const filteredRows = quizzes.filter((row) => {
        return row.subject?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "quiz_type") {
      const filteredRows = quizzes.filter((row) => {
        return row.quiz_type
          ?.toLowerCase()
          .includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "status") {
      const filteredRows = quizzes.filter((row) => {
        return row.status?.toLowerCase().includes(searchedVal?.toLowerCase());
      });
      setRows(filteredRows);
    }
    if (filter === "date") {
      const filteredRows = quizzes.filter((row) => {
        return row.date_created.includes(searchedVal);
      });
      setRows(filteredRows);
    }
  };

  return (
    <>
      <Box mt={10}>
        <Box
          sx={{
            mt: 3,

            p: "3% 5%",
          }}
        >
          {loads ? (
            <>
              <SkeletonContainer />
            </>
          ) : (
            <>
              <Paper>
                <Stack direction={"row"}>
                  <FormControl sx={{ minWidth: 150 }} size="small">
                    <InputLabel id="demo-select-small">Filter by</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={filter}
                      label="Filter by"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={"quiz_name"}>Quiz Name</MenuItem>
                      <MenuItem value={"status"}>Status</MenuItem>
                      <MenuItem value={"subject"}>Subject</MenuItem>
                      <MenuItem value={"date"}>Date</MenuItem>
                      <MenuItem value={"quiz_type"}>Quiz Type</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`Search by ${filter}`}
                    value={searched}
                    onChange={(event) => requestSearch(event.target.value)}
                  />
                </Stack>
                <StudentTable
                  column={columns}
                  title={"Quiz Available"}
                  data={rows}
                  type="quiz"
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default QuizList;
