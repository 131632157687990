import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import bk from '../../images/bk_faq.png'
import { Grid, Card, CardContent, Stack, Button } from "@mui/material";
import { Add, ArrowCircleRight } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const faqs = [
  {
    question: "Is technical support available for users experincing issues  with EduSupport?",
    answer:
      "Yes, we provide comprehensive technical support for users via email,live chat, and a dedicated support portal to assist users with any issues or queries they may encounter while using EduSupport. "
      },

  {
    question: "Is there a feature for students to review thier answers and learn from the thier mistakes",
    answer:
      "Yes, students can review the quiz answers and explanation for thier correct answers,enabling them to learn from their mistakes and improve thier understanding of the subject matter.",
  },
  {
    question: "Is there a feature for real-time feedback during quizzess or spelling bee tests?",
    answer:
      "Absolutely, our web app provides real-time feedback during quizzess or spelling bee tests, providing students with immediate insights into thier performance and areas for improvement enhancing the learnig experince.",
  },
  {
    question: "Is it possible for students to submit each question as they answered rather than submit all at once after they finished?",
    answer:
      "Edusupport App does not support submitting each question individually. This ensures assessment integrity by preventing unfair advantages and maintains consistency and standardization in the assessment experice for all partiipants ",
  },
  {
    question: "Are there any tutorials or guides available for new users?",
    answer:
      "Yes, we offer comprehensive tutorials and guides accessible from the (Help) section of the app, providing step-by-step instrutions on how to navigate the platform, create tests, and maximize its features.",
  },
  {
    question: "Can students access the App outside of school hours?",
    answer:
      "Yes, students can access the App 24/7 from any internet-enabled device,enabling them to practice quizzes,review materials and engage in learning activities at thier own pace and convienience. ",
  },

  {
    question: "How are edits to a test applied?",
    answer:
      "The edits you make will affect the original test and any instances or copies of that test.",
  },
  {
    question: "What happens to users scores and reponses when a test is edited?",
    answer:
      "If you edit a test that has  already been takn by users, thier scores and responses may be affected by the changes you make ",

  },

  {
    question: "What is a duplicated test?",
    answer:
      "A duplicated test involves creating a new copy of an existing published test ",

  },

  {
    question: "How does duplicating a test differ from editing it?",
    answer:
      "The duplicated test is independent of the original test and any modifications made to it will not impact the original test or any other copies ",
  },
  {
    question: "When is duplicating a test useful?",
    answer:
      "Duplicating a test is useful when you want to create a variations or alternate versions of the same test without affecting the original content or the scores of the users who have already taken the original test.",

  },
  {
    question: "What can be done after duplicating a test?",
    answer:
      "After duplicating a test, you can then make modifications to the duplicated test as needed. ",

  },
  {
    question: "How can i export questions to excel using the EduSupport App?",
    answer:
      "Navigate to the (QUIZZES/SPELLINGS) section. Select the test you would like to export its questions by clicking on the checkbox next to test. Once you have selected the test you want to export , click on (ADD/EDIT QUESTIONS). The various Questions will be displayed.  Click on (EXPORT CSV) button.",

  },
  {
    question: "What happens after cicking the export Csv button?",
    answer:
      "Your browser will download the csv file with the questions you selected.",

  },

  {
    question: "How do i view the exported questions in Excel?",
    answer:
      "Open the downloaded CSV file in excel or any other spreadsheet application. ",

  },
  {
    question: "Can i manipulate the exported questions?",
    answer:
      "Yes, you can manipulate the questions in the exported CSV file as needed including sorting,filtering and formatting.",

  },

];
function FAQDiv() {
  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fa=faqs.slice(0,4)
  

  return (
    <>
    <Grid item xs={12}  sx={{ 

backgroundImage: `url(${bk})`,
backgroundSize: 'cover',
backgroundPosition: 'center',
backgroundRepeat: 'no-repeat',

    }}>
      <Grid
        container
    p={{xs:4,md:5}}
      
      >
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: "40px", 
            
            border: "1px solid #3C4DA7" }}>
            <CardContent sx={{p:0}}>
              <Stack
                spacing={1}
                direction={"column"}
                justifyItems={"center"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  variant="h4"
                  className="gradient-text"
                  sx={{
                    textAlign: "center",
                    mb: 3,
                    mt: 3,
                    fontWeight: "bold",
                    
                  }}
                >
                  Frequently Asked Questions
                </Typography>
                <div>
                  {fa.map((faq, index) => (
                    <Accordion key={index} sx={{  borderBottom: "1px solid #0005A3" }}>
                      <AccordionSummary
                        expandIcon={<Add sx={{ml:2}}/>}
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                      >
                        <Typography color={'red'}>{faq.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="subtitle1">{faq.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>

                <Button
                  variant="outlined"
                  className="gradient-text"
                  size="large"
                  sx={{
                    width: "fit-content",

                    color: "#3C4DA7",
                    borderColor: "#3C4DA7",
                  }}
                  endIcon={<ArrowCircleRight />}
                  onClick={handleClickOpen}
                >
                  More FAQ
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}></Grid>
      </Grid>
</Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
    
        onClose={handleClose}
        
     
      >
        <DialogTitle className="gradient-text">Frequently Asked Questions</DialogTitle>
        <DialogContent sx={{p:0}} >
        {faqs.slice(4).map((faq, index) => (
                    <Accordion key={index} sx={{ p: 1, borderBottom: "1px solid #0005A3" }}>
                      <AccordionSummary
                        expandIcon={<Add style={{ml:2}}/>}
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                      >
                        <Typography color={'red'}>{faq.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="subtitle1">{faq.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            size="large"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FAQDiv;
