import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const SelectMatch = ({ data, index, item, M, handleChange, onFocus, arr }) => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    data !== null && data !== undefined && data?.length > 0 && setLoad(true);
  }, [data]);

  return (
    <>
      {load && (
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={data[index]?.match || ""}
          onFocus={onFocus}
          autoFocus
          onChange={(e) => handleChange(e, index, item?.options, M?.length)}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: "white",
                "& .MuiMenuItem-root": {
                  padding: 2,
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word !important",
                  overflowWrap: " break-word !important",
                },
              },
            },
          }}
        >
          {arr.map((dt, index) => {
            return (
              <MenuItem key={index} value={dt}>
                {dt}
              </MenuItem>
            );
          })}
        </Select>
      )}
      {!load && (
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={data[index]?.match}
          onFocus={onFocus}
          onChange={(e) => handleChange(e, index, item?.options, M?.length)}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: "white",
                "& .MuiMenuItem-root": {
                  padding: 2,
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word !important",
                  overflowWrap: " break-word !important",
                },
              },
            },
          }}
        >
          {arr.map((dt, index) => {
            return (
              <MenuItem key={index} value={dt}>
                {dt}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </>
  );
};

export default SelectMatch;
