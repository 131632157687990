import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Radio,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import Api from "../../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../../slices/StateManager";
const TrueOrFalseChoice = ({ data, setQuestions }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [question, setQuestion] = useState("");
  const [category, setCategory] = useState(
    localStorage.getItem("t") || data?.category[0]?.text
  );
  const dispatch = useDispatch();
  const onChangeRadio = (e) => {
    setSelectedValue(e.target.value);
  };

  const onChangeQuestion = (e) => {
    setQuestion(e.target.value);
  };
  let menu = [];
  for (let index = 0; index <= data?.category?.length; index++) {
    menu.push(
      <MenuItem key={index} value={data?.category[index]?.text}>
        {data?.category[index]?.text}
      </MenuItem>
    );
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const token = localStorage.getItem("token");
    if (question === "") {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please Type Question!",
          open: true,
          variant: "error",
        })
      );
    } else if (selectedValue === null) {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please select the correct option",
          open: true,
          variant: "error",
        })
      );
    } else {
      const options = JSON.stringify([
        e.target.true.value,
        e.target.false.value,
      ]);

      const answers = JSON.stringify([selectedValue]);

      const API = new Api();
      let newValue = question.replaceAll("'", "~~");
      API.setQuestion(
        token,
        data.quiz_id,
        newValue,
        "tf",
        options,
        answers,
        category
      )
        .then((res) => {
          if (res.data.message === "Question Added Successfully!") {
            setQuestions(res.data.data);
            setSelectedValue(null);
            setQuestion("");
            setCategory(localStorage.getItem("t"));
          }
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "success",
            })
          );
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };
  return (
    <>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Typography variant="h6" component="h6" sx={{ textAlign: "left" }}>
          True or False Question
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={12}>
            <FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
              <InputLabel id="demo-simple-select-label">
                Question Section
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Question Section"
                onChange={(e) => {
                  setCategory(e.target.value);
                  localStorage.setItem("t", e.target.value);
                }}
                value={category}
              >
                {menu.map((item) => {
                  return item;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              size="small"
              fullWidth
              id="standard-basic"
              label="Question"
              multiline
              rows={4}
              value={question}
              onChange={(e) => onChangeQuestion(e)}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={2} md={1}>
                {" "}
                <Radio
                  size="small"
                  checked={selectedValue === "true"}
                  value={"true"}
                  name={`Option1`}
                  onClick={onChangeRadio}
                />
              </Grid>
              <Grid item xs={7} md={9} sm={8}>
                {" "}
                <TextField
                  size="small"
                  fullWidth
                  id="standard-basic"
                  label="Option 1"
                  name="true"
                  value="True"
                />
              </Grid>
              <Grid item xs={3} md={2} sm={3}></Grid>
            </Grid>

            <Grid container spacing={4} sx={{ mb: 2 }}>
              <Grid item xs={2} md={1}>
                {" "}
                <Radio
                  size="small"
                  checked={selectedValue === "false"}
                  value={"false"}
                  name={`Option1`}
                  onClick={onChangeRadio}
                />
              </Grid>
              <Grid item xs={7} md={9}>
                {" "}
                <TextField
                  size="small"
                  fullWidth
                  id="standard-basic"
                  label="Option 2"
                  name="false"
                  value="False"
                />
              </Grid>
              <Grid item xs={3} md={2}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={4} md={4}></Grid>
          <Grid item md={4}></Grid>
          <Grid item xs={4} md={4}>
            <Button type="submit" variant="contained" size="large">
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TrueOrFalseChoice;
