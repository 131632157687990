import React from "react";

import { Box, Backdrop, Button, Modal, Fade, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95vw", sm: "90vw", md: "fit-content" },
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};

const Instruction = ({ opened, setOpened }) => {
  const role = localStorage.getItem("role");

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opened}
        onClose={() => setOpened(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opened}>
          <Box className="class-view" sx={style}>
            <Box
              sx={{
                position: "sticky",
                bgcolor: "background.paper",
                padding: "10px",
                zIndex: 100,
                borderBottom: "1px solid #1976d2",
                marginTop: "0px",
                top: 0,
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpened(false)}
              >
                close
              </Button>
            </Box>
            <Box
              sx={{
                overflowY: "auto",
                height: "80vh",
              }}
            >
              {role === "admin" ? (
                <Box p={4}>
                  <Typography
                    textAlign={"center"}
                    sx={{ mt: 3, fontWeight: "bold" }}
                    variant="h5"
                  >
                    USAGE INSTRUCTIONS
                  </Typography>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Sidebar
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          On devices with small width, the sidebar is hidden.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography
                          sx={{ mt: 3, display: "flex" }}
                          fontSize={18}
                          variant="p"
                        >
                          Click the &nbsp;
                          <MenuIcon sx={{ alignSelf: "center" }} />
                          &nbsp; icon to open the sidebar.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Overview Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          This page shows an overview of{" "}
                          <b>Users, Test and Classes</b> that have signed up
                          using your <b>ADMIN ID</b>.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          It shows your <b>Subscription</b> details.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can change your <b>Subscription</b> from this
                          page.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Teacher's Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the list of Teachers who
                          have signed up using your <b>ADMIN ID</b>.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can view thier <b>Profile</b> and <b>Delete</b>{" "}
                          them.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can also <b>export</b> the list.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Student's Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the list of Students who
                          have signed up using your <b>ADMIN ID</b>.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can view thier <b>Profile</b> and <b>Delete</b>{" "}
                          them.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can also <b>export</b> the list.
                        </Typography>
                      </li>
                    </ul>
                  </Box>

                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Quizzes/Spellings Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the list of{" "}
                          <b>Quzzies/Spelling Test</b> created by the teachers
                          in your organisation.
                        </Typography>
                      </li>

                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can view the parameters of each Test
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Classes Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the list of <b>Classes</b>{" "}
                          created by the teachers in your organisation.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          Classes are used to collate students' names who will
                          be eligible to take a particluar Quiz/Spelling Test.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can view the parameters of each class
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          To <b>invite students</b> to join a class, you can{" "}
                          <b>Share</b> the <b>Class ID</b> to the students via{" "}
                          <b>Whatsapp</b> or <b>Emaill</b> by Clicking the Icon
                          that indicate the channel.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Profile Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, your <b>profile details</b> are
                          displayed.
                        </Typography>
                      </li>

                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          you can share your <b>Admin ID</b> via <b>Whatsapp</b>
                          <b>Email</b> from this page.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          To <b>invite a teacher or student</b> to join the
                          organisation, share your <b>Admin ID</b> via{" "}
                          <b>Whatsapp</b> or
                          <b>Email</b> by clicking the button that indicates the
                          channel for sharing.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Edit</b> your profile from this page
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Change</b> your Password from this page
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Quiz/Spelling Result Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the <b>Results</b> of the
                          Test that has been taken by <b>Students</b> in your
                          organisation.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>View Answers</b> of each student.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Delete</b> the result of a student.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Teacher Operations
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          To Create Classes and Set Questions, simply click on
                          the
                          <b> SIDEBAR</b> located on the left side of the screen
                          and click on <b>TEACHERS FUNCTIONALITIES</b>.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Box>
              ) : role === "teacher" ? (
                <Box p={4}>
                  <Typography
                    textAlign={"center"}
                    sx={{ mt: 3, fontWeight: "bold" }}
                    variant="h5"
                  >
                    USAGE INSTRUCTIONS
                  </Typography>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Sidebar
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          On devices with small width, the sidebar is hidden.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography
                          sx={{ mt: 3, display: "flex" }}
                          fontSize={18}
                          variant="p"
                        >
                          Click the &nbsp;
                          <MenuIcon sx={{ alignSelf: "center" }} />
                          &nbsp; icon to open the sidebar.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Overview Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          This page shows an overview of <b>Quizzes/Spelling</b>{" "}
                          Tests and <b>Classes</b> you have created.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can create a <b>Class</b> from this page
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          Classes are used to collate students' names who will
                          be eligible to take a particluar Quiz/Spelling Test.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Create Quiz/Spelling Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can <b>Create</b> a <b>Quiz</b> or{" "}
                          <b>Spelling Bee</b> Tests.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You assign class/classes to Tests which will enable
                          any student that has joined that class to take the
                          Quiz/Spelling Test.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Quizzes/Spellings Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the list of{" "}
                          <b>Quzzies/Spelling Test</b> you have created.
                        </Typography>
                      </li>

                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>View</b> the parameters of each Test.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Edit</b> the parameters of each Test.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Add Questions</b> to a Test.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Delete</b> a Test.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Classes Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the list of <b>Classes</b>{" "}
                          you have created.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          Classes are used to collate students names who will be
                          eligible to take a particluar Quiz/Spelling Test.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>View</b> the parameters of each Class.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Edit</b> the parameters of each Class.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Delete</b> a Class.
                        </Typography>
                      </li>

                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          To <b>Invite students to join a class</b>, You can{" "}
                          <b>Share</b> the Class ID to students via{" "}
                          <b>Whatsapp</b> or <b>Emaill</b> by Clicking the Icon
                          that indicate the channel.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Student in Class Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the list of Students who has
                          joined any of your classes using the <b>Class ID</b>.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can view the Student's <b>Profile</b> and{" "}
                          <b>Remove</b> them from the class.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can also <b>export</b> the list.
                        </Typography>
                      </li>
                    </ul>
                  </Box>

                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Profile Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, your <b>profile details</b> are
                          displayed.
                        </Typography>
                      </li>

                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Edit</b> your profile from this page
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Change</b> your Password from this page
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Quiz/Spelling Result Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the <b>Results</b> of the
                          Test that has been taken by <b>Students</b>.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>View Answers</b> of each student.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Box>
              ) : role === "student" ? (
                <Box p={4}>
                  <Typography
                    textAlign={"center"}
                    sx={{ mt: 3, fontWeight: "bold" }}
                    variant="h5"
                  >
                    USAGE INSTRUCTIONS
                  </Typography>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Sidebar
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          On devices with small width, the sidebar is hidden.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography
                          sx={{ mt: 3, display: "flex" }}
                          fontSize={18}
                          variant="p"
                        >
                          Click the &nbsp;
                          <MenuIcon sx={{ alignSelf: "center" }} />
                          &nbsp; icon to open the sidebar.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Overview Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          This page shows an overview of <b>Quizzes/Spelling</b>{" "}
                          Tests available for you to take as well as the Test
                          yet to be taken.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          This page also shows an overview of <b>Classes</b>{" "}
                          Joined and number of <b>Results</b> from quiz taken.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          A list of <b>Results</b> for each <b>Quiz/Spelling</b>{" "}
                          is also displayed.
                        </Typography>
                      </li>
                    </ul>
                  </Box>

                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Classes Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the list of <b>Classes</b>{" "}
                          you have joined.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Join</b> a Class using the <b>Class ID</b>{" "}
                          sent by your Teachers or Organisation Admin.
                        </Typography>
                      </li>

                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Leave</b> a Class.
                        </Typography>
                      </li>
                    </ul>
                  </Box>

                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Profile Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, your <b>profile details</b> are
                          displayed.
                        </Typography>
                      </li>

                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Edit</b> your profile from this page
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Change</b> your Password from this page
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ mt: 3, fontWeight: "bold" }} variant="h5">
                      Take Quiz/Spelling Bee Page
                    </Typography>
                    <ul style={{ marginLeft: 30 }}>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          In this page, you can see the <b>Quizzes/Spelling</b>{" "}
                          Tests that are available for you based on the classes
                          you have joined.
                        </Typography>
                      </li>
                      <li className="list">
                        <Typography sx={{ mt: 3 }} fontSize={18} variant="p">
                          You can <b>Take a Test</b> by clicking the take test
                          button when you have selected a Test.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Instruction;
