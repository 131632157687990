import { Box, Button, Typography, Grid } from "@mui/material";
import React from "react";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import logo from "././../../images/edu-dark.png";
import "./login.css";
import Footer from "./Footer";
import { Outlet, Route, Routes } from "react-router";
import Home from "./home";
import About from "./about";
import Contact from "./contact";
import { Link, NavLink, useLocation } from "react-router-dom";

const drawerWidth = 240;

function Login(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={logo} alt="logo" />
      <Divider />
      <List>
        <ListItem disablePadding>
          <Link to="/">
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Home" />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/about">
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="About Us" />
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/contact">
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Contact" />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Grid container>
        <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
          <AppBar
            component="nav"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 0px 0px  0px",
              borderBottom: "6px solid  #3C4DA7",
              maxWidth: "1450px",
              marginLeft: "auto",
              marginRight: "auto",
              right: "auto",
            }}
          >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <img src={logo} alt="logo" />

              <Box
                sx={{
                  display: { sm: "flex" },
                  flexDirection: "row",
                  gap: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#3C4DA7",
                }}
              >
                <a href="/" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={`${
                      pathname === "/" ? "gradient-text text-bold" : ""
                    }`}
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", sm: "none", md: "block" },
                      fontWeight: `${pathname === "/" ? "bold" : "normal"}`,
                    }}
                  >
                    Home
                  </Typography>
                </a>
                <a href="/about" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={`${
                      pathname === "/about" ? "gradient-text text-bold" : ""
                    }`}
                    sx={{
                      flexGrow: 1,
                      fontWeight: `${
                        pathname === "/about" ? "bold" : "normal"
                      }`,
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  >
                    About Us
                  </Typography>
                </a>
                <a href="/contact" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    className={`${
                      pathname === "/contact" ? "gradient-text text-bold" : ""
                    }`}
                    sx={{
                      flexGrow: 1,
                      fontWeight: `${
                        pathname === "/contact" ? "bold" : "normal"
                      }`,
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  >
                    Contact
                  </Typography>
                </a>
                <NavLink to="/signin" style={{ textDecoration: "none" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      flexGrow: 1,
                      mx: 2,
                      color: "#3C4DA7",
                      borderColor: " #3C4DA7",
                    }}
                  >
                    Sign In
                  </Button>
                </NavLink>
                <NavLink to="/signup" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", sm: "none", md: "block" },
                      background:
                        "linear-gradient(90deg, #171E41 0%, #3C4DA7 100%)",
                    }}
                  >
                    Sign Up
                  </Button>
                </NavLink>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    color: "#3C4DA7",
                    mr: 2,
                    display: { md: "none", lg: "none" },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>

          <Drawer
            container={container}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>

          <Routes>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Grid>

        <Outlet />

        <Footer />
      </Grid>
    </>
  );
}

Login.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Login;
