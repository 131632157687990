import { Alert } from "@mui/material";
import { useState, useEffect } from "react";

const AutoLogout = ({ handleIdleLogout, logoutTime = 600 }) => {
  const [seconds, setSeconds] = useState(logoutTime);
  const [show, setShow] = useState(false);

  useEffect(() => {
    let interval;

    const resetTimer = () => {
      setSeconds(logoutTime);
    };

    const tick = () => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    };

    const activityHandler = () => {
      resetTimer();
      setShow(false);
    };

    interval = setInterval(tick, 1000);

    window.addEventListener("mousemove", activityHandler);
    window.addEventListener("click", activityHandler);
    window.addEventListener("keydown", activityHandler);
    window.addEventListener("touchstart", activityHandler);
    window.addEventListener("touchend", activityHandler);
    window.addEventListener("scroll", activityHandler);

    return () => {
      clearInterval(interval);
      window.removeEventListener("mousemove", activityHandler);
      window.addEventListener("click", activityHandler);
      window.removeEventListener("keydown", activityHandler);
      window.removeEventListener("touchstart", activityHandler);
      window.removeEventListener("touchend", activityHandler);
      window.removeEventListener("scroll", activityHandler);
    };
  }, [logoutTime]);

  useEffect(() => {
    if (seconds <= 120) {
      setShow(true);
    }

    if (seconds === 0) {
      handleIdleLogout();
    }
  }, [seconds, handleIdleLogout]);
  return (
    show && (
      <Alert
        severity="warning"
        variant="filled"
        sx={{
          zIndex: 9999,
          position: "fixed",
          margin: "auto",
          left: 0,
          right: 0,
          top: 2,
          width: { sm: 460 },
        }}
      >
        {`You will be automatically logged out in ${
          seconds > 60 ? (seconds / 60).toFixed(0) : seconds
        } ${seconds > 60 ? "minute(s)" : "second(s)"} dues to inactivity`}
      </Alert>
    )
  );
};
export default AutoLogout;
