import React, { useState } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Views from "../Views";
import moment from "moment";
import { Button, Stack } from "@mui/material";
import { CSVLink } from "react-csv";

const SuperAdminTable = ({ title, data, column, type }) => {
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [open, setOpen] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const errorText =
		"No data to display or Something could be wrong with your internet connection.";
	const EnhancedTableToolbar = ({ itemSelected }) => {
		let item_name;
		if (type === "teacher") {
			item_name = itemSelected.teacher_id;
		} else if (type === "student") {
			item_name = itemSelected.student_id;
		} else if (type === "admin") {
			item_name = itemSelected.admin_id;
		}

		return (
			<Toolbar
				sx={{
					position: item_name ? "sticky" : "initial",
					top: "68px",
					pl: { sm: 2 },
					paddingY: { xs: 1, sm: 0 },
					pr: { xs: 1, sm: 1 },
					zIndex: 2,
					...(item_name && {
						bgcolor: (theme) => alpha("#7CB9E8", 1),
					}),
				}}
			>
				{item_name ? (
					<Typography
						sx={{ flex: "1 1 100%" }}
						color="inherit"
						variant="subtitle1"
						component="div"
						display={{ xs: "none", sm: "block" }}
					>
						selected
					</Typography>
				) : (
					<Typography
						sx={{ flex: "1 1 100%" }}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						{title}
					</Typography>
				)}

				{item_name ? (
					<Box sx={{ display: "flex", columnGap: 2 }}>
						<Stack direction={"row"} spacing={1}>
							<Tooltip title="View">
								<Button variant="contained" onClick={handleOpen}>
									View
								</Button>
							</Tooltip>
						</Stack>
					</Box>
				) : null}
			</Toolbar>
		);
	};

	const handleClick = (event, item) => {
		if (type === "teacher") {
			if (selected.teacher_id === item.teacher_id) {
				setSelected([]);
			} else {
				setSelected(item);
			}
		} else if (type === "student") {
			if (selected.student_id === item.student_id) {
				setSelected([]);
			} else {
				setSelected(item);
			}
		} else if (type === "admin") {
			if (selected.admin_id === item.admin_id) {
				setSelected([]);
			} else {
				setSelected(item);
			}
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	let isSelected;
	if (type === "teacher") {
		isSelected = (teacher_id) => selected.teacher_id === teacher_id;
	} else if (type === "student") {
		isSelected = (student_id) => selected.student_id === student_id;
	} else if (type === "admin") {
		isSelected = (admin_id) => selected.admin_id === admin_id;
	}
	var date = new Date();
	return (
		<>
			<Views
				open={open}
				handleClose={handleClose}
				data={selected.length === 0 ? null : selected}
				type={type}
			/>
			<Box sx={{ width: "100%" }}>
				<Paper sx={{ width: "100%", mb: 2 }}>
					<Button
						variant="contained"
						color="primary"
						sx={{
							ml: "38%",
							mb: 1,
							mt: 2,
						}}
					>
						<CSVLink
							data={data || []}
							filename={`data-${date}.csv`}
							className="export"
							target="_blank"
						>
							Export Csv
						</CSVLink>
					</Button>

					<EnhancedTableToolbar itemSelected={selected} />
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size={"medium"}
						>
							<TableHead>
								<TableRow>
									<TableCell padding="checkbox"></TableCell>
									{column.map((col) => (
										<TableCell key={col} align={"left"} padding={"normal"}>
											<TableSortLabel>{col}</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							{type === "student" ? (
								<TableBody>
									{data !== null && data.length !== 0 ? (
										data
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((d, index) => {
												const isItemSelected = isSelected(d.student_id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														onClick={(event) => handleClick(event, d)}
														role="checkbox"
														aria-checked={isItemSelected}
														key={d.student_id}
														selected={isItemSelected}
													>
														<TableCell padding="checkbox">
															<Checkbox
																color="primary"
																checked={isItemSelected}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>

														<TableCell padding="normal" align="left">
															{d.firstname}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d.lastname}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d.username}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d.email}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d?.paid === "true" ? "Yes" : "No"}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d?.plan}
														</TableCell>
														<TableCell padding="normal" align="left">
															{moment(d?.date_sub).format("DD-MM-YYYY")}
														</TableCell>
														<TableCell padding="normal" align="left">
															{moment(d?.date_end).format("DD-MM-YYYY")}
														</TableCell>
													</TableRow>
												);
											})
									) : (
										<TableRow>
											<TableCell colSpan={4} padding="normal" align="left">
												{errorText}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							) : type === "teacher" ? (
								<TableBody>
									{data !== null && data.length !== 0 ? (
										data
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((d, index) => {
												const isItemSelected = isSelected(d.teacher_id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														onClick={(event) => handleClick(event, d)}
														role="checkbox"
														aria-checked={isItemSelected}
														key={d.teacher_id}
														selected={isItemSelected}
													>
														<TableCell padding="checkbox">
															<Checkbox
																color="primary"
																checked={isItemSelected}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>

														<TableCell padding="normal" align="left">
															{d.firstname}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d.lastname}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d.username}
														</TableCell>

														<TableCell padding="normal" align="left">
															{d.email}
														</TableCell>

														<TableCell padding="normal" align="left">
															{moment(d.date_created).format("DD-MM-YYYY")}
														</TableCell>
													</TableRow>
												);
											})
									) : (
										<TableRow>
											<TableCell colSpan={4} padding="normal" align="left">
												{errorText}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							) : type === "admin" ? (
								<TableBody>
									{data !== null && data.length !== 0 ? (
										data
											.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)
											.map((d, index) => {
												const isItemSelected = isSelected(d.admin_id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														onClick={(event) => handleClick(event, d)}
														role="checkbox"
														aria-checked={isItemSelected}
														key={d.admin_id}
														selected={isItemSelected}
													>
														<TableCell padding="checkbox">
															<Checkbox
																color="primary"
																checked={isItemSelected}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>

														<TableCell padding="normal" align="left">
															{d?.firstname}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d?.lastname}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d?.username}
														</TableCell>

														<TableCell padding="normal" align="left">
															{d?.email}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d?.paid === "true" ? "Yes" : "No"}
														</TableCell>
														<TableCell padding="normal" align="left">
															{d?.plan}
														</TableCell>
														<TableCell padding="normal" align="left">
															{moment(d?.date_sub).format("DD-MM-YYYY")}
														</TableCell>
														<TableCell padding="normal" align="left">
															{moment(d?.date_end).format("DD-MM-YYYY")}
														</TableCell>
													</TableRow>
												);
											})
									) : (
										<TableRow>
											<TableCell colSpan={4} padding="normal" align="left">
												{errorText}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							) : null}
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 20, 30, 40, 50]}
						component="div"
						count={data ? data.length : 0}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			</Box>
		</>
	);
};

export default SuperAdminTable;
