import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Radio,
  TextField,
  Typography,
  FormControl,
  Stack,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Api from "../../../api/API";
import { useDispatch } from "react-redux";
import { setLoading, setOpen } from "../../../slices/StateManager";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const SingleChoice = ({ data, setQuestions }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [ckValue, setCkvalue] = useState("");
  const [options, setOptions] = useState([{ options: "" }]);
  const [category, setCategory] = useState(
    localStorage.getItem("c") || data?.category[0]?.text
  );
  const dispatch = useDispatch();

  const addOptions = () => {
    if (options.length > 5) {
      alert("you cannot add more than 6 options");
      return;
    } else {
      setOptions([...options, { options: "" }]);
    }
  };

  const removeOptions = (i) => {
    const list = [...options];
    list.splice(i, 1);
    setOptions(list);
  };

  const onChangeOption = (index, e) => {
    let getterr = [...options];
    getterr[index].options = e.target.value;
    setOptions(getterr);
  };

  const onChangeRadio = (e) => {
    e.target.value.length > 0 && setSelectedValue(e.target.value);
  };

  let menu = [];
  for (let index = 0; index <= data?.category?.length; index++) {
    menu.push(
      <MenuItem key={index} value={data?.category[index]?.text}>
        {data?.category[index]?.text}
      </MenuItem>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const token = localStorage.getItem("token");

    const value = ckValue;
    if (selectedValue === null) {
      dispatch(setLoading(false));
      dispatch(
        setOpen({
          message: "Please select the correct option",
          open: true,
          variant: "error",
        })
      );
    } else {
      let arr = options.map((data) => {
        return data.options;
      });
      const optionsArr = JSON.stringify(arr).replaceAll("'", "~~");
      const answers = JSON.stringify([selectedValue]).replaceAll("'", "~~");
      const API = new Api();

      let newValue = value.replaceAll("'", "~~");
      API.setQuestion(
        token,
        data.quiz_id,
        newValue,
        "sc",
        optionsArr,
        answers,
        category
      )
        .then((res) => {
          if (res.data.message === "Question Added Successfully!") {
            dispatch(setLoading(false));
            setQuestions(res.data.data);
            setSelectedValue(null);
            setOptions([...options, { options: "" }]);
            setCkvalue("");
            setCategory(localStorage.getItem("c"));
          }

          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: res.data.message,
              open: true,
              variant: "success",
            })
          );
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setOpen({
              message: err.response.data.message,
              open: true,
              variant: "error",
            })
          );
        });
    }
  };
  const editorConfiguration = {
    removePlugins: ["Heading", "Link", "CKFinder", "Alignment"],
  };
  return (
    <>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Typography variant="h6" component="h6" sx={{ textAlign: "left" }}>
          Single Choice Question
        </Typography>
        <Grid container spacing={4} sx={{ mt: 1 }}>
          <Grid item xs={12} md={12}>
            <FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
              <InputLabel id="demo-simple-select-label">
                Question Section
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Question Section"
                onChange={(e) => {
                  setCategory(e.target.value);
                  localStorage.setItem("c", e.target.value);
                }}
                value={category}
              >
                {menu.map((item) => {
                  return item;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <CKEditor
              config={editorConfiguration}
              editor={DecoupledEditor}
              onReady={(editor) => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
              }}
              data={ckValue}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCkvalue(data);
              }}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              {options.map((x, index) => (
                <Grid container key={index} spacing={1} sx={{ mb: 2 }}>
                  <Grid item xs={2} md={1}>
                    {" "}
                    <Radio
                      size="small"
                      checked={selectedValue === options[index].options}
                      value={options[index].options}
                      onClick={onChangeRadio}
                    />
                  </Grid>
                  <Grid item xs={6} md={9} sm={5}>
                    {" "}
                    <TextField
                      size="small"
                      fullWidth
                      multiline
                      required
                      id="standard-basic"
                      label={`Option ${index + 1}`}
                      value={options[index].options}
                      onChange={(e) => onChangeOption(index, e)}
                    />
                  </Grid>
                  <Grid item xs={3} md={2} sm={5}>
                    <Stack direction={"row"}>
                      {options.length !== 1 && (
                        <>
                          <IconButton
                            aria-label="delete"
                            onClick={() => removeOptions(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                      {options.length - 1 === index && (
                        <>
                          <Fab
                            size="small"
                            color="primary"
                            aria-label="add"
                            onClick={addOptions}
                            sx={{
                              width: { xs: "50px", sm: "auto" },
                              height: "40px",
                              p: 1,
                            }}
                          >
                            <AddIcon />
                          </Fab>
                        </>
                      )}
                    </Stack>{" "}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={4} md={4}></Grid>
          <Grid item md={4}></Grid>
          <Grid item xs={4} md={4}>
            <Button type="submit" variant="contained" size="large">
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SingleChoice;
