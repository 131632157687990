import React from "react";

import { Paper, Stack, Typography, Box, Grid, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const EndScreen = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const role = localStorage.getItem("role");

  return (
    <>
      <Box>
        <Grid container>
          <Grid item md={4}></Grid>
          <Grid item md={4} sx={{ mt: 10 }}>
            <Paper elevation={2}>
              <Stack spacing={2} sx={{ p: 7 }}>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  Below Is Your Result!
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Point:&nbsp;<b>{state?.data?.point}</b> Points
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Right Answers:&nbsp;<b>{state?.data?.right_answers}</b>
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Wrong Answers:&nbsp;<b>{state?.data?.wrong_answers}</b>
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Remark:&nbsp;<b>{state?.data?.status}</b>
                </Typography>
                <Typography sx={{ textAlign: "center" }}>
                  Percentage:&nbsp;<b>{state?.data?.percent}%</b>
                </Typography>
                <Typography sx={{ textAlign: "center" }}>
                  Time Taken:&nbsp;<b>{state?.data?.time_taken}</b>
                </Typography>

                <Button
                  variant="contained"
                  onClick={() => {
                    if (role === "student") {
                      navigate("/dashboard/student");
                    } else if (role === "gstudent") {
                      navigate("/dashboard/gstudent");
                    }
                  }}
                >
                  Click here to go homepage
                </Button>
              </Stack>
            </Paper>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EndScreen;
